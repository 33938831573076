import React, { createContext, RefObject, useRef } from 'react';

type ConsultationReasonsTimeSlotRef = {
  time: Date;
  getTime: Date;
  set: (time: Date, keyAgenda: string) => void;
  unset: () => void;
};

export const ConsultationReasonsTimeslotRefContext = createContext<
  RefObject<ConsultationReasonsTimeSlotRef | null>
>({ current: null });

type Props = {
  children: any;
};

const ConsultationReasonsTimeslotRefContextProvider = (props: Props) => {
  const { children } = props;
  const ref = useRef<ConsultationReasonsTimeSlotRef | null>(null);

  return (
    <ConsultationReasonsTimeslotRefContext.Provider value={ref}>
      {children}
    </ConsultationReasonsTimeslotRefContext.Provider>
  );
};

export default ConsultationReasonsTimeslotRefContextProvider;
