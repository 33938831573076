import React from 'react';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';

import { stringify } from 'querystring';

import { useTranslation } from '../../../i18n';
import { useCenterId, usePractitionerId } from '../../../src/hooks/selectors';

import { BillingNotificationCenter } from '../../../types/pro.types';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Typography from '../../atoms/Typography';
import NotificationWrapper from './NotificationWrapper';

import {
  InternetPatientIcon,
  PatientLabelField,
  PatientName,
} from '@/components/molecules/PatientLabel/PatientLabel';
import { GAActions, GAActionStatus, GALabels } from '@/src/constants/tracking';

const useStyles = makeStyles()((theme: any) => ({
  notificationFacturationRoot: {
    backgroundColor: theme.palette.grey[200],
    transition: 'all 0.2s ease',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
      borderTop: `1px solid ${theme.palette.grey[400]}`,
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
  },
}));

type NotificationFacturationViewProps = {
  notification: BillingNotificationCenter;
  closeModal: () => void;
  triggerGAEvent: Function;
};

const NotificationFacturationView: React.FunctionComponent<NotificationFacturationViewProps> = props => {
  const { notification, closeModal, triggerGAEvent } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation('common');
  const router = useRouter();
  const practitionerId = usePractitionerId();
  const centerId = useCenterId();
  const { paymentInformation } = notification.displayData;
  const today = moment();
  const newPathName = `/transactions?${stringify({
    practitionerId,
    centerId,
  })}`;
  const dayLeft = moment(paymentInformation?.expirationDate).diff(
    today,
    'days',
  );
  const handleChangeView = () => {
    triggerGAEvent(
      GAActions.ClickNotification,
      GAActionStatus.SUCCESS,
      GALabels.BillingNotification,
    );
    closeModal();
    router.push(newPathName);
  };

  return (
    <NotificationWrapper
      notification={notification}
      className={cx(
        'notificationFacturationRoot',
        classes.notificationFacturationRoot,
      )}
      onClick={handleChangeView}
    >
      <Typography variant="subtitle" className="notificationTitle">
        {t('notification_facturation_reminder')}
      </Typography>
      <Typography className="link">
        {dayLeft >= 2
          ? t('notification_facturation_leftdays', {
              number: dayLeft,
              maxTeleconsultationPrice:
                paymentInformation?.maxTeleconsultationPrice,
            })
          : t('notification_facturation_leftday', {
              number: dayLeft,
              maxTeleconsultationPrice:
                paymentInformation?.maxTeleconsultationPrice,
            })}
      </Typography>
      {notification.displayData.patient && (
        <Typography variant="bodySmall" className="patientText">
          <FontAwesomeIcon name="user:solid" className="h-5 w-5" />
          <PatientName
            field={PatientLabelField.FULLNAME}
            partialPatient={notification.displayData.patient}
            asChild
          />
          <InternetPatientIcon
            partialPatient={notification.displayData.patient}
            wrapperComponent="span"
            wrapperProps={{
              className: 'ml-1 [&_div]:inline-block',
            }}
          />
        </Typography>
      )}
    </NotificationWrapper>
  );
};

export default NotificationFacturationView;
