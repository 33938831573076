import React, { forwardRef } from 'react';
import { Paper, PaperProps } from '@mui/material';

export type WrappedPaperProps = {
  children?: React.ReactNode;
  elevation?: number;
} & PaperProps;

const WrappedPaper = forwardRef(
  (
    { elevation = 2, children, ...defaultProps }: WrappedPaperProps,
    ref: React.Ref<HTMLDivElement>,
  ) => (
    <Paper elevation={elevation} {...defaultProps} ref={ref}>
      {children}
    </Paper>
  ),
);

WrappedPaper.displayName = 'Paper';

export default WrappedPaper;
