import React from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import createTheme from '../../../src/theme';
import BrandNameCegedim from '../../icons/BrandNameCegedim';
import MaintenanceIllustration from './MaintenanceIllustration';

const muiTheme = createTheme();

const useStyles = makeStyles()({
  maintenanceRoot: {
    fontFamily: muiTheme.typography.fontFamily,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    background: muiTheme.palette.gradient,
    overflow: 'hidden',
    '--illu-width': 'min(25vw, 400px)',
    ' & .illu': {
      '& .animblob, & .action': {
        top: '0',
        left: '-25vw',
        height: 'auto',
        width: 'var(--illu-width)',
        position: 'absolute',
      },
      '& .animblob': {
        fill: 'white',
        filter: 'drop-shadow(10px 0 50px #F5F8F9)',
      },
      '& .action': {},
    },
    '& .text': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      position: 'relative',
      margin: '0 var(--illu-width)',
      '& svg': {
        fill: 'white',
        width: 'min(30vw, 400px)',
        height: 'auto',
      },
      '& h3, & p': {
        padding: '20px',
        textAlign: 'center',
        maxWidth: '50ch',
      },
      '& h3': {
        fontSize: muiTheme.size.font['3xl'],
        marginBottom: 0,
        fontWeight: 500,
      },
      '& p': {
        marginTop: 0,
        paddingTop: 0,
        // fontSize: muiTheme.size.font['2xl'],
        fontSize: muiTheme.size.font.xl,
      },
    },
  },
});

const MaintenancePage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('common');

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <div className={classes.maintenanceRoot}>
          <div className="text">
            <BrandNameCegedim />
            <h3>{t('maintenance_page__title')}</h3>
            <p>{t('maintenance_page__text')}</p>
            <MaintenanceIllustration className="illu" />
          </div>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MaintenancePage;
