import {
  crudInitialDefaultState as initialStateCrudState,
  GET_LIST_SUCCESS,
  reducersGenerator,
} from '@docavenue/core';

export const initialState = {
  ...initialStateCrudState,
  invoicedAmount: 0,
  transferredAmount: 0,
};

const name = 'appointmentsTlc';

const extendReducer: any = {};

[GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach((type: string) => {
  extendReducer[type] = (
    state: Object,
    action: { payload: { transferredAmount: number; invoicedAmount: number } },
  ) => {
    const {
      payload: { transferredAmount, invoicedAmount },
    } = action;
    return { ...state, transferredAmount, invoicedAmount };
  };
});

const appointmentsTlc = reducersGenerator({
  name,
  extendReducer,
});

export default appointmentsTlc;
