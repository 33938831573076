import { GenericSelector, InitialGenericSelector } from '@docavenue/chat';
import { generateInitialSelectors } from '@docavenue/core';
import {
  SubstituteData,
  User,
} from '@maiia/model/generated/model/api-pro/api-pro';

const initialSelectors: InitialGenericSelector<
  User | SubstituteData
> = generateInitialSelectors('substitutePractitioners');

const selectors: GenericSelector<User | SubstituteData> = {
  name: 'substitutePractitioners',
  ...initialSelectors,
};

export default selectors;
