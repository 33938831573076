import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import { detect } from 'detect-browser';
import cookie from 'js-cookie';
import moment from 'moment';
import NProgress from 'nprogress';
import { Store } from 'redux';
import { END } from 'redux-saga';
import { createEmotionSsrAdvancedApproach } from 'tss-react/next/pagesDir';

import { IncomingMessage } from 'http';

import { screebInit } from '@docavenue/chat';
import { JiraDrawer, RefreshToken } from '@docavenue/components';
import { ApiClientProvider } from '@maiia/model/generated/core/react-query';

import SeoContainer from '../components/metaOrganisms/seo/App/containers/SeoContainer';
import { i18n } from '../i18n';
import ErrorBoundary from '../src/_errorBoundary';
import LayoutWrapper from '../src/_layoutWrapper';
import { authenticationActions, loaderMaiiaActions } from '../src/actions';
import appCompose from '../src/appCompose';
import AppProvider from '../src/AppProvider';
import config from '../src/config';
import { LOCAL_PERSISTANCE_TIME } from '../src/constants';
import Routes from '../src/constants/routes';
import {
  useCenterId,
  useIsSecretary,
  useIsTelesecretary,
  useUserId,
} from '../src/hooks/selectors';
import {
  useIsJiraHelperAvailable,
  useIsProductionEnvironment,
} from '../src/hooks/utils';
import { getApiClient, setupApiClient } from '../src/http/client';
import { makeQueryClient } from '../src/query/client';
import sentry from '../src/sentry';
import { getTimezone, getToken, wrapper } from '../src/store';
import { isPathAllowedToMobileUsers } from '../src/utils/RoutesUtils';

import 'dayjs/locale/fr';
import '../global.css';
import '../components/organisms/BigCalendar/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import { WebsocketBridge } from '@/src/query/WebsocketBridge';
import { removeOldCookieTrackers } from '@/src/utils';
import {
  setMatomoTrackingUserId,
  setMatomoTrackingUserRole,
} from '@/src/utils/matomo.utils';
import { isPocReactQueryAgendaActivated } from '@/src/utils/queryMigration';
import { WebSocketProvider } from '@/src/WebsocketProvider';

/**
 * The svg sprite from `@kiiwi/ui` has been copied to the following path by `scripts/copy-kiiwi-fontawesome-sprite.js`
 * which is executed before the build and dev server.
 * The global variable `KIIWI_FONTAWESOME_ICON_SPRITE_PATH` lets the `FontawesomeIcon` component inside `@kiiwi/ui` know where
 * to fetch the svg sprite that was copied.
 *
 * This is necessary because you may have an other `FontawesomeIcon` component, specific to your project with your own sprite,
 * we need them to coexist.
 */
if (typeof window !== 'undefined') {
  (window as any).KIIWI_FONTAWESOME_ICON_SPRITE_PATH =
    '/static/images/fontawesome-sprites-kiiwi.svg';
}

dayjs.extend(duration);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

setupApiClient({ getToken, getTimezone });

const LoaderMaiiaView = dynamic(
  import('../components/metaOrganisms/loaders/Maiia/views/LoaderMaiiaView'),
);

const OutDatedBrowser = dynamic(() => import('../src/OutdatedBrowser'));

if (process.env.ENVK8S !== 'production') {
  // @ts-ignore
  // import('@docavenue/components/lib/jiraStyle.css').then(() => {
  // eslint-disable-next-line workspaces/no-relative-imports
  import('../../components/lib/jiraStyle.css').then(() => {
    /* eslint-disable-next-line no-console */
    console.log('JIRA DEV');
  });
}

const tagManagerArgs = {
  gtmId: config.get('GTM_ID'),
  MATOMO_URL_BASE: config.get('MATOMO_URL_BASE'),
  MATOMO_SITE_ID: config.get('MATOMO_SITE_ID'),
  TARTEAUCITRON_DOMAIN: config.get('TARTEAUCITRON_DOMAIN'),
  TARTEAUCITRON_UUID: config.get('TARTEAUCITRON_UUID'),
};

const { captureException } = sentry(
  config.get('SENTRY_DSN'),
  config.get('SENTRY_RELEASE'),
);

type Props = {
  Component: React.ElementType<any>;
  pageProps: Record<string, any>;
  layoutWrapperProps: Object;
  store: Store;
  isOutdatedBrowser: boolean;
  cookies: unknown;
};
// http://localhost:3000/?centerId=5d95c1ea2f21370001922b3e&practitionerId=5d95e2df94417b0001d953ed&tsToken=5ee9e2bbb06f831455aef775&patientPhoneNumber=0635595048
const LoaderMaiiaContainer = () => {
  const isOpen = useSelector(state => state.loaderMaiia.isOpen);
  const dispatch = useDispatch();
  const { query: { centerId } = {} } = useRouter();
  const centerIdStore = useCenterId();
  const userId = useUserId();

  useEffect(() => {
    if ((centerId || centerIdStore) && isOpen) {
      dispatch(loaderMaiiaActions.setOpen(false));
    }
  }, [centerId, centerIdStore, isOpen]);

  useEffect(() => {
    import('../src/cypressHelpers').then(lib => {
      if ((window as any).Cypress) (window as any).Cypress.helpers = lib;
    });
  }, []);

  useUpdateEffect(() => {
    if (userId) {
      screebInit(dispatch);
    }
  }, [userId]);

  return (
    <div>
      {isOpen && (
        <div
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            zIndex: 99999,
            height: '100vh',
            width: '100vw',
          }}
        >
          <LoaderMaiiaView />
        </div>
      )}
    </div>
  );
};

// from documentation: https://docs.tss-react.dev/ssr/next.js
const {
  augmentDocumentWithEmotionCache,
  withAppEmotionCache,
} = createEmotionSsrAdvancedApproach({ key: 'css' });

export { augmentDocumentWithEmotionCache };

const MyApp = (props: Props) => {
  const {
    Component,
    pageProps = {},
    layoutWrapperProps,
    isOutdatedBrowser,
    cookies,
  } = props;
  const router = useRouter();
  const isProductionEnvironment = useIsProductionEnvironment();
  const { query, route } = router;
  const isSecretary = useIsSecretary();
  const isTls = useIsTelesecretary();
  const user = useSelector(state => state.users.item);
  const [queryClient] = React.useState(() => makeQueryClient({ getApiClient }));

  useEffect(() => {
    if (isPocReactQueryAgendaActivated()) {
      // eslint-disable-next-line no-console
      console.info('POC_REACT_QUERY_AGENDA_ACTIVATED'); // this will be logged, even on build version (to check if the version you deployed is correctly activated)
    }
  }, []);

  const initProgressBar = () => {
    NProgress.configure({ showSpinner: false });

    Router.events.on('routeChangeStart', () => {
      NProgress.start();
    });
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());
  };

  useEffect(() => {
    moment.locale(i18n.language);
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    let isPWA = false;
    const remove = async () => {
      if (window.navigator.cookieEnabled && window.navigator.serviceWorker) {
        await window.navigator.serviceWorker
          .getRegistrations()
          // eslint-disable-next-line func-names
          .then(function(registrations) {
            for (const registration of registrations) {
              isPWA = true;
              registration.unregister();
            }
          });
      }
    };
    remove();
    // eslint-disable-next-line no-restricted-globals
    if (isPWA) location.reload();

    // Create tarteaucitron instance (excluding iframe)
    if (window.self === window.top) {
      const script = document.createElement('script');
      script.id = 'tarteaucitronScript';
      script.type = 'text/javascript';
      script.src = `https://tarteaucitron.io/load.js?domain=${tagManagerArgs.TARTEAUCITRON_DOMAIN}&uuid=${tagManagerArgs.TARTEAUCITRON_UUID}&locale=fr`;
      document.body.appendChild(script);
    }

    // Remove unused cookies
    removeOldCookieTrackers();
  }, []);

  useEffect(() => {
    if (tagManagerArgs.gtmId) {
      ReactGA.initialize(tagManagerArgs.gtmId);

      if (!isProductionEnvironment) {
        ReactGA.gtag('config', tagManagerArgs.gtmId, {
          debug_mode: true,
        });
      }
    }
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles?.parentNode?.removeChild(jssStyles);
    }

    initProgressBar();
  }, []);

  useEffect(() => {
    if (user?.id) {
      setMatomoTrackingUserId(user.id);
    }
    if (user?.role?.name) {
      setMatomoTrackingUserRole(user.role.name);
    }
  }, [user?.id, user?.role?.name]);

  useEffect(() => {
    // save part of router query in a cookie to retrieve the multi agenda settings when a new tab is open
    // For TLS and secretaries only
    if ((isTls || isSecretary) && query?.practitionerId && query?.centerId) {
      const { practitionerId, centerId, agendas, keyAgendaAggregate } = query;
      const queryToSave = JSON.stringify({
        practitionerId,
        centerId,
        agendas,
        keyAgendaAggregate,
      });
      cookie.set('multiAgenda', queryToSave, {
        expires: Date.now() + LOCAL_PERSISTANCE_TIME,
        sameSite: 'lax',
        secure: true,
      });
    }
  }, [
    isTls,
    isSecretary,
    query?.practitionerId,
    query?.centerId,
    query?.agendas,
    query?.keyAgendaAggregate,
  ]);
  // Redirect to app pro mobile if user is on smartphone
  if (isMobileOnly && !isPathAllowedToMobileUsers(route)) {
    router.push(Routes.LOGIN);
  }

  if (isOutdatedBrowser) return <OutDatedBrowser />;

  return (
    <ApiClientProvider getApiClient={getApiClient}>
      <QueryClientProvider client={queryClient}>
        <WebSocketProvider>
          <WebsocketBridge />
          <Hydrate state={pageProps.dehydratedState}>
            <ErrorBoundary>
              <DndProvider backend={HTML5Backend}>
                <AppProvider cookies={cookies}>
                  <SeoContainer />
                  <RefreshToken
                    onResetSuccess={state => {
                      const { token, refresh } = state.refresh?.item || {};
                      return authenticationActions.setItem({
                        token,
                        refresh,
                      });
                    }}
                  />
                  <LoaderMaiiaContainer />
                  <LayoutWrapper {...layoutWrapperProps}>
                    <Component {...pageProps} />
                  </LayoutWrapper>
                  <JiraDrawer
                    useIsJiraHelperAvailable={useIsJiraHelperAvailable}
                  />
                  <ReactQueryDevtools />
                </AppProvider>
              </DndProvider>
            </ErrorBoundary>
          </Hydrate>
        </WebSocketProvider>
      </QueryClientProvider>
    </ApiClientProvider>
  );
};

function parseCookies(req?: IncomingMessage) {
  if (!req || !req.headers) {
    return {};
  }
  // @ts-ignore
  return cookie.parse(req.headers.cookie || '');
}

MyApp.getInitialProps = wrapper.getInitialAppProps(
  store => async ({ Component, ctx, ...rest }) => {
    const namespacesRequired = ['common'];

    try {
      const browser = ctx.req
        ? detect(ctx.req.headers['user-agent'])
        : { name: '' };

      const layoutWrapperProps = await LayoutWrapper.getInitialProps(
        {
          ctx: {
            ...ctx,
            store,
          },
        },
        // @ts-ignore
        Component.getInitialProps,
      );

      let pageProps = {};
      if (Component.getInitialProps) {
        // eslint-disable-next-line prefer-destructuring
        pageProps = (
          await App.getInitialProps({
            Component,
            ctx: {
              ...ctx,
              // @ts-ignore
              store,
            },
            ...rest,
          })
        ).pageProps;
      }

      if (ctx.req) {
        store.dispatch(END);
        await store.sagaTask.toPromise();
      }

      return {
        pageProps,
        layoutWrapperProps: { ...layoutWrapperProps },
        namespacesRequired,
        isOutdatedBrowser: ['ie', 'edge'].indexOf(browser?.name || '') !== -1,
        // @ts-ignore
        cookies: parseCookies(ctx?.ctx?.req), // todo WARN - seems the cookie isn't passed down, use `ctx.req?.headers.cookie` ?
      };
    } catch (error) {
      console.error(error);
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error, ctx, ctx.store);
      return {
        hasError: true,
        errorEventId,
        pageProps: {},
      };
    }
  },
);

export default appCompose()(withAppEmotionCache(MyApp));
