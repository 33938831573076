import { actionsGeneratorV2 } from '@docavenue/core';

const practitionersTimeSlotsActions = actionsGeneratorV2({
  resource: 'practitionersTimeSlots',
  chunkUrlResource: 'time-slots',
});

const { getList } = practitionersTimeSlotsActions;

const ACTIVATE_LIGHT_TIMESLOT = false;

if (ACTIVATE_LIGHT_TIMESLOT) {
  practitionersTimeSlotsActions.getList = (params: any, options: any = {}) =>
    getList(params, {
      chunkUrlResource: 'light-time-slots',
      ...options,
    });
}

export default practitionersTimeSlotsActions;
