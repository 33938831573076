import { actionsGeneratorV2 } from '@docavenue/core';
import { Patient } from '@maiia/model/generated/model/api-pro/api-pro';

export const SET_PATIENTS = 'SET_PATIENTS';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

const mergePatientsActions = actionsGeneratorV2({
  resource: 'patients',
  chunkUrlResource: 'patients',
  chunkResource: 'product',
});
const { getOne } = mergePatientsActions;

const setPatients = (items: Patient[]) => ({
  type: SET_PATIENTS,
  payload: {
    items,
  },
});
const openModal = () => ({
  type: OPEN_MODAL,
});
const closeModal = () => ({
  type: CLOSE_MODAL,
});

const canMergePatients = (sourceId: string, targetId: string) => {
  return getOne('', {
    chunkUrlResource: 'patients',
    chunkResource: 'can-merge',
    params: { sourceId, targetId },
  });
};

export default {
  ...mergePatientsActions,
  setPatients,
  openModal,
  closeModal,
  canMergePatients,
};
