import Routes from '../constants/routes';
import {
  AUTH_PATHS,
  REQUIRE_PRACTITIONER_ID,
  ROUTES_ALLOWED_TO_CHAT,
  ROUTES_ALLOWED_TO_MOBILE_USERS,
  ROUTES_ALLOWED_TO_REGULATOR,
  ROUTES_AVAILABLE_TO_FREEMIUM_USERS,
  ROUTES_WITH_MINI_CALENDAR,
  ROUTES_WITH_PATIENT_SEARCH,
  ROUTES_WITH_SIDEBAR,
  ROUTES_WITHOUT_HEADER,
  WHITE_LIST,
} from '../constants/routesLists';
import { makeInclusionCheckerFn as isIn, removeUndefined } from '../utils';

export function buildUrlString(
  url: string,
  searchParamsObject: Record<string, string | undefined>,
) {
  const params = removeUndefined(searchParamsObject);
  const searchParamsStr = new URLSearchParams(params).toString();

  return searchParamsStr ? `${url}?${searchParamsStr}` : url;
}

export const isValidRoute = isIn(Object.values(Routes));
export const requiresPractitionerId = isIn(REQUIRE_PRACTITIONER_ID);
export const isAuthPath = isIn(AUTH_PATHS);
export const isPathWithMiniCalendar = isIn(ROUTES_WITH_MINI_CALENDAR);
export const isPathWithPatientSearch = isIn(ROUTES_WITH_PATIENT_SEARCH);
export const isPathWithoutHeader = isIn(ROUTES_WITHOUT_HEADER);
export const isPathWithSidebar = isIn(ROUTES_WITH_SIDEBAR);
export const isPathAllowedToMobileUsers = isIn(ROUTES_ALLOWED_TO_MOBILE_USERS);
export const isPathAllowedToFreemiumUsers = isIn(
  ROUTES_AVAILABLE_TO_FREEMIUM_USERS,
);
export const isPathAllowedToRegulator = isIn(ROUTES_ALLOWED_TO_REGULATOR);
export const isPathAllowedToChat = isIn(ROUTES_ALLOWED_TO_CHAT);
export const isPathWhiteListed = isIn(WHITE_LIST);
