import { QueryClient } from '@tanstack/react-query';

import { makeQueryKey as makeProUnreadChatMessageQueryKey } from '@maiia/model/generated/model/api-pro/hooks/proChatMessagesCountUnreadMessages';

import { WS_CONNECT_RESOURCES } from '@/components/utils/constants';

type ConnectWebSocketBridgeProps = {
  queryClient: QueryClient;
  webSocketMessage: {
    action?: string;
    resource?: string;
    payload?: any;
  };
};

const connectWebSocketBridge = ({
  queryClient,
  webSocketMessage,
}: ConnectWebSocketBridgeProps) => {
  const { resource } = webSocketMessage;

  switch (resource) {
    case WS_CONNECT_RESOURCES.CHAT_MESSAGE:
    case WS_CONNECT_RESOURCES.CHAT_REPLY_MESSAGE:
    case WS_CONNECT_RESOURCES.CHAT_THREAD:
      queryClient.invalidateQueries({
        queryKey: makeProUnreadChatMessageQueryKey({}),
      });
      break;
    default:
  }
};

export default connectWebSocketBridge;
