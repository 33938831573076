import React, { ReactElement } from 'react';
import { makeStyles } from 'tss-react/mui';


import CircularProgress from '../../atoms/CircularProgress';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import IconButton from '../../atoms/IconButton';
import Typography from '../../atoms/Typography';

const useStyles = makeStyles()((theme: any) => ({
  snackbarEvent: {
    position: 'absolute',
    bottom: '150px',
    right: '50px',
    zIndex: theme.zIndex.snackbar,
    maxWidth: theme.size.spacing[14],
    minWidth: theme.size.spacing[12],
    borderRadius: theme.shape.borderRadius,
    display: 'grid',
    gridTemplateAreas: "'rect rest'",
    gridTemplateColumns: `${theme.size.spacing[4]} 1fr`,
    overflow: 'auto',
    '& a': {
      color: theme.palette.blue.main,
      '&:hover': { color: theme.palette.blue.dark },
    },

    '& .rect': {
      gridArea: 'rect',
      width: '100%',
      height: '100%',
    },

    '& .content': {
      gridArea: 'rest',
      gridGap: theme.size.spacing[2],
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: `${theme.size.spacing[3]} ${theme.size.spacing[2]}`,
    },

    '&.warning': {
      color: theme.palette.yellow[600],
      backgroundColor: theme.palette.yellow[100],
      border: `1px solid ${theme.palette.yellow[400]}`,
      '& .rect': { backgroundColor: theme.palette.yellow[300] },
    },
    '&.success': {
      color: theme.palette.green[600],
      backgroundColor: theme.palette.green[100],
      border: `1px solid ${theme.palette.green[400]}`,
      '& .rect': { backgroundColor: theme.palette.green[300] },
    },
    '&.error': {
      color: theme.palette.red[600],
      backgroundColor: theme.palette.red[100],
      border: `1px solid ${theme.palette.red[400]}`,
      '& .rect': { backgroundColor: theme.palette.red[300] },
    },
  },
}));

type Props = {
  text: string | ReactElement;
  variant?: 'warning' | 'success' | 'error';
  onClose?: () => void;
  className?: string;
  isLoading?: boolean;
};

const SnackbarEvents = (props: Props) => {
  const { variant, isLoading, text, onClose, className } = props;

  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.snackbarEvent, className, variant)}>
      <div className="rect" />
      <div className="content">
        <Typography>{text}</Typography>
        {isLoading && <CircularProgress size="50" />}
        {onClose && (
          <IconButton size="large" onClick={onClose}>
            <FontAwesomeIcon name="xmark:regular" className="h-5 w-5" />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default SnackbarEvents;
