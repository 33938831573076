import {
  crudInitialDefaultState as initialStateCrudState,
  GET_ONE_SUCCESS,
  reducersGenerator,
} from '@docavenue/core';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { CrudInitialDefaultState } from '@docavenue/core/src/types';

import { VideoSessionAggregate } from '../../types/pro.types';
import {
  CLEAR_EXPIRED,
  CLEAR_SHARED_DOCUMENTS,
  SET_OPEN,
  SET_SHARED_DOCUMENTS,
  SubscribersPing,
  UPDATE_PATIENT_PINGS,
} from '../actions/videoSessions';

type VideoSessionDocuments = VideoSessionAggregate['documents'];

export type VideoSessionsState = CrudInitialDefaultState<
  VideoSessionAggregate
> & {
  isOpenWaitingRoom: boolean;
  expiredSession: VideoSessionAggregate | null;
  sharedDocuments: VideoSessionDocuments;
  // The key string here is actually videoSessionId for rapid indexing and updating
  subscribersPing: Record<string, SubscribersPing>;
};

export const initialState: VideoSessionsState = {
  ...(initialStateCrudState as CrudInitialDefaultState<VideoSessionAggregate>),
  isOpenWaitingRoom: false,
  expiredSession: null,
  sharedDocuments: [],
  subscribersPing: {},
};

const surchargeReducer = (itemsDictMapReducerConfig: any) => {
  const initialCrudGetOneSuccess = itemsDictMapReducerConfig[GET_ONE_SUCCESS];

  const extendReducer = itemsDictMapReducerConfig;
  extendReducer[GET_ONE_SUCCESS] = (state: VideoSessionsState, action) => {
    const { payload } = action;
    let newState = state;
    if (payload.videoSessionStatus === 'PATIENT_EXPIRED') {
      newState = { ...state, expiredSession: payload };
    }
    return initialCrudGetOneSuccess(newState, action);
  };

  extendReducer[SET_OPEN] = (state: VideoSessionsState) => {
    const newState = {
      ...state,
      isOpenWaitingRoom: !state.isOpenWaitingRoom,
    };
    return newState;
  };

  extendReducer[SET_SHARED_DOCUMENTS] = (
    state = initialState,
    action: { payload: VideoSessionDocuments[number] },
  ) => {
    const { payload } = action;
    const sharedDocuments: VideoSessionDocuments = [...state.sharedDocuments];
    if (payload) {
      sharedDocuments.push(payload);
    }

    return {
      ...state,
      sharedDocuments,
    };
  };

  extendReducer[CLEAR_EXPIRED] = (state: VideoSessionsState) => {
    const newState = {
      ...state,
      expiredSession: null,
    };
    return newState;
  };

  extendReducer[CLEAR_SHARED_DOCUMENTS] = (state: VideoSessionsState) => {
    const newState = {
      ...state,
      sharedDocuments: [],
    };
    return newState;
  };

  extendReducer[UPDATE_PATIENT_PINGS] = (
    state: VideoSessionsState,
    {
      payload: { data, videoSessionId },
    }: {
      payload: { videoSessionId: string; data: SubscribersPing | null };
    },
  ) => {
    const newSubscribersPing = { ...state.subscribersPing };
    if (data === null) {
      delete newSubscribersPing[videoSessionId];
    } else newSubscribersPing[videoSessionId] = data;

    const newState = {
      ...state,
      subscribersPing: newSubscribersPing,
    };
    return newState;
  };
  return extendReducer;
};

const videoSessions = reducersGenerator({
  name: 'videoSessions',
  surchargeReducer,
});

export default videoSessions;
