import React from 'react';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import { useLoadAnimation } from '../../../../../../src/hooks/styles';

const jumpAnimation = keyframes`
  80% { transform: translateY(-7%) },
  100% { transform: translateY(0) },
`;
const bounceAnimation = keyframes`
  0% { transform: translate3d(0, 0, 30px) },
  100% { transform: translate3d(0, -30px, 0) },
`;

const useStyles = makeStyles()({
  loaderMaiia: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    '& .ball': {
      position: 'absolute',
      borderRadius: '100%',
      opacity: '0.7',
    },
    '& svg': {
      width: 200,
    },
    '& .M': {},
    '& .A1': {
      animationDelay: '0.2s',
    },

    '& .I1': {
      animationDelay: '0.25s',
    },

    '& .I1dot': {
      animation: `${bounceAnimation} 0.4s  infinite alternate 1s`,
    },

    '& .I2': {
      animationDelay: '0.3s',
    },

    '& .I2dot': {
      animation: `${bounceAnimation} 0.4s  infinite alternate 1.2s`,
    },
    '& .A2': {
      animationDelay: '0.35s',
    },

    '& .I1dot, .I2dot': {
      animationFillMode: 'forwards',
    },

    '& svg *': {
      transform: 'translateY(100%)',
      animation: `${jumpAnimation} 1s cubic-bezier(0.175, 0.885, 0.32, 1.2)`,
      animationFillMode: 'forwards',
      fill: '#08A6A0',
      '&:hover': {
        fill: 'rgb(246, 128, 146)',
      },
    },
  },
});

const LoaderMaiiaView = () => {
  const { classes } = useStyles();

  useLoadAnimation();

  return (
    <div className={classes.loaderMaiia} id="loadercontainer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -100 990.05 400">
        <g className="A2">
          <path
            className="cls-1"
            d="M957.34,72.17v31.12A103,103,0,0,0,926.49,78c-14-7.38-29.87-11.11-47.15-11.11-29.87,0-55.77,10.9-77,32.39s-32,47.7-32,77.93c0,30.85,10.66,57.41,31.7,79s46.81,32.52,76.56,32.52a104,104,0,0,0,46.52-10.51A112.63,112.63,0,0,0,957.33,254V283.5h32.72V72.17ZM959,178.82c0,14.85-3.37,28.39-10,40.2a73.36,73.36,0,0,1-28.45,28.17,79.22,79.22,0,0,1-39.22,10.45A74.9,74.9,0,0,1,843,247.13a77.73,77.73,0,0,1-28.51-29.42,79.55,79.55,0,0,1-.11-79.3A76,76,0,0,1,881.5,99c21.74,0,40.26,7.68,55.1,22.83S959,156.21,959,178.82Z"
          />
        </g>
        <g className="I2dot">
          <path
            className="cls-1"
            d="M711,.07a20.22,20.22,0,1,0,20.22,20.22h0A20.25,20.25,0,0,0,711,.07Z"
          />
        </g>
        <g className="I2">
          <path
            className="cls-1"
            d="M692.81,87.77v-.54c-.18-6.41,1.46-10.63,5-12.9a13,13,0,0,1,4.89-1.8h29v185c0,11.38-5.64,15.72-7.37,16.79l-.31.18-31.33,9.65v-4c-.18-24.92-.21-52.12-.21-77.38C692.42,144.27,692.78,89.78,692.81,87.77Z"
          />
        </g>
        <g className="I1dot">
          <path
            className="cls-1"
            d="M638.59.07a20.22,20.22,0,1,0,20.22,20.22h0A20.25,20.25,0,0,0,638.59.07Z"
          />
        </g>
        <g className="I1">
          <path
            className="cls-1"
            d="M660.15,87.77v-.54c.18-6.41-1.46-10.63-5-12.9a13,13,0,0,0-4.89-1.8h-29v185c0,11.38,5.64,15.72,7.37,16.79l.31.18,31.33,9.65v-4c.18-24.92.21-52.12.21-77.38C660.54,144.27,660.18,89.78,660.15,87.77Z"
          />
        </g>
        <g className="A1">
          <path
            className="cls-1"
            d="M540.26,72.17v31.12A103,103,0,0,0,509.41,78c-14-7.38-29.87-11.11-47.15-11.11-29.87,0-55.77,10.9-77,32.39s-32,47.7-32,77.93c0,30.85,10.66,57.41,31.7,79s46.81,32.52,76.56,32.52A103.91,103.91,0,0,0,508,278.25,112.53,112.53,0,0,0,540.25,254V283.5H573V72.17ZM542,178.82c0,14.85-3.37,28.39-10,40.2a73.36,73.36,0,0,1-28.45,28.17,79.34,79.34,0,0,1-39.25,10.45,74.9,74.9,0,0,1-38.38-10.51,77.67,77.67,0,0,1-28.54-29.42,79.57,79.57,0,0,1,0-79.3A76,76,0,0,1,464.5,99c21.74,0,40.25,7.68,55.1,22.83S542,156.21,542,178.82Z"
          />
        </g>
        <g className="M">
          <polygon
            className="cls-1"
            points="253.23 0 160.57 166.71 61.05 0 34.46 0 0 283.44 33.55 283.44 61.96 74.53 155.2 227.36 165.4 227.36 256.93 74.53 285.84 283.44 319.78 283.44 279.7 0 253.23 0"
          />
        </g>
      </svg>
    </div>
  );
};

export default LoaderMaiiaView;
