// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js

const CAPTURED_EXCEPTION_CENTER_IDS = null; // set to null if we want capture all

const Sentry = require('@sentry/node'); // eslint-disable-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
// const SentryIntegrations = require('@sentry/integrations');
const Cookie = require('js-cookie');

module.exports = (dsn, release = process.env.NEXT_PUBLIC_SENTRY_RELEASE) => {
  const sentryOptions = {
    dsn,
    release,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    integrations: integrations =>
      // Temporary disable all Global errors for saving money$$$
      integrations.filter(
        integration =>
          integration.name !== 'GlobalHandlers' &&
          integration.name !== 'TryCatch',
      ),
    beforeSend(event) {
      if (
        CAPTURED_EXCEPTION_CENTER_IDS &&
        !CAPTURED_EXCEPTION_CENTER_IDS.includes(event.extra.centerId)
      )
        return null;
      return event;
    },
  };

  // When we're developing locally
  if (process.env.NODE_ENV !== 'production') {
    return { Sentry, captureException: () => {} };
    // //eslint-disable-next-line import/no-extraneous-dependencies
    // const sentryTestkit = require('sentry-testkit');
    // const { sentryTransport } = sentryTestkit();

    // // Don't actually send the errors to Sentry
    // sentryOptions.transport = sentryTransport;

    // // Instead, dump the errors to the console
    // sentryOptions.integrations = [
    //   new SentryIntegrations.Debug({
    //     // Trigger DevTools debugger instead of using console.log
    //     debugger: false,
    //   }),
    // ];
  }

  Sentry.init(sentryOptions);

  return {
    Sentry,
    captureException: (err, ctx, extraInformations) => {
      Sentry.configureScope(async scope => {
        if (err.message) {
          // De-duplication currently doesn't work correctly for SSR / browser errors
          // so we force deduplication by error message if it is present
          scope.setFingerprint([err.message]);
        }

        if (err.statusCode) {
          scope.setExtra('statusCode', err.statusCode);
        }

        if (extraInformations) {
          const { user, ...rest } = extraInformations;

          scope.setUser(user);

          if (extraInformations.action) {
            if (extraInformations.action.resource) {
              scope.setTag('resource', extraInformations.action.resource);
            }
            if (extraInformations.action.type) {
              scope.setTag('type', extraInformations.action.type);
            }
          }

          Object.keys(rest).forEach(key => scope.setExtra(key, rest[key]));
        }

        if (ctx) {
          const { req, res, errorInfo, query, pathname } = ctx;

          if (res && res.statusCode) {
            scope.setExtra('statusCode', res.statusCode);
          }

          if (typeof window !== 'undefined') {
            scope.setTag('ssr', false);
            scope.setExtra('query', query);
            scope.setExtra('pathname', pathname);

            // On client-side we use js-cookie package to fetch it
            const token = Cookie.get('token');
            if (token) {
              scope.setUser({ id: token });
            }
          } else {
            scope.setTag('ssr', true);
            scope.setExtra('url', req.url);
            scope.setExtra('method', req.method);
            scope.setExtra('headers', req.headers);
            scope.setExtra('params', req.params);
            scope.setExtra('query', req.query);

            // On server-side we take session cookie directly from request
            if (req.cookies.token) {
              scope.setUser({ id: req.cookies.token });
            }
          }

          if (errorInfo) {
            Object.keys(errorInfo).forEach(key =>
              scope.setExtra(key, errorInfo[key]),
            );
            if (errorInfo.scope) {
              scope.setTag('scope', errorInfo.scope);
            }
            if (errorInfo.subScope) {
              scope.setTag('subScope', errorInfo.subScope);
            }
          }
        }
        // Add info practitionerId, centerId
        if (typeof window !== 'undefined') {
          const params = new URLSearchParams(window.location.search);
          const keys = ['practitionerId', 'centerId'];
          for (const k of keys) {
            if (params.has(k)) {
              scope.setExtra(k, params.get(k));
            }
          }
        }
      });

      return Sentry.captureException(err);
    },
  };
};
