/* eslint-disable */
/**
 * THIS FILE IS GENERATED, DO NOT EDIT
 *
 * Use the generator as explained in https://gitlab.maiia.io/dev/maiia-frontend/-/tree/develop#fontawesome-sprites-generator
 */
import React, { SVGAttributes } from "react";

export type AcceptedIconNames =
  | "align-center:regular"
  | "align-justify:regular"
  | "align-left:regular"
  | "align-right:regular"
  | "angle-down:regular"
  | "angle-down:regular"
  | "angle-left:regular"
  | "angle-right:regular"
  | "angle-up:regular"
  | "angle-up:regular"
  | "apple:brands"
  | "arrow-left:regular"
  | "arrow-right-from-bracket:regular"
  | "arrow-turn-down-left:regular"
  | "arrow-turn-down-right:regular"
  | "arrow-up-right-from-square:regular"
  | "arrows-maximize:regular"
  | "arrows-minimize:regular"
  | "arrows-up-down:regular"
  | "at:regular"
  | "at:solid"
  | "badge-check:solid"
  | "bars-filter:regular"
  | "bars-filter:solid"
  | "bell:regular"
  | "bell:solid"
  | "bold:regular"
  | "briefcase-medical:regular"
  | "browser:regular"
  | "building:regular"
  | "building:solid"
  | "calendar-circle-plus:regular"
  | "calendar-day:regular"
  | "calendar-pen:regular"
  | "calendar-xmark:regular"
  | "calendar:regular"
  | "calendars:regular"
  | "caret-down:solid"
  | "caret-up:solid"
  | "chart-line:regular"
  | "chart-simple:regular"
  | "check:regular"
  | "check:regular"
  | "check:solid"
  | "chevron-down:regular"
  | "chevron-left:regular"
  | "chevron-right:regular"
  | "chevron-up:regular"
  | "circle-a:solid"
  | "circle-check:regular"
  | "circle-check:solid"
  | "circle-exclamation:regular"
  | "circle-exclamation:solid"
  | "circle-info:regular"
  | "circle-info:solid"
  | "circle-minus:regular"
  | "circle-n:solid"
  | "circle-pause:regular"
  | "circle-pause:solid"
  | "circle-play:regular"
  | "circle-play:solid"
  | "circle-plus:regular"
  | "circle-plus:solid"
  | "circle-question:regular"
  | "circle-user:regular"
  | "circle-xmark:regular"
  | "circle-xmark:solid"
  | "circle:solid"
  | "clipboard-question:regular"
  | "clock-rotate-left:regular"
  | "clock-rotate-left:regular"
  | "clock:regular"
  | "clock:regular"
  | "cloud-arrow-down:regular"
  | "code:regular"
  | "comment:regular"
  | "comment:regular"
  | "comment:solid"
  | "copy:regular"
  | "credit-card:regular"
  | "droplet-slash:regular"
  | "droplet:regular"
  | "ellipsis-vertical:regular"
  | "ellipsis:regular"
  | "envelope:regular"
  | "envelope:solid"
  | "euro-sign:regular"
  | "euro-sign:solid"
  | "eye-slash:regular"
  | "eye-slash:solid"
  | "eye:regular"
  | "eye:solid"
  | "face-smile:regular"
  | "file-arrow-up:regular"
  | "file-code:regular"
  | "file:regular"
  | "fill-drip:regular"
  | "floppy-disk:regular"
  | "folder-arrow-up:regular"
  | "gear:regular"
  | "ghost:regular"
  | "ghost:solid"
  | "grid:solid"
  | "hashtag:regular"
  | "heading:regular"
  | "headset:regular"
  | "heart:solid"
  | "highlighter:regular"
  | "hourglass-half:regular"
  | "hourglass-half:solid"
  | "house-medical:regular"
  | "house-medical:solid"
  | "house:regular"
  | "house:solid"
  | "italic:regular"
  | "link-simple:regular"
  | "list-ol:regular"
  | "list-ul:regular"
  | "location-crosshairs:regular"
  | "location-dot:regular"
  | "location-pin:regular"
  | "lock-keyhole:regular"
  | "lock-keyhole:solid"
  | "magnifying-glass-minus:regular"
  | "magnifying-glass-plus:regular"
  | "magnifying-glass:regular"
  | "magnifying-glass:regular"
  | "merge:regular"
  | "message-lines:regular"
  | "message-lines:regular"
  | "message-lines:solid"
  | "message-question:regular"
  | "message-slash:regular"
  | "messages:regular"
  | "messages:solid"
  | "microphone-slash:regular"
  | "microphone-slash:solid"
  | "microphone:regular"
  | "microphone:solid"
  | "minus:regular"
  | "mobile:regular"
  | "mobile:regular"
  | "mobile:solid"
  | "notes-medical:light"
  | "notes-medical:solid"
  | "paper-plane-top:regular"
  | "paper-plane-top:solid"
  | "paperclip-vertical:regular"
  | "paperclip-vertical:regular"
  | "party-horn:regular"
  | "pause:solid"
  | "pen:regular"
  | "pen:solid"
  | "phone-hangup:regular"
  | "phone:regular"
  | "phone:solid"
  | "play:regular"
  | "play:solid"
  | "plus:regular"
  | "power-off:regular"
  | "print:regular"
  | "print:regular"
  | "quote-right:regular"
  | "registered:solid"
  | "rotate-left:regular"
  | "rotate-right:regular"
  | "scale-balanced:regular"
  | "shapes:regular"
  | "share:regular"
  | "sparkles:regular"
  | "spinner-third:duotone"
  | "square-check:regular"
  | "square-pen:solid"
  | "star:regular"
  | "star:solid"
  | "stethoscope:regular"
  | "stop:solid"
  | "strikethrough:regular"
  | "subscript:regular"
  | "superscript:regular"
  | "thumbs-down:regular"
  | "thumbs-down:solid"
  | "thumbs-up:regular"
  | "thumbs-up:solid"
  | "timer:regular"
  | "trash-can:regular"
  | "trash:regular"
  | "triangle-exclamation:regular"
  | "triangle-exclamation:solid"
  | "underline:regular"
  | "user-gear:regular"
  | "user-group:regular"
  | "user-nurse-hair-long:regular"
  | "user-plus:regular"
  | "user-plus:regular"
  | "user-tie:regular"
  | "user-tie:solid"
  | "user:regular"
  | "user:solid"
  | "users-medical:solid"
  | "users:regular"
  | "video-arrow-down-left:regular"
  | "video-slash:regular"
  | "video-slash:solid"
  | "video:regular"
  | "video:solid"
  | "wifi-exclamation:solid"
  | "wifi-fair:duotone"
  | "wifi-weak:duotone"
  | "wifi:duotone"
  | "xmark:regular";

type Props = {
  name: AcceptedIconNames;
} & SVGAttributes<SVGElement>;

const viewBoxMapping = {
  "align-center:regular": "0 0 448 512",
  "align-justify:regular": "0 0 448 512",
  "align-left:regular": "0 0 448 512",
  "align-right:regular": "0 0 448 512",
  "angle-down:regular": "0 0 448 512",
  "angle-left:regular": "0 0 320 512",
  "angle-right:regular": "0 0 320 512",
  "angle-up:regular": "0 0 448 512",
  "apple:brands": "0 0 384 512",
  "arrow-left:regular": "0 0 448 512",
  "arrow-right-from-bracket:regular": "0 0 512 512",
  "arrow-turn-down-left:regular": "0 0 512 512",
  "arrow-turn-down-right:regular": "0 0 512 512",
  "arrow-up-right-from-square:regular": "0 0 512 512",
  "arrows-maximize:regular": "0 0 512 512",
  "arrows-minimize:regular": "0 0 512 512",
  "arrows-up-down:regular": "0 0 320 512",
  "at:regular": "0 0 512 512",
  "at:solid": "0 0 512 512",
  "badge-check:solid": "0 0 512 512",
  "bars-filter:regular": "0 0 448 512",
  "bars-filter:solid": "0 0 448 512",
  "bell:regular": "0 0 448 512",
  "bell:solid": "0 0 448 512",
  "bold:regular": "0 0 384 512",
  "briefcase-medical:regular": "0 0 512 512",
  "browser:regular": "0 0 512 512",
  "building:regular": "0 0 384 512",
  "building:solid": "0 0 384 512",
  "calendar-circle-plus:regular": "0 0 576 512",
  "calendar-day:regular": "0 0 448 512",
  "calendar-pen:regular": "0 0 448 512",
  "calendar-xmark:regular": "0 0 448 512",
  "calendar:regular": "0 0 448 512",
  "calendars:regular": "0 0 512 512",
  "caret-down:solid": "0 0 320 512",
  "caret-up:solid": "0 0 320 512",
  "chart-line:regular": "0 0 512 512",
  "chart-simple:regular": "0 0 448 512",
  "check:regular": "0 0 448 512",
  "check:solid": "0 0 448 512",
  "chevron-down:regular": "0 0 512 512",
  "chevron-left:regular": "0 0 320 512",
  "chevron-right:regular": "0 0 320 512",
  "chevron-up:regular": "0 0 512 512",
  "circle-a:solid": "0 0 512 512",
  "circle-check:regular": "0 0 512 512",
  "circle-check:solid": "0 0 512 512",
  "circle-exclamation:regular": "0 0 512 512",
  "circle-exclamation:solid": "0 0 512 512",
  "circle-info:regular": "0 0 512 512",
  "circle-info:solid": "0 0 512 512",
  "circle-minus:regular": "0 0 512 512",
  "circle-n:solid": "0 0 512 512",
  "circle-pause:regular": "0 0 512 512",
  "circle-pause:solid": "0 0 512 512",
  "circle-play:regular": "0 0 512 512",
  "circle-play:solid": "0 0 512 512",
  "circle-plus:regular": "0 0 512 512",
  "circle-plus:solid": "0 0 512 512",
  "circle-question:regular": "0 0 512 512",
  "circle-user:regular": "0 0 512 512",
  "circle-xmark:regular": "0 0 512 512",
  "circle-xmark:solid": "0 0 512 512",
  "circle:solid": "0 0 512 512",
  "clipboard-question:regular": "0 0 384 512",
  "clock-rotate-left:regular": "0 0 512 512",
  "clock:regular": "0 0 512 512",
  "cloud-arrow-down:regular": "0 0 640 512",
  "code:regular": "0 0 640 512",
  "comment:regular": "0 0 512 512",
  "comment:solid": "0 0 512 512",
  "copy:regular": "0 0 448 512",
  "credit-card:regular": "0 0 576 512",
  "droplet-slash:regular": "0 0 640 512",
  "droplet:regular": "0 0 384 512",
  "ellipsis-vertical:regular": "0 0 128 512",
  "ellipsis:regular": "0 0 448 512",
  "envelope:regular": "0 0 512 512",
  "envelope:solid": "0 0 512 512",
  "euro-sign:regular": "0 0 320 512",
  "euro-sign:solid": "0 0 320 512",
  "eye-slash:regular": "0 0 640 512",
  "eye-slash:solid": "0 0 640 512",
  "eye:regular": "0 0 576 512",
  "eye:solid": "0 0 576 512",
  "face-smile:regular": "0 0 512 512",
  "file-arrow-up:regular": "0 0 384 512",
  "file-code:regular": "0 0 384 512",
  "file:regular": "0 0 384 512",
  "fill-drip:regular": "0 0 576 512",
  "floppy-disk:regular": "0 0 448 512",
  "folder-arrow-up:regular": "0 0 512 512",
  "gear:regular": "0 0 512 512",
  "ghost:regular": "0 0 384 512",
  "ghost:solid": "0 0 384 512",
  "grid:solid": "0 0 448 512",
  "hashtag:regular": "0 0 448 512",
  "heading:regular": "0 0 448 512",
  "headset:regular": "0 0 512 512",
  "heart:solid": "0 0 512 512",
  "highlighter:regular": "0 0 576 512",
  "hourglass-half:regular": "0 0 384 512",
  "hourglass-half:solid": "0 0 384 512",
  "house-medical:regular": "0 0 576 512",
  "house-medical:solid": "0 0 576 512",
  "house:regular": "0 0 576 512",
  "house:solid": "0 0 576 512",
  "italic:regular": "0 0 384 512",
  "link-simple:regular": "0 0 576 512",
  "list-ol:regular": "0 0 512 512",
  "list-ul:regular": "0 0 512 512",
  "location-crosshairs:regular": "0 0 512 512",
  "location-dot:regular": "0 0 384 512",
  "location-pin:regular": "0 0 384 512",
  "lock-keyhole:regular": "0 0 448 512",
  "lock-keyhole:solid": "0 0 448 512",
  "magnifying-glass-minus:regular": "0 0 512 512",
  "magnifying-glass-plus:regular": "0 0 512 512",
  "magnifying-glass:regular": "0 0 512 512",
  "merge:regular": "0 0 512 512",
  "message-lines:regular": "0 0 512 512",
  "message-lines:solid": "0 0 512 512",
  "message-question:regular": "0 0 512 512",
  "message-slash:regular": "0 0 640 512",
  "messages:regular": "0 0 640 512",
  "messages:solid": "0 0 640 512",
  "microphone-slash:regular": "0 0 640 512",
  "microphone-slash:solid": "0 0 640 512",
  "microphone:regular": "0 0 384 512",
  "microphone:solid": "0 0 384 512",
  "minus:regular": "0 0 448 512",
  "mobile:regular": "0 0 384 512",
  "mobile:solid": "0 0 384 512",
  "notes-medical:light": "0 0 512 512",
  "notes-medical:solid": "0 0 512 512",
  "paper-plane-top:regular": "0 0 512 512",
  "paper-plane-top:solid": "0 0 512 512",
  "paperclip-vertical:regular": "0 0 384 512",
  "party-horn:regular": "0 0 512 512",
  "pause:solid": "0 0 320 512",
  "pen:regular": "0 0 512 512",
  "pen:solid": "0 0 512 512",
  "phone-hangup:regular": "0 0 640 512",
  "phone:regular": "0 0 512 512",
  "phone:solid": "0 0 512 512",
  "play:regular": "0 0 384 512",
  "play:solid": "0 0 384 512",
  "plus:regular": "0 0 448 512",
  "power-off:regular": "0 0 512 512",
  "print:regular": "0 0 512 512",
  "quote-right:regular": "0 0 448 512",
  "registered:solid": "0 0 512 512",
  "rotate-left:regular": "0 0 512 512",
  "rotate-right:regular": "0 0 512 512",
  "scale-balanced:regular": "0 0 640 512",
  "shapes:regular": "0 0 512 512",
  "share:regular": "0 0 512 512",
  "sparkles:regular": "0 0 512 512",
  "spinner-third:duotone": "0 0 512 512",
  "square-check:regular": "0 0 448 512",
  "square-pen:solid": "0 0 448 512",
  "star:regular": "0 0 576 512",
  "star:solid": "0 0 576 512",
  "stethoscope:regular": "0 0 576 512",
  "stop:solid": "0 0 384 512",
  "strikethrough:regular": "0 0 512 512",
  "subscript:regular": "0 0 512 512",
  "superscript:regular": "0 0 512 512",
  "thumbs-down:regular": "0 0 512 512",
  "thumbs-down:solid": "0 0 512 512",
  "thumbs-up:regular": "0 0 512 512",
  "thumbs-up:solid": "0 0 512 512",
  "timer:regular": "0 0 512 512",
  "trash-can:regular": "0 0 448 512",
  "trash:regular": "0 0 448 512",
  "triangle-exclamation:regular": "0 0 512 512",
  "triangle-exclamation:solid": "0 0 512 512",
  "underline:regular": "0 0 448 512",
  "user-gear:regular": "0 0 640 512",
  "user-group:regular": "0 0 640 512",
  "user-nurse-hair-long:regular": "0 0 448 512",
  "user-plus:regular": "0 0 640 512",
  "user-tie:regular": "0 0 448 512",
  "user-tie:solid": "0 0 448 512",
  "user:regular": "0 0 448 512",
  "user:solid": "0 0 448 512",
  "users-medical:solid": "0 0 640 512",
  "users:regular": "0 0 640 512",
  "video-arrow-down-left:regular": "0 0 576 512",
  "video-slash:regular": "0 0 640 512",
  "video-slash:solid": "0 0 640 512",
  "video:regular": "0 0 576 512",
  "video:solid": "0 0 576 512",
  "wifi-exclamation:solid": "0 0 640 512",
  "wifi-fair:duotone": "0 0 640 512",
  "wifi-weak:duotone": "0 0 640 512",
  "wifi:duotone": "0 0 640 512",
  "xmark:regular": "0 0 384 512",
};

export default function FontAwesomeIcon({ name, ...rest }: Props) {
  return (
    <svg fill="currentColor" viewBox={viewBoxMapping[name]} {...rest}>
      <use
        // @ts-ignore
        xlinkHref={`${typeof FONTAWESOME_ICON_SPRITE_PATH !== "undefined" ? FONTAWESOME_ICON_SPRITE_PATH : "/static/images/fontawesome-sprites.svg"}?hash=251ee6ae5556ae3ec74f3f976d740dec3cb2e7f2c67cda20c32a5eac6dd64d63#${name}`}
      />
    </svg>
  );
}
