import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Typography from '../../atoms/Typography';
import { BulletCheck } from '../../icons';

type Props = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  message: string | null;
  errorMessage?: string | null;
  mainIllu: string;
  catchTitle: string;
  catchPhrases: Array<string | JSX.Element>;
  close: () => void;
};

const useStyles = makeStyles<{ mainIllu: Props['mainIllu'] }>()(
  (theme: any, { mainIllu }) => ({
    martekingMessageRoot: {
      '--height': '427px',
      '--brds': theme.spacing(2),
      '--afterClick-height': '240px',
      width: 940,
      backgroundColor: 'white',
      height: 'var(--height)',
      boxShadow: theme.shadows[5],
      display: 'flex',
      alignItems: 'center',
      borderRadius: 'var(--brds)',
      position: 'relative',

      '& .close': {
        position: 'absolute',
        padding: theme.spacing(1),
        right: 10,
        top: 10,
      },

      '&.afterClick': {
        height: 'var(--afterClick-height)',
        '& .illu': { height: 'var(--afterClick-height)' },
      },

      '& .illu': {
        height: 'var(--height)',
        width: 291,
        flexShrink: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 'var(--brds) 0 0 var(--brds)',

        '&-ok': {
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          background: 'url(/static/images/success-illu.svg)',
        },

        '&-ko': {
          paddingTop: theme.spacing(2),
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          background: 'url(/static/images/failure-illu.svg)',
          color: theme.palette.destructive.main,
          fontSize: theme.size.font.lg,
        },
      },

      '& .speech': {
        fontFamily: theme.typography.fontFamily,
        margin: theme.spacing(5),
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: theme.size.font.lg,

        '& .catchPhrase': {
          marginLeft: theme.spacing(4),
          marginBottom: theme.spacing(5),
          display: 'flex',

          '& svg': {
            '--size': '19px',
            marginRight: theme.spacing(3),
            height: 'var(--size)',
            width: 'var(--size)',
          },
        },

        '& .btn': {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          marginTop: theme.spacing(5),
          width: 'calc(100% - 48px)',
          alignSelf: 'center',
        },
      },
    },

    catchTitle: {
      fontSize: theme.size.font['3xl'],
      fontWeight: 700,
      color: theme.palette.main.main,
      marginBottom: theme.spacing(7),
    },

    mainIllu: {
      background: `url(${mainIllu})`,
    },
  }),
);

const MarketingMessage = (props: Props) => {
  const {
    onClick,
    message,
    errorMessage,
    mainIllu,
    catchTitle,
    catchPhrases,
    close,
  } = props;
  const { t } = useTranslation('common');
  const { classes, cx } = useStyles({ mainIllu });

  return (
    <div
      className={cx(
        classes.martekingMessageRoot,
        (message || errorMessage) && 'afterClick',
      )}
    >
      <Button className="close" onClick={close}>
        <FontAwesomeIcon name="xmark:regular" className="h-5 w-5" />
      </Button>
      {message ? (
        <>
          <div className="illu-ok illu" />
          <Typography className="speech">{message}</Typography>
        </>
      ) : (
        <>
          <div className={cx(classes.mainIllu, 'illu')} />
          <div className="speech">
            <Typography variant="title" className={classes.catchTitle}>
              {catchTitle}
            </Typography>
            {catchPhrases.map((phrase, i) => (
              <Typography
                variant="body"
                className="catchPhrase"
                // eslint-disable-next-line react/no-array-index-key
                key={`marketingPhrases${i}`}
              >
                <BulletCheck />
                <span>{phrase}</span>
              </Typography>
            ))}
            <Button
              variant="contained"
              color="primary"
              onClick={onClick}
              className="btn"
            >
              {t('WaitingRoomDialog_NeedContactTlc_button_label')}
            </Button>
          </div>
        </>
      )}
      {errorMessage && (
        <>
          <div className="illu-ko illu" />
          <Typography className="speech">{errorMessage}</Typography>
        </>
      )}
    </div>
  );
};

export default MarketingMessage;
