import React, { useState } from 'react';
import { useDebounce, useUpdateEffect } from 'react-use';
import { InputProps as IProps, TextFieldProps } from '@mui/material';

import TextField from './TextField';

type Props = {
  onChange: (text: string) => void;
  value?: string;
  InputProps: IProps;
  rows?: number;
} & TextFieldProps;

const DebouncedTextField = (props: Props) => {
  const { onChange, value = '', InputProps = {}, ...rest } = props;
  const [text, setText] = useState<string>(value as string);

  useUpdateEffect(() => {
    setText(value);
  }, [value]);

  const [, cancel] = useDebounce(() => onChange && onChange(text), 500, [text]);

  const { onBlur } = InputProps;

  return (
    <TextField
      disabled={false}
      onKeyDown={() => {}}
      onClick={() => {}}
      {...rest}
      InputProps={{
        ...InputProps,
        onBlur: (event: any) => {
          if (onBlur) {
            onBlur(event);
          }
          cancel();
          if (onChange) {
            onChange(event.target.value);
          }
        },
      }}
      value={text}
      onChange={event => setText(event.target.value)}
    />
  );
};

export default DebouncedTextField;
