import {
  CHATUTILS_IS_ADD_MEMBER_DIALOG_OPEN,
  CHATUTILS_IS_CREATE_GROUP_DIALOG_OPEN,
  CHATUTILS_IS_HEADER_GROUP_SIDEBAR_OPEN,
  CHATUTILS_IS_PATIENT_DETAILS_DRAWER_OPEN,
  CHATUTILS_SEARCH_TERM,
  CHATUTILS_SHARED_PATIENT_INFOS,
} from '../actions/chatUtils';

export type ChatUtilsState = {
  isCreateGroupDialogOpen: boolean;
  isAddMembersDialogOpen: boolean;
  isHeaderGroupSidebarOpen: boolean;
  isPatientDetailsDrawerOpen: boolean;
  searchTerm: string;
  sharedPatientInfos: { creationDate: string } | null;
};

export const initialState: ChatUtilsState = {
  isCreateGroupDialogOpen: false,
  isAddMembersDialogOpen: false,
  isHeaderGroupSidebarOpen: false,
  isPatientDetailsDrawerOpen: false,
  searchTerm: '',
  sharedPatientInfos: null,
};

function chatUtils(
  state = initialState,
  action: { type: string; payload: any },
): ChatUtilsState {
  switch (action.type) {
    case CHATUTILS_IS_CREATE_GROUP_DIALOG_OPEN: {
      return {
        ...state,
        isCreateGroupDialogOpen: action.payload,
      };
    }
    case CHATUTILS_IS_ADD_MEMBER_DIALOG_OPEN: {
      return {
        ...state,
        isAddMembersDialogOpen: action.payload,
      };
    }
    case CHATUTILS_IS_HEADER_GROUP_SIDEBAR_OPEN: {
      return {
        ...state,
        isHeaderGroupSidebarOpen: action.payload,
      };
    }
    case CHATUTILS_IS_PATIENT_DETAILS_DRAWER_OPEN: {
      return {
        ...state,
        isPatientDetailsDrawerOpen: action.payload,
      };
    }
    case CHATUTILS_SEARCH_TERM: {
      return {
        ...state,
        searchTerm: action.payload,
      };
    }
    case CHATUTILS_SHARED_PATIENT_INFOS: {
      return {
        ...state,
        sharedPatientInfos: action.payload,
      };
    }
    default:
      return state;
  }
}

export default chatUtils;
