import { HYDRATE } from 'next-redux-wrapper';
import { combineReducers, Reducer } from 'redux';

import {
  agendaSettings,
  agendaSettingsDefault,
  agendaSettingsDefaultInitialState,
  agendaSettingsInitialState,
  centers,
  centersInitialState,
  chatDrafts,
  chatDraftsInitialState,
  chatInvitations,
  chatInvitationsInitialState,
  chatMessages,
  chatMessagesInitialState,
  chatReplyMessages,
  chatReplyMessagesInitialState,
  chatResourceList,
  chatRoomDocuments,
  chatRooms,
  chatRoomsInitialState,
  chatThreads,
  chatUsersConnected,
  chatUsersConnectedInitialState,
  chatUsersTyping,
  chatUsersTypingInitialState,
  getTaskPatternForGetListAction,
  searchInvitation,
  searchInvitationInitialState,
  teleExpertise,
  teleExpertiseInitialState,
  users,
  usersInitialState,
} from '@docavenue/chat';
import {
  addressesReducer as addresses,
  crudInitialDefaultState as initialStateCrudState,
  errorReducer as errors,
  // eslint-disable-next-line import/no-unresolved
  HEALTH_CHECK_ACCOUNT_CREATION_PRO,
  HEALTH_CHECK_FRONT_PRO,
  HEALTH_CHECK_IDENTITY_VERIFICATION_PRO,
  loadingReducer as loading,
  reducersGenerationHelper,
  storeReducer,
  websocketReducer as websocket,
} from '@docavenue/core';

import config from '../config';
import { getOffset } from '../utils';
import announcements, {
  initialState as initialStateAnnouncements,
} from './announcement';
import appointments, {
  initialState as appointmentsInitialState,
} from './appointments';
import appointmentsNotes, {
  initialState as appointmentsNotesInitialState,
} from './appointmentsNotes';
import appointmentsTlc, {
  initialState as appointmentsTlcInitialState,
} from './appointmentsTlc';
import availablePractitioners from './availablePractitioners';
import bcbDosages from './bcbDosages';
import bcbProducts from './bcbProducts';
import calendar, { initialState as initialStateCalendar } from './calendar';
import chatUtils, { initialState as initialStateChatUtils } from './chatUtils';
import complexForm, {
  initialState as complexFormInitialState,
} from './complexForm';
import confirmLogout, {
  initialState as confirmLogoutInitialState,
} from './confirmLogout';
import dialog, { initialState as dialogInitialState } from './dialog';
import documentsHistory, {
  initialState as documentsHistoryInitialState,
} from './documentsHistory';
import exportStatisticsDocument, {
  initialState as exportStatisticsDocumentState,
} from './exportStatisticsDocument';
import loaderMaiia, {
  initialState as loaderMaiiaInitialState,
} from './loaderMaiia';
import medicalCertificate, {
  initialStateMedicalCertificate,
} from './medicalCertificate';
import medicalReport, { initialStateMedicalReport } from './medicalReport';
import mergePatients, {
  initialState as mergePatientsInitialState,
} from './mergePatients';
import miniVideoSession, {
  initialState as miniVideoSessionInitialState,
} from './miniVideoSession';
import notificationCenters from './notificationCenters';
import notificationSettings from './notificationSettings';
import offset, { initialState as offsetInitialState } from './offset';
import patients from './patients';
import prescription, {
  initialState as PrescriptionState,
} from './prescription';
import snacks, { initialState as snacksInitialState } from './snacks';
import teleExpertiseFilters, {
  initialState as TeleExpertiseFiltersState,
} from './teleExpertiseFilters';
import timeSlotResourcesCalendar, {
  initialState as timeSlotResourcesCalendarInitialState,
} from './timeSlotResourcesCalendar';
import timeSlots, { initialState as timeSlotsInitialState } from './timeSlots';
import videoSessions, {
  initialState as videoSessionsInitialState,
} from './videoSessions';

const resourcesList = [
  { name: 'invitationSuggestions' },
  { name: 'acknowledgeDocuments' },
  { name: 'actionsHistory' },
  { name: 'agendaSettingsCenters' },
  { name: 'appointmentsAbsencesConflicts' },
  { name: 'appointmentsConflicts' },
  { name: 'appointmentsHistory' },
  { name: 'availablePractitioners' },
  { name: 'authentication' },
  { name: 'autoOnBoarding' },
  { name: 'availabilities' },
  { name: 'build' },
  { name: 'centersChat' },
  { name: 'centersParent' },
  { name: 'clearAccessRequest' },
  { name: 'complexFormData' },
  { name: 'contacts' },
  { name: 'consultationReasons' },
  { name: 'consultationReasonsParent' },
  { name: 'consultationReasonsComplexForm' },
  { name: 'consultationReasonsComplexFormRoot' },
  { name: 'creatableConsultationReasons' },
  { name: 'creatableConsultationReasonsGroups' },
  { name: 'consultationTypes' },
  { name: 'documents' },
  { name: 'expertises' },
  { name: 'feedbackTLC' },
  { name: 'leaveCenters' },
  { name: 'leaveCentersRoot' },
  { name: 'nextAvailabilities' },
  { name: 'notificationSettingsTokens' },
  { name: 'omnidocDocuments' },
  { name: 'onfido' },
  { name: 'passwordChanges' },
  { name: 'passwordRequests' },
  { name: 'passwordReset' },
  { name: 'passwordResetToken' },
  { name: 'payments' },
  { name: 'pendingAppointments' },
  { name: 'practitioners' },
  { name: 'practitionersComplexForm' },
  { name: 'practitionersComplexFormRoot' },
  { name: 'practitionersDefault' },
  { name: 'practitionersTimeSlots' },
  { name: 'printableAppointments' },
  { name: 'profilePictures' },
  { name: 'profiles' },
  { name: 'profilesParent' },
  { name: 'refresh' },
  { name: 'resourceLabels' },
  { name: 'resources' },
  { name: 'resourcesTimeSlot' },
  { name: 'resourcesTimeSlotGroup' },
  { name: 'timeSlotResourcesCalendar' },
  { name: 'resourcesCenter' },
  { name: 'substitutePractitioners' },
  { name: 'search' },
  { name: 'shareDocuments' },
  { name: 'specialities' },
  { name: 'statistics' },
  { name: 'teleconsultationReminders' },
  { name: 'trackingProfile' },
  { name: 'treatments' },
  { name: 'specialitiesComplexForm' },
  { name: 'specialitiesComplexFormRoot' },
  { name: 'userPractitioners' },
  { name: 'posologies' },
  { name: 'videoSessions' },
  { name: 'weekTemplateCycles' },
  { name: 'weekTemplateCyclesConflicts' },
  { name: 'chatOnBoarding' },
  { name: 'verifyIdentity' },
  { name: 'existingEmail' },
  { name: 'confirmCode' },
  { name: 'resendCode' },
  { name: 'unreadMessages' },
  { name: 'recurringAvailabilities' },
  { name: 'videoProMeeting' },
  { name: 'rightsCenter' },
  { name: 'assistedTLC' },
  { name: 'patientReferral' },
  { name: 'conventionedActs' },
  { name: 'medicalContexts' },
  { name: 'secretaryInstructions' },
  { name: 'secretaryInstructionCategories' },
  { name: 'phoneCheck' },
];
const reducers = reducersGenerationHelper(
  resourcesList.map(({ name }) => ({
    name,
  })),
);

const mergedReducers = {
  ...reducers,
  addresses,
  agendaSettings,
  chatRoomDocuments,
  agendaSettingsDefault,
  appointments,
  appointmentsNotes,
  appointmentsTlc,
  availablePractitioners,
  bcbDosages,
  bcbProducts,
  calendar,
  centers,
  chatDrafts,
  chatMessages,
  chatReplyMessages,
  chatInvitations,
  teleExpertise,
  chatRooms,
  chatThreads,
  chatUsersConnected,
  chatUsersTyping,
  complexForm,
  confirmLogout,
  dialog,
  errors,
  exportStatisticsDocument,
  loaderMaiia,
  teleExpertiseFilters,
  loading,
  mergePatients,
  notificationCenters,
  notificationSettings,
  offset,
  chatUtils,
  patients,
  prescription,
  snacks,
  timeSlotResourcesCalendar,
  timeSlots,
  users,
  websocket,
  announcements: announcements([
    HEALTH_CHECK_FRONT_PRO,
    HEALTH_CHECK_ACCOUNT_CREATION_PRO,
    HEALTH_CHECK_IDENTITY_VERIFICATION_PRO,
  ]),
  documentsHistory,
  videoSessions,
  searchInvitation,
  medicalReport,
  miniVideoSession,
  medicalCertificate,
};

const sortedReducers: any = {};

Object.keys(mergedReducers)
  .sort()
  .forEach((key: string) => {
    sortedReducers[key] = mergedReducers[key];
  });

// TODO: order by name for better readability in redux dev tools
// TODO(nampnq): Add flow type action instead object of combineReducers<reducers, Action>
export const appReducer = combineReducers(sortedReducers);

export const resourcesGetListWithoutConcurrency = [
  ...resourcesList,
  ...chatResourceList,
  { name: 'appointments', getTaskPatternForGetListAction },
  { name: 'bcbProducts' },
  { name: 'chatMessages' },
  { name: 'documentsHistory' },
  { name: 'bcbDosages' },
  { name: 'appointmentsNotes', getTaskPatternForGetListAction },
  { name: 'notificationSettings' },
  { name: 'patients' },
  { name: 'timeSlots', getTaskPatternForGetListAction },
];

export const initialState = {
  invitationSuggestions: initialStateCrudState,
  phoneCheck: initialStateCrudState,
  announcements: initialStateAnnouncements,
  acknowledgeDocuments: initialStateCrudState,
  actionsHistory: initialStateCrudState,
  addresses: initialStateCrudState,
  agendaSettings: agendaSettingsInitialState,
  agendaSettingsCenters: initialStateCrudState,
  agendaSettingsDefault: agendaSettingsDefaultInitialState,
  appointments: appointmentsInitialState,
  appointmentsAbsencesConflicts: initialStateCrudState,
  appointmentsConflicts: appointmentsInitialState,
  appointmentsHistory: initialStateCrudState,
  appointmentsNotes: appointmentsNotesInitialState,
  appointmentsTlc: appointmentsTlcInitialState,
  availablePractitioners: initialStateCrudState,
  authentication: initialStateCrudState,
  autoOnBoarding: initialStateCrudState,
  availabilities: initialStateCrudState,
  bcbDosages: initialStateCrudState,
  bcbProducts: initialStateCrudState,
  build: {
    ...initialStateCrudState,
    item: {
      id: +new Date(),
      releaseVersion: config.get('RELEASE_VERSION'),
      reload: false,
    },
  },
  calendar: initialStateCalendar,
  centers: centersInitialState,
  centersChat: initialStateCrudState,
  centersParent: initialStateCrudState,
  complexFormData: initialStateCrudState,
  chatDrafts: chatDraftsInitialState,
  chatMessages: chatMessagesInitialState,
  chatInvitations: chatInvitationsInitialState,
  teleExpertise: teleExpertiseInitialState,
  chatReplyMessages: chatReplyMessagesInitialState,
  chatRooms: chatRoomsInitialState,
  chatUtils: initialStateChatUtils,
  chatThreads: initialStateCrudState,
  chatUsersConnected: chatUsersConnectedInitialState,
  searchInvitation: searchInvitationInitialState,
  chatUsersTyping: chatUsersTypingInitialState,
  clearAccessRequest: initialStateCrudState,
  complexForm: complexFormInitialState,
  confirmLogout: confirmLogoutInitialState,
  consultationReasons: initialStateCrudState,
  consultationReasonsComplexForm: initialStateCrudState,
  consultationReasonsComplexFormRoot: initialStateCrudState,
  consultationReasonsParent: initialStateCrudState,
  consultationTypes: initialStateCrudState,
  contacts: initialStateCrudState,
  creatableConsultationReasons: initialStateCrudState,
  creatableConsultationReasonsGroups: initialStateCrudState,
  dialog: dialogInitialState,
  documents: initialStateCrudState,
  errors: {},
  expertises: initialStateCrudState,
  exportStatisticsDocument: exportStatisticsDocumentState,
  feedbackTLC: initialStateCrudState,
  leaveCenters: initialStateCrudState,
  leaveCentersRoot: initialStateCrudState,
  loaderMaiia: loaderMaiiaInitialState,
  teleExpertiseFilters: TeleExpertiseFiltersState,
  loading: {},
  mergePatients: mergePatientsInitialState,
  nextAvailabilities: initialStateCrudState,
  notificationCenters: initialStateCrudState,
  notificationSettings: initialStateCrudState,
  notificationSettingsTokens: initialStateCrudState,
  offset: offsetInitialState,
  omnidocDocuments: initialStateCrudState,
  onfido: initialStateCrudState, // initialStateCrudState,
  passwordChanges: initialStateCrudState,
  passwordRequests: initialStateCrudState,
  passwordReset: initialStateCrudState,
  passwordResetToken: initialStateCrudState,
  patients: { ...initialStateCrudState, page: 0 },
  payments: initialStateCrudState,
  pendingAppointments: initialStateCrudState,
  practitioners: initialStateCrudState,
  practitionersComplexForm: initialStateCrudState,
  practitionersComplexFormRoot: initialStateCrudState,
  practitionersDefault: initialStateCrudState,
  practitionersTimeSlots: initialStateCrudState,
  prescription: PrescriptionState,
  printableAppointments: initialStateCrudState,
  profilePictures: initialStateCrudState,
  profiles: initialStateCrudState,
  profilesParent: initialStateCrudState,
  refresh: initialStateCrudState,
  resourceLabels: initialStateCrudState,
  resources: initialStateCrudState,
  resourcesTimeSlot: initialStateCrudState,
  resourcesTimeSlotGroup: initialStateCrudState,
  resourcesCenter: initialStateCrudState,
  search: initialStateCrudState,
  shareDocuments: initialStateCrudState,
  snacks: snacksInitialState,
  specialities: initialStateCrudState,
  specialitiesComplexForm: initialStateCrudState,
  specialitiesComplexFormRoot: initialStateCrudState,
  statistics: initialStateCrudState,
  substitutePractitioners: initialStateCrudState,
  teleconsultationReminders: initialStateCrudState,
  trackingProfile: initialStateCrudState,
  timeSlots: timeSlotsInitialState,
  timeSlotResourcesCalendar: timeSlotResourcesCalendarInitialState,
  treatments: initialStateCrudState,
  userPractitioners: initialStateCrudState,
  users: usersInitialState,
  videoSessions: videoSessionsInitialState,
  websocket: { connected: false, rooms: [], countReconnections: 0 },
  weekTemplateCycles: initialStateCrudState,
  weekTemplateCyclesConflicts: initialStateCrudState,
  documentsHistory: documentsHistoryInitialState,
  unreadMessages: initialStateCrudState,
  recurringAvailabilities: initialStateCrudState,
  videoProMeeting: initialStateCrudState,
  medicalReport: initialStateMedicalReport,
  rightsCenter: initialStateCrudState,
  miniVideoSession: miniVideoSessionInitialState,
  medicalCertificate: initialStateMedicalCertificate,
};

const withHydrate = (reducer: Reducer): Reducer => (state, action) => {
  /**
   * HYDRATE action is dispatched both on server and client, see: https://github.com/kirill-konshin/next-redux-wrapper#how-it-works
   * on _app's getInitialProps, or page's getXXXProps
   *
   * That way, we can alter the state client-side before any render (keeping the serialized state from server)
   */
  if (action.type === HYDRATE) {
    let newState = { ...state, ...action.payload };
    /**
     * This fragment of code comes from https://gitlab.maiia.io/dev/maiia-frontend/-/blob/980a3d34e4/packages/pro-frontend/src/store.ts#L77-86
     */
    if (typeof window !== 'undefined') {
      if (newState.centers.item) {
        const offsetFromServerStore = getOffset(newState.centers.item);
        newState = {
          ...newState,
          offset: {
            offset: offsetFromServerStore,
          },
        };
      }
    }
    return newState;
  }
  // by default, return the result of the decorated reducer
  return reducer(state, action);
};

const rootReducer = withHydrate(
  storeReducer(appReducer, JSON.parse(JSON.stringify(initialState))),
);

export type RootReducer = typeof rootReducer;

export default rootReducer;
