import { createContext } from 'react';

type AutoCompleteType = {
  resourceLabel: string;
  dividerMatchLabel?: string;
  dividerUnMatchLabel?: string;
  emptyStateLabel?: string;
};

const AutoCompleteContext = createContext<AutoCompleteType | null>(null);

export default AutoCompleteContext;
