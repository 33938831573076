/* eslint-disable react/jsx-no-literals */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Snackbar, SxProps } from '@mui/material';
import { closeMiniVideoSession } from 'packages/pro-frontend/src/actions/miniVideoSession';
import {
  useIsTlcStarted,
  useMiniVideoSession,
  useVideoSessions,
} from 'packages/pro-frontend/src/hooks/selectors';
import {
  useAcceptPatient,
  useRefusePatient,
} from 'packages/pro-frontend/src/hooks/videoSession';

import { useTranslation } from '../../../i18n';
import TlcErrorsBeforeMeet from '../Tlc/TlcErrorsBeforeMeet';
import TlcNetworkTestingLoading from '../Tlc/TlcNetworkTestingLoading';
import RefusePatientDialog from '../VideoSessionItem/RefusePatientDialog';
import MiniVideoSessionPendingDialogActions from './MiniVideoSessionPendingDialogActions';
import MiniVideoSessionPendingDialogInfo from './MiniVideoSessionPendingDialogInfo';

import { TLC_24_7 } from '@/src/constants';
import OpenTokNetworkTestingProvider from '@/src/contexts/OpenTokNetworkTestingContext';
import useOpenTokNetworkTestingContext from '@/src/hooks/useOpenTokNetworkTestingContext';
import { getPatientDisplayName } from '@/src/utils';
import { VideoSessionAggregate } from '@/types/pro.types';

const styles: { [key: string]: SxProps } = {
  container: {
    bgcolor: 'white',
    display: 'flex',
    flexDirection: 'column',
    pb: 4,
    gap: 4,
    boxShadow: '0px 8px 25px 0px #0000001A',
    borderRadius: '8px',
    overflow: 'hidden',
    minWidth: `384px`,
  },
  header: {
    color: 'white',
    backgroundColor: 'secondary.main',
    px: 4,
    py: 2,
    fontWeight: 'bold',
    width: '100%',
  },
};

type Props = {
  open: boolean;
  videoSession: NonNullable<VideoSessionAggregate>;
};

const MiniVideoSessionPendingDialog = ({ open, videoSession }: Props) => {
  const [isRefuseDialogOpen, setIsRefuseDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    runNetworkTest,
    networkTestResult,
    getSimplifiedError,
    retryLatestTest,
  } = useOpenTokNetworkTestingContext();

  const { id: videoSessionId } = videoSession;
  const isPriorityPatient = videoSession?.amcTlcType === TLC_24_7;
  const { refuse: refusePatient } = useRefusePatient({ videoSession });
  const acceptPatientControls = useAcceptPatient({
    videoSession,
    miniDialog: true,
  });

  const handleClose = () => {
    dispatch(closeMiniVideoSession());
  };

  const onStart = () => {
    runNetworkTest({
      network: true,
      quality: false,
      loggingInfo: {
        videoSessionId,
        origin: 'TlcMiniDialog',
      },
      onSuccessCallback() {
        handleClose();
        acceptPatientControls?.acceptPatient();
      },
    });
  };

  return (
    <>
      <Snackbar open={open}>
        <Box sx={styles.container}>
          <Box sx={styles.header}>
            {t('common:miniVideoSessionPendingDialog_title')}
          </Box>
          {networkTestResult.connectivityTest.loading && (
            <div>
              <TlcNetworkTestingLoading />
            </div>
          )}
          <div>
            <TlcErrorsBeforeMeet
              retryLatestTest={retryLatestTest}
              networkTestResult={networkTestResult}
              getSimplifiedError={getSimplifiedError}
            />
          </div>
          <MiniVideoSessionPendingDialogInfo
            name={getPatientDisplayName(videoSession.patient!)}
            reason={videoSession.consultationReasonLabel ?? ''}
            birthday={videoSession.patient!.birthDate}
            patientId={videoSession.patient!.id}
            isPriority={isPriorityPatient}
          />
          <MiniVideoSessionPendingDialogActions
            onReject={() => setIsRefuseDialogOpen(true)}
            onWait={handleClose}
            onStart={onStart}
          />
        </Box>
      </Snackbar>
      <RefusePatientDialog
        item={videoSession}
        isOpen={isRefuseDialogOpen}
        handleRefuse={refusePatient}
        handleCancel={() => setIsRefuseDialogOpen(false)}
      />
    </>
  );
};

const MiniVideoSessionPendingDialogWrapper = () => {
  const { open, videoSessionId } = useMiniVideoSession();
  const isTlcStarted = useIsTlcStarted();
  const videoSessions = useVideoSessions();
  const filteredVideoSession = videoSessions.filter(
    vSession => vSession.id === videoSessionId,
  );

  const videoSession = filteredVideoSession[0];

  if (!videoSession || !videoSession.patient || isTlcStarted) {
    return null;
  }

  return (
    <OpenTokNetworkTestingProvider>
      <MiniVideoSessionPendingDialog open={open} videoSession={videoSession} />
    </OpenTokNetworkTestingProvider>
  );
};

export default MiniVideoSessionPendingDialogWrapper;
