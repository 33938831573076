import React, { ComponentProps } from 'react';
import { IMaskInput as Input } from 'react-imask';

type Props = {
  onChange: (object: Object) => void;
  value: string | null;
  unmask: boolean | 'typed';
  mask?: ComponentProps<typeof Input>;
};

const IMaskInput = React.forwardRef((props: Props, ref) => {
  const { onChange, unmask = false, mask: maskProps, ...rest } = props;

  return (
    <Input
      {...rest}
      {...maskProps}
      inputRef={ref}
      unmask={unmask} // true|false|'typed'
      onAccept={
        // depending on prop above first argument is
        // `value` if `unmask=false`,
        // `unmaskedValue` if `unmask=true`,
        // `typedValue` if `unmask='typed'`
        // eslint-disable-next-line no-unused-vars
        (newValue: string, mask: { unmaskedValue: string }) => {
          // WARNING: without this check, if a value is provided, on new onChange is trigger one level up
          if (newValue !== props.value) {
            onChange({
              target: { value: unmask ? mask.unmaskedValue : newValue },
            });
          }
        }
      }
    />
  );
});

IMaskInput.displayName = 'IMaskInput';

export default IMaskInput;
