import React, { useCallback, useEffect, useState } from 'react';
import Bowser from 'bowser';

import MediaDevicesContext, { MediaDeviceItem } from './MediaDevicesContext';

import {
  MediaPermissionsError,
  requestAudioPermissions,
  requestMediaPermissions,
  requestVideoPermissions,
} from '@/src/utils/mediaDevicesUtils';

type Props = { children: React.ReactNode };

type MediaDevice = {
  audio: MediaDeviceItem;
  video: MediaDeviceItem;
};

/**
 * This component centralizes the management of requests for accessing the camera and microphone. It performs the following tasks:
 * - It enables the correct listener to be activated depending on the browser.
 * - It also returns a more precise error message depending on the browser.
 * - Finally, any component may, at any time, view the status of a request and/or display its error message by simply accessing the context.
 */
const MediaDevicesContextProvider = ({ children }: Props) => {
  const [mediaDevices, setMediaDevices] = useState<MediaDevice>({
    audio: {
      state: undefined,
    },
    video: {
      state: undefined,
    },
  });

  const [mediaWatcher, setMediaWatcher] = useState<{
    audio: {
      hasSetListener: boolean;
      permissionStatus: PermissionStatus | null;
    };
    video: {
      hasSetListener: boolean;
      permissionStatus: PermissionStatus | null;
    };
  }>({
    audio: {
      hasSetListener: false,
      permissionStatus: null,
    },
    video: {
      hasSetListener: false,
      permissionStatus: null,
    },
  });

  /**
   * Since the query API for name "camera" and "microphone" are only available in chrome,
   *  therefore, we enable the watcher
   */
  const enableDeviceWatchForChromeLikeBrowsers = useCallback(
    (name: 'camera' | 'microphone') => {
      if (name === 'microphone' && mediaWatcher.audio.hasSetListener) return;
      if (name === 'camera' && mediaWatcher.video.hasSetListener) return;

      const browser = Bowser.getParser(window.navigator.userAgent);
      const browserName = browser.getBrowserName();
      if (
        !(
          // Because at the moment, only MacOs has a consistent state between device permission status (granted | denied | prompt) and error related.
          //  meaning that in Windows for instance, we could have a granted state with an error along side.
          //  especially when another app is already using the device and the user grant us the device as well. In such case, we'd not have access to the raw material even though we have a grant status
          (
            browser.getOSName() === 'macOs' &&
            (browserName === 'Chrome' || browserName === 'Chromium')
          )
        )
      )
        return;

      navigator.permissions
        .query({ name: name as PermissionName })
        .then(permissionStatus => {
          setMediaWatcher(_mediaWatcher => {
            const newState = permissionStatus.state;
            const key: keyof MediaDevice =
              name === 'microphone' ? 'audio' : 'video';
            setMediaDevices(_mediaDevices => ({
              ..._mediaDevices,
              [key]: {
                state: newState,
                error:
                  newState === 'granted' ? undefined : _mediaDevices[key].error,
              },
            }));
            const newMediaWatcher = { ..._mediaWatcher };
            if (name === 'microphone')
              newMediaWatcher.audio.permissionStatus = permissionStatus;
            else newMediaWatcher.video.permissionStatus = permissionStatus;

            return newMediaWatcher;
          });
        })
        // The browser doest not support the given permission
        .catch(() => {});
    },
    [mediaWatcher],
  );

  /**
   * Audio device listener
   */
  useEffect(() => {
    if (mediaWatcher.audio.hasSetListener) return;
    if (!mediaWatcher.audio.permissionStatus) return;

    function permissionChangeHandler(this: PermissionStatus) {
      // eslint-disable-next-line react/no-this-in-sfc
      const newState = this.state;
      // eslint-disable-next-line react/no-this-in-sfc
      const { name } = this;
      const key: keyof MediaDevice =
        name === 'audio_capture' || name === 'audio' ? 'audio' : 'video';
      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        [key]: {
          state: newState,
          error: newState === 'granted' ? undefined : _mediaDevices[key].error,
        },
      }));
    }

    mediaWatcher.audio.permissionStatus.addEventListener(
      'change',
      permissionChangeHandler,
    );

    // Set only one listener per device type
    setMediaWatcher(_mediaWatcher => {
      const newMediaWatcher = { ..._mediaWatcher };
      newMediaWatcher.audio.hasSetListener = true;
      return newMediaWatcher;
    });

    return () => {
      mediaWatcher.audio.permissionStatus?.removeEventListener(
        'change',
        permissionChangeHandler,
      );
    };
  }, [mediaWatcher.audio.permissionStatus]);

  /**
   * Video device listener
   */
  useEffect(() => {
    if (mediaWatcher.video.hasSetListener) return;
    if (!mediaWatcher.video.permissionStatus) return;

    function permissionChangeHandler(this: PermissionStatus) {
      // eslint-disable-next-line react/no-this-in-sfc
      const newState = this.state;
      // eslint-disable-next-line react/no-this-in-sfc
      const { name } = this;
      const key: keyof MediaDevice =
        name === 'audio_capture' || name === 'audio' ? 'audio' : 'video';
      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        [key]: {
          state: newState,
          error: newState === 'granted' ? undefined : _mediaDevices[key].error,
        },
      }));
    }

    mediaWatcher.video.permissionStatus.addEventListener(
      'change',
      permissionChangeHandler,
    );

    // Set only one listener per device type
    setMediaWatcher(_mediaWatcher => {
      const newMediaWatcher = { ..._mediaWatcher };
      newMediaWatcher.video.hasSetListener = true;
      return newMediaWatcher;
    });

    return () => {
      mediaWatcher.video.permissionStatus?.removeEventListener(
        'change',
        permissionChangeHandler,
      );
    };
  }, [mediaWatcher.video.permissionStatus]);

  const requestAudioPermission = useCallback(async (): Promise<
    MediaDeviceItem
  > => {
    try {
      await requestAudioPermissions();
      enableDeviceWatchForChromeLikeBrowsers('microphone');

      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        audio: { state: 'granted' },
      }));
      return {
        state: 'granted',
      };
    } catch (err) {
      enableDeviceWatchForChromeLikeBrowsers('microphone');
      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        audio: { state: 'denied', error: err as MediaPermissionsError },
      }));
      return {
        state: 'denied',
        error: err as MediaPermissionsError,
      };
    }
  }, [enableDeviceWatchForChromeLikeBrowsers]);

  const requestVideoPermission = useCallback(async (): Promise<
    MediaDeviceItem
  > => {
    try {
      await requestVideoPermissions();
      enableDeviceWatchForChromeLikeBrowsers('camera');

      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        video: { state: 'granted' },
      }));
      return {
        state: 'granted',
      };
    } catch (err) {
      enableDeviceWatchForChromeLikeBrowsers('camera');
      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        video: { state: 'denied', error: err as MediaPermissionsError },
      }));
      return {
        state: 'denied',
        error: err as MediaPermissionsError,
      };
    }
  }, []);

  /**
   * Make both audio and video requests at the same time
   */
  const requestMediaPermission = useCallback(async () => {
    try {
      await requestMediaPermissions();
      enableDeviceWatchForChromeLikeBrowsers('microphone');
      enableDeviceWatchForChromeLikeBrowsers('camera');

      const newMediaDevices: MediaDevice = {
        audio: { state: 'granted' },
        video: { state: 'granted' },
      };
      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        ...newMediaDevices,
      }));
      return newMediaDevices.audio;
    } catch (err) {
      enableDeviceWatchForChromeLikeBrowsers('microphone');
      enableDeviceWatchForChromeLikeBrowsers('camera');

      const newMediaDevices: MediaDevice = {
        audio: { state: 'denied', error: err as MediaPermissionsError },
        video: { state: 'denied', error: err as MediaPermissionsError },
      };
      setMediaDevices(_mediaDevices => ({
        ..._mediaDevices,
        ...newMediaDevices,
      }));
      return newMediaDevices.audio;
    }
  }, [enableDeviceWatchForChromeLikeBrowsers]);

  return (
    <MediaDevicesContext.Provider
      value={{
        audio: mediaDevices.audio,
        video: mediaDevices.video,
        hasAccessToAudio:
          mediaDevices.audio.state !== 'denied' &&
          mediaDevices.audio.error === undefined,
        hasAccessToVideo:
          mediaDevices.video.state !== 'denied' &&
          mediaDevices.video.error === undefined,
        requestAudioPermission,
        requestVideoPermission,
        requestMediaPermission,
      }}
    >
      {children}
    </MediaDevicesContext.Provider>
  );
};

export default MediaDevicesContextProvider;
