import {
  Center,
  CenterDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

// eslint-disable-next-line import/prefer-default-export
export const convertCenterDTOToCenter = (centerDTO: CenterDTO): Center => {
  return {
    ...centerDTO,
    id: centerDTO.id || '',
    customerCenterId: centerDTO.customerCenterId || '',
    name: centerDTO.name || '',
    status: centerDTO.status || 'ACTIVE',
  };
};
