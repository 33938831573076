import { actionsGeneratorV2 } from '@docavenue/core';
import { TimeSlotResourceDTO } from '@maiia/model/generated/model/api-pro/api-pro';

const resourcesTimeSlotActions = actionsGeneratorV2({
  resource: 'resourcesTimeSlot',
  chunkUrlResource: 'time-slot-resources',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { updateManyPost } = resourcesTimeSlotActions;

const updatePosition = (items: TimeSlotResourceDTO[], options: Object = {}) =>
  updateManyPost(items, {
    chunkUrlResource: 'time-slot-resources/update-position',
    ...options,
  });

export default {
  ...resourcesTimeSlotActions,
  updatePosition,
};
