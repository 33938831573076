import { actionsGeneratorV2 } from '@docavenue/core';

type SubstituedListParamsType = {
  userId: string;
  centerId: string;
  recursive?: boolean;
};

export function isSubstituedListParams(
  params: Record<string, any>,
): params is SubstituedListParamsType {
  return params.centerId && params.userId;
}

const practitionersActions = actionsGeneratorV2({
  resource: 'practitioners',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { getList } = practitionersActions;

const getSubstitutedList = (
  params: SubstituedListParamsType,
  options: Object = {},
) =>
  getList(params, {
    chunkUrlResource: 'users',
    chunkResource: 'substitutedPractitioners',
    ...options,
  });

export default {
  ...practitionersActions,
  getSubstitutedList,
};
