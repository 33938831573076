/**
 * Media access utility heavily inspired by https://github.com/helenamerk/mic-check/blob/main/src/requestMediaPermissions.tsx#
 * 
 * The errors differ based on browser and os. Therefore, they were able to summarize errors in the bellow table: 
 * 
    | Problem                                                                                  | OS (macOS, Windows) | Chrome                                         | Safari                                                                                                                                             | Edge                                           | Firefox                                                                                               | Error Type (MediaPermissionsError) | Recommended User Action                                                               |
    | ---------------------------------------------------------------------------------------- | ------------------- | ---------------------------------------------- | -------------------------------------------------------------------------------------------------------------------------------------------------- | ---------------------------------------------- | ----------------------------------------------------------------------------------------------------- | ---------------------------------- | ------------------------------------------------------------------------------------- |
    | Browser doesn't have System Preferences access to camera or mic                          | macOS               | NotAllowedError: Permission denied by system   | N/A (Safari always has access)                                                                                                                     | NotAllowedError: Permission denied             | NotFoundError: The object can not be found here.                                                      | SystemPermissionDenied             | Open Mac System Preferences and enable under Camera                                   |
    | Browser doesn't have System Preferences access to camera or mic                          | Windows             | NotReadableError: Could not start video source | N/A (Safari not available)                                                                                                                         | NotReadableError: Could not start video source | NotReadableError: Failed to allocate videosource                                                      | N/A                                | Open Windows Settings and enable under Camera                                         |
    | User denied permission to access camera or mic                                           | macOS, Windows      | NotAllowedError: Permission denied             | NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission. | NotAllowedError: Permission denied             | NotAllowedError: The request is not allowed by the user agent or the platform in the current context. | UserPermissionDenied               | Manually give permission by clicking on Camera Blocked icon (Safari needs a reprompt) |
    | Camera in use by another application (Zoom, Webex) or tab (Google Meet, Messenger Video) | Windows             | NotReadableError: Could not start video source | N/A (Safari not available)                                                                                                                         | NotReadableError: Could not start video source | AbortError: Starting videoinput failed                                                                | CouldNotStartVideoSource           | Turn off other video                                                                  |
    | All Other Errors                                                                         |                     |                                                |                                                                                                                                                    |                                                |                                                                                                       | Generic                            |                                                                                       |
 */

import Bowser from 'bowser';

export enum MediaPermissionsErrorType {
  /** (macOS) browser does not have permission to access cam/mic */
  SystemPermissionDenied = 'SystemPermissionDenied',
  /** user denied permission for site to access cam/mic */
  UserPermissionDenied = 'UserPermissionDenied',
  /** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
  CouldNotStartVideoSource = 'CouldNotStartVideoSource',
  /** all other errors */
  Generic = 'Generic',
}

export class MediaPermissionsError extends Error {
  type: MediaPermissionsErrorType;

  constructor(
    message: string,
    name?: string,
    stack?: string,
    type?: MediaPermissionsErrorType,
  ) {
    super(message);
    this.name = name || 'MediaPermissionsError';
    this.stack = stack || new Error(message).stack;
    this.type = type || MediaPermissionsErrorType.Generic;
  }
}

/**
 * Request camera and/or mic permissions from the browser.
 * @throws {MediaPermissionsError}
 */
export const requestMediaPermissions = async (
  /**
   * For audio only: ```{ audio: true, video: false }```
   *
   * For video only: ```{ audio: false, video: true }```
   *
   * @default ```{ audio: true, video: true }```
   */
  constraints?: MediaStreamConstraints,
): Promise<void> => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(
      constraints ?? { audio: true, video: true },
    );

    stream.getTracks().forEach(t => {
      t.stop();
    });
    return;
  } catch (err) {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName();
    const error =
      err instanceof Error
        ? new MediaPermissionsError(err.message, err.name, err.stack)
        : new MediaPermissionsError(`${err}`);

    if (browserName === 'Chrome') {
      if (error.name === 'NotAllowedError') {
        if (error.message === 'Permission denied by system') {
          error.type = MediaPermissionsErrorType.SystemPermissionDenied;
        } else if (error.message === 'Permission denied') {
          error.type = MediaPermissionsErrorType.UserPermissionDenied;
        }
      } else if (error.name === 'NotReadableError') {
        error.type = MediaPermissionsErrorType.CouldNotStartVideoSource;
      }
    } else if (browserName === 'Safari') {
      if (error.name === 'NotAllowedError') {
        error.type = MediaPermissionsErrorType.UserPermissionDenied;
      }
    } else if (browserName === 'Microsoft Edge') {
      if (error.name === 'NotAllowedError') {
        error.type = MediaPermissionsErrorType.UserPermissionDenied;
      } else if (error.name === 'NotReadableError') {
        error.type = MediaPermissionsErrorType.CouldNotStartVideoSource;
      }
    } else if (browserName === 'Firefox') {
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia#exceptions
      if (error.name === 'NotFoundError') {
        error.type = MediaPermissionsErrorType.SystemPermissionDenied;
      } else if (error.name === 'NotReadableError') {
        error.type = MediaPermissionsErrorType.SystemPermissionDenied;
      } else if (error.name === 'NotAllowedError') {
        error.type = MediaPermissionsErrorType.UserPermissionDenied;
      } else if (error.name === 'AbortError') {
        error.type = MediaPermissionsErrorType.CouldNotStartVideoSource;
      }
    }
    throw error;
  }
};

export const requestAudioPermissions = () =>
  requestMediaPermissions({ audio: true, video: false });
export const requestVideoPermissions = () =>
  requestMediaPermissions({ audio: false, video: true });
