import React from 'react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { makeQueryKey as makeQueryKeyConfigGlobalFeatureFlags } from '@maiia/model/generated/model/api-pro/hooks/proConfigGlobalFeatureFlags';

import { isPocReactQueryAgendaActivated } from '../utils/queryMigration';
import { useWebsocket } from '../WebsocketProvider';
import connectWebSocketBridge from './connectWebSocketBridge';

import { makeQueryKey as makeQueryKeyAppointments } from '@/src/hooks/proAppointmentsByPractitionerIdCenterIdAndTargetDate';

const showWsBridgeLogs =
  process.env.NEXT_PUBLIC_WS_BRIDGE_SHOW_LOGS === 'true' &&
  process.env.NODE_ENV === 'development';

/**
 * If you need to react to Websocket messages by acting on react-query, you are in the right place.
 * You have access to the queryClient.
 *
 * If you need to react to Websocket messages by acting on the redux store, you should go to `packages/pro-frontend/src/store.ts` `mappings`
 */
function onWsMessage(
  queryClient: QueryClient,
  webSocketMessage: { action?: string; resource?: string; payload?: any },
): void {
  const { action, resource, payload } = webSocketMessage;
  switch (action) {
    case 'DELETED':
    case 'CREATED':
    case 'UPDATED': {
      if (isPocReactQueryAgendaActivated() && resource === 'appointment') {
        const previousTargetDate = dayjs(payload.previousStartDate)
          .startOf('day')
          .toISOString();
        const nextTargetDate = dayjs(payload.startDate)
          .startOf('day')
          .toISOString();

        // queryKey invalidation - we make sure to call the server to have the latest version
        const queryKey = makeQueryKeyAppointments({
          practitionerId: payload.practitionerId,
          centerId: payload.centerId,
          targetDateKey: previousTargetDate,
        });
        queryClient.invalidateQueries({ queryKey });
        if (previousTargetDate !== nextTargetDate) {
          const queryKeyNextTargetDate = makeQueryKeyAppointments({
            practitionerId: payload.practitionerId,
            centerId: payload.centerId,
            targetDateKey: nextTargetDate,
          });
          queryClient.invalidateQueries({
            queryKey: queryKeyNextTargetDate,
          });
          // eslint-disable-next-line no-unused-expressions
          showWsBridgeLogs &&
            // eslint-disable-next-line no-console
            console.log(
              '[WebsocketBridge.onWsMessage][appointment] queryKeys to invalidate',
              queryKey,
              queryKeyNextTargetDate,
            );
        } else {
          // eslint-disable-next-line no-unused-expressions
          showWsBridgeLogs &&
            // eslint-disable-next-line no-console
            console.log(
              '[WebsocketBridge.onWsMessage][appointment] queryKey to invalidate',
              queryKey,
            );
        }
        break;
      }
      // eslint-disable-next-line no-unused-expressions
      showWsBridgeLogs &&
        // eslint-disable-next-line no-console
        console.log(
          `[WebsocketBridge.onWsMessage] action "${action}" ignored on resource "${resource}" ignored`,
        );
      break;
    }
    case 'SYSTEM': {
      if (resource === 'systemMessage') {
        const queryKey = makeQueryKeyConfigGlobalFeatureFlags();
        queryClient.invalidateQueries({ queryKey });
      }
      break;
    }
    default: {
      // eslint-disable-next-line no-unused-expressions
      showWsBridgeLogs &&
        // eslint-disable-next-line no-console
        console.log(
          `[WebsocketBridge.onWsMessage] action "${action}" ignored on resource "${resource}" ignored`,
        );
    }
  }

  connectWebSocketBridge({ queryClient, webSocketMessage });
}

export function WebsocketBridge() {
  const websocket = useWebsocket();
  const queryClient = useQueryClient();
  React.useEffect(() => {
    if (websocket) {
      websocket.on('message', (message: Record<string, any>) => {
        // eslint-disable-next-line no-unused-expressions
        showWsBridgeLogs &&
          // eslint-disable-next-line no-console
          console.log('[WebsocketBridge]', message);
        onWsMessage(queryClient, message);
      });
    }
  }, [websocket]);
  return null;
}
