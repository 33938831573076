import { useContext } from 'react';

import MediaDevicesContext, {
  MediaDevicesContextValue,
} from '../contexts/MediaDevicesContext/MediaDevicesContext';

const useMediaDeviceContext = (): MediaDevicesContextValue => {
  const context = useContext(MediaDevicesContext);

  if (!context) {
    throw new Error(
      'useMediaDeviceContext has to be used within <MediaDevicesContextProvider>',
    );
  }

  return context;
};

export default useMediaDeviceContext;
