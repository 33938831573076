import React, { forwardRef, Ref } from 'react';
import { makeStyles } from 'tss-react/mui';

import InputAdornment from '../../atoms/InputAdornment';

import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

const useStyles = makeStyles<SProps>()((_theme, { disabled }) => {
  return {
    root: {
      cursor: disabled ? 'not-allowed' : 'pointer',
      color: '#336071',
    },
  };
});

type Props = {
  setIsOpen: () => void;
  isOpen: boolean;
  disabled: boolean;
};

type SProps = Pick<Props, 'disabled'>;

const UnfoldInputAdornment = forwardRef(
  (props: Props, ref: Ref<HTMLInputElement>) => {
    const { setIsOpen, isOpen, disabled, ...rest } = props;
    const handleClick = () => {
      if (!disabled) setIsOpen();
    };
    const { classes } = useStyles({ disabled });
    if (disabled) return null;
    return (
      <InputAdornment
        className={classes.root}
        onClick={handleClick}
        ref={ref}
        position="end"
        {...rest}
      >
        <FontAwesomeIcon
          name="angle-down:regular"
          className="absolute right-2 top-3 h-4 w-4"
        />
      </InputAdornment>
    );
  },
);

UnfoldInputAdornment.displayName = 'UnfoldInputAdornment';

export default UnfoldInputAdornment;
