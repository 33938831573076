import { createContext, CSSProperties } from 'react';

import { AgendaInterface } from './AgendaContext';

import { EventCalendar } from '@/types/pro.types';

export type DisplayedDays = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type PasteInformation = {
  style: CSSProperties;
  dateLabel: string;
};

export type PastedEvent = {
  id: string;
  copiedEventId: string;
  startDate: Date;
  endDate: Date;
};

export type DuplicationInfo = {
  mode: 'copy' | 'paste';
  date: Date;
  centerId: string;
  practitionerId: string;
  copiedEvents: EventCalendar[];
  pastedEvents: PastedEvent[];
};

export interface CalendarInterface {
  agendas: AgendaInterface[];
  timeSlotResources: string[];
  timeSlotResourcesMode: boolean;
  nbDisplayedCalendars: number;
  nbDisplayedDays: DisplayedDays;
  range: Date[];
  displayStartTime: string;
  displayEndTime: string;
  duplicationInfo?: DuplicationInfo;
  setDuplicationInfo: React.Dispatch<
    React.SetStateAction<DuplicationInfo | undefined>
  >;
}

const CalendarContext = createContext<CalendarInterface | null>(null);

export default CalendarContext;
