import React, { ComponentType, forwardRef, Ref, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { Skeleton as SkeletonSrc } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  imageRoot: {
    display: 'initial',
  },
});

type Props = {
  src: string;
  fallbackSrc?: string;
  skeletonProps?: Object;
  Skeleton?: ComponentType;
  onLoad?: (ref: Ref<HTMLImageElement>) => void;
  className?: string;
  onMouseDown?: () => void;
};

// TODO: on error set isLoaded to false
const Image = forwardRef((props: Props, ref: React.Ref<HTMLImageElement>) => {
  const {
    src,
    fallbackSrc = '/static/images/docs.svg',
    skeletonProps = {},
    Skeleton = SkeletonSrc,
    onLoad,
    ...rest
  } = props;
  const { classes } = useStyles();
  const [isLoaded, setisloaded] = useState(false);
  const [isError, setisError] = useState(false);
  useUpdateEffect(() => {
    setisloaded(false);
    setisError(false);
  }, [src]);

  return (
    <>
      {!isLoaded && (
        <Skeleton
          width="100%"
          height="100%"
          variant="rectangular"
          {...skeletonProps}
        />
      )}
      <img
        alt=""
        onLoad={() => {
          if (isLoaded) return;
          setisError(false);
          setisloaded(true);
          if (onLoad) onLoad(ref);
        }}
        onError={() => {
          if (isLoaded) return;
          setisError(true);
          setisloaded(true);
        }}
        src={isError ? fallbackSrc : src}
        ref={ref}
        className={classes.imageRoot}
        {...rest}
      />
    </>
  );
});

Image.displayName = 'Image';

export default Image;
