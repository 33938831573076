import React, { useMemo } from 'react';
import { IMask } from 'react-imask';
import { InputBaseComponentProps } from '@mui/material';
import { FieldProps } from 'formik';

import IMaskInput from '../../IMaskInput';
import TextField from './TextField';
import TextFieldController from './TextFieldController';

type Props = {
  placeholder: string;
} & FieldProps<string | number> &
  React.ComponentProps<typeof TextFieldController>;

const CurrencyFieldController = (props: Props) => {
  const masked = useMemo(
    () =>
      IMask.createMask({
        mask: [
          { mask: '' },
          {
            mask: 'num €',
            lazy: false,
            blocks: {
              num: {
                mask: Number,
                thousandsSeparator: ' ',
              },
            },
          },
        ],
      }),
    [],
  );

  return (
    <TextFieldController
      InputProps={{
        inputComponent: IMaskInput as React.ForwardRefExoticComponent<
          InputBaseComponentProps
        >,
      }}
      //  eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{
        mask: masked,
        unmask: true, // sinon la virgule ne fonctionne pas
      }}
      {...props}
      InputComponent={TextField}
    />
  );
};

CurrencyFieldController.displayName = 'CurrencyFieldController';

export default CurrencyFieldController;
