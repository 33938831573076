// //https://github.com/isaachinman/next-i18next

// // const { localeSubpaths } = require('next/config').default().publicRuntimeConfig;

// const NextI18Next = require('next-i18next').default;

// // const localeSubpathVariations = {
// //   none: {},
// //   foreign: {
// //     en: 'en',
// //   },
// //   all: {
// //     fr: 'fr',
// //     en: 'en',
// //   },
// // };

// const N = new NextI18Next({
//   defaultLanguage: 'fr',
//   otherLanguages: ['fr'],
//   // localeSubpaths: localeSubpathVariations[localeSubpaths],
// });

// module.exports = N;

// https://github.com/isaachinman/next-i18next

const NextI18Next = require('next-i18next').default;

const KEY_LANG = 'maiia_lang';

const lang =
  typeof window !== 'undefined' &&
  window.navigator.cookieEnabled &&
  window.localStorage &&
  window.localStorage.getItem(KEY_LANG);

const isProduction = process.env.ENVK8S === 'production';

const otherLanguages = [isProduction ? 'fr' : 'en'];

// fix cwd when running jest from root of project
if (process.env.NODE_ENV === 'test') {
  process.chdir(__dirname);
}

const lng = isProduction ? 'fr' : lang || 'fr';
const NextI18NextInstance = new NextI18Next({
  lng,
  defaultLanguage: 'fr',
  otherLanguages,
  localePath: typeof window === 'undefined' ? 'public/locales' : 'locales',
});

const {
  appWithTranslation,
  useTranslation,
  withTranslation,
  Trans,
  config,
  i18n,
} = NextI18NextInstance;

module.exports = {
  appWithTranslation,
  useTranslation,
  withTranslation,
  Trans,
  config,
  i18n,
};
