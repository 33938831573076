import React from 'react';
import { Box, Stack, SxProps } from '@mui/material';

const styles: (props: {
  isHover: boolean;
  color: string;
}) => { [key: string]: SxProps } = ({ isHover, color }) => ({
  startButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: isHover ? 'center' : 'flex-end',
    borderRadius: '48px',
    boxShadow: 'none',
    transition: 'all .3s cubic-bezier(0.45, 0.1, 0.2, 1)',
    width: isHover ? '200px' : '48px',
    height: '48px',
    px: '8px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    backgroundColor: isHover ? color : 'transparent',
    color: isHover ? 'white' : color,
    borderColor: isHover ? 'transparent' : color,
    border: '2px solid',
    ':hover': {
      boxShadow: 'none',
      cursor: 'pointer',
      ...(isHover && {
        width: '200px',
        justifyContent: 'center',
      }),
    },
  },
});

interface ActionButtonProps {
  isHover: boolean;
  onEnter: () => void;
  onLeave: () => void;
  onClick: () => void;
  icon: React.ReactNode;
  label: string;
  color: string;
}

const ActionButton = ({
  isHover,
  onEnter,
  onLeave,
  icon,
  label,
  color,
  onClick,
}: ActionButtonProps) => {
  return (
    <Box
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      onClick={onClick}
      sx={styles({ isHover, color }).container}
    >
      <Stack direction="row" spacing={2}>
        <Box>{label}</Box>
        <Box sx={styles({ isHover, color }).startButton}>{icon}</Box>
      </Stack>
    </Box>
  );
};

export default ActionButton;
