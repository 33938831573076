export const SET_OPEN_LOADER_MAIIA = 'SET_OPEN_LOADER_MAIIA';

const setOpen = (isOpen: boolean) => ({
  type: SET_OPEN_LOADER_MAIIA,
  payload: isOpen,
});

const loaderMaiiaActions = {
  setOpen,
};

export default loaderMaiiaActions;
