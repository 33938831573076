import React, { forwardRef } from 'react';
import {
  ListItemAvatar as MaterialListItemAvatar,
  ListItemAvatarProps,
} from '@mui/material';

const ListItemAvatar = forwardRef(
  (props: ListItemAvatarProps, ref: React.Ref<HTMLDivElement>) => (
    <MaterialListItemAvatar {...props} ref={ref} />
  ),
);

ListItemAvatar.displayName = 'ListItemAvatar';

export default ListItemAvatar;
