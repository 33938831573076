import React from 'react';
import { Tooltip as MaterialTooltip, TooltipProps } from '@mui/material';

import { getDisplayName } from '@docavenue/components';

// note: when using this tooltip, you should wrap the children with a div
const Tooltip = MaterialTooltip;

export const withTooltip = (
  WrappedComponent: any,
  tooltipProps: Omit<TooltipProps, 'children'>,
) => {
  const WithToolTip = props => (
    <Tooltip {...tooltipProps}>
      <span>
        <WrappedComponent {...props} />
      </span>
    </Tooltip>
  );
  WithToolTip.displayName = `WithToolTip(${getDisplayName(WrappedComponent)})`;
  return WithToolTip;
};

export default Tooltip;
