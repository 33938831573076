import { UrlObject } from 'url';

export type SnackVariant = 'success' | 'info' | 'warning' | 'error';
export type SnackActionType =
  | 'CREATE'
  | 'UPDATE'
  | 'DELETE'
  | 'UPLOAD'
  | 'CANCEL'
  | 'GET'
  | 'CREATE_MANY'
  | 'UPDATE_MANY'
  | 'DELETE_MANY'
  | 'UPLOAD_MANY'
  | 'CANCEL_MANY'
  | 'GET_MANY';
export type SnackNotification = {
  id?: string;
  variant: SnackVariant;
  message?: string;
  action?: SnackActionType;
  resource?: string;
  variables?: {
    name?: string;
    link?: {
      href: string | UrlObject;
      target?: string;
      rel?: string;
      label?: string;
    };
  };
  duration?: number | null;
  actionNode?: React.ReactNode;
  onClick?: () => void;
  onExited?: () => void;
};
export type SnacksState = {
  queue: Array<SnackNotification>;
};
export const initialState: SnacksState = {
  queue: [],
};
export const ENQUEUE_SNACK = 'ENQUEUE_SNACK';

function snacks(
  state = initialState,
  action: { type: string; payload: SnackNotification },
) {
  switch (action.type) {
    case ENQUEUE_SNACK: {
      const alreadyEnqueued = state.queue.some(
        item => item?.id === action.payload?.id,
      );
      if (alreadyEnqueued) {
        return state;
      }
      return {
        ...state,
        queue: state.queue.concat([action.payload]),
      };
    }
    default:
      return state;
  }
}

export default snacks;
