import {
  ProUserDTO,
  SubstituteData,
  User,
  UserChatRoomDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

// eslint-disable-next-line import/prefer-default-export
export function isUser(
  data: ProUserDTO | User | UserChatRoomDTO | SubstituteData,
): data is User {
  return !!(data as User).id;
}

export function isFileType<T extends Object>(doc: File | T): doc is File {
  return 'webkitRelativePath' in doc;
}

type ErrorWithCode = {
  code: string;
};

export function errorHasCode(error: unknown): error is ErrorWithCode {
  if (typeof error === 'object' && error && 'code' in error) {
    return true;
  }
  return false;
}
