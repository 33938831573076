import { GET_LIST_SUCCESS, reducersGenerator } from '@docavenue/core';

const name = 'bcbDosages';

const surchargeReducer = reducerConfig => {
  const initialGetListSuccess = reducerConfig[GET_LIST_SUCCESS];

  [GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach(actionType => {
    // eslint-disable-next-line no-param-reassign
    reducerConfig[actionType] = (state, action) => {
      const { payload } = action;
      return initialGetListSuccess(state, {
        ...action,
        payload: { items: payload },
      });
    };
  });

  return reducerConfig;
};

const bcbProducts = reducersGenerator({
  name,
  surchargeReducer,
});

export default bcbProducts;
