import { makeSubsetter } from '../utils';
import Routes from './routes';

const createRoutesList = makeSubsetter(Routes);

export const WHITE_LIST = createRoutesList([
  'ACCOUNT_UNLOCK',
  'ERROR',
  'FORGOT_PASSWORD',
  'IFRAME',
  'LOGIN',
  'RESET_PASSWORD',
  'SIGNUP',
  'SIGNUP_LGC',
  'SIGNUP_LGC_SYNC',
  'SSO',
  'SSO_AUTHORIZE',
  'SSO_ERROR',
]);

export const REQUIRE_PRACTITIONER_ID = createRoutesList([
  'ROOT',
  'AGENDA_SETTINGS',
  'TIMELINE',
  'CREATE_APPOINTMENT',
  'CHAT',
]);

export const AUTH_PATHS = createRoutesList([
  'LOGIN',
  'SSO',
  'SSO_AUTHORIZE',
  'FORGOT_PASSWORD',
  'RESET_PASSWORD',
]);

export const ROUTES_WITH_MINI_CALENDAR = createRoutesList([
  'AGENDA_SETTINGS',
  'ROOT',
  'TIMELINE',
]);

export const ROUTES_WITH_PATIENT_SEARCH = createRoutesList(['EDIT_PATIENT']);

export const ROUTES_WITHOUT_HEADER = createRoutesList([
  'FORGOT_PASSWORD',
  'LOGIN',
  'RESET_PASSWORD',
  'SIGNUP',
  'SSO',
  'SSO_AUTHORIZE',
  'SSO_ERROR',
  'SIGNUP_LGC_SYNC',
  'SIGNUP_LGC',
  'CREATE_APPOINTMENT',
]);

export const ROUTES_WITH_SIDEBAR = createRoutesList([
  'ADMINISTRATION',
  'AGENDA_SETTINGS',
  'CHAT',
  'CHAT_INVITATIONS',
  'CHAT_TELE_EXPERTISE',
  'CHAT_CREATE_INVITATIONS',
  'EDIT_PATIENT',
  'TRANSACTIONS',
  'ROOT',
  'SETTINGS',
  'STATISTICS',
  'TIMELINE',
]);

export const ROUTES_AVAILABLE_TO_FREEMIUM_USERS = createRoutesList([
  'ADMINISTRATION',
  'CHAT',
  'CHAT_CREATE_INVITATIONS',
  'CHAT_INVITATIONS',
  'CHAT_TELE_EXPERTISE',
  'MY_ACCOUNT',
  'SETTINGS',
  'PATIENTS',
  'EDIT_PATIENT',
  'OFFERS',
]);

export const ROUTES_ALLOWED_TO_CHAT = createRoutesList([
  'CHAT',
  'CHAT_CREATE_INVITATIONS',
  'CHAT_INVITATIONS',
  'CHAT_TELE_EXPERTISE',
]);
export const ROUTES_ALLOWED_TO_MOBILE_USERS = createRoutesList([
  'LOGIN',
  'SIGNUP',
  'RESET_PASSWORD',
]);

export const ROUTES_ALLOWED_TO_REGULATOR = createRoutesList([
  'LOGIN',
  'SIGNUP',
  'PATIENTS',
  'EDIT_PATIENT',
  'ADMINISTRATION',
  'MY_ACCOUNT',
  'CHAT',
  'CHAT_CREATE_INVITATIONS',
  'CHAT_INVITATIONS',
]);
