import { makeSubsetter } from '../utils';
import Tabs from './tabs';

const createTabsList = makeSubsetter(Tabs);

export const TABS_WITH_SIDEBAR = Object.values(Tabs);

export const TABS_ALLOWED_TO_FREEMIUM_USERS = createTabsList([
  'PROFILE',
  'PERMISSIONS',
  'DOCUMENTS',
]);
