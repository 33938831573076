import React, {
  forwardRef,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Grow, PopperProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';


import ClickAwayListener from '../../atoms/ClickAwayListener';
import Popper from '../../atoms/Popper';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    display: 'flex',
  },
  container: {
    zIndex: theme.zIndex.menu,
  },
}));

type Props = PopperProps & {
  trigger: ReactElement;
  autoClose?: boolean;
  setOpen?: Function;
  isWithContainer?: boolean;
  onClose?: Function;
  handleAutoCloseCallback?: Function;
  onChange?: (open: boolean) => void;
};

const Menu = (props: Props, ref: RefObject<typeof Menu>) => {
  const {
    autoClose,
    children,
    disablePortal = true,
    id = 'menu-with-popper',
    isWithContainer = false,
    modifiers = [],
    onChange = () => {},
    open = false,
    setOpen = () => {},
    trigger,
    className,
    onClose,
    handleAutoCloseCallback,
    placement = 'bottom',
    ...rest
  } = props;

  const { classes, cx } = useStyles();
  const [isOpen, setIsOpen] = useState(open);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState(null);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (isOpen !== open) {
      onChange(open);
      setOpen(open);
    }
  }, [isOpen, onChange, open, setOpen]);

  const handleToggle = useCallback(() => {
    const value = !isOpen;

    if (!value && onClose) {
      onClose();
    }
    setIsOpen(value);
  }, [isOpen]);

  const handleClickAway = useCallback(
    event => {
      if (anchorEl && anchorEl?.contains(event.target)) {
        return;
      }

      if (onClose) {
        onClose();
      }

      if (autoClose) {
        setIsOpen(false);
        handleAutoCloseCallback?.();
      }
    },
    [anchorEl, autoClose],
  );
  const renderTrigger = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const props = {
      onClick: e => {
        setAnchorEl(e.currentTarget);
        if (isWithContainer) {
          setContainer(e.currentTarget.parentElement);
        }
        if (trigger.props && typeof trigger.props.onClick === 'function') {
          trigger.props.onClick(e);
        }
        if (!e.defaultPrevented) {
          handleToggle();
        }
      },
    };
    const mergedProps = {
      ...trigger.props,
      ...props,
    };
    const cloned = React.cloneElement(trigger, mergedProps);
    return cloned;
  }, [trigger, isWithContainer, handleToggle]);

  useImperativeHandle(ref, () => ({
    close() {
      setIsOpen(false);
    },
    open() {
      setIsOpen(true);
    },
  }));

  return (
    <div className={classes.root}>
      {renderTrigger()}
      <Popper
        id={id}
        container={container}
        open={isOpen}
        anchorEl={anchorEl}
        transition
        disablePortal={disablePortal}
        placement={placement}
        className={cx(classes.container, className)}
        modifiers={modifiers}
        {...rest}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div>
              <ClickAwayListener
                onClickAway={isOpen ? handleClickAway : () => {}}
              >
                <>{children}</>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default forwardRef<typeof Menu, Props>(Menu);
