import { createContext } from 'react';
import { Form, FormikProps } from 'formik';
import { Moment } from 'moment';

import { SelectedAvailability } from '../../types/pro.types';

interface FormInterface {
  form: FormikProps<any>;
  resetFormTimeValues: (form: typeof Form) => void;
  setCalendarDateWithStartDate: (startDate: string | Moment) => void;
  setPendingAppointments: (availabilities: SelectedAvailability[]) => void;
}

const FormContext = createContext<FormInterface | null>(null);

export default FormContext;
