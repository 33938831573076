import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material';

import { MaiiaTheme } from '../maiiaTheme';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    'text-hover': true;
  }
  interface ButtonPropsColorOverrides {
    text: true;
    primaryTonal: true;
    secondaryTonal: true;
  }
}

interface MuiButtonOverrideProps {
  defaultProps?: ComponentsProps['MuiButton'];
  styleOverrides?: ComponentsOverrides<MaiiaTheme>['MuiButton'];
  variants?: ComponentsVariants['MuiButton'];
}

const MuiButtonOverride: MuiButtonOverrideProps = {
  defaultProps: {
    disableElevation: true,
    color: 'secondary',
  },
  variants: [
    {
      props: { variant: 'text-hover' },
      style: ({ theme }) => {
        return {
          color: theme.palette.text.main,
          ':hover': {
            backgroundColor: 'transparent',
          },
        };
      },
    },
  ],
  styleOverrides: {
    root: ({ theme, ownerState }) => {
      return {
        borderRadius: theme.shape.rounded.full,
        padding: `2px 20px`,
        textTransform: 'none',
        ...theme.typography.maiiaButton,
        ...(ownerState.variant === 'text-hover' && {
          ':hover': {
            color: theme.palette[ownerState.color!].main,
          },
        }),
      };
    },
  },
};

export default MuiButtonOverride;
