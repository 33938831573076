import MuiButtonOverride from './components/MuiButtonOverride';
import MuiMenuOverride from './components/MuiMenuOverride';
import MuiRadioOverride from './components/MuiRadioOverride';
import MuiSwitchOverride from './components/MuiSwitchOverride';
import maiiaPalette from './maiiaPalette';
import maiiaShape from './maiiaShape';
import { MaiiaThemeOptions } from './maiiaTheme';
import maiiaTypography from './maiiaTypography';

const themeOptions: MaiiaThemeOptions = {
  palette: maiiaPalette,
  shape: maiiaShape,
  typography: maiiaTypography,
  components: {
    MuiButton: MuiButtonOverride,
    MuiMenu: MuiMenuOverride,
    MuiSwitch: MuiSwitchOverride,
    MuiRadio: MuiRadioOverride,
  },
};

export default themeOptions;
