import React from 'react';
import { useRouter } from 'next/router';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';

import Typography from '../../atoms/Typography';

const useStyles = makeStyles()((theme: any) => ({
  background: {
    backgroundColor: theme.palette.background.defaultGrey,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rectangle: {
    backgroundColor: 'white',
    width: theme.spacing(16),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: theme.shadows[2],
  },
  illustration: {
    background: 'url(/static/images/sad-computer.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  title: {
    fontSize: theme.size.font['4xl'],
    color: theme.palette.main.main,
    fontWeight: 'bold',
  },
  explanation: {
    fontSize: theme.size.font.lg,
    marginBottom: theme.spacing(7),
    marginTop: theme.spacing(5),
  },
  button: {
    marginBottom: theme.spacing(6),
  },
}));

const ErrorPage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const router = useRouter();

  const goHome = () => {
    router.push('/');
  };
  return (
    <div className={classes.background}>
      <div className={classes.rectangle}>
        <div className={classes.illustration} />
        <Typography className={classes.title}>
          {t('common:page_error__title')}
        </Typography>
        <Typography className={classes.explanation}>
          {t('common:page_error__explanation')}
        </Typography>
        <Button
          onClick={goHome}
          className={classes.button}
          variant="contained"
          color="primary"
        >
          {t('common:button_refresh')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorPage;
