/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';

import { GenericSelector, InitialGenericSelector } from '@docavenue/chat';
import { generateInitialSelectors } from '@docavenue/core';
import { Practitioner } from '@maiia/model/generated/model/api-pro/api-pro';

import { RootState } from '../reducer/rootState';
import { getLocale } from '../utils';

const initialSelectors: InitialGenericSelector<Practitioner> = generateInitialSelectors(
  'practitioners',
);

export type PractitionerSelector = GenericSelector<Practitioner> & {
  getItemsOrderedByLastNameAndFirstName: (state: RootState) => Practitioner[];
};

const selectors: PractitionerSelector = {
  name: 'practitioners',
  ...initialSelectors,
  getItemsOrderedByLastNameAndFirstName: createSelector(
    initialSelectors.getItems,
    items => {
      const locale = getLocale();
      return items
        .map(item => ({
          ...item,
          lastName: item.lastName?.trim(),
          firstName: item.firstName?.trim(),
        }))
        .sort(
          (a, b) =>
            a.lastName.localeCompare(b.lastName, locale) ||
            a.firstName.localeCompare(b.firstName, locale),
        );
    },
  ),
};

export default selectors;
