import React from 'react';

import { MediaPermissionsError } from '@/src/utils/mediaDevicesUtils';

export type MediaDeviceItem = {
  state: PermissionState | undefined;
  error?: MediaPermissionsError;
};

export type MediaDevicesContextValue = {
  audio: MediaDeviceItem;
  video: MediaDeviceItem;
  hasAccessToAudio: boolean;
  hasAccessToVideo: boolean;
  requestAudioPermission: () => Promise<MediaDeviceItem>;
  requestVideoPermission: () => Promise<MediaDeviceItem>;
  requestMediaPermission: () => Promise<MediaDeviceItem>;
};

const MediaDevicesContext = React.createContext<MediaDevicesContextValue>(
  // @ts-ignore
  undefined,
);

export default MediaDevicesContext;
