import { actionsGeneratorV2 } from '@docavenue/core';

const phoneCheckActions = actionsGeneratorV2({
  resource: 'phoneCheck',
  chunkUrlResource: 'phones/check',
  scope: 'pro-public',
  disableConcurrency: {
    isGetListDisableConcurrency: false,
  },
});

const { getList } = phoneCheckActions;

const checkValidity = (params: { phoneNumber: string; type: string }) => {
  return getList(params);
};

export default {
  ...phoneCheckActions,
  checkValidity,
};
