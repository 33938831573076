import { createContext, useContext, useRef } from 'react';
import { Chunk, FindChunks } from 'react-highlight-words';
import { IMask } from 'react-imask';
import { useSelector } from 'react-redux';
import { NextPageContext } from 'next';
import getConfig from 'next/config';
import Router from 'next/router';
import dayjs, { Dayjs } from 'dayjs';
import formatSSNLib from 'french-ssn/dist/format';
import i18next from 'i18next';
import Cookie from 'js-cookie';
import camelCase from 'lodash/camelCase';
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import each from 'lodash/each';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import { deburr, equals } from 'lodash/fp';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import memoize from 'lodash/memoize';
import omit from 'lodash/omit';
import snakeCase from 'lodash/snakeCase';
import stubTrue from 'lodash/stubTrue';
import uniqBy from 'lodash/uniqBy';
import upperFirst from 'lodash/upperFirst';
import moment, { Moment } from 'moment-timezone';
import { Store } from 'redux';
import { createSelector } from 'reselect';

import { ParsedUrlQueryInput, stringify } from 'querystring';

import {
  Actions,
  AuthData,
  BaseAction,
  ChatRoutes,
  ItemsDictMap,
  TELE_EXPERTISE,
  WEEKS_AFTER,
  WEEKS_BEFORE,
} from '@docavenue/chat';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { ChatMessage, TaskAttachment } from '@docavenue/chat/src/types';
// eslint-disable-next-line no-restricted-imports
import { ActionTemplateType } from '@docavenue/core/src/types';
import {
  AuthorExtraInformation,
  Caregiver,
  Center,
  CenterDTO,
  Document,
  Patient,
  Practitioner,
  ProAgendaSettingsDTO,
  ProfessionalCardDTO,
  ProfessionalHcd,
  ProUserDTO,
  SelfOnboardingInvitationDTO,
  SpecialityHcd,
  SubstituteData,
  User,
  UserChatRoomDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { dialogActions, snacksActions, timeSlotsActions } from './actions';
import { WEEK } from './actions/calendar';
import { DialogType } from './actions/dialog';
import {
  ALL,
  ASSOCIATED,
  BLOCKED,
  CANCELLED,
  COMPLETED,
  DATE_EMPTY_VALUE,
  DayOfWeek,
  daysOfTheWeek,
  DEFAULT_TIMEZONE,
  DELETED,
  FORMAT_EVENT_DATACY,
  FRIDAY,
  IMPORT,
  KEY_LANG,
  MAIIA_MEDICINES_URL,
  MAX_DAY_MULTI_CALENDAR_DISPLAYED,
  MIGRATION,
  MONDAY,
  MONTHS,
  ONLINE_CALLIBRI,
  ONLINE_CEGEDIM,
  PAT_MEDICINES_URL,
  PRO,
  PUBLIC,
  SATURDAY,
  SUNDAY,
  SYNCHRO,
  SYNCHRONIZED,
  TELESECRETARY,
  THURSDAY,
  TUESDAY,
  WEDNESDAY,
} from './constants';
import { BcbPrescriptionProduct, RootState } from './reducer/rootState';
import { TeleExpertiseStatus } from './reducer/teleExpertiseFilters';
import { isUser } from './utils/typeGuards';

import { DisplayedDays } from '@/components/contexts/CalendarContext';
import { ONGOING, SIMPLY_SOFTWARE_CODE } from '@/components/utils/constants';
import { i18n } from '@/i18n';
import config from '@/src/config';
import sentry from '@/src/sentry';
import {
  Appointment,
  FormattedTimeSlot,
  VideoSessionAggregate,
} from '@/types/pro.types';

export const { captureException } = sentry(
  config.get('SENTRY_DSN'),
  config.get('SENTRY_RELEASE'),
);

/**
 * Quotient of the Euclidean division.
 * @param x The dividend
 * @param y The divisor
 * @see https://stackoverflow.com/a/77111866/2326961
 */
export const quotient = (x: number, y: number): number =>
  Math.sign(y) * Math.floor(x / Math.abs(y));

/**
 * Remainder of the Euclidean division.
 * @param x The dividend
 * @param y The divisor
 * @see https://stackoverflow.com/a/77111866/2326961
 */
export const remainder = (x: number, y: number): number => {
  const z = Math.abs(y);
  return ((x % z) + z) % z;
};

export type RouterObject = {
  pathname: string;
  query: ParsedUrlQueryInput;
};

const targetToString = (target: RouterObject | string): string => {
  if (typeof target === 'string') return target;
  return `${target.pathname}?${stringify(target.query)}`;
};

export const redirect = (
  context: NextPageContext,
  target: RouterObject | string,
): void => {
  if (context.res) {
    // server side
    // 303: "See other"
    context.res.writeHead(303, { Location: targetToString(target) });
    context.res.end();
  } else {
    // In the browser, we just pretend like this never even happened
    Router.replace(targetToString(target));
  }
};

export const hex2rgba = (
  hex: string,
  alpha: number = 1,
): string | undefined => {
  const match = hex.match(/\w\w/g); // add ?
  if (!match) return;
  const [r, g, b] = match.map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const aggregateDate = (
  date: Moment | Dayjs,
  time: Moment | Dayjs,
): string =>
  date
    .clone()
    .hour(time.hour())
    .minute(time.minute())
    .toISOString();

export const isSameDateTime = (
  date1: string | Date | Moment,
  date2: string | Date | Moment,
): boolean =>
  moment(date1)
    .startOf('minute')
    .unix() ===
  moment(date2)
    .startOf('minute')
    .unix();

export const getItemsConflictDates = <T extends Record<string, any>>(
  items: T[],
  target: T & { recurrencePeriod?: string; recurrenceEndDate?: string },
  getStartDate: (option: T) => Date | string | undefined = option =>
    option.startDate,
  getEndDate: (option: T) => Date | string | undefined = option =>
    option.endDate,
): T[] => {
  if (target.recurrencePeriod && target.recurrenceEndDate) {
    return items;
  }
  const targetStartDate = getStartDate(target);
  const targetEndDate = getEndDate(target);
  const targetStartDateTime = moment(targetStartDate).valueOf();
  const targetEndDateTime = moment(targetEndDate).valueOf();
  const conflicts = items.filter(item => {
    const startDate = moment(getStartDate(item)).format();
    const endDate = moment(getEndDate(item)).format();
    const startDateItem = new Date(startDate).getTime();
    const endDateItem = new Date(endDate).getTime();
    return (
      (targetStartDate &&
        targetEndDate &&
        isSameDateTime(startDate, targetStartDate) &&
        isSameDateTime(endDate, targetEndDate)) ||
      (startDateItem > targetStartDateTime &&
        startDateItem < targetEndDateTime) ||
      (endDateItem > targetStartDateTime && endDateItem < targetEndDateTime) ||
      (targetStartDateTime > startDateItem &&
        targetStartDateTime < endDateItem) ||
      (targetEndDateTime > startDateItem && targetEndDateTime < endDateItem)
    );
  });
  return conflicts;
};

type GetDocumentUrlParams = {
  id: string;
  name: string;
  isPdf?: boolean;
  centerId?: string | null;
};

export const getDocumentUrl = ({
  id,
  name,
  isPdf = false,
  centerId,
}: GetDocumentUrlParams): string =>
  `/documents/secured/${id}?name=${name}&isPdf=${isPdf}&centerId=${centerId}`;

export const getDocumentThumbnail = (
  { id }: { id: string },
  centerId: string | null = null,
): string => `/documents-thumbnails/secured/${id}?centerId=${centerId}`;

export const getAvatarUrl = (id: string): string => `/avatars/${id}`;

export const getPractitionerAvatarFromS3Id = ({
  thumbnailS3Id,
  s3Id,
}: {
  thumbnailS3Id?: string;
  s3Id?: string;
}): string | undefined => {
  const id = thumbnailS3Id ?? s3Id;
  return id && getAvatarUrl(id);
};

export const getUserAvatarUrl = (
  user?: User | Practitioner | UserChatRoomDTO,
  agendaSettings?: ProAgendaSettingsDTO | null,
): string | undefined => {
  const { s3Id, thumbnailS3Id } =
    agendaSettings?.publicInformation?.mainPicture ?? {};
  const userPictureId =
    thumbnailS3Id ?? s3Id ?? (user as UserChatRoomDTO)?.mainPictureS3Id;
  if (userPictureId) {
    return getAvatarUrl(userPictureId);
  }
};

export const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getActionGetListTimeSlots = (
  practitionerId: string,
  centerId: string,
  date: string,
): ActionTemplateType => {
  const from = moment(date).startOf('isoWeek');
  const to = from
    .clone()
    .add(6, 'days')
    .endOf('day');
  const common = {
    centerId,
    from: from.toISOString(),
    practitionerId,
    to: to.toISOString(),
  };
  const timeSlotsParams = {
    ...common,
  };
  return timeSlotsActions.getList(timeSlotsParams);
};

export const isKnownByPractitioner = (
  patient: Pick<Patient, 'origin' | 'knownPractitioners'>,
  currentPractitionerId: string,
): boolean => {
  const { origin, knownPractitioners = {} } = patient || {};

  if (!patient.knownPractitioners) {
    return false;
  }

  const neverNew = [SYNCHRO, MIGRATION, IMPORT];
  const map = new Map(Object.entries(knownPractitioners));
  const date = map.get(currentPractitionerId);
  return (
    (origin !== undefined && neverNew.includes(origin)) ||
    (!!date && moment(date).isBefore(moment()))
  );
};

export const isMaiiaPatient = (patient?: Pick<Patient, 'profileId'>): boolean =>
  Boolean(patient?.profileId);

export const isOpenWaitingByOtherUser = (
  agendaSettings: ProAgendaSettingsDTO | null,
  userId: string | null,
): boolean => {
  if (agendaSettings?.teleconsultationAvailabilityStartTime) {
    const startTime = agendaSettings.teleconsultationAvailabilityStartTime;
    const endTime = agendaSettings.teleconsultationAvailabilityEndTime;

    return (
      moment().isBetween(startTime, endTime) &&
      !!agendaSettings.teleconsultationUserId &&
      agendaSettings.teleconsultationUserId !== userId
    );
  }
  return false;
};

export const isWaitingRoomOpenedByUser = (
  agendaSettings: ProAgendaSettingsDTO | null,
  userId: string | null,
): boolean => {
  if (agendaSettings?.teleconsultationAvailabilityStartTime) {
    const startTime = agendaSettings.teleconsultationAvailabilityStartTime;
    const endTime = agendaSettings.teleconsultationAvailabilityEndTime;

    return (
      moment().isBetween(startTime, endTime) &&
      !!agendaSettings.teleconsultationUserId &&
      agendaSettings.teleconsultationUserId === userId
    );
  }
  return false;
};

export const getAgendaSettingsWithWaitingRoomOpened = (
  agendaSettings: ProAgendaSettingsDTO | ProAgendaSettingsDTO[] | null,
  userId: string | null,
): (ProAgendaSettingsDTO | undefined)[] => {
  const agendaSettingsList =
    !agendaSettings || Array.isArray(agendaSettings)
      ? agendaSettings
      : [agendaSettings];
  return [
    agendaSettingsList?.find(agendaSetting =>
      isWaitingRoomOpenedByUser(agendaSetting, userId),
    ),
    agendaSettingsList?.find(agendaSetting =>
      isOpenWaitingByOtherUser(agendaSetting, userId),
    ),
  ];
};

export const getAgendaSettingsWithWaitingRoomOpenedInOtherCenter = (
  agendaSettingsDefaults: ProAgendaSettingsDTO[],
  currentAgenda: ProAgendaSettingsDTO | null,
  userId: string | null,
): (ProAgendaSettingsDTO | undefined)[] => {
  if (!currentAgenda) return [];
  const agendaSettings = agendaSettingsDefaults.filter(
    settings => settings.id !== currentAgenda.id,
  );
  return [
    agendaSettings.find(agendaSetting =>
      isWaitingRoomOpenedByUser(agendaSetting, userId),
    ),
    agendaSettings.find(agendaSetting =>
      isOpenWaitingByOtherUser(agendaSetting, userId),
    ),
  ];
};

export const stopMediaDevices = async (): Promise<void> => {
  if (!navigator.mediaDevices) {
    return;
  }
  await navigator.mediaDevices
    .getUserMedia({ audio: true })
    .then(stream => stream.getTracks().forEach(track => track.stop()))
    .catch(() => {});
  await navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(stream => stream.getTracks().forEach(track => track.stop()))
    .catch(() => {});
};

type CalendarConfig = {
  unit: moment.unitOfTime.DurationAs;
  period: moment.unitOfTime.StartOf;
};

const calendarConfig: {
  week: CalendarConfig;
  day: CalendarConfig;
  agenda: CalendarConfig;
  month: CalendarConfig;
} = {
  week: {
    unit: 'week',
    period: 'isoWeek',
  },
  day: {
    unit: 'day',
    period: 'day',
  },
  agenda: {
    unit: 'day',
    period: 'day',
  },
  month: {
    unit: 'month',
    period: 'month',
  },
};

export const getPeriod = (
  date?: Moment | string | Date,
): {
  from: Moment;
  to: Moment;
} => {
  const startOf = moment(date)
    .clone()
    .startOf(calendarConfig.week.period);
  const endOf = moment(date)
    .clone()
    .endOf(calendarConfig.week.period);
  const from = startOf.clone().subtract(0, calendarConfig.week.unit);
  const to = endOf.clone().add(0, calendarConfig.week.unit);
  return { from, to };
};

export const getListPeriod = <T>(
  resources: {
    currentDate: Moment;
    itemsDictMap: ItemsDictMap<T>;
  },
  resourcesActions: { getList: Actions<T>['getList'] },
  params: { centerId: string; practitionerId: string },
  isFetchMultiWeek?: boolean,
): (() => BaseAction)[] => {
  const { centerId, practitionerId } = params;
  const { currentDate, itemsDictMap } = resources;
  const { itemsDict } = itemsDictMap[`${practitionerId}-${centerId}`];
  const newActions: (() => BaseAction)[] = [];
  const date = moment(currentDate);
  const nbWeekAfter = isFetchMultiWeek ? WEEKS_AFTER : 0;
  const nbWeekBefore = isFetchMultiWeek ? WEEKS_BEFORE : 0;
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < nbWeekAfter; index++) {
    const newDate = date.clone().add(index, 'weeks');
    if (!itemsDict[newDate.toISOString()]) {
      const { from, to } = getPeriod(newDate);
      newActions.push(() =>
        resourcesActions.getList(
          {
            ...params,
            from: from.toISOString(),
            to: to.toISOString(),
          },
          { isReplace: true },
        ),
      );
    }
  }
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < nbWeekBefore; index++) {
    const newDate = date.clone().subtract(index, 'weeks');
    if (!itemsDict[newDate.toISOString()]) {
      const { from, to } = getPeriod(newDate);
      newActions.push(() =>
        resourcesActions.getList(
          {
            ...params,
            from: from.toISOString(),
            to: to.toISOString(),
          },
          { isReplace: true },
        ),
      );
    }
  }
  return newActions;
};

export const isTelesecretary = (
  user: ProUserDTO | User | UserChatRoomDTO,
): boolean => isUser(user) && user.role?.name === TELESECRETARY;

export type PractitionerDisplayName = Pick<
  Partial<Practitioner>,
  'firstName' | 'lastName' | 'speciality'
>;

export const getDisplayName = ({
  isDoctor = false,
  firstName,
  lastName,
  short = false,
}: {
  isDoctor?: boolean;
  firstName?: string | null;
  lastName?: string | null;
  short?: boolean;
}) => {
  const civility = isDoctor && !short && 'Dr';
  const first = short
    ? firstName && `${firstName.charAt(0).toUpperCase()}.`
    : firstName &&
      `${firstName.charAt(0).toUpperCase()}${firstName.slice(1).toLowerCase()}`;
  const last = lastName && lastName.toUpperCase();
  return [civility, first, last].filter(Boolean).join(' ');
};

export const getPatientDisplayName = (
  patient: Patient,
  withHash: boolean = false,
  short: boolean = false,
): string => {
  const isDoctor = false;
  const firstName = patient?.firstName;
  const lastName = patient?.lastName;
  return (
    (withHash ? '#' : '') +
    getDisplayName({ isDoctor, firstName, lastName, short })
  );
};

export const getPractitionerDisplayName = (
  practitioner?: PractitionerDisplayName | null,
  short: boolean = false,
) => {
  const isDoctor =
    !!practitioner?.speciality && !practitioner.speciality?.isParamedical;
  const firstName = practitioner?.firstName;
  const lastName = practitioner?.lastName;
  return getDisplayName({ isDoctor, firstName, lastName, short });
};

export const getSubstituteDisplayName = (
  substituteData: SubstituteData,
  practitionerSpecialty?: Practitioner['speciality'],
) =>
  getPractitionerDisplayName({
    ...substituteData,
    speciality: practitionerSpecialty,
  });

export const getTlsDisplayName = (
  info?: AuthorExtraInformation | null,
  short: boolean = false,
) => {
  const isDoctor = false;
  const firstName = info?.firstName;
  const lastName = info?.lastName;
  return getDisplayName({ isDoctor, firstName, lastName, short });
};

export const getUserDisplayName = (
  user: ProUserDTO | User | UserChatRoomDTO,
  authentication?: AuthData | null,
  practitioner?: Partial<Practitioner> | null,
  short: boolean = false,
): string => {
  const isDoctor =
    !!practitioner?.speciality && !practitioner.speciality.isParamedical;
  const firstName =
    isTelesecretary(user) && authentication?.tlsFirstName
      ? authentication.tlsFirstName
      : user.userProInformation?.firstName;
  const lastName =
    isTelesecretary(user) && authentication?.tlsLastName
      ? authentication.tlsLastName
      : user.userProInformation?.lastName;
  return getDisplayName({ isDoctor, firstName, lastName, short });
};

export const getAutoCompletePatientLabel = (
  currentPractitionerId?: string,
): ((patient: Patient, isLight?: boolean) => string) =>
  memoize(
    (patient: Patient, isLight: boolean = false): string => {
      if (patient) {
        let label = getPatientDisplayName(patient);
        if (patient.birthName || patient.birthDate) {
          label += ` (${patient.birthName ? `${patient.birthName} ` : ''}${
            patient.birthDate
              ? moment(patient.birthDate).format('DD/MM/YYYY')
              : ''
          })`;
        }
        if (!isLight) {
          if (
            !!currentPractitionerId &&
            patient.knownPractitioners &&
            isKnownByPractitioner(
              {
                origin: patient.origin,
                knownPractitioners: patient.knownPractitioners,
              },
              currentPractitionerId,
            )
          ) {
            label += ` ${i18n.t('common:star_indicator')}`;
          }
          if (isMaiiaPatient(patient)) {
            label += ` ${i18n.t(
              'common:complex_patient_label__internet_indicator',
            )}`;
          }
          if (
            !!patient.referringPractitionerId &&
            !!currentPractitionerId &&
            patient.referringPractitionerId === currentPractitionerId
          ) {
            label += ` ${i18n.t('common:heart_indicator')}`;
          }
          if (patient.mobilePhoneNumber) {
            label += ` ${patient.mobilePhoneNumber}`;
          }
        }
        return (label || '').trim();
      }
      return '';
    },
    (patient, isLight) => `${patient.id}-${isLight ? 'light' : 'classic'}`,
  );

const neverNew = [SYNCHRO, MIGRATION, IMPORT];
const newUntilFirstAppointment = [PUBLIC, PRO];

const checkIsNewPatient = (appointment: Appointment): boolean => {
  const { firstAppointmentDate, patient } = appointment;
  if (!patient || !patient.origin) return false;
  if (neverNew.includes(patient.origin)) {
    return false;
  }
  if (newUntilFirstAppointment.includes(patient.origin)) {
    return Boolean(
      firstAppointmentDate &&
        !patient.mergedPatientSourceId &&
        new Date(firstAppointmentDate).getTime() > new Date().getTime(),
    );
  }
  return false;
};

export const getLabelNewOrWeb = (
  appointment: Appointment,
): {
  isWebAppointment: boolean;
  isWebPatient: boolean;
  isNewPatient: boolean;
  iconCount: number;
  hasSeparator: boolean;
} => {
  const { isWeb, patient } = appointment;
  const isWebPatient = isMaiiaPatient(patient);
  const isNewPatient = checkIsNewPatient(appointment);

  let iconCount = 0;
  let hasSeparator = false;
  if ((isWeb || isWebPatient) && isNewPatient) {
    iconCount = 2;
    hasSeparator = true;
  } else if (isWeb || isWebPatient || isNewPatient) {
    iconCount = 1;
  }
  return {
    isWebAppointment: isWeb ?? false,
    isWebPatient,
    isNewPatient,
    iconCount,
    hasSeparator,
  };
};

export const getPercentage = (part: number, total: number): number =>
  Math.round((part / total) * 100);

export const getOffset = (center: CenterDTO | null, date?: string): number => {
  const timeZone = center?.timeZone;
  const offsetBrowser = moment(date).utcOffset();
  const offsetAgenda = moment(date)
    .tz(timeZone ?? DEFAULT_TIMEZONE)
    .utcOffset();
  const offset = offsetBrowser - offsetAgenda;
  return offset;
};

export const getAppointmentDatacy = (
  appointment: Appointment,
): string | undefined =>
  appointment.patient
    ? `appointment_${
        appointment.patient.firstName
          ? snakeCase(appointment.patient.firstName).concat('_')
          : ''
      }${snakeCase(appointment.patient.lastName)}_${
        appointment?.appointmentStatus === CANCELLED ? `-${CANCELLED}` : ''
      }`
    : undefined;

export const getAbsenceDatacy = (absence: Appointment): string =>
  `absence_${new Date(absence.startDate).toISOString()}`;

export const getTimeSlotDatacy = ({
  endDate,
  startDate,
  isPunctual,
}: Partial<Omit<FormattedTimeSlot, 'id'>>): string =>
  `time_slot_from_${moment(startDate).format(FORMAT_EVENT_DATACY)}_to_${moment(
    endDate,
  ).format(FORMAT_EVENT_DATACY)}${isPunctual ? '_punctual' : ''}`;

export const getFormattedAppointmentDate = (
  appointment: Appointment,
  options: { dateFormat?: string; timeFormat?: string } = {},
): string => {
  const { dateFormat = 'DD MMM', timeFormat = 'HH:mm' } = options;
  return i18n.t('common:appointment_date', {
    day: moment(appointment.startDate)
      .format(dateFormat)
      .replace('.', ''),
    startTime: moment(appointment.startDate).format(timeFormat),
    endTime: moment(appointment.endDate).format(timeFormat),
  });
};

export const formatAgendaKey = (
  practitionerId: string,
  centerId: string,
): string => `${practitionerId}-${centerId}`;

export const removeAgendaKey = (
  agendaKeys: string[] = [],
  agendaKey: string,
): string[] => agendaKeys.filter(key => key !== agendaKey);

export const getAgendaRange = (
  date: string,
  agendasWeekDaysSettings: (typeof daysOfTheWeek[0] & {
    id: number;
    isActive: boolean;
  })[],
  nbDisplayedDays: number,
  isBackward: boolean = false,
): Date[] => {
  const range: Date[] = [];
  let i = 0;
  let index = 0;
  const dateOperation = isBackward ? 'subtract' : 'add';
  while (i < nbDisplayedDays && index < 7) {
    const day = moment(date)
      .clone()
      [dateOperation](index, 'days');
    if (agendasWeekDaysSettings[(day.day() + 6) % 7].isActive) {
      if (isBackward) {
        range.unshift(day.toDate());
      } else {
        range.push(day.toDate());
      }
      // eslint-disable-next-line no-plusplus
      i++;
    }
    // eslint-disable-next-line no-plusplus
    index++;
  }
  return range;
};

export const getWeekDaySettings = (
  displaySettingsDays: (
    | typeof MONDAY
    | typeof TUESDAY
    | typeof WEDNESDAY
    | typeof THURSDAY
    | typeof FRIDAY
    | typeof SATURDAY
    | typeof SUNDAY
  )[][],
): (DayOfWeek & { id: number; isActive: boolean })[] =>
  daysOfTheWeek.map(d => ({
    ...d,
    id: d.value,
    isActive: displaySettingsDays.some(displaySettings =>
      displaySettings.includes(d.key),
    ),
  }));

export const getNbDisplayedDays = (
  nbDisplayedCalendars: number,
  view?: string,
): DisplayedDays => {
  if (
    view === WEEK &&
    nbDisplayedCalendars < MAX_DAY_MULTI_CALENDAR_DISPLAYED
  ) {
    return cond([
      [equals(1), constant(7)],
      [equals(2), constant(3)],
      [equals(3), constant(2)],
      [stubTrue, constant(1)],
    ])(nbDisplayedCalendars) as DisplayedDays;
  }
  return 1;
};

type Agenda = {
  practitionerId: string;
  centerId: string;
};

export const getAgendaSettingsByAgendas = (
  agendaSettings: ProAgendaSettingsDTO[],
  agendas: Agenda[],
): ProAgendaSettingsDTO[] =>
  agendaSettings.filter(({ practitionerId, centerId }) =>
    find(agendas, { practitionerId, centerId }),
  );

export const getDisplayDaysByAgendas = (
  agendaSettings: ProAgendaSettingsDTO[],
  agendas: Agenda[],
): (
  | typeof MONDAY
  | typeof TUESDAY
  | typeof WEDNESDAY
  | typeof THURSDAY
  | typeof FRIDAY
  | typeof SATURDAY
  | typeof SUNDAY
)[][] =>
  getAgendaSettingsByAgendas(agendaSettings, agendas).map(
    (settings: ProAgendaSettingsDTO) =>
      settings?.agendaDisplaySettings?.displayDays || [],
  );

export const getDisplayTimesByAgendas = (
  agendaSettings: ProAgendaSettingsDTO[],
  agendas: Agenda[],
): string[][] =>
  getAgendaSettingsByAgendas(
    agendaSettings,
    agendas,
  ).map(({ agendaDisplaySettings }) =>
    agendaDisplaySettings
      ? [
          agendaDisplaySettings?.displayStartTime,
          agendaDisplaySettings?.displayEndTime,
        ]
      : [],
  );

export const displayReferrerData = (
  t: i18next.TFunction,
  { firstName, lastName, specialityName, isDoctor }: Caregiver,
): string =>
  `${t('appointment_referred_by', {
    referrer: getDisplayName({
      isDoctor,
      firstName,
      lastName,
    }),
  })}
  ${specialityName ? ` - ${specialityName}` : ''}`;

export const stripHtml = (
  html: string | typeof undefined,
  trim: boolean = true,
): string => {
  if (typeof document === 'undefined') return '';
  const el = document.createElement('div');
  el.innerHTML = html || '';
  if (trim) {
    return (el.textContent || el.innerText || '').trim();
  }
  return el.textContent || el.innerText || '';
};

export const clearAppointmentConsultationReasonIfNotActive = (
  appointment: Appointment,
): Appointment => {
  let consultationReason = appointment?.consultationReason;

  // In case there is no consultationReason or if it's false, clear it
  if (!appointment.consultationReason?.isActive) {
    consultationReason = undefined;
  }
  return { ...appointment, consultationReason };
};

export const formatSSN = (ssn: string = ''): string => {
  try {
    return ssn.length ? formatSSNLib(ssn) : ssn;
  } catch (error) {
    return ssn;
  }
};

export const startDateFromForm = (
  appointmentDate: string,
  startDate: { date: Moment },
): Moment =>
  startDate &&
  moment(appointmentDate, 'DD/MM/YYYY').set({
    hours: startDate.date.hours(),
    minutes: startDate.date.minutes(),
  });

export const createDownload = (data: BlobPart, title: string): void => {
  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', title);
  document.body.appendChild(link);
  link.click();
};

/**
 * Easily convert a thing (Moment | Dayjs | Date | string) to js Date
 * @returns Date
 */
export const convertToDate = (date: Moment | Dayjs | Date | string): Date =>
  date instanceof moment
    ? moment(date as Moment).toDate()
    : dayjs(date as Dayjs | Date | string).toDate();

export const getUnitCountForAge = (
  date: Date | Dayjs | Moment | string | undefined | null,
): undefined | ['age_year' | 'age_month' | 'age_day', number] => {
  if (!date || date === DATE_EMPTY_VALUE) return undefined;
  let units: 'age_year' | 'age_month' | 'age_day' = 'age_year';
  const _date = dayjs(moment.isMoment(date) ? moment(date).toDate() : date);
  let age = dayjs().diff(_date, 'years');
  if (age === 0) {
    age = dayjs().diff(_date, 'month');
    units = 'age_month';
    if (age === 0) {
      age = dayjs().diff(_date, 'days');
      units = 'age_day';
    }
  }
  return [units, age];
};

export const booleanToNumber = (param: boolean): number => (param ? 1 : 0);

export const noopEvent = (event: Event | React.SyntheticEvent): void => {
  event.preventDefault();
  event.stopPropagation();
};

export const isSafari = (): boolean =>
  /Safari\/.+/.test(navigator.userAgent) &&
  !/Chrome\/.+/.test(navigator.userAgent) &&
  !/Chromium\/.+/.test(navigator.userAgent);

export const fullscreen = (elem: HTMLElement | null): void => {
  const isInFullScreen =
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    // @ts-ignore
    (document.webkitFullscreenElement &&
      // @ts-ignore
      document.webkitFullscreenElement !== null) ||
    // @ts-ignore
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    // @ts-ignore
    (document.msFullscreenElement && document.msFullscreenElement !== null);
  if (!isInFullScreen && elem) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
      // @ts-ignore
    } else if (elem.mozRequestFullScreen) {
      // @ts-ignore
      elem.mozRequestFullScreen();
      // @ts-ignore
    } else if (elem.webkitRequestFullScreen) {
      // @ts-ignore
      elem.webkitRequestFullScreen();
      // @ts-ignore
    } else if (elem.msRequestFullscreen) {
      // @ts-ignore
      elem.msRequestFullscreen();
    }
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
    // @ts-ignore
  } else if (document.webkitExitFullscreen) {
    // @ts-ignore
    document.webkitExitFullscreen();
    // @ts-ignore
  } else if (document.mozCancelFullScreen) {
    // @ts-ignore
    document.mozCancelFullScreen();
    // @ts-ignore
  } else if (document.msExitFullscreen) {
    // @ts-ignore
    document.msExitFullscreen();
  }
};

export const changeThousandWithK = (num: number): string =>
  num > 999 ? `${(num / 1000)?.toFixed(1)}K` : num?.toString();

export const normalizeString = (text: string): string =>
  deburr(text)
    .replace(/ /g, '')
    .toLowerCase();

export type CenterNode = (CenterDTO | Center) & {
  children?: CenterNode[];
  level?: number;
};

export const isNextRouteSafe = (nextRoute: string): boolean =>
  // eslint-disable-next-line no-useless-escape
  !!/^[^\/.]+\/[^\/]$|^\/[^\/].*$/.test(nextRoute);

export const makeTreeForCenters = (data: CenterNode[]): CenterNode[] => {
  const groupedByParents = groupBy(data, 'parentCenter.id');
  const itemsById = keyBy(data, 'id');
  if (!Object.prototype.hasOwnProperty.call(groupedByParents, 'undefined')) {
    groupedByParents.undefined = [];
  }
  each(omit(groupedByParents, 'undefined'), (children, parentId) => {
    if (itemsById[parentId]) itemsById[parentId].children = children;
    else groupedByParents.undefined.push(...children);
  });
  return groupedByParents.undefined;
};
export const isSimplyV1 = (
  softwareCode?: string,
  simplyVersion?: string | string[],
): boolean =>
  softwareCode === SIMPLY_SOFTWARE_CODE && simplyVersion !== '4.1.0';

export const isOnlineCegedimOrCallibri = (origin?: string): boolean =>
  !!origin && [ONLINE_CALLIBRI, ONLINE_CEGEDIM].includes(origin);

export const isOnboardingSynchronized = (
  center: CenterDTO | null,
  simplyVersion?: string | string[],
): boolean => {
  if (!center) {
    return false;
  }
  const centerExternalSync = center?.centerExternalSyncList?.[0];
  const syncStatus = centerExternalSync?.syncStatus;
  const hasAppointmentsToSync = !!centerExternalSync?.appointmentSyncMetadata
    ?.initAppointmentMetaData?.length;
  const hasAppointmentsSynchronizationEnded = !!centerExternalSync?.appointmentSyncMetadata?.initAppointmentMetaData?.every(
    initAppointmentMetaData =>
      initAppointmentMetaData?.appointmentSyncStatus === SYNCHRONIZED,
  );
  const isSynchronized =
    syncStatus === SYNCHRONIZED ||
    (syncStatus === ASSOCIATED &&
      isSimplyV1(centerExternalSync?.software?.code, simplyVersion));
  return hasAppointmentsToSync
    ? hasAppointmentsSynchronizationEnded
    : isSynchronized;
};

export const isSynchronizationNeeded = (
  center: CenterDTO,
  simplyVersion?: string | string[],
): boolean => {
  if (!center?.centerExternalSyncList?.length) {
    return false;
  }
  const syncStatus = center?.centerExternalSyncList?.[0]?.syncStatus;
  return (
    !isOnboardingSynchronized(center, simplyVersion) &&
    syncStatus !== BLOCKED &&
    syncStatus !== DELETED &&
    isOnlineCegedimOrCallibri(center?.origin)
  );
};

export const getTaskStatusInformation = (
  user: User,
  task: TaskAttachment,
  creationDate: string | Date | undefined,
): {
  user: string;
  date: string;
  hour: string;
} => {
  const isTaskDone = task?.status === 'DONE';
  if (isTaskDone) {
    const accomplisherName =
      user?.role?.name === TELESECRETARY
        ? `${task.taskAccomplisher?.authorExtraInformation?.firstName} ${task.taskAccomplisher?.authorExtraInformation?.lastName}`
        : getUserDisplayName(user);
    const taskAccomplishDate = `${moment(task?.endDate).format('DD')} ${MONTHS[
      moment(task?.endDate).month()
    ].toLowerCase()} ${moment(task?.endDate).format('YYYY')}`;
    const taskAccomplishTime = moment(task?.endDate).format('HH:mm');
    return {
      user: accomplisherName,
      date: taskAccomplishDate,
      hour: taskAccomplishTime,
    };
  }
  let myDate = moment(creationDate);
  if (!myDate || !myDate.isValid()) {
    myDate = moment();
  }
  const creatorName =
    user?.role?.name === TELESECRETARY
      ? `${task.creator.authorExtraInformation?.firstName} ${task.creator?.authorExtraInformation?.lastName}`
      : getUserDisplayName(user);
  const taskCreationDate = `${myDate.format('DD')} ${MONTHS[
    myDate.month()
  ].toLowerCase()} ${myDate.format('YYYY')} `;
  const taskCreationTime = myDate.format('HH:mm');
  return {
    user: creatorName,
    date: taskCreationDate,
    hour: taskCreationTime,
  };
};

export const makeInclusionCheckerFn = (
  arr: string[],
): ((pathname: string) => boolean) => pathname => arr.includes(pathname);

/**
 * Utility function that returns a function that creates a subset of values
 * from an array of keys.
 *
 * Example:
 *
 * Source object:
 * const Routes = { LOGIN: '/login', ROOT: '/', FORGOT_PASSWORD: '/forgot-password' };
 *
 * const createRoutesList = makeSubsetter(Routes);
 *
 * TypeScript provides autocomplete for the keys in the subset array.
 * const PASSWORD_PAGES = createRoutesList([ 'FORGOT_PASSWORD' ]); // -> ['/forgot-password']
 *
 * @param src
 */
export const makeSubsetter = <T extends Record<string, string>>(
  src: T,
): ((subset: (keyof T)[]) => string[]) => subset =>
  Array.from(Object.entries(src))
    .filter(([key]) => subset.includes(key))
    .map(([, value]) => value);

export const removeUndefined = <T>(
  obj: Record<string, T | undefined>,
): Record<string, T> =>
  Object.fromEntries(
    Object.entries(obj).filter(
      (entry: [string, T | undefined]): entry is [string, T] =>
        entry[1] !== undefined,
    ),
  );

export const filterFalsyValues = <T>(
  value: T,
): value is Exclude<T, false | null | undefined | '' | 0> => {
  return Boolean(value);
};

export const isDefined = <T>(value: T | undefined): value is T => {
  return value !== undefined;
};

export const isServerSideContext = (ctx: NextPageContext): boolean =>
  !!ctx?.req;

export const isOldBcbLink = (bcbProductUrl: string): boolean =>
  bcbProductUrl.includes(PAT_MEDICINES_URL);

export const formatOldBcbLink = (bcbProductUrl: string): string =>
  bcbProductUrl.replace(PAT_MEDICINES_URL, MAIIA_MEDICINES_URL);

export const hasMatomoBcbParams = (bcbProductUrl: string): boolean => {
  const regex = /utm_campain|utm_medium/;
  return regex.test(bcbProductUrl);
};

const getMatomoBcbParams = (utmMedium: string): string =>
  `utm_campain=BCB&utm_medium=${utmMedium}`;

export const addMatomoBcbParams = (
  bcbProductUrl: string,
  utmMedium: string,
): string => {
  const matomoBcbParams = getMatomoBcbParams(utmMedium);
  return bcbProductUrl.concat(`?${matomoBcbParams}`);
};

export const getBcbProductExternalLink = (
  bcbProduct: BcbPrescriptionProduct,
  utmMedium: string,
): string | undefined => {
  const medicineLabel = bcbProduct.libelleCourt.replace(/\W/g, '-');
  const medicineId = bcbProduct.idProduit;
  const brandId = bcbProduct.idMarque;
  const brandLabel = bcbProduct.libelleMarque;
  const maiiaBcbUrl = getConfig()?.publicRuntimeConfig?.MAIIA_MEDICINES_URL;
  if (brandId && brandLabel && maiiaBcbUrl) {
    const url = `${maiiaBcbUrl}/${brandLabel}-${brandId}/${medicineLabel}-${medicineId}`;
    return addMatomoBcbParams(url, utmMedium);
  }
};

export const getCategoryGALabel = (category: string): string =>
  category === 'PRESCRIPTION'
    ? 'MedicalPrescription'
    : upperFirst(camelCase(category));

export const checkConnection = async (): Promise<boolean> => {
  const servers = [
    'https://www.google.com/favicon.ico',
    'https://www.facebook.com/favicon.ico',
    'https://www.amazon.com/favicon.ico',
    'https://www.apple.com/favicon.ico',
    'https://www.netflix.com/favicon.ico',
  ];
  const _check = src =>
    new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = `${src}?_=${new Date().getTime()}`;
      setTimeout(() => resolve(false), 1000);
    });
  const results = await Promise.all(servers.map(_check));
  return results.some(Boolean);
};

export const parseOpenTokConnectionData = (
  data: string,
): Record<string, string> =>
  data.split(';').reduce((curr, item) => {
    const [key, value] = item.split('=');
    // eslint-disable-next-line no-param-reassign
    curr[key] = value;
    return curr;
  }, {});

const STETHOSCOPE = 'device_stethoscope';
const OTOSCOPE = 'device_otoscope';
const stethoscopes = ['PCP-USB'];
const otoscopes = ['Otoscope', 'Microscope'];

/**
 * This function takes a technical name of a device and return a human-friendly name for it.
 */
const transform = (label: string): string => {
  const checkStethoscope = (name: string): string =>
    stethoscopes.reduce(
      (value, type) => value || name.indexOf(type) >= 0,
      false,
    )
      ? STETHOSCOPE
      : name;
  const checkOtoscope = (name: string): string =>
    otoscopes.reduce((value, type) => value || name.indexOf(type) >= 0, false)
      ? OTOSCOPE
      : name;
  // Remove technical term from device label. For example, most of devices have this form 'Device label (xxxx:xxxx)'
  const cleanUp = (name: string): string => {
    const matches = /(.+?) \(([^)]+)\)/g.exec(name);
    if (matches && matches.length > 1) {
      return matches[1];
    }
    return name;
  };
  return [checkStethoscope, checkOtoscope, cleanUp].reduce(
    (v, f) => f(v),
    label,
  );
};

type MediaDevice = {
  id: string;
  key: string;
  label: string;
};

/**
 * Clean device names then remove duplication
 */
const cleanDeviceNames = (
  t: (arg0: string) => string,
): ((devices: MediaDevice[]) => MediaDevice[]) => devices =>
  uniqBy(
    devices.map(device => ({
      ...device,
      key: transform(device.key),
      label: t(transform(device.label)),
    })),
    'label',
  );

const mapDeviceFn = (device: MediaDeviceInfo): MediaDevice => ({
  id: device.deviceId,
  label: device.label,
  key: device.label,
});

export const getListMediaDevices = async (
  t: (arg0: string) => string,
): Promise<{
  audioDevices: MediaDevice[];
  videoDevices: MediaDevice[];
}> => {
  // This is required to always get a list with label filled. otherwise, sometimes, the label would be empty and thus would break the helpers.
  // @see https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/enumerateDevices#examples, https://stackoverflow.com/questions/60297972/navigator-mediadevices-enumeratedevices-returns-empty-labels
  await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
  const devices = await navigator.mediaDevices.enumerateDevices();
  const inputDevices = devices.filter(device =>
    ['audioinput', 'videoinput'].includes(device.kind),
  );
  const audioDevices = inputDevices
    .filter(device => device.kind === 'audioinput')
    .map(mapDeviceFn);
  const videoDevices = inputDevices
    .filter(device => device.kind === 'videoinput')
    .map(mapDeviceFn);
  return {
    audioDevices: cleanDeviceNames(t)(audioDevices),
    videoDevices: cleanDeviceNames(t)(videoDevices),
  };
};

export const mergePdfsToOne = async (
  blob: Blob,
  docs: File[],
): Promise<Buffer> => {
  const PDFMerger = (await import('pdf-merger-js')).default;
  const merger = new PDFMerger();
  const arrayBuffer = await blob.arrayBuffer();
  await merger.add(arrayBuffer);
  const promiseDow = docs
    .filter(doc => doc.type === 'application/pdf')
    .map(async doc => {
      const b = await doc.arrayBuffer();
      await merger.add(b);
    });
  await Promise.all(promiseDow);
  const buffer = await merger.saveAsBuffer();
  return buffer;
};

export const urlChecker = (text: string): [] | RegExpMatchArray => {
  const expression = /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<,]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s<,]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s<,]{2,}/g;
  const urlExpression = new RegExp(expression);
  const textLink = text.match(urlExpression);
  return textLink ?? [];
};

export const findLinksInChatMessage = (
  text: string,
  linksArray: string[],
): string => {
  let textWithLink = text;
  if (!isEmpty(linksArray)) {
    for (let i = 0; i < linksArray?.length; i++) {
      const httpPrefix = linksArray[i].includes('http') ? '' : 'http://';
      // @ts-ignore - todo update lib to 2021 in tsconfig
      textWithLink = textWithLink.replaceAll(
        linksArray[i],
        `<a href="${httpPrefix}${linksArray[i]}" target="_blank" rel="noopener">${linksArray[i]}</a>`,
      );
    }
  }
  return textWithLink;
};

export const chatMessagesCustomAction = <T>(
  store: Store,
  chatMessage: ChatMessage,
  actionToPut: T,
): T => {
  const {
    authorExtraInformation,
    chatMessageType,
    teleExpertiseData,
  } = chatMessage;
  const showSnackBar =
    chatMessageType === TELE_EXPERTISE &&
    teleExpertiseData?.status === 'COMPLETED' &&
    authorExtraInformation;
  if (showSnackBar) {
    const { patient } = teleExpertiseData;
    store.dispatch(
      snacksActions.enqueueSnack({
        id: chatMessage.id,
        variant: 'success',
        duration: 10000,
        message:
          patient &&
          i18n.t('chat_message_snackbar_teleExpertise_completed', {
            practitionerName: getPractitionerDisplayName(
              authorExtraInformation,
            ),
            // TODO: Remove this type assertion when Patient and PatientGetDTO match in the backend.
            patientName: getPatientDisplayName(patient as Patient),
          }),
        actionNode: i18n.t(
          'chat_message_completed_tele_expertise_report_button',
        ),
        onClick: () => {
          store.dispatch(
            dialogActions.setDialogType({
              dialogType: DialogType.TELEEXPERTISE_SUMMARY,
              dialogProps: { chatMessage },
            }),
          );
        },
      }),
    );
  }
  return actionToPut;
};

export const computeTeleExpertisePayload = (
  centerId?: string | null,
  practitionerId?: string | null,
  tleStatus?: TeleExpertiseStatus[] | TeleExpertiseStatus,
  limit?: number,
  page: number = 0,
  direction?: string,
  sort?: string,
): {
  centerId: string | null | undefined;
  limit: number | undefined;
  page: number;
  userId: string | null | undefined;
  tleStatus: TeleExpertiseStatus[] | TeleExpertiseStatus | undefined;
  direction: string | undefined;
  sort: string | undefined;
} => {
  return {
    centerId,
    limit,
    page,
    userId: practitionerId === ALL ? '' : practitionerId,
    tleStatus: tleStatus === ALL ? [ONGOING, COMPLETED] : tleStatus,
    direction,
    sort,
  };
};

export const sortChatMessagesByDate = (
  messages: ChatMessage[],
  direction = 'asc',
): ChatMessage[] => {
  messages.sort((message, nextMessage) => {
    if (direction === 'asc') {
      return moment(message.creationDate).isBefore(nextMessage.creationDate)
        ? -1
        : 1;
    }
    return moment(message.creationDate).isAfter(nextMessage.creationDate)
      ? -1
      : 1;
  });
  return messages;
};

const selectAppointmentId = (state: RootState): string =>
  state.appointmentsTlc.item?.id || '';

const selectVideoSessions = (state: RootState): VideoSessionAggregate[] =>
  state.videoSessions.items;

export const useCurrentVideoSessionsInfo = ():
  | VideoSessionAggregate
  | undefined => {
  const videoSession = useSelector(
    createSelector(
      selectAppointmentId,
      selectVideoSessions,
      (id: string, videoSessions: VideoSessionAggregate[]) => {
        const videoSessionsId = videoSessions.reduce(
          (_, curr) => (curr.appointmentId === id ? curr.id : ''),
          '',
        );
        return videoSessions.find(item => item.id === videoSessionsId);
      },
    ),
  );
  return videoSession;
};

export const getPractitionerSharedDocuments = (docs: Document[]): Document[] =>
  // TODO types re-export references to overrided classes
  docs.filter(doc => doc.sharedInformation?.sourceUserType?.name === PRO);

export const toHoursAndMinutes = (totalMinutes: number): string => {
  const hours = quotient(totalMinutes, 60);
  const minutes = remainder(totalMinutes, 60);
  const hourPart = hours > 0 ? `${hours} h` : '';
  const separator = hours > 0 && minutes > 0 ? ' ' : '';
  const minutePart =
    minutes > 0 || (hours === 0 && minutes === 0) ? `${minutes} min` : '';
  return hourPart + separator + minutePart;
};

export const findClosest = (value: number, sequence: number[]): number =>
  sequence.reduce((accumulator, current) =>
    Math.abs(current - value) < Math.abs(accumulator - value)
      ? current
      : accumulator,
  );

export const roundToNearest = (value: number, nearest = 1): number =>
  Math.round(value / nearest) * nearest;

export const capitalize = (value: string): string =>
  value.toLowerCase().replace(/^./, string => string.toUpperCase());

export const formatFileSize = (bytes: number, decimalPoint = 2): string => {
  if (bytes === 0) return `0 ${i18n.t('common:bytes')}`;
  const fileSizeUnits = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'].map(
    s => `${s}${i18n.t('common:fileSizeUnit')}`,
  );
  const k = 1000;
  const dm = decimalPoint;
  const sizes = [i18n.t('common:bytes'), ...fileSizeUnits];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${(bytes / k ** i).toFixed(dm)} ${sizes[i]}`;
};

export const getLocale = (): string =>
  (window.navigator.cookieEnabled && window.localStorage.getItem(KEY_LANG)) ||
  'fr';

export const setLocale = (locale: string): void => {
  if (window.navigator.cookieEnabled) {
    window.localStorage.setItem(KEY_LANG, locale);
    window.location.href = window.location.href;
  }
};

export const findChunks = ({
  sanitize,
  searchWords,
  textToHighlight,
}: FindChunks): Chunk[] => {
  const chunks: Chunk[] = [];
  for (const searchWord of searchWords) {
    if (typeof searchWord === 'string') {
      const pattern = sanitize?.(searchWord) ?? searchWord;
      let start = 0;
      let end = 0;
      let patternIndex = 0;
      for (let fromIndex = 0; fromIndex < textToHighlight.length; ++fromIndex) {
        for (
          let matchIndex = fromIndex;
          matchIndex < textToHighlight.length;
          ++matchIndex
        ) {
          const text =
            sanitize?.(textToHighlight[matchIndex]) ??
            textToHighlight[matchIndex];
          if (text === pattern[patternIndex]) {
            if (patternIndex === 0) {
              start = matchIndex;
            }
            if (patternIndex === pattern.length - 1) {
              end = matchIndex + 1;
              chunks.push({ start, end });
              patternIndex = 0;
              break;
            }
            ++patternIndex;
          } else if (text) {
            patternIndex = 0;
            break;
          }
        }
      }
    }
  }
  return chunks;
};

export const getPractitionerCardHcdInfo = (
  item: ProfessionalCardDTO,
): ProfessionalCardDTO & {
  name: string;
  speciality: SpecialityHcd['name'];
  avatarUrl?: string;
  chatInvitationDTO: ProfessionalCardDTO['selfOnboardingInvitationDTO'];
  creationDate?: SelfOnboardingInvitationDTO['creationDate'];
  customMessage?: SelfOnboardingInvitationDTO['receiverData']['customMessage'];
} => {
  const {
    firstName,
    lastName,
    cpsId,
    practitionerId,
    mainPicture,
    selfOnboardingInvitationDTO: chatInvitationDTO,
    clientMaiia,
    chatRoom,
    address,
    cardHcdId,
  } = item;
  const speciality = item.speciality?.name || '';
  const name = `${firstName} ${lastName}`;
  const s3Id = mainPicture?.s3Id;
  const avatarUrl = s3Id && `/avatars/${s3Id}`;
  const creationDate = chatInvitationDTO?.creationDate;
  const customMessage = chatInvitationDTO?.receiverData?.customMessage;
  return {
    name,
    firstName,
    lastName,
    speciality,
    address,
    avatarUrl,
    cpsId,
    practitionerId,
    chatInvitationDTO,
    clientMaiia,
    chatRoom,
    cardHcdId,
    creationDate,
    customMessage,
  };
};

export const isConnectPathname = (pathname: string): boolean =>
  ([
    ChatRoutes.CHAT,
    ChatRoutes.CHAT_INVITATIONS,
    ChatRoutes.CHAT_CREATE_INVITATIONS,
    ChatRoutes.CHAT_TELE_EXPERTISE,
  ] as string[]).includes(pathname);

export const getPractictionerCivility = (
  isParamedical: boolean = true,
): string => (!isParamedical ? 'Dr' : '');

export const isMaiiaPractictioner = (
  user?: User | Practitioner | ProfessionalHcd | object,
): unknown => user && 'clientMaiia' in user && user.clientMaiia;

/**
 * Remove all cookies that we are no more using,
 * especially from tracking providers like Facebook, Google, or Hotjar.
 */
export const removeOldCookieTrackers = (): void => {
  /**
   * The following cookies lists are taken from tarteaucitron.services.js
   * @see https://github.com/AmauriC/tarteaucitron.js/blob/master/tarteaucitron.services.js
   */
  const cookiesList = {
    googleAnalytics: [
      '_ga',
      '_gat',
      '_gid',
      '__utma',
      '__utmb',
      '__utmc',
      '__utmt',
      '__utmz',
      '_gcl_au',
    ],
    facebookPixelCookies: ['_fbp'],
    hotjarCookies: [
      'hjClosedSurveyInvites',
      '_hjDonePolls',
      '_hjMinimizedPolls',
      '_hjDoneTestersWidgets',
      '_hjMinimizedTestersWidgets',
      '_hjDoneSurveys',
      '_hjIncludedInSample',
      '_hjShownFeedbackMessage',
      '_hjAbsoluteSessionInProgress',
      '_hjIncludeInPageviewSample',
      '_hjid',
    ],
  };
  const allCookies = Cookie.get();
  const cookieKeys = flatten(Object.values(cookiesList));
  Object.keys(allCookies).forEach(cookieKey => {
    if (cookieKeys.some(c => c === cookieKey || c.startsWith(cookieKey))) {
      Cookie.remove(cookieKey);
    }
  });
};

/**
 * Generate a range of numbers following these constraints:
 * - result[index] === start + index * step;
 * - index >= 0;
 * - result[index] < stop if step > 0;
 * - result[index] > stop if step < 0.
 * @param start - The start of the range (inclusive).
 * @param stop - The end of the range (exclusive).
 * @param [step=1] - The step between each number in the range.
 * @return A generator of numbers.
 * @exception {RangeError} Parameter step must be different from 0.
 *
 * @example
 * // Generates a range of numbers from 0 (inclusive) to 5 (exclusive) with a step of 1.
 * [...range(5)]; // [0, 1, 2, 3, 4]
 *
 * // Generates a range of numbers from 1 (inclusive) to 6 (exclusive) with a step of 1.
 * [...range(1, 6)]; // [1, 2, 3, 4, 5]
 *
 * // Generates a range of numbers from 1 (inclusive) to 6 (exclusive) with a step of 2.
 * [...range(1, 6, 2)]; // [1, 3, 5]
 *
 * // Generates a range of numbers from 5 (inclusive) to 0 (exclusive) with a step of -1.
 * [...range(5, 0, -1)]; // [5, 4, 3, 2, 1]
 */
export function range(stop: number): Generator<number, undefined, undefined>;
export function range(
  start: number,
  stop: number,
  step?: number,
): Generator<number, undefined, undefined>;
export function* range(
  startOrStop: number,
  stop?: number,
  step: number = 1,
): Generator<number, undefined, undefined> {
  if (step === 0) {
    throw new RangeError('Parameter step must be different from 0');
  }
  const startBound = stop === undefined ? 0 : startOrStop;
  const stopBound = stop === undefined ? startOrStop : stop;
  let element = startBound;
  while (step > 0 ? element < stopBound : element > stopBound) {
    yield element;
    element += step;
  }
}

const blacklistedDomains: {
  loaded: boolean;
  domains: Set<string>;
} = {
  loaded: false,
  domains: new Set(),
};

/**
 * Check whether an email is disposable against a blacklist of maintained domains.
 * @param email string
 * @returns true if it's disposable and false otherwise
 * @throws if unable to fetch
 */
export const isDisposableEmail = async (email: string) => {
  if (!email) return true;
  const domain = (email.split('@')[1] || '').toLowerCase();

  if (blacklistedDomains.loaded) return blacklistedDomains.domains.has(domain);

  const response = await fetch(
    'https://raw.githubusercontent.com/disposable-email-domains/disposable-email-domains/master/disposable_email_blocklist.conf',
  );
  if (!response.ok) {
    throw new Error('failed to download remote blocklist');
  }

  const data = await response.text();
  const domains = [
    ...data.split('\n').slice(0, -1),
    // Custom domains
    'trashmail.fr',
  ];
  domains.forEach(d => {
    blacklistedDomains.domains.add(d);
  });
  blacklistedDomains.loaded = true;
  return blacklistedDomains.domains.has(domain);
};

/**
 * Get all query parameters from url
 * For array values, you may use the default system or use a custom separator by providing it in parameter.
 * See example for usage
 * @param {string?} urlSearch - a search url to lookup. Lust start with "?"
 * @param {string} keyValueSeparator - char to separate key from value
 * @param {string} valuesSeparator - separator to use for multiple values of a single key
 * @example
 * The examples below use a custom url for showcase
 *
 * // Simple value
 * getAllQueryParams("?id=12&page=1"); // { page: '1', id: '12'}
 *
 * // Default separators
 * getAllQueryParams("?id=12,487,20&page=1"); // { page: '1', id: ['12', '487', '620']}
 *
 * // Default separators but with keys splitted
 * getAllQueryParams("?id=12,487&id=20&page=1"); // { page: '1', id: ['12', '487', '620']}
 *
 * // Custom separators
 * getAllQueryParams("?id~~12~487~620=&page=1", "~~", "~"); // { page: '1', id: ['12', '487', '620']}
 */
export const getAllQueryParams = <T extends string>(
  urlSearch?: string,
  keyValueSeparator: string = '=',
  valuesSeparator: string = ',',
): Record<T, string | string[]> => {
  const searchParams = new URLSearchParams(urlSearch || window.location.search);
  return Array.from(searchParams.keys()).reduce((acc, key) => {
    // Custom key-value system
    if (key.includes(keyValueSeparator)) {
      const [realKey, realValue] = key.split(keyValueSeparator);
      let newValue = realValue?.includes(valuesSeparator)
        ? realValue?.split(valuesSeparator)
        : realValue;
      // Merge values
      if (Array.isArray(acc[realKey])) newValue = [...acc[realKey], newValue];
      //  or set the current one
      else newValue = acc[realKey] ? [acc[realKey], newValue] : newValue;

      return {
        ...acc,
        [realKey]: newValue,
      };
    }
    // Transform single or multiple values into literal or array
    // ?foo=10&bar=id => { foo: "10", bar: "id" }
    // ?foo=10&baz=&foo=23 => { foo: ["10", "23"], baz: "" }
    // ?foo=10,34&foo=23 => { foo: ["10", "34", "23"] }
    const values = searchParams
      .getAll(key)
      .map(v => (v?.includes(',') ? v?.split(',') : v))
      .flat();
    return {
      ...acc,
      [key]: values.length === 1 ? values[0] : values,
    };
  }, {} as Record<T, string | string[]>);
};

/**
 * Set given values to the current page url or specified url without refreshing the page nor changing the history stack.
 * Keys with falsy values or empty array would be removed instead.
 *
 * For array values, if a separator is specified and !== '=', then the generated url would be like
 *  - key: {realKey}{keyValueSeparator}{value1}{valuesSeparator}{value2}
 *  - value: ''
 * @param {object} values - <key,value> object to set in the url
 * @param {string?} url - the full url to update or the current page url
 * @param {string} keyValueSeparator - char to separate key from value
 * @param {string} valuesSeparator - separate to use for multiple values of a single key
 * @example
 * The examples below use a custom url for showcase
 *
 * // Simple values
 * setValuesToQueryParams({ page: '1', id: '12'}, "http://a.com); // "http://a.com?id=12&page=1"
 * setValuesToQueryParams({ page: '1', id: '12'}, "http://a.com?page=4"); // "http://a.com?id=12&page=1"
 * setValuesToQueryParams({ page: null, id: '12'}, "http://a.com?page=4"); // "http://a.com?id=12"
 * setValuesToQueryParams({ page: null }, "http://a.com?id=10"); // "http://a.com?id=10"
 *
 * // Array values with default separators
 * getAllQueryParams("?id=12,487,20&page=1"); // { page: '1', id: ['12', '487', '620']}
 *
 * // Default separators but with keys splitted
 * getAllQueryParams("?id=12,487&id=20&page=1"); // { page: '1', id: ['12', '487', '620']}
 *
 * // Custom separators
 * getAllQueryParams("?id~~12~487~620=&page=1", "~~", "~"); // { page: '1', id: ['12', '487', '620']}
 */
export const setValuesToQueryParams = <T extends Record<string, unknown>>(
  values: Partial<T>,
  url?: string,
  keyValueSeparator = '=',
  valuesSeparator = ',',
) => {
  const _url = new URL(url || window.location.href);
  const searchParams = new URLSearchParams(_url.search);

  Object.entries(values).forEach(elt => {
    const [key, value] = elt;
    if (![undefined, null, NaN, ''].includes(value)) {
      // Handle new array case with custom key-value system
      if (Array.isArray(value)) {
        // Deletion
        // Delete key from a custom separator system
        Array.from(searchParams.keys()).forEach(k => {
          if (k.includes(key)) {
            _url.searchParams.delete(k);
          }
        });
        // Delete the key if using the default system or separator
        _url.searchParams.delete(key);

        if (value.length > 0) {
          // Set new value
          if (keyValueSeparator === '=') {
            value.forEach(v => {
              _url.searchParams.append(key, v);
            });
          } else {
            // For keyValueSeparator='~~' and valuesSeparator='~' and { foo: [12, 45, "bar"] } as value
            //  the result should look like foo~~12~45~bar dependent on separators values
            const newKey =
              key + keyValueSeparator + value.join(valuesSeparator);
            _url.searchParams.set(newKey, '');
          }
        }
      }
      // literal value case
      else _url.searchParams.set(key, `${value}`);
    } else {
      _url.searchParams.delete(key);
    }
  });
  // Update the browser url if not provided or return the update string
  if (!url) window.history.replaceState(null, '', _url.toString());

  return _url.toString();
};

const maskSecurityNumberOpts = {
  mask: '0 00 00 0* 000 000 00',
};

export const maskSecurityNumber = (v: string) => {
  const mask = IMask.createMask(maskSecurityNumberOpts);
  mask.resolve(v);
  return v;
};

/**
 * Create a proxy for function window.open which opens *any* resources in the
 * same browsing context (tab, window, or iframe) for the same target argument.
 * The function has the same signature as window.open.
 */
export const createOpenProxy = (): ((
  url?: string,
  target?: string,
  windowFeatures?: string,
) => Window | null) => {
  let prevBrowsingContext: Window | null = null;
  let prevTarget: string | undefined;
  return (url, target, windowFeatures) => {
    if (
      prevBrowsingContext === null ||
      prevBrowsingContext.closed ||
      target !== prevTarget
    ) {
      prevBrowsingContext = window.open(url, target, windowFeatures);
      prevTarget = target;
    } else {
      prevBrowsingContext.focus();
      if (url) {
        prevBrowsingContext.location.replace(url);
      }
    }
    return prevBrowsingContext;
  };
};

export const openProxy = createOpenProxy();

export function createSafeContext<T>(
  defaultValue?: T,
): [React.Provider<T>, () => NonNullable<T>] {
  const context = createContext<T>(defaultValue as T);
  const useSafeContext = () => {
    const value = useContext(context);
    if (!value) {
      throw new Error('useContext must be inside a Provider with a value');
    }

    return value;
  };

  return [context.Provider, useSafeContext];
}

/**
 * Pack non-array data into an array and leave array data unchanged.
 * @param data - Data to pack.
 */
export const pack = <T>(data: T): T extends unknown[] ? T : T[] =>
  Array.isArray(data) ? data : ([data] as any); // This type assertion is necessary to overcome TypeScript’s inference limitations on generics, cf. https://stackoverflow.com/q/78337718/2326961.

/**
 * Unpack an element from array data and leave non-array data unchanged.
 * @param data - Data from which to unpack.
 */
export const unpack = <T>(
  data: T,
  index: number = 0,
): T extends unknown[] ? T[number] : T =>
  Array.isArray(data) ? data[index] : data;

/**
 * Memoise a value, update the memoised value on deep inequality with a new value, and return the memoised value.
 * @param cacheable - The value to memoise.
 */
export const useDeepEqualMemo = <T>(cacheable: T): T => {
  const ref = useRef(cacheable);
  if (!isEqual(cacheable, ref.current)) {
    ref.current = cacheable;
  }
  return ref.current;
};

/**
 * Check whether a candidate is an object value (as opposed to a primitive value).
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof#description
 * @param candidate - The candidate to check.
 */
export const isObject = (candidate: unknown): candidate is object =>
  ['object', 'function'].includes(typeof candidate) && candidate !== null;

/**
 * Return the entries of an object that meet the condition specified in a predicate.
 * @param obj - The object to filter.
 * @param predicate - The predicate called with each value and key of the object.
 */
export const filterObject = <T>(
  obj:
    | {
        [key: string]: T;
      }
    | ArrayLike<T>,
  predicate: (
    value: T,
    key: string,
    object:
      | {
          [key: string]: T;
        }
      | ArrayLike<T>,
  ) => boolean,
): typeof obj extends ArrayLike<T>
  ? ArrayLike<T>
  : {
      [key: string]: T;
    } =>
  Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => predicate(value, key, obj)),
  );

/**
 * Return an array of pages with ellipses when necessary.
 * @param page - The current page.
 * @param pageCount - The number of pages.
 * @param boundaryPageCount - The number of pages at the start and at the end.
 * @param siblingPageCount - The number of pages before and after the current page.
 *
 * p: page
 * c: page count
 * b: boundary page count
 * s: sibling page count
 *
 * Pagination: 1 b '…' p-s p+s     '…'     c-b+1   c
 * Indices:    1 b b+1 b+2 b+2*s+2 b+2*s+3 b+2*s+4 2*b+2*s+3
 *
 * Lower boundary pages: range(1, min(b, c))
 * Upper boundary pages: range(max(c-b+1, b+1), c)
 * Sibling pages: range(max(min(p-s, c-b-2s-1), b+2), min(max(p+s, b+2s+2), c-b-1))
 * Lower ellipsis: sibling pages start > b+2 ? '…' : b+1 < c-b ? b+1 : undefined
 * Upper ellipsis: sibling pages end < c-b-1 ? '…' : c-b > b ? c-b : undefined
 *
 * References:
 * - https://mui.com/material-ui/react-pagination/#usepagination
 * - https://github.com/mui/material-ui/blob/master/packages/mui-material/src/usePagination/usePagination.js
 */
export const getPagination = ({
  page,
  pageCount,
  boundaryPageCount = 1,
  siblingPageCount = 1,
}: {
  page: number;
  pageCount: number;
  boundaryPageCount?: number;
  siblingPageCount?: number;
}): (number | string)[] => {
  const lowerBoundaryPages = range(
    1,
    Math.min(boundaryPageCount, pageCount) + 1,
  );
  const upperBoundaryPages = range(
    Math.max(pageCount - boundaryPageCount + 1, boundaryPageCount + 1),
    pageCount + 1,
  );
  const firstSiblingPage = Math.max(
    Math.min(
      page - siblingPageCount,
      pageCount - boundaryPageCount - 2 * siblingPageCount - 1,
    ),
    boundaryPageCount + 2,
  );
  const lastSiblingPage = Math.min(
    Math.max(
      page + siblingPageCount,
      boundaryPageCount + 2 * siblingPageCount + 2,
    ),
    pageCount - boundaryPageCount - 1,
  );
  const siblingPages = range(firstSiblingPage, lastSiblingPage + 1);
  let startEllipsis: string | number | undefined;
  if (firstSiblingPage > boundaryPageCount + 2) {
    startEllipsis = 'start-ellipsis';
  } else if (boundaryPageCount + 1 < pageCount - boundaryPageCount) {
    startEllipsis = boundaryPageCount + 1;
  }
  let endEllipsis: string | number | undefined;
  if (lastSiblingPage < pageCount - boundaryPageCount - 1) {
    endEllipsis = 'end-ellipsis';
  } else if (boundaryPageCount < pageCount - boundaryPageCount) {
    endEllipsis = pageCount - boundaryPageCount;
  }
  return [
    ...lowerBoundaryPages,
    ...(startEllipsis !== undefined ? [startEllipsis] : []),
    ...siblingPages,
    ...(endEllipsis !== undefined ? [endEllipsis] : []),
    ...upperBoundaryPages,
  ];
};

/**
 * Split an iterable into slices of equal length.
 * If it cannot be split evenly, the last slice will contain the remaining elements.
 * @param iterable - The iterable to slice.
 * @param sliceLength - The length of each slice.
 * @return A generator of slices of equal length.
 * @exception {RangeError} Parameter sliceLength must be positive.
 *
 * @example
 * // Splits an array.
 * [...splitEvenly([1, 2, 3, 4, 5], 2)] // [[1, 2], [3, 4], [5]]
 *
 * // Splits a string.
 * [...splitEvenly('abcde', 2)] // ['ab', 'cd', 'e']
 *
 * // Splits a user-defined iterable.
 * [...splitEvenly({ *[Symbol.iterator]() { yield 1; yield 2; yield 3 } }, 2)] // [[1, 2], [3]]
 */
export function* splitEvenly<T>(
  iterable: Iterable<T>,
  sliceLength: number,
): Generator<T extends string ? T : T[], undefined, undefined> {
  if (sliceLength <= 0) {
    throw new RangeError('Argument sliceLength must be positive');
  }
  let slice: T[] = [];
  const isString = typeof iterable === 'string';
  for (const element of iterable) {
    slice.push(element);
    if (slice.length === sliceLength) {
      yield (isString ? slice.join('') : slice) as any;
      slice = [];
    }
  }
  if (slice.length) {
    yield (isString ? slice.join('') : slice) as any;
  }
}
