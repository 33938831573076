import React from 'react';
import {
  ClickAwayListener as MaterialClickAwayListener,
  ClickAwayListenerProps,
} from '@mui/material';
import clsx from 'clsx';

type Props = ClickAwayListenerProps & {
  className?: string;
};

const ClickAwayListener = (props: Props) => {
  const { children, className, onClickAway, ...defaultProps } = props;
  return (
    <MaterialClickAwayListener onClickAway={onClickAway} {...defaultProps}>
      <div className={clsx(className)}>{children}</div>
    </MaterialClickAwayListener>
  );
};

ClickAwayListener.displayName = 'ClickAwayListener';

export default ClickAwayListener;
