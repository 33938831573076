import { actionsGeneratorV2 } from '@docavenue/core';

const timeSlotResourcesCalendarActions = actionsGeneratorV2({
  resource: 'timeSlotResourcesCalendar',
  chunkUrlResource: 'time-slots/with-time-slot-resources',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

export default timeSlotResourcesCalendarActions;
