import React, { forwardRef } from 'react';
import {
  DialogActions as DialogActionsMaterial,
  DialogActionsProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';



const useStyles = makeStyles()((theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    '& button > *': {
      ...theme.components.popup.button,
    },
  },
}));

const DialogActions = (
  props: DialogActionsProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { classes } = useStyles();
  return <DialogActionsMaterial ref={ref} classes={classes} {...props} />;
};

export default forwardRef(DialogActions);
