import React, { forwardRef } from 'react';
import {
  ButtonBaseProps,
  IconButton as MaterialIconButton,
  IconButtonProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useDatacyName } from '@docavenue/chat';

const useStyles = makeStyles()({
  disabled: {
    filter: 'grayscale(1)',
    opacity: '0.7',
  },
});

type Props = {
  datacy?: string;
  disabled?: boolean;
  component?: string | React.ComponentType;
  variant?: string;
} & IconButtonProps &
  ButtonBaseProps;

const IconButton = forwardRef(
  (props: Props, ref: React.Ref<HTMLButtonElement>) => {
    const { datacy, disabled, ...rest } = props;
    const datacyName = useDatacyName(datacy);
    const { classes, cx } = useStyles();
    return (
      <MaterialIconButton
        className={cx(disabled && classes.disabled)}
        datacy={`${datacyName}_btn`}
        disabled={disabled}
        {...rest}
        ref={ref}
      />
    );
  },
);

IconButton.displayName = 'IconButton';

export default IconButton;
