import {
  initialStateItemsDict,
  itemsDictMapReducerGenerator,
  keysOptionsDefault,
} from '@docavenue/chat';

import { Appointment } from '../../types/pro.types';

const name = 'appointments';

export const initialState = initialStateItemsDict;

const appointments = itemsDictMapReducerGenerator<Appointment>({
  name,
  keysOptions: keysOptionsDefault,
});

export default appointments;
