import React, { ReactNode, ReactNodeArray } from 'react';

import DialogActions from './DialogActions';
import DialogContent from './DialogContent';
import DialogTitle from './DialogTitle';

type Props = {
  buttons?: ReactNode | ReactNodeArray;
  children: ReactNode | ReactNodeArray;
  footer?: ReactNode;
  severity?: 'info' | 'success' | 'warning' | 'alert';
  onClose?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  title?: ReactNode;
  titleActions?: ReactNode;
};

const CardLayout = (props: Props) => {
  const {
    children,
    onClose,
    title,
    titleActions,
    footer,
    buttons,
    severity,
  } = props;
  return (
    <>
      {title && (
        <DialogTitle
          severity={severity}
          onClose={onClose}
          titleActions={titleActions}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {buttons && <DialogActions>{buttons}</DialogActions>}
      {footer}
    </>
  );
};

export default CardLayout;
