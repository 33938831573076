import {
  crudInitialDefaultState as initialStateCrudState,
  reducersGenerator,
} from '@docavenue/core';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { CrudInitialDefaultState } from '@docavenue/core/src/types';

import { Appointment } from '../../types/pro.types';
import {
  COMPLEX_FORM_RESOURCE,
  PreviousPathState,
  SET_ITEM_COMPLEX,
  SET_MULTI,
  SET_PREVIOUS_PATH,
  SET_SELECTED_PRACTITIONER,
  SET_WAITING_LIST,
} from '../actions/complexForm';

type Item = Appointment | null;

export type ComplexFormState = CrudInitialDefaultState<Appointment> & {
  isMulti: boolean;
  item: Item;
  previousPath: PreviousPathState;
  selectedPractitioner: string | null;
  isWaitingList: boolean;
};

export const initialState: ComplexFormState = {
  ...(initialStateCrudState as CrudInitialDefaultState<Appointment>),
  isMulti: false,
  item: null,
  selectedPractitioner: null,
  previousPath: null,
  isWaitingList: false,
};

const surchargeReducer = () => {
  const obj: any = {};

  obj[SET_MULTI] = (
    state: ComplexFormState,
    action: { type: string; payload: { isMulti: boolean } },
  ) => {
    const { payload } = action;
    return { ...state, isMulti: payload.isMulti };
  };

  obj[SET_ITEM_COMPLEX] = (
    state: ComplexFormState,
    action: { type: string; payload: { item: Item } },
  ) => ({ ...state, item: action.payload });

  obj[SET_SELECTED_PRACTITIONER] = (
    state: ComplexFormState,
    action: { type: string; payload: string | null },
  ) => ({
    ...state,
    selectedPractitioner: action.payload,
  });

  obj[SET_PREVIOUS_PATH] = (
    state: ComplexFormState,
    action: { type: string; payload: PreviousPathState },
  ) => ({ ...state, previousPath: action.payload });

  obj[SET_WAITING_LIST] = (
    state: ComplexFormState,
    action: { type: string; payload: { isWaitingList: boolean } },
  ) => {
    const { payload } = action;
    return { ...state, isWaitingList: payload.isWaitingList };
  };

  return obj;
};

const complexForm = reducersGenerator({
  name: COMPLEX_FORM_RESOURCE,
  surchargeReducer,
});

export default complexForm;
