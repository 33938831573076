import { actionsGeneratorV2 } from '@docavenue/core';
import { ResourceDTO } from '@maiia/model/generated/model/api-pro/api-pro';

const resourcesActions = actionsGeneratorV2({
  resource: 'resources',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { updateManyPost: _updateManyPost } = resourcesActions;

const updateManyPost = (items: Array<ResourceDTO>, options: Object = {}) =>
  _updateManyPost(items, {
    chunkUrlResource: 'update-resources',
    ...options,
  });

export default {
  ...resourcesActions,
  updateManyPost,
};
