import React, { forwardRef } from 'react';
import { MenuItem as MaterialMenuItem, MenuItemProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useDatacyName } from '@docavenue/chat';

type Props = {
  classes?: {
    root?: string;
  };
  keepOpen?: boolean;
  button?: true;
  datacy?: string;
} & MenuItemProps;

const useStyles = makeStyles()((theme: any) => ({
  root: {
    color: theme.palette.text.body,
    minHeight: 'auto',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    '& > .MuiIconButton-root': {
      padding: theme.size.spacing[1],
    },
    padding: `${theme.size.spacing[1]} ${theme.size.spacing[2]}`,
  },
}));

const MenuItem = forwardRef((props: Props, ref: React.Ref<HTMLLIElement>) => {
  const { classes: classesProps = {}, datacy, ...rest } = props;
  const { classes, cx } = useStyles();
  const datacyName = useDatacyName(datacy);
  return (
    <MaterialMenuItem
      datacy={`${datacyName}_btn`}
      {...rest}
      ref={ref}
      classes={{
        ...classesProps,
        root: cx(classes.root, classesProps.root),
      }}
    />
  );
});

MenuItem.displayName = 'MenuItem';

export default MenuItem;
