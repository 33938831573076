export const SIDEBAR_WIDTH = 236;
export const SIDEBAR_CONNECT_WIDTH = 280;
export const SIDEBAR_TLC_WIDTH = 236;
export const HEADER_HEIGHT = 48;
export const TAB_HEIGHT = 36;
export const CHAT_HEADER_HEIGHT = 48;
export const MINICALENDAR_HEIGHT = 256;
export const PATIENTTOOLBAR_HEIGHT = 64;
export const PATIENT_CREATION_TOOLBAR_HEIGHT = 39;
export const PAGE_PADDING = 24;
export const TOP_HEIGHT = HEADER_HEIGHT + PAGE_PADDING; // goes in the theme
export const AGENDA_MENU_HEIGHT = 40;
export const PATIENTDETAILS_DRAWER_WIDTH = 740;
export const SIDEBAR_EXPAND_BUTTON_WIDTH = 20;
export const SIDEBAR_CENTER_TREE_HEIGHT = 50;
export const SIDEBAR_PRACTITIONER_TITLE = 31;
export const SIDEBAR_SEARCH_INPUT_WITH_PADDING = 70;
export const CHAT_INVITATION_DRAWER_WIDTH = 375;
