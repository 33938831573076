import { Actions } from '@docavenue/chat';
import { actionsGeneratorV2 } from '@docavenue/core';
import { TreatmentGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

export type ActionTreatment = Actions<TreatmentGetDTO> & {
  addAppointments: (
    treatmentId: string,
    appointmentIds: string[],
    sourceTreatmentId?: string,
  ) => void;
  removeAppointments: (treatmentId: string, appointmentIds: string[]) => void;
};

const treatmentsActions = actionsGeneratorV2({
  resource: 'treatments',
});

const addAppointments = (treatmentId: string, appointmentIds: string[]) =>
  treatmentsActions.updateOne(appointmentIds, {
    chunkId: treatmentId,
    chunkResource: 'add-appointments',
  });

const removeAppointments = (treatmentId: string, appointmentIds: string[]) =>
  treatmentsActions.updateOne(appointmentIds, {
    chunkId: treatmentId,
    chunkResource: 'remove-appointments',
  });

export default {
  ...treatmentsActions,
  addAppointments,
  removeAppointments,
};
