import { ChatRoutes } from '@docavenue/chat';

const Routes = Object.freeze({
  ...ChatRoutes,
  ACTIONS_HISTORY: '/actions-history',
  ACCOUNT_UNLOCK: '/account/unlock',
  ADMINISTRATION: '/administration',
  AGENDA_SETTINGS: '/agenda-settings',
  CREATE_APPOINTMENT: '/create-appointment',
  EDIT_PATIENT: '/edit-patient',
  ERROR: '/_error',
  FORGOT_PASSWORD: '/forgot-password',
  HELP: '/help',
  TRANSACTIONS: '/transactions',
  IFRAME: '/lab/iframe',
  LAB: '/lab',
  LOGIN: '/login',
  MY_ACCOUNT: '/my-account',
  PATIENTS: '/patients',
  RESET_PASSWORD: '/reset-password',
  ROOT: '/',
  SETTINGS: '/settings',
  SIGNUP: '/signup',
  SIGNUP_LGC: '/subscription', // -> /signup-cegedim
  SIGNUP_LGC_SYNC: '/subscription/synchro', // -> /signup-cegedim/synchro
  SSO: '/sso',
  SSO_AUTHORIZE: '/sso/authorize/code',
  SSO_ERROR: '/sso/error',
  STATISTICS: '/statistics',
  TIMELINE: '/timeline',
  TLC: '/tlc',
  OFFERS: '/offers',
} as const);

export default Routes;

// todo check obsolete routes:
// /practitioner-settings
// /center-settings
