import { createContext } from 'react';

type CreateAppointmentContextValue = {
  handleChangeField: (
    lastInput: string,
    lastUpdate: {
      [key: string]: string | undefined | null | boolean;
    },
  ) => void;
};

const CreateAppointmentContext = createContext<CreateAppointmentContextValue | null>(
  null,
);

export default CreateAppointmentContext;
