import React, { MouseEventHandler, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Practitioner } from '@maiia/model/generated/model/api-pro/api-pro';

import { useTranslation } from '../../../i18n';
import Avatar from '../../atoms/Avatar';
import ListItem from '../../atoms/ListItem';
import ListItemAvatar from '../../atoms/ListItemAvatar';
import ListItemText from '../../atoms/ListItemText';
import PractitionerAvatar from '../../atoms/PractitionerAvatar';
import Tooltip from '../../atoms/Tooltip';

const useStyles = makeStyles()((theme: any) => ({
  headerSearchItemView: {
    display: 'grid',
    gridTemplateAreas: `"icon content action"`,
    gridTemplateColumns: '50px 1fr 46px',
    alignItems: 'center',
    gridColumnGap: theme.spacing(2),
    padding: 0,
    height: 60,
    cursor: 'pointer',
    marginBottom: 2,

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },

    '& .avatar': {
      gridArea: 'icon',
      padding: theme.spacing(1),
      display: 'grid',
      placeItems: 'center',
      '& > div': {
        '--avatar-size': '40px',
        width: 'var(--avatar-size)',
        height: 'var(--avatar-size)',
      },
    },

    '& .content': {
      gridArea: 'content',
      display: 'grid',
      gridTemplateAreas: `'name lgc' 'number lgc'`,
      gridTemplateColumns: '1fr auto',
      gridColumnGap: theme.spacing(3),
      margin: 0,

      '& .primary': {
        ...theme.typography.body,
        minWidth: 0,
        gridArea: 'name',
        padding: 0,

        '& .firstName, & .lastName, & .birthName': {
          ...theme.ellipsis,
        },
        '& .firstName': { flexShrink: 2 },
        '& .lastName': { flexShrink: 1 },
        '& .birthName': { flexShrink: 5 },

        '& svg': {
          height: theme.spacing(4),
          width: theme.spacing(4),
        },
      },
      '& .secondary': {
        gridArea: 'number',
        ...theme.ellipsis,
        ...theme.typography.body,
        color: theme.palette.grey[500],
      },
      '& .lgc': {
        gridArea: 'lgc',
        placeSelf: 'center',
        '& span': {
          color: 'white',
          backgroundColor: theme.palette.accent.main,
          padding: '5px 12px',
          borderRadius: '50px',
        },
      },
    },

    '& .icon': {
      backgroundColor: theme.palette.background.defaultAvatarBackground,
      color: theme.palette.text.body,
      '&>.MuiAvatar-root.MuiAvatar-circular': {
        width: 28,
        height: 28,
      },
      '& > * .MuiSvgIcon-root': {
        fontSize: theme.size.font['2xl'],
      },
    },

    '& .rightAction': {
      gridArea: 'action',
      alignSelf: 'center',
    },

    '& .action': {
      gridArea: 'action',
      backgroundColor: theme.palette.background.headerIconButton,
      height: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-around',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: theme.palette.background.headerIconButton,
        cursor: 'pointer',
      },
      '& >.MuiTypography-root': {
        lineHeight: 1,
        marginTop: theme.spacing(1),
        '& > .MuiSvgIcon-root': {
          color: theme.palette.accent.main,
          fontSize: theme.size.font['2xl'],
          position: 'inherit',
        },
      },
    },
  },
}));

const getInitials = (text: string) => {
  const initials = text.match(/\b\w/g) || [];

  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

type Props = {
  avatar?: string;
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  hideAvatar?: boolean;
  selected?: boolean;
  rightAction?: MouseEventHandler;
  LeftIcon?: any;
  RightIcon?: any;
  rightTooltip?: string;
  LgcComponent?: ReactNode;
  user?: Practitioner;
} & React.ComponentProps<typeof ListItem>;

const HeaderSearchItemView = ({
  avatar = '',
  primary,
  secondary,
  hideAvatar = false,
  selected = false,
  rightAction,
  LeftIcon,
  RightIcon,
  rightTooltip = '',
  LgcComponent,
  user,
  ...rest
}: Props) => {
  const { t } = useTranslation('common');
  const { classes, cx } = useStyles();

  return (
    <ListItem
      className={cx(classes.headerSearchItemView)}
      selected={selected}
      divider
      component="div"
      {...rest}
    >
      {!hideAvatar && (
        <ListItemAvatar classes={{ root: 'avatar' }}>
          {avatar || user ? (
            <PractitionerAvatar
              alt={typeof primary === 'string' ? primary : 'avatar'}
              src={avatar}
              user={user}
            />
          ) : (
            <Avatar alt={typeof primary === 'string' ? primary : 'avatar'}>
              {typeof primary === 'string' ? getInitials(primary) : null}
            </Avatar>
          )}
        </ListItemAvatar>
      )}
      {LeftIcon && (
        <ListItemAvatar classes={{ root: 'avatar' }}>
          <Avatar classes={{ colorDefault: 'icon' }}>
            <LeftIcon />
          </Avatar>
        </ListItemAvatar>
      )}

      <div className="content">
        <span className="primary">{primary}</span>
        <span className="secondary">{secondary}</span>
        <span className="lgc">{LgcComponent || null}</span>
      </div>

      {!!rightAction && (
        <div className="rightAction">
          <Tooltip title={t(rightTooltip)} placement="bottom-start">
            <ListItemText
              classes={{ root: 'action', secondary: 'secondary' }}
              onClick={rightAction}
              primary={RightIcon ? <RightIcon /> : null}
            />
          </Tooltip>
        </div>
      )}
    </ListItem>
  );
};

HeaderSearchItemView.displayName = 'HeaderSearchItemView';

export default HeaderSearchItemView;
