import { actionsGeneratorV2 } from '@docavenue/core';
import {
  VideoProMeetingDTO,
  VideoProMeetingMessageActionDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

const videoProMeetingActions = actionsGeneratorV2({
  resource: 'videoProMeeting',
  chunkUrlResource: 'video/pro-meeting',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { create } = videoProMeetingActions;

const _create = (
  data: Partial<VideoProMeetingDTO>,
  options?: {
    isListDst: boolean;
  },
) => {
  return create(data, options);
};

const createAction = (
  videoProMeetingId: string,
  data: VideoProMeetingMessageActionDTO,
  options?: { isListDst: boolean },
) =>
  create(data, {
    chunkId: videoProMeetingId,
    chunkResource: 'actions',
    ...options,
  });

const actions = {
  ...videoProMeetingActions,
  createAction,
  create: _create,
};

export type VideoProMeetingActions = typeof actions;

export default actions;
