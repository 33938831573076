import React from 'react';
import { Alert, AlertDescription, AlertTitle } from '@kiiwi/ui';

import { Trans, useTranslation } from '../../../i18n';

import useMediaDeviceContext from '@/src/hooks/useMediaDeviceContext';
import { MediaPermissionsErrorType } from '@/src/utils/mediaDevicesUtils';

type Props = {
  titleTransKey?: string;
  contentTransKey?: string;
  isTLCProToPro?: boolean;
  footer?: React.ReactNode;
};

export const CameraOrMicAlertContent = ({
  footer,
  titleTransKey = 'device_camera_or_micro_unavailable_title',
  contentTransKey = 'device_camera_or_micro_unavailable_content',
  isTLCProToPro = false,
}: Props) => {
  const { t } = useTranslation('common');
  return (
    <Alert variant="error">
      <AlertTitle>{titleTransKey && t(titleTransKey)}</AlertTitle>
      <AlertDescription>
        <Trans
          i18nKey={`common:${contentTransKey}`}
          components={[
            <br key="br" />,
            <a
              key={contentTransKey}
              target="_blank"
              className="underline"
              href={t(
                isTLCProToPro
                  ? 'device_camera_or_micro_unavailable_content_link_pro'
                  : 'device_camera_or_micro_unavailable_content_link',
              )}
              rel="noreferrer"
            />,
          ]}
        />
      </AlertDescription>
      {footer}
    </Alert>
  );
};

const CameraOrMicAlert = ({
  footer,
  isTLCProToPro = false,
}: Pick<Props, 'isTLCProToPro' | 'footer'>) => {
  const {
    audio: microphonePermission,
    video: cameraPermission,
    hasAccessToAudio,
    hasAccessToVideo,
  } = useMediaDeviceContext();

  const isMicrophoneDeniedOrUnsupported = !hasAccessToAudio;
  const isCameraDeniedOrUnsupported = !hasAccessToVideo;

  if (!(isCameraDeniedOrUnsupported || isMicrophoneDeniedOrUnsupported))
    return null;

  const { title, content } = (() => {
    if (
      cameraPermission.error?.type ===
        MediaPermissionsErrorType.CouldNotStartVideoSource ||
      microphonePermission.error?.type ===
        MediaPermissionsErrorType.CouldNotStartVideoSource
    ) {
      return {
        title: 'device_camera_or_micro_busy_title',
        content: 'device_camera_or_micro_busy_content',
      };
    }

    if (
      cameraPermission.error?.type ===
        MediaPermissionsErrorType.SystemPermissionDenied ||
      microphonePermission.error?.type ===
        MediaPermissionsErrorType.SystemPermissionDenied
    ) {
      return {
        title: 'device_camera_and_micro_system_denied_title',
        content: 'device_camera_and_micro_system_denied_content',
      };
    }

    if (isCameraDeniedOrUnsupported && isMicrophoneDeniedOrUnsupported) {
      return {
        title: 'device_camera_and_micro_unavailable_title',
        content: 'device_camera_and_micro_unavailable_content',
      };
    }

    if (isCameraDeniedOrUnsupported) {
      return {
        title: 'device_camera_unavailable_title',
        content: 'device_camera_unavailable_content',
      };
    }

    return {
      title: 'device_microphone_unavailable_title',
      content: 'device_microphone_unavailable_content',
    };
  })();

  return (
    <CameraOrMicAlertContent
      footer={footer}
      titleTransKey={title}
      contentTransKey={content}
      isTLCProToPro={isTLCProToPro}
    />
  );
};

// Prevent unnecessary rerender from the caller, for instance VideoMeetingView.tsx updates periodically
export default React.memo(CameraOrMicAlert);
