import React from 'react';
import { useRouter } from 'next/router';
import Routes from 'packages/pro-frontend/src/constants/routes';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import { PatientInstructionNotificationCenter } from '../../../types/pro.types';
import Typography from '../../atoms/Typography';
import NotificationWrapper from './NotificationWrapper';

import { GAActions, GAActionStatus, GALabels } from '@/src/constants/tracking';

type NotificationAppointmentViewProps = {
  notification: PatientInstructionNotificationCenter;
  closeModal: () => void;
  triggerGAEvent: Function;
};

const useStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  notificationAppointmentView: {
    backgroundColor: theme.palette[color][100],
    color: theme.palette[color][500],
    transition: 'all 0.2s ease',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette[color][150],
      borderTop: `1px solid ${theme.palette[color][300]}`,
      borderBottom: `1px solid ${theme.palette[color][300]}`,
    },
  },
  link: {
    fontWeight: 'bold',
  },
}));

const NotificationPatientInstructionView: React.FunctionComponent<NotificationAppointmentViewProps> = props => {
  const { notification, closeModal, triggerGAEvent } = props;
  const { displayData } = notification;

  const { t } = useTranslation('common');
  const router = useRouter();
  const { query } = router;

  const isDeleted = notification.event === 'DELETED';
  const isUpdated = notification.event === 'MODIFIED';
  const isDefaultPatientInstruction = !notification.displayData
    ?.consultationReasonName;

  const getNotificationColor = () => {
    if (isDeleted) {
      return 'red';
    }
    if (isUpdated) {
      return 'orange';
    }
    return 'green';
  };
  const { classes, cx } = useStyles({ color: getNotificationColor() });

  function getNotificationTitleKey() {
    if (isDeleted) {
      return 'notification__patient_instruction__deleted_title';
    }

    if (isUpdated) {
      return 'notification__patient_instruction__updated_title';
    }

    return 'notification__patient_instruction__created_title';
  }

  function getNotificationBodyKey() {
    if (isDeleted) {
      return isDefaultPatientInstruction
        ? 'notification__patient_instruction_default__deleted_body'
        : 'notification__patient_instruction__deleted_body';
    }

    if (isUpdated) {
      return isDefaultPatientInstruction
        ? 'notification__patient_instruction_default__updated_body'
        : 'notification__patient_instruction__updated_body';
    }

    return isDefaultPatientInstruction
      ? 'notification__patient_instruction_default__created_body'
      : 'notification__patient_instruction__created_body';
  }

  const notificationTitle = `${t(getNotificationTitleKey())}`;

  const redirectToInstruction = () => {
    triggerGAEvent(
      GAActions.ClickNotification,
      GAActionStatus.SUCCESS,
      GALabels.AppointmentNotification,
    );
    router.push({
      pathname: Routes.SETTINGS,
      query: {
        ...query,
        centerId: displayData.centerId,
        practitionerId: displayData.practitionerId,
        tab: 'instructions',
      },
    });
    closeModal();
  };

  return (
    <NotificationWrapper
      notification={notification}
      onClick={() => (isDeleted ? null : redirectToInstruction())}
      className={cx(
        classes.notificationAppointmentView,
        'notificationAppointmentViewRoot',
      )}
    >
      <Typography variant="subtitle" className="notificationTitle">
        {notificationTitle}
      </Typography>

      <Typography variant="bodySmall">
        {t(getNotificationBodyKey(), {
          name: notification.createdBy?.label,
          consultationReason: displayData.consultationReasonName,
        })}
      </Typography>
      {!isDeleted && (
        <Typography variant="bodySmall" className={classes.link}>
          {t('notification__view_instruction')}
        </Typography>
      )}
    </NotificationWrapper>
  );
};

export default NotificationPatientInstructionView;
