import React from 'react';

import { getActiveWebsocket, onWebsocketConnected } from '@docavenue/core';

const WebsocketContext = React.createContext<any>(null); // no available type definitions for this version of socket.io-client (2.4.0) - https://www.npmjs.com/package/@types/socket.io-client?activeTab=versions

export const WebSocketProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [websocket, setActiveWebsocket] = React.useState(() =>
    getActiveWebsocket(),
  );

  /**
   * Retrieves the current WebSocket connection used by redux-saga
   *
   * In the future, you end up creating a new ws connection for the react-query part,
   * it is already isolated and available.
   */
  React.useEffect(() => {
    onWebsocketConnected(() => {
      setActiveWebsocket(getActiveWebsocket());
    });
  }, []);

  return (
    <WebsocketContext.Provider value={websocket}>
      {children}
    </WebsocketContext.Provider>
  );
};

export function useWebsocket() {
  const websocket = React.useContext<any>(WebsocketContext);
  return websocket;
}
