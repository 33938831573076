import { NextPageContext } from 'next';
import nextCookie from 'next-cookies';

import { loaderMaiiaActions } from '../actions';
import Routes from '../constants/routes';
import { isomorphicRedirect } from '../hoc/securedPageFunctions';
import { MaiiaNextPageContext } from '../types/MaiiaNextContext';
import { buildUrlString, isPathWhiteListed } from './RoutesUtils';

export interface AuthProperties {
  token?: string | undefined;
  refresh?: string | undefined;
  tokenHash?: string | undefined;
  data?: NextPageContext['query']['data'];
}

export function Auth(ctx: MaiiaNextPageContext): AuthProperties {
  const { tokenPro, refreshPro } = nextCookie(ctx);
  const { token = tokenPro, refresh = refreshPro } =
    ctx.store.getState()?.authentication.item || {};

  const {
    pathname,
    query: { tsToken, tokenHash, hash, data, ...restQuery },
  } = ctx;

  if (tokenHash || hash || data) {
    ctx.store.dispatch(loaderMaiiaActions.setOpen(true));
  }

  const hasQueryParams = Object.entries(restQuery).length > 0;

  // only add encoded `next` param if necessary
  const next =
    pathname !== '/' || hasQueryParams
      ? buildUrlString(
          pathname,
          restQuery as Record<string, string | undefined>,
        )
      : undefined;

  const LoginUrl = buildUrlString(Routes.LOGIN, {
    tokenHash,
    tsToken,
    data: data?.toString(), // can be string, string[], or undefined.
    next,
  });

  if ((!token || tokenHash || data) && !isPathWhiteListed(pathname)) {
    isomorphicRedirect(LoginUrl, ctx);
    return {};
  }

  return { token, refresh, tokenHash, data };
}
