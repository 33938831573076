import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { MaiiaTheme } from '../maiiaTheme';

interface MuiMenuOverrideProps {
  defaultProps?: ComponentsProps['MuiMenu'];
  styleOverrides?: ComponentsOverrides<MaiiaTheme>['MuiMenu'];
  variants?: ComponentsVariants['MuiMenu'];
}

const MuiMenuOverride: MuiMenuOverrideProps = {
  defaultProps: {},
  styleOverrides: {
    paper: () => {
      return {
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        border: `1px solid ${grey[300]}`,
      };
    },
  },
};

export default MuiMenuOverride;
