import React from 'react';
import { Popper as MaterialPopper, PopperProps } from '@mui/material';

type Props = {
  children: React.ReactNode;
  Placement?:
    | 'left'
    | 'right'
    | 'top'
    | 'bottom'
    | 'right-start'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'top-end'
    | 'top-start'
    | undefined;
  open: boolean;
} & PopperProps;

const Popper = React.forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const { placement = 'right-start', children, ...defaultProps } = props;
    return (
      <MaterialPopper placement={placement} {...defaultProps} ref={ref}>
        {children}
      </MaterialPopper>
    );
  },
);

Popper.displayName = 'Popper';

export default Popper;
