/* eslint-disable import/no-cycle */
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import moment, { Moment } from 'moment';

import { AGENDA, WEEK } from '../actions/calendar';
import { getAgendaRange } from '../utils';
import { useCalendarContextValue } from './contexts';
import { useCalendarView } from './query';
import {
  useGetAgendasWeekDaysSettings,
  useGetTimeSlotResourcesWeekDaysSettings,
} from './selectors';

export const useSetDate = () => {
  const router = useRouter();
  return useCallback(
    (date: Moment | Date | string) => {
      const newDate = moment(date).toISOString();
      const newRoute = {
        pathname: router.pathname,
        query: {
          ...router.query,
          date: newDate,
        },
      };
      router.push(newRoute, newRoute, { shallow: true });
    },
    [router?.pathname, router?.query],
  );
};

export const useDate = () => {
  const router = useRouter();
  const date = useMemo(() => router.query.date || moment().toISOString(), [
    router.query.date,
  ]) as string;
  return date;
};

export const useKeyFrom = () => {
  const date = useDate();
  const keyFrom = moment(date)
    .startOf('isoWeek')
    .toISOString();
  return keyFrom;
};

export const useRangeToKeyFrom = (range: Date[]): string[] => {
  const view = useCalendarView();
  const currentDate = useDate();
  const newRange = view === WEEK ? range : [currentDate];
  const keyFroms = useMemo(
    () => [
      ...new Set(
        newRange.map(date =>
          moment(date)
            .startOf('isoWeek')
            .toISOString(),
        ),
      ),
    ],
    [range, currentDate, view],
  );
  return keyFroms;
};

export const useDateByCalendar = (nbDisplayedCalendars: number = 1) => {
  const date = useDate();
  const view = useCalendarView();
  const mondayStart = moment(date)
    .startOf('isoWeek')
    .toDate();
  const dateStart =
    view === WEEK && nbDisplayedCalendars <= 1
      ? mondayStart
      : moment(date)
          .startOf('day')
          .toDate();
  return dateStart;
};

export const useNavigationDate = () => {
  const view = useCalendarView();
  const getAgendasWeekDaysSettings = useGetAgendasWeekDaysSettings();
  const getTimeSlotResourcesWeekDaysSettings = useGetTimeSlotResourcesWeekDaysSettings();
  const {
    nbDisplayedCalendars,
    nbDisplayedDays,
    agendas,
    range,
    timeSlotResourcesMode,
  } = useCalendarContextValue();
  const router = useRouter();
  const date = router?.query?.date || moment().toISOString();
  const navigationDate = useCallback(
    (incr: number) => {
      const modifier = incr > 0 ? 'add' : 'subtract';
      const period = `${
        view === AGENDA || nbDisplayedCalendars > 1 ? 'day' : view
      }s` as 'weeks' | 'days';
      const agendasWeekDaysSettings = timeSlotResourcesMode
        ? getTimeSlotResourcesWeekDaysSettings
        : getAgendasWeekDaysSettings(agendas);
      const isMulti = nbDisplayedCalendars > 1 && view === WEEK;
      const newDate = isMulti
        ? moment(range[incr > 0 ? range.length - 1 : 0])
            [modifier](1, 'day')
            .toISOString()
        : moment(date)
            [modifier](1, period)
            .toISOString();
      const newQueryDate = moment(
        isMulti
          ? getAgendaRange(
              newDate,
              agendasWeekDaysSettings,
              nbDisplayedDays,
              isMulti && incr < 0,
            )[0]
          : newDate,
      );
      const newRoute = {
        pathname: router.pathname,
        query: {
          ...router.query,
          date: newQueryDate.toISOString(),
        },
      };
      router.push(newRoute, newRoute, { shallow: true });
    },
    [view, date, router?.pathname, router?.query],
  );
  return navigationDate;
};
