import {
  crudInitialDefaultState as initialStateCrudState,
  reducersGenerator,
  SET_ITEM,
} from '@docavenue/core';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { CrudInitialDefaultState } from '@docavenue/core/src/types';

import { Document } from '../../types/pro.types';
import {
  SET_LOADING_GENERATION,
  SET_LOADING_GENERATION_ERROR,
} from '../actions/exportStatisticsDocument';

export type ExportStatisticsDocumentState = {
  isLoadingGeneration: boolean;
  isLoadingGenerationError: boolean;
} & CrudInitialDefaultState<Document>;

export const initialState: ExportStatisticsDocumentState = {
  ...(initialStateCrudState as CrudInitialDefaultState<Document>),
  isLoadingGeneration: false,
  isLoadingGenerationError: false,
};

const name = 'exportStatisticsDocument';

const extendReducer: any = {};

[SET_ITEM, `${SET_ITEM}/${name}`].forEach((type: string) => {
  extendReducer[type] = (state: any) => ({
    ...state,
    isLoadingGeneration: false,
    isLoadingGenerationError: false,
  });
});

const surchargeReducer = () => {
  const obj: any = {};

  obj[SET_LOADING_GENERATION] = (state: ExportStatisticsDocumentState) => ({
    ...state,
    isLoadingGeneration: true,
    isLoadingGenerationError: false,
  });
  obj[SET_LOADING_GENERATION_ERROR] = (
    state: ExportStatisticsDocumentState,
  ) => ({
    ...state,
    isLoadingGeneration: false,
    isLoadingGenerationError: true,
  });

  return obj;
};

const exportStatisticsDocument = reducersGenerator({
  name: 'exportStatisticsDocument',
  surchargeReducer,
  extendReducer,
});

export default exportStatisticsDocument;
