import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import range from 'lodash/range';
import { createSelector } from 'reselect';
import { useDebouncedCallback } from 'use-debounce';

import { useIsFreemium } from '@docavenue/chat';
import { serialActions } from '@docavenue/core';
import {
  Center,
  Patient,
  Practitioner,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { useTranslation } from '../../../i18n';
import {
  complexFormActions,
  patientsActions,
  searchActions,
} from '../../../src/actions';
import Routes from '../../../src/constants/routes';
import { useOpenComplexForm } from '../../../src/hooks/actions';
import { useCenterId, useIsRegulator } from '../../../src/hooks/selectors';
import { useAnalyticsTracker } from '../../../src/hooks/utils';
import ClickAwayListener from '../../atoms/ClickAwayListener';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import {
  formatAutoCompleteInput,
  patientInputToObject,
} from '../../utils/tools';
import Autocomplete, { AUTOCOMPLETE_DIVIDER } from '../Autocomplete';
import { DIVIDER } from '../Autocomplete/Autocomplete';
import HeaderSearchInput from './HeaderSearchInput';
import HeaderSearchItem from './HeaderSearchItem';
import HeaderSearchListComponent from './HeaderSearchListComponent';

import {
  GAActions,
  GAActionStatus,
  GAActionsType,
  GACategories,
  GALabels,
  GALabelsType,
} from '@/src/constants/tracking';

const useStyles = makeStyles()({
  autCompleteListRoot: {
    width: '100%',
  },
});

type SearchInput = (Center | Patient | Practitioner) & {
  centers?: Center[];
};

type SearchOutput = SearchInput & {
  centerId?: string;
  center?: Center;
};

const getPractitionerWithCenter = (users: SearchInput[]): SearchOutput[] => {
  const items = [] as SearchOutput[];

  for (const user of users) {
    if (user.objectType === 'practitioner') {
      const centerId: string = get(user, 'agendaSettings.centerId');
      const center = user?.centers?.find((c: Center) => c.id === centerId);
      items.push({
        ...user,
        centerId,
        center,
      });
    } else items.push(user);
  }
  return items;
};

const getSearchSlotsFromUsers = users => {
  const MAX_PRAT_HITS = 4;
  const MAX_CENTER_HITS = 2;
  const usersWeights = {
    patient: 1,
    agendaSettings: 2,
    center: 3,
  };

  let centerHits = 0;
  let pratHits = 0;

  const slots = range(12).map(() => {
    if (centerHits < MAX_CENTER_HITS) {
      const centerIdx = users.findIndex(u => u.objectType === 'center');
      if (centerIdx !== -1) {
        const center = users.splice(centerIdx, 1)[0];
        if (center) {
          center.key = center.id;
          centerHits++;
        }
        return center;
      }
    }
    if (pratHits < MAX_PRAT_HITS) {
      const pratIdx = users.findIndex(u => u.objectType === 'agendaSettings');
      if (pratIdx !== -1) {
        const prat = users.splice(pratIdx, 1)[0];
        if (prat && prat.center) {
          prat.key = `${prat.id}_${prat.center.id}`;
          pratHits++;
          return prat;
        }
      }
    }
    const patientIdx = users.findIndex(u => u.objectType === 'patient');
    if (patientIdx !== -1) {
      const patient = users.splice(patientIdx, 1)[0];
      patient.key = patient.id;
      return patient;
    }
    return null;
  });

  return orderBy(
    slots.filter(slot => !!slot),
    [item => usersWeights[item.objectType]],
    ['asc'],
  );
};

const addDividers = (items: SearchInput[]) => {
  const patients: Patient[] = [];
  const practitioners: Practitioner[] = [];
  const centers: Center[] = [];
  for (let i = 0; i < items.length; i++) {
    if (items[i].objectType === 'patient') patients.push(items[i] as Patient);
    else if (items[i].objectType === 'agendaSettings')
      practitioners.push(items[i] as Practitioner);
    else if (items[i].objectType === 'center') centers.push(items[i] as Center);
  }
  const itemsWithDividers = (patients as (
    | Center
    | Practitioner
    | Patient
    | DIVIDER<any>
  )[])
    .concat(AUTOCOMPLETE_DIVIDER)
    .concat(practitioners)
    .concat(AUTOCOMPLETE_DIVIDER)
    .concat(centers);
  return itemsWithDividers;
};

const getSearchItems = createSelector(
  store => store.search.items as SearchInput[],
  results =>
    addDividers(getSearchSlotsFromUsers(getPractitionerWithCenter(results))),
);

const HeaderSearchAutocomplete = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const looseFocus = useRef(null);
  const { t } = useTranslation('common');
  const items = useSelector(getSearchItems);
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const { classes } = useStyles();
  const patientCenterId = useCenterId();
  const [isOpen, setOpen] = useState(false);
  const router = useRouter();
  const triggerGAEvent = useAnalyticsTracker(GACategories.Header);
  const openComplexForm = useOpenComplexForm();
  const centerId = useCenterId();
  const isFreemium = useIsFreemium();
  const isRegulatorUser = useIsRegulator();

  const handleGAEvent = (
    action: GAActionsType,
    status: GAActionStatus,
    label: GALabelsType,
  ) => {
    triggerGAEvent(action, status, label);
  };
  // @ts-ignore
  const redirectToComplexAppointment = (
    center,
    practitioner,
    patient,
    gaLabel: GALabelsType,
  ) => {
    const currentEvent = {
      centerId: center?.id,
      center,
      practitionerId: practitioner?.id,
      practitioner,
      patient,
    };

    const _serialActions = serialActions.serial([
      () =>
        patient?.id
          ? patientsActions.getOne(patient?.id, {
              params: { centerId },
            })
          : patientsActions.setItem(null),
      () => complexFormActions.setItem(currentEvent),
      () => openComplexForm(),
    ]);

    _serialActions.onSuccess = () =>
      handleGAEvent(GAActions.SelectSearch, GAActionStatus.SUCCESS, gaLabel);
    _serialActions.onError = () =>
      handleGAEvent(GAActions.SelectSearch, GAActionStatus.ERROR, gaLabel);

    dispatch(_serialActions);
  };

  const blurSearchInput = () => {
    searchRef.current?.blur();
    setOpen(false);
  };

  const redirectToAgenda = (
    centerId_: string,
    practitionerId: string,
    gaLabel: GALabelsType,
  ) => {
    handleGAEvent(
      GAActions.ClickSearchActionIcon,
      GAActionStatus.SUCCESS,
      gaLabel,
    );
    blurSearchInput();
    dispatch(patientsActions.setItem(null));
    router.push({
      pathname: Routes.ROOT,
      query: {
        centerId: centerId_,
        practitionerId,
      },
    });
  };

  const redirectToEditPatient = (patientId: string, gaLabel: GALabelsType) => {
    handleGAEvent(
      GAActions.ClickSearchActionIcon,
      GAActionStatus.SUCCESS,
      gaLabel,
    );
    blurSearchInput();
    router.push({
      pathname: Routes.EDIT_PATIENT,
      query: {
        ...router.query,
        patientId,
      },
    });
  };

  const onCreateNewPatient = () => {
    dispatch(patientsActions.setItem(patientInputToObject(searchTerm)));
    setSearchTerm('');
    router.push({
      pathname: Routes.EDIT_PATIENT,
      query: pick(router.query, ['centerId', 'practitionerId']),
    });
  };

  const setSearchTermDebounced = useDebouncedCallback((term: string) => {
    dispatch(
      searchActions.getList({
        term: formatAutoCompleteInput(term),
        limit: 12,
        patientCenterId,
      }),
    );
  }, 300);

  useUpdateEffect(() => setSearchTermDebounced(searchTerm), [searchTerm]);

  return (
    <>
      <div ref={looseFocus} />
      <ClickAwayListener onClickAway={blurSearchInput}>
        <Autocomplete
          isMenuOpen={isOpen}
          items={items}
          inputRef={searchRef}
          ItemComponent={HeaderSearchItem}
          itemProps={{
            searchTerms: searchTerm
              ? [searchTerm].concat(searchTerm.split(' '))
              : '',
            redirectToEditPatient,
            redirectToAgenda,
          }}
          InputComponent={HeaderSearchInput}
          inputProps={{
            placeholder: t('headersearchautocomplete_placeholder'),
            setOpen,
          }}
          endAdornment={
            <FontAwesomeIcon
              name="magnifying-glass:regular"
              className="absolute left-2 h-3 w-3"
            />
          }
          listClassName={classes.autCompleteListRoot}
          ListComponent={HeaderSearchListComponent}
          listProps={{ blurSearchInput, onCreateNewPatient }}
          optionKey="key"
          // @ts-ignore
          onChange={(selected: Practitioner | Patient | Center | null) => {
            blurSearchInput();
            switch (selected?.objectType) {
              case 'patient':
                if (isFreemium || isRegulatorUser) {
                  redirectToEditPatient(
                    get(selected, 'id'),
                    GALabels.GoPatientFile,
                  );
                } else {
                  redirectToComplexAppointment(
                    get(selected, 'center'),
                    get(selected, 'practitioner'),
                    selected,
                    GALabels.PatientAppointment,
                  );
                }
                break;
              case 'agendaSettings':
                redirectToComplexAppointment(
                  get(selected, 'center'),
                  get(selected, 'practitioner'),
                  null,
                  GALabels.PractitionerAppointment,
                );
                break;
              case 'center':
                redirectToComplexAppointment(
                  selected,
                  null,
                  null,
                  GALabels.CenterAppointment,
                );
                break;
              default:
                break;
            }
          }}
          formatInputForComparaison={() => ''}
          inputValue={searchTerm}
          setInputValue={term => {
            setSearchTerm(term || '');
          }}
        />
      </ClickAwayListener>
    </>
  );
};

export default HeaderSearchAutocomplete;
