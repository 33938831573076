import React, { Component, ErrorInfo, ReactNode } from 'react';

import Error from '../components/organisms/Error';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    /* eslint-disable-next-line no-console */
    console.log('getDerivedStateFromError:', error);
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    /* eslint-disable-next-line no-console */
    console.log('componentDidCatch:', error, errorInfo);
    if (super.componentDidCatch)
      return super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return <Error />;
    }

    return children;
  }
}

export default ErrorBoundary;
