import { actionsGeneratorV2 } from '@docavenue/core';

const associationCodeActions = actionsGeneratorV2({
  resource: 'associationCode',
  chunkUrlResource: 'center/association/renew-code',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

export default associationCodeActions;
