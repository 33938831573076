import React, { forwardRef, ReactNode } from 'react';
import { DialogTitle as DialogTitleMaterial } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import IconButton from '../../atoms/IconButton';
import Typography from '../../atoms/Typography';

import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

type Severity = 'info' | 'success' | 'warning' | 'alert';

const useStyles = makeStyles<SProps>()((theme: any) => ({
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.main.main,
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    '& button.active': {
      color: theme.palette.secondary.contrastText,
    },
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),

    '& .title-actions': {
      display: 'flex',
      '& svg': { color: theme.palette.secondary.contrastText },
    },
  },
  info: {},
  success: {},
  warning: {},
  alert: { backgroundColor: theme.palette.destructive.main },

  title: {
    color: theme.palette.common.white,
    fontFamily: theme.typography.title.fontFamily,
    fontWeight: theme.typography.title.fontWeight,
    fontSize: theme.size.font['2xl'],
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
  },

  closeButton: { color: theme.palette.common.white },
}));

type SProps = {
  severity?: Severity;
};

type Props = {
  children: ReactNode | string;
  onClose?: React.MouseEventHandler;
  titleActions: ReactNode;
  severity?: Severity;
};

const DialogTitle = forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const { children, onClose, titleActions, severity = 'info' } = props;
    const { classes, cx } = useStyles({ severity });

    const titleClasses = cx(classes.dialogTitle, severity && classes[severity]);

    return (
      <DialogTitleMaterial ref={ref} className={titleClasses}>
        <div className={classes.contentWrapper}>
          {typeof children === 'string' ? (
            <Typography className={classes.title} variant="h6">
              {children}
            </Typography>
          ) : (
            children
          )}
        </div>
        <div className="title-actions">
          {titleActions}
          {onClose ? (
            <IconButton
              datacy="icon_close_dialog"
              aria-label="Close"
              className={classes.closeButton}
              onClick={onClose}
              size="large"
            >
              <FontAwesomeIcon name="xmark:regular" className="h-5 w-5" />
            </IconButton>
          ) : null}
        </div>
      </DialogTitleMaterial>
    );
  },
);

export default DialogTitle;
