import React from 'react';

import SvgIcon from '../atoms/SvgIcon';
import { IconProps } from './icons';

type Props = IconProps;

const BrandNameConnect = (props: Props) => (
  <SvgIcon className="brandNameConnect" viewBox="0 0 98 48" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.9034 9.97833C97.1077 9.97875 97.3034 10.0601 97.4479 10.2045C97.5923 10.3489 97.6736 10.5447 97.674 10.7489V17.4086C97.6736 17.6128 97.5923 17.8086 97.4479 17.953C97.3034 18.0974 97.1077 18.1788 96.9034 18.1792H90.2438C90.0394 18.1792 89.8434 18.098 89.6989 17.9535C89.5544 17.809 89.4732 17.6129 89.4732 17.4086V10.7489C89.4732 10.5446 89.5544 10.3486 89.6989 10.204C89.8434 10.0595 90.0394 9.97833 90.2438 9.97833H96.9034ZM86.9187 0C87.1222 0.000838806 87.317 0.0822313 87.4605 0.226361C87.604 0.370491 87.6846 0.565617 87.6846 0.769027V7.42865C87.6846 7.63261 87.6036 7.82821 87.4594 7.97243C87.3151 8.11665 87.1195 8.19767 86.9156 8.19767H80.256C80.0519 8.19767 79.8561 8.1167 79.7116 7.97253C79.5671 7.82835 79.4858 7.63275 79.4854 7.42865V0.769027C79.4858 0.564923 79.5671 0.369322 79.7116 0.225146C79.8561 0.0809712 80.0519 -4.32065e-07 80.256 0L86.9187 0Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.2427 6.54606e-06H96.9023C97.0037 -0.000411779 97.1041 0.0192236 97.1979 0.0577828C97.2917 0.096342 97.3769 0.153064 97.4487 0.224684C97.5204 0.296305 97.5773 0.381411 97.6161 0.475104C97.6548 0.568797 97.6747 0.669229 97.6745 0.77062V7.43024C97.6745 7.53136 97.6545 7.6315 97.6158 7.7249C97.5771 7.81831 97.5203 7.90316 97.4487 7.97459C97.3771 8.04602 97.2921 8.10263 97.1987 8.14119C97.1052 8.17974 97.005 8.19948 96.9039 8.19927H90.2442C90.1431 8.19948 90.0429 8.17974 89.9495 8.14119C89.856 8.10263 89.771 8.04602 89.6994 7.97459C89.6279 7.90316 89.5711 7.81831 89.5323 7.7249C89.4936 7.6315 89.4736 7.53136 89.4736 7.43024V0.77062C89.4734 0.669496 89.4932 0.569325 89.5317 0.475839C89.5703 0.382353 89.6269 0.297389 89.6983 0.22581C89.7697 0.154231 89.8546 0.097443 89.948 0.0586966C90.0414 0.0199502 90.1415 6.33111e-06 90.2427 6.54606e-06Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5689 12.458C41.025 12.458 43.5144 13.606 44.8892 15.7292V12.5801H48.2856V31.6076H44.8892V28.4839C44.1695 29.4889 43.2209 30.3081 42.1219 30.8739C41.0229 31.4397 39.805 31.7357 38.5689 31.7376C33.7454 31.7376 28.8538 28.17 28.8538 22.097C28.8585 15.8687 33.7454 12.458 38.5689 12.458ZM75.3775 12.458C77.8321 12.458 80.3215 13.606 81.6978 15.7292V12.5801H85.0927V31.6076H81.6978V28.4839C80.9779 29.4886 80.0292 30.3076 78.9303 30.8734C77.8313 31.4391 76.6136 31.7354 75.3775 31.7376C70.5541 31.7376 65.6608 28.17 65.6608 22.097C65.6608 15.8687 70.5604 12.458 75.3775 12.458ZM55.5129 12.5801V31.6076H52.1164V12.5801H55.5129ZM63.0683 12.5801V31.6076H59.6735V12.5801H63.0683ZM3.6471 6.50085L12.8929 20.8206H13.3686L22.5858 6.50085H26.1741V31.6155H22.7776V12.0918H22.7079L13.9679 25.3H12.2777L3.52025 12.0918H3.46793V31.6155H0.0556641V6.50085H3.6471ZM38.5689 15.5024C35.3485 15.5024 32.2486 18.1124 32.2486 22.097C32.2486 26.0817 35.3485 28.6996 38.5689 28.6996C41.7893 28.6996 44.8733 26.0817 44.8733 22.097C44.8733 18.1124 41.7734 15.5024 38.5689 15.5024ZM75.3775 15.5024C72.1556 15.5024 69.0557 18.1124 69.0557 22.097C69.0557 26.0817 72.1556 28.6996 75.3775 28.6996C78.5995 28.6996 81.6804 26.0896 81.6804 22.105C81.6804 18.1203 78.5805 15.5024 75.3775 15.5024ZM53.8226 5.1499C54.9547 5.1499 55.8839 6.02041 55.8839 7.06375C55.8839 8.10709 54.9611 8.9776 53.8226 8.9776C52.6841 8.9776 51.7692 8.10709 51.7692 7.06375C51.7692 6.02041 52.692 5.1499 53.8226 5.1499ZM61.3797 5.1499C62.5118 5.1499 63.441 6.02041 63.441 7.06375C63.441 8.10709 62.5181 8.9776 61.3797 8.9776C60.2412 8.9776 59.3183 8.10709 59.3183 7.06375C59.3183 6.02041 60.2539 5.1499 61.3797 5.1499Z"
      fill="white"
    />
    <path
      d="M40.5166 41.5345C40.5166 43.4009 41.9365 44.7047 43.6868 44.7047C44.9817 44.7047 46.098 44.0617 46.5713 42.8472H45.3479C45.0174 43.5081 44.428 43.8206 43.6868 43.8206C42.4545 43.8206 41.5614 42.9365 41.5614 41.5345C41.5614 40.1324 42.4545 39.2573 43.6868 39.2573C44.428 39.2573 45.0174 39.5698 45.3479 40.2307H46.5713C46.098 39.0162 44.9817 38.3643 43.6868 38.3643C41.9365 38.3643 40.5166 39.677 40.5166 41.5345Z"
      fill="white"
    />
    <path
      d="M54.2106 41.5345C54.2106 39.677 52.8085 38.3643 51.0403 38.3643C49.29 38.3643 47.8701 39.677 47.8701 41.5345C47.8701 43.4009 49.29 44.7137 51.0403 44.7137C52.8085 44.7137 54.2106 43.4009 54.2106 41.5345ZM48.9149 41.5345C48.9149 40.1324 49.808 39.2573 51.0403 39.2573C52.2727 39.2573 53.1657 40.1324 53.1657 41.5345C53.1657 42.9365 52.2727 43.8296 51.0403 43.8296C49.808 43.8296 48.9149 42.9365 48.9149 41.5345Z"
      fill="white"
    />
    <path
      d="M59.74 38.4357V43.0616L56.6769 38.4357H55.6589V44.6511H56.6769V40.0164L59.74 44.6511H60.758V38.4357H59.74Z"
      fill="white"
    />
    <path
      d="M66.6225 38.4357V43.0616L63.5595 38.4357H62.5414V44.6511H63.5595V40.0164L66.6225 44.6511H67.6406V38.4357H66.6225Z"
      fill="white"
    />
    <path
      d="M72.8532 38.4357H69.424V44.6511H72.8532V43.8206H70.442V41.9185H72.5853V41.088H70.442V39.2662H72.8532V38.4357Z"
      fill="white"
    />
    <path
      d="M74.2143 41.5345C74.2143 43.4009 75.6342 44.7047 77.3845 44.7047C78.6794 44.7047 79.7957 44.0617 80.269 42.8472H79.0455C78.7151 43.5081 78.1257 43.8206 77.3845 43.8206C76.1521 43.8206 75.2591 42.9365 75.2591 41.5345C75.2591 40.1324 76.1521 39.2573 77.3845 39.2573C78.1257 39.2573 78.7151 39.5698 79.0455 40.2307H80.269C79.7957 39.0162 78.6794 38.3643 77.3845 38.3643C75.6342 38.3643 74.2143 39.677 74.2143 41.5345Z"
      fill="white"
    />
    <path
      d="M81.5856 39.2751H83.2467V44.6511H84.2647V39.2751H85.9168V38.4446H81.5856V39.2751Z"
      fill="white"
    />
  </SvgIcon>
);

export default BrandNameConnect;
