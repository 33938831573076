import React from 'react';
import { AvatarProps } from '@mui/material';

import {
  Practitioner,
  ProfessionalHcd,
  User,
  UserChatRoomDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import Avatar from '../Avatar';
import InitialsAvatar from '../InitialsAvatar';

type Props = {
  src?: string;
  user?: User | Practitioner | ProfessionalHcd | UserChatRoomDTO;
  noSkeleton?: boolean;
} & AvatarProps;

const PractitionerAvatar = (props: Props) => {
  const { src = null, user = null, ...rest } = props;

  return src ? (
    // @ts-ignore
    <Avatar src={src} {...rest} />
  ) : (
    <InitialsAvatar user={user!} {...rest} />
  );
};

export default PractitionerAvatar;
