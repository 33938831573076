/* eslint-disable no-restricted-globals */
import Router from 'next/router';
import {
  cancel,
  delay,
  fork,
  put,
  race,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';

import { CLEAR_SESSION } from '@docavenue/core';

import { i18n } from '../i18n';
import { buildActions, snacksActions, videoSessionsActions } from './actions';
import {
  ACTION_ANNOUNCE,
  EXPIRED,
  TELESECRETARY,
  UNWATCH_PING_PATIENT,
  WATCH_PING_PATIENT,
} from './constants';
import Routes from './constants/routes';
import { buildUrlString, isAuthPath } from './utils/RoutesUtils';

const PATIENT_PING_TIMEOUT = 60 * 10 * 1000; // = every 5s patient ping + 2s latency of internet
const CHECK_WS_STATUS_RETRY_INTERVAL = 2000;

function* jwtExpired() {
  // eslint-disable-next-line no-console
  console.log('hello from expired');
  const user = yield select(state => state.users?.item);
  const isTelesecretary = user?.role?.name === TELESECRETARY;
  const tsToken = user?.userProInformation?.secretaryAccessToken?.[0];

  yield put({ type: 'RESET_STORE', payload: {} });

  if (typeof window !== 'undefined' && !isAuthPath(window.location.pathname)) {
    const url = buildUrlString(Routes.LOGIN, {
      tsToken: isTelesecretary && tsToken ? tsToken : undefined,
      next: window.location.pathname,
      session_expired: 'true',
    });
    Router.push(url);
  }
}

function* watchPingTimeoutTLC(action) {
  const { videoSession } = action;
  const { patientId } = videoSession;
  // make sure websocket connected
  let isWsConnected = false;
  for (let i = 0; i < 5; i++) {
    isWsConnected = yield select(state => state.websocket.connected);
    if (isWsConnected) {
      break;
    } else {
      yield delay(CHECK_WS_STATUS_RETRY_INTERVAL);
    }
  }
  if (!isWsConnected) {
    // eslint-disable-next-line no-console
    console.error(`Retries check ws connection failed`);
    return;
  }
  while (true) {
    const { timeout } = yield race({
      timeout: delay(PATIENT_PING_TIMEOUT),
      patientPing: take(
        // @ts-ignore
        (pingAction: {
          type: string;
          message: { action?: string; patientId?: string };
        }) =>
          pingAction?.type === '@@WS/MESSAGE' &&
          pingAction?.message?.action === 'ping' &&
          pingAction?.message?.patientId === patientId,
      ),
    });
    const checkStatus = timeout ? 'timeout' : 'ping';
    // eslint-disable-next-line no-console
    console.log(`[${patientId}] ping or timeout? => ${checkStatus}`);
    if (timeout) {
      // eslint-disable-next-line no-console
      console.log(`Set to expired [${patientId}]`);
      yield put(
        videoSessionsActions.updateOne({
          ...videoSession,
          videoSessionStatus: EXPIRED,
        }),
      );
      return;
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* watchInJWTExpired() {
  yield takeEvery(CLEAR_SESSION, jwtExpired);
}

export function* pingPatient() {
  // Only take latest action by patient
  // eslint-disable-next-line func-names
  yield fork(function*() {
    const tasks = {};
    while (true) {
      const action = yield take([WATCH_PING_PATIENT, UNWATCH_PING_PATIENT]);

      const { type, videoSession } = action;

      const taskKey = videoSession.patientId;
      // eslint-disable-next-line no-continue
      if (!taskKey) continue;

      switch (type) {
        case WATCH_PING_PATIENT:
          // eslint-disable-next-line no-console
          console.log(
            'Start watching ping for patient ',
            action?.videoSession?.patientId,
          );
          if (tasks[taskKey]) {
            yield cancel(tasks[taskKey]);
          }
          tasks[taskKey] = yield fork(watchPingTimeoutTLC, action);
          break;
        case UNWATCH_PING_PATIENT:
          // eslint-disable-next-line no-console
          console.log(
            'Unwatch ping for patient ',
            action?.videoSession?.patientId,
          );
          if (tasks[taskKey]) {
            yield cancel(tasks[taskKey]);
          }
          break;
        default:
          break;
      }
    }
  });
}
function* websocketMessageHandle(wsMessage) {
  const {
    message: { action, payload, resource },
  } = wsMessage;
  if (action === ACTION_ANNOUNCE && payload?.release === true) {
    const { releaseVersion } = payload;
    // @ts-ignore
    const build = yield select(state => state.build);
    const currentReleaseVersion = build.item?.releaseVersion;

    if (releaseVersion && releaseVersion !== currentReleaseVersion) {
      yield put(
        buildActions.setItem({
          id: +new Date(),
          releaseVersion,
          reload: true,
        }),
      );
    }
  }
  if (resource === 'appointment' && action === 'PATIENT_ARRIVED') {
    yield put(
      snacksActions.enqueueSnack({
        message: i18n.t('appointment_notification_patient_has_arrived', {
          firstName: payload?.patient?.firstName,
          lastName: payload?.patient?.lastName,
        }),
        variant: 'success',
      }),
    );
  }
}
export function* wsProSaga() {
  yield takeEvery('@@WS/MESSAGE', websocketMessageHandle);
}
