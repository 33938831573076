import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ANNOUNCEMENT,
  fetchRetry,
  HEALTH_CHECK_FRONT_PRO,
} from '@docavenue/core';

// eslint-disable-next-line import/prefer-default-export
export const useMaintenanceMaiia = (
  serviceMaintenance: string = HEALTH_CHECK_FRONT_PRO,
) => {
  // variables
  const baseURL = '';

  // global state
  const isOnline = useSelector(
    s => s?.announcements?.maintenance?.[serviceMaintenance],
  );

  // hooks
  const dispatch = useDispatch();

  /** ******************************************************************************************************************
   * Callbacks / handles
   ******************************************************************************************************************** */
  const handleGetStatusAnnouncement = (s: string, channel: string) => {
    const url = `${baseURL}/healthcheck/status/${s}`;

    fetchRetry(fetch, url, 1000, 5).then(async res => {
      let theResponse;

      if (!res.ok) {
        theResponse = 'ko';
      } else {
        try {
          theResponse = await res.text();
        } catch (e) {
          theResponse = 'ko';
        }
      }

      // eslint-disable-next-line no-console
      console.log(`[INFO] Call maintenance status ${url}`, theResponse);

      dispatch({
        type: ANNOUNCEMENT,
        payload: {
          service: s,
          channel,
          status: theResponse === 'ok',
        },
      });
    });
  };

  /** ******************************************************************************************************************
   * Lifecycles
   ******************************************************************************************************************** */
  // on app start, we check if app is in maintenance or not
  useEffect(() => {
    handleGetStatusAnnouncement(serviceMaintenance, 'maintenance');
  }, []);

  return isOnline;
};
