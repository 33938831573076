import React, { forwardRef } from 'react';
import {
  CircularProgress as MaterialCircularProgress,
  CircularProgressProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Props = {
  className?: string;
  size?: number | string;
} & CircularProgressProps;

const useStyles = makeStyles<Props>()((_theme, { size }) => ({
  circularLoader: {
    height: size,
    width: size,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > svg': {
      height: size,
      width: size,
    },
  },
}));

const CircularProgress = forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>) => {
    const { className, size = 20, ...rest } = props;
    const { classes, cx } = useStyles({ size });
    return (
      <div className={cx(classes.circularLoader, 'circularLoader', className)}>
        <MaterialCircularProgress
          thickness={7}
          size={Number(size) - 10}
          {...rest}
          ref={ref}
        />
      </div>
    );
  },
);

CircularProgress.displayName = 'CircularProgress';

export default CircularProgress;
