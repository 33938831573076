import React, { memo } from 'react';
import Highlighter, { Chunk, FindChunks } from 'react-highlight-words';
import { makeStyles } from 'tss-react/mui';


import { findChunks, normalizeString } from '../../../src/utils';

const useStyles = makeStyles()((theme: any) => ({
  highlighter: {
    backgroundColor: 'transparent',
    color: theme.palette.accent.main,
  },
}));

type Props = {
  searchTerm?: string[] | string;
  text: string;
  className?: string;
  highlighterClassName?: string;
  findChunks?: (options: FindChunks) => Chunk[];
};

const Highlight = (props: Props) => {
  const {
    searchTerm = '',
    text = '',
    className,
    highlighterClassName,
    ...rest
  } = props;
  const { classes, cx } = useStyles();
  return (
    <Highlighter
      {...rest}
      className={className}
      highlightClassName={cx(classes.highlighter, highlighterClassName)}
      autoEscape
      sanitize={normalizeString}
      findChunks={findChunks}
      searchWords={typeof searchTerm === 'string' ? [searchTerm] : searchTerm}
      textToHighlight={text}
    />
  );
};

export default memo(Highlight);
