import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import moment from 'moment';
import Routes from 'packages/pro-frontend/src/constants/routes';

import { useTranslation } from '../../../i18n';

interface MiniVideoSessionPendingDialogInfoProps {
  name: string;
  isPriority: boolean;
  reason: string;
  birthday?: string;
  patientId: string;
}

const styles: { [key: string]: SxProps } = {
  priorityLabel: {
    backgroundColor: 'info.main',
    verticalAlign: 'center',
    lineHeight: 1.7,
    px: 2,
    py: 0.5,
    borderRadius: '6px',
    color: 'white',
  },
  name: {
    fontWeight: 'bold',
    transition: 'all .2s',
    ':hover': {
      cursor: 'pointer',
      color: 'secondary.main',
    },
    fontFamily: 'Source Sans 3',
  },
};

const MiniVideoSessionPendingDialogInfo = ({
  name,
  reason,
  birthday,
  isPriority,
  patientId,
}: MiniVideoSessionPendingDialogInfoProps) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <Box px={4}>
      <Stack
        sx={{
          backgroundColor: 'rgba(242, 245, 247, 0.8)',
          p: 3,
        }}
        spacing={3}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Link
            href={{
              pathname: Routes.EDIT_PATIENT,
              query: {
                ...router.query,
                patientId,
              },
            }}
          >
            <Typography sx={styles.name}>{name}</Typography>
          </Link>
          {isPriority && (
            <Typography variant="body2" sx={styles.priorityLabel}>
              {t('common:video_session_row_ priority')}
            </Typography>
          )}
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          {birthday && (
            <Typography>
              {`(${moment(birthday).format('DD[/]MM[/]YYYY')})`}
            </Typography>
          )}
          <Typography fontSize="1rem">{reason}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MiniVideoSessionPendingDialogInfo;
