import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  ChatContext,
  chatMessagesActions,
  useCenterId,
  useUserId,
} from '@docavenue/chat';
import { serialActions } from '@docavenue/core';
import { VideoProMeetingDTO } from '@maiia/model/generated/model/api-pro/api-pro';
import { useGet as useGetChatRoom } from '@maiia/model/generated/model/api-pro/hooks/proChatRoomsByChatRoomId';

import { videoProMeetingActions } from '../actions';
import { RootState } from '../reducer/rootState';

export const VIDEO_MEETING_DURATION_MAX = 30 * 60;
export const VIDEO_MEETING_COUNT_DOWN_DURATION = 5 * 60;
const getDuration = (startDate: string, endDate?: string) =>
  endDate
    ? moment(endDate).diff(moment(startDate), 'seconds')
    : moment()
        .utc()
        .diff(moment(startDate), 'seconds') || 0;

export const useCountdown = (startDate: string, endDate?: string) => {
  const initialDuration = getDuration(startDate, endDate);
  const [duration, setDuration] = useState(initialDuration);

  useEffect(() => {
    const interval = setInterval(() => {
      const d = getDuration(startDate, endDate);
      setDuration(d);
    }, 1000);
    return () => clearInterval(interval);
  }, [startDate, endDate]);

  const countDown = useMemo(() => {
    if (
      duration <=
        VIDEO_MEETING_DURATION_MAX - VIDEO_MEETING_COUNT_DOWN_DURATION ||
      endDate
    )
      return;

    const count = VIDEO_MEETING_DURATION_MAX - duration;
    return count;
  }, [duration]);

  return {
    isElapsed: duration === VIDEO_MEETING_DURATION_MAX,
    countDown,
    duration,
    countDownMMSS: countDown
      ? moment((countDown || 0) * 1000).format('mm:ss')
      : '',
  };
};

export const useVideoMeeting = (
  videoMeeting: VideoProMeetingDTO | null | undefined,
) => {
  const { useRouter } = useContext(ChatContext);

  const router = useRouter();
  const {
    query: { chatRoomId = '' },
  } = router;

  const { data: chatRoom } = useGetChatRoom(
    {
      chatRoomId: chatRoomId.toString(),
    },
    {
      enabled: !['threads', 'search', 'new_conversation'].includes(
        chatRoomId.toString(),
      ),
    },
  );

  const userId = useUserId() || '';
  const centerId = useCenterId()!;
  const videosProMeeting = useSelector(state => state.videoProMeeting.item);
  const tlc = useSelector(state => state.videoSessions.item);
  const videoMeetingId = videoMeeting?.id;
  const users = chatRoom?.users || [];
  const dispatch = useDispatch();

  const create = useCallback(() => {
    dispatch(
      serialActions.serial([
        () =>
          typeof chatRoomId === 'string'
            ? videoProMeetingActions.create(
                {
                  chatRoomId,
                  centerId,
                },
                {
                  isListDst: false,
                },
              )
            : serialActions.continue(),
        (state: RootState) =>
          chatMessagesActions.create(
            {
              authorId: userId,
              centerId,
              chatRoomId,
              attachments: [
                {
                  type: 'VIDEO_PRO_MEETING',
                  attachment: state.videoProMeeting?.item?.id,
                },
              ],
            },
            { isListDst: true },
          ),
        (state: RootState) =>
          videoProMeetingActions.createAction(
            state.videoProMeeting?.item?.id || '',
            {
              centerId,
              action: 'JOIN',
            },
          ),
      ]),
    );
  }, [userId, centerId, chatRoomId, videoMeetingId]);

  const list = useCallback(() => {
    dispatch(videoProMeetingActions.getList());
  }, []);

  const join = useCallback(() => {
    if (!videoMeetingId) return;
    dispatch(
      serialActions.serial([
        () =>
          videoProMeetingActions.createAction(
            videoMeetingId,
            {
              centerId,
              action: 'JOIN',
            },
            {
              isListDst: false,
            },
          ),
      ]),
    );
  }, [userId, centerId, videoMeetingId]);

  const leave = useCallback(() => {
    if (!videoMeetingId) return;
    dispatch(
      serialActions.serial([
        () =>
          videoProMeetingActions.createAction(
            videoMeetingId,
            {
              centerId,
              action: 'LEAVE',
            },
            {
              isListDst: false,
            },
          ),
        () => videoProMeetingActions.reset(),
      ]),
    );
  }, [userId, centerId, videoMeetingId]);

  const status = useMemo(() => videoMeeting?.videoProMeetingStatus, [
    videoMeeting,
  ]);

  const isBusy = useMemo(() => !!videosProMeeting || !!tlc, [
    videosProMeeting,
    tlc,
  ]);

  const publisher = useMemo(() => {
    return users.find(item => item.id === userId);
  }, [userId, users]);

  const subscriber = useMemo(() => {
    return users.find(item => item.id !== userId);
  }, [userId, users]);

  return {
    create,
    join,
    list,
    leave,
    status,
    isBusy,
    publisher,
    subscriber,
    ...useCountdown(videoMeeting?.statusDate || '', videoMeeting?.finishDate),
  };
};
