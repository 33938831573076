import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  fallback: ReactNode;
  isEmpty: boolean;
};

const EmptyState = (props: Props) => {
  const { children, isEmpty, fallback } = props;
  return <>{isEmpty ? fallback : children}</>;
};

export default EmptyState;
