import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material';

import { MaiiaTheme } from '../maiiaTheme';

interface MuiSwitchOverrideProps {
  defaultProps?: ComponentsProps['MuiSwitch'];
  styleOverrides?: ComponentsOverrides<MaiiaTheme>['MuiSwitch'];
  variants?: ComponentsVariants['MuiSwitch'];
}

const MuiSwitchOverride: MuiSwitchOverrideProps = {
  defaultProps: {},
  styleOverrides: {
    root: ({ ownerState }) => {
      return {
        '& .MuiSwitch-switchBase': {
          '&.Mui-checked': {
            ...(ownerState.color === 'primary' && { color: 'primary.main' }),
            ...(ownerState.color === 'secondary' && {
              color: 'secondary.main',
            }),
            '& + .MuiSwitch-track': {
              ...(ownerState.color === 'primary' && { color: 'primary.main' }),
              ...(ownerState.color === 'secondary' && {
                color: 'secondary.main',
              }),
            },
          },
        },
      };
    },
  },
};

export default MuiSwitchOverride;
