import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material';

import { MaiiaTheme } from '../maiiaTheme';

interface MuiRadioOverrideProps {
  defaultProps?: ComponentsProps['MuiSwitch'];
  styleOverrides?: ComponentsOverrides<MaiiaTheme>['MuiSwitch'];
  variants?: ComponentsVariants['MuiSwitch'];
}

const MuiRadioOverride: MuiRadioOverrideProps = {
  defaultProps: {
    color: 'secondary',
  },
};

export default MuiRadioOverride;
