/*
 * This is NOT a regular generated hook
 * It is based on the generated `proAppointments` hook
 *
 * It fakes a generated hook that would be generated for an api that will soon be
 * created by the backend that would be of shape:
 *
 * `/pro/appointments/{practitionerId}/{centerId}/{targetDateKey}`
 */

import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import dayjs from 'dayjs';

import {
  ApiClientFactory,
  useApiClient,
} from '@maiia/model/generated/core/react-query';
// import omitBy from "lodash/omitBy";
// import pick from "lodash/pick";
import { ResultPageAppointmentGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

type UseGetParams = {
  /** Center unique id */
  centerId: string;
  /** Practitioner unique id */
  practitionerId: string;
  /** Target date key - format YYYY-MM-DD */
  targetDateKey: string;

  // The following params would be part of the new api default implementation

  // /** Start date-time */
  // from?: string;
  // /** End date-time */
  // to?: string;
  // /** Collections to aggregate with the appointments (center, patient, practitioner, consultationReason and agendaSettings) */
  // aggregateWith?: string[];
  // /** Indicate whether or not we should report attached documents */
  // withDocuments?: boolean;
  // /** Indicate the appointment types */
  // types?: (
  //   | 'ALL'
  //   | 'PHYSICAL'
  //   | 'TELECONSULTATION'
  //   | 'DIRECT_TELECONSULTATION'
  //   | 'ABSENCE'
  //   | 'OTHER'
  //   | 'NOTE'
  //   | 'EXPIRED_TELECONSULTATION'
  //   | 'HOME_VISIT'
  // )[];
  // /** Indicate the video session current status */
  // videoSessionCurrentStatus?: (
  //   | 'STARTED'
  //   | 'PENDING'
  //   | 'WAITING'
  //   | 'REFUSED'
  //   | 'FINISHED'
  //   | 'CRON_FINISHED'
  //   | 'EXPIRED'
  //   | 'CANCELLED'
  //   | 'PATIENT_FINISHED'
  //   | 'PATIENT_CANCELLED'
  //   | 'PATIENT_EXPIRED'
  //   | 'MISSED'
  // )[];
  // /** Indicate if we want to get a light appointment */
  // light?: boolean;
  // /** Zero-based page index */
  // page?: number;
  // /** The size of the page to be returned */
  // limit?: number;
  // /** Sort direction (asc or desc). */
  // direction?: string;
  // /** Property on which the sort is applied */
  // sort?: string;
};

// const queryParamsList = [];

export function makeQueryKey(params: UseGetParams): unknown[] {
  // const queryParams = omitBy(
  // 	pick(params, queryParamsList),
  // 	(o) => o === "" || o === null,
  // );

  return [
    '#poc',
    'pro',
    'appointments',
    params.practitionerId,
    params.centerId,
    params.targetDateKey,
  ];
}

export function useInvalidateQuery() {
  const queryClient = useQueryClient();
  return function invalidateQuery(params) {
    // const queryParams = params
    // 	? omitBy(pick(params, queryParamsList), (o) => o === "" || o === null)
    // 	: null;

    return queryClient.invalidateQueries({
      queryKey: [
        '#poc',
        'pro',
        'appointments',
        params.practitionerId,
        params.centerId,
        params.targetDateKey,
      ].filter(Boolean),
    });
  };
}

/**
 * List appointments by centerId and by (practitionerId or patientId)
 */
// export function useGet<TError = unknown, TData = ResultPageAppointmentGetDTO>(
// 	params: UseGetParams,
// options?: Omit<
// 	UseQueryOptions<ResultPageAppointmentGetDTO, TError, TData>,
// 	"queryKey"
// >,
// ): UseQueryResult<TData, TError>;

type MakeQueryClientRequireOptions = {
  getApiClient: ApiClientFactory;
};

export function makeQueryFn({ getApiClient }: MakeQueryClientRequireOptions) {
  return ({ queryKey }: { queryKey: readonly unknown[] }) => {
    const [practitionerId, centerId, targetDateKey] = queryKey.slice(3);
    return getApiClient({ apiName: 'api-pro' })
      .get(`/pro/appointments`, {
        params: {
          practitionerId,
          centerId,
          from: targetDateKey,
          // the "to" is calculated based on the "from", it will be handled by the default implementation of the new api
          to: dayjs(targetDateKey as string)
            .add(1, 'day')
            .toISOString(),
          light: true,
          limit: 2500,
          page: 0,
          types: [
            'PHYSICAL',
            'TELECONSULTATION',
            'ABSENCE',
            'OTHER',
            'HOME_VISIT',
          ],
        },
      })
      .then(({ data }) => data);
  };
}

export function useGet(
  params: UseGetParams,
  options?: Omit<
    UseQueryOptions<ResultPageAppointmentGetDTO>,
    'queryKey' | 'queryFn'
  >,
) {
  const { getApiClient } = useApiClient();
  const useQueryOptions: UseQueryOptions<ResultPageAppointmentGetDTO> = {
    ...options,
    queryKey: makeQueryKey(params),
    queryFn: makeQueryFn({ getApiClient }),
    // only enable hook if all required params are passed at runtime (don't trust the developer who could cast)
    enabled:
      !!params.practitionerId &&
      !!params.centerId &&
      !!params.targetDateKey &&
      options?.enabled !== false,
  };
  return useQuery(useQueryOptions);
}
