import React from 'react';

import { useTranslation } from '../../../i18n';
import { useSendMailContact } from '../../../src/hooks/actions';
import MarketingMessage from '../../molecules/MarketingMessage';

type Props = {
  close: () => void;
};

const NeedContactAgenda = (props: Props) => {
  const { close } = props;
  const { t } = useTranslation('common');
  const { sendMail, error, message } = useSendMailContact({
    objectMail: 'needContactAgenda_mail_object',
    messageMail: 'needContactAgenda_mail_message',
    contactReason: 'UPSELL_AGENDA_CONNECT_ONLY',
  });

  return (
    <MarketingMessage
      errorMessage={error}
      message={message || null}
      onClick={sendMail}
      mainIllu="/static/images/illu-market-agenda.svg"
      catchTitle={t('needContactAgenda_catchtitle')}
      catchPhrases={[
        t('needContactAgenda_catchphrase1'),
        t('needContactAgenda_catchphrase2'),
        t('needContactAgenda_catchphrase3'),
      ]}
      close={close}
    />
  );
};

export default NeedContactAgenda;
