/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';

import {
  Week,
  WeekTemplateCycle,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { RootState } from '../reducer/rootState';
import weekTemplateCyclesSelectors from './weekTemplateCyclesSelectors';

const getWeeks = createSelector(
  weekTemplateCyclesSelectors.getItems,
  (weekTemplateCycles: WeekTemplateCycle[]) =>
    weekTemplateCycles.map(weekTemplateCycle => weekTemplateCycle.weeks).flat(),
);

export type WeeksSelector = {
  name: string;
  getWeeks: (state: RootState) => (Week | undefined)[];
};

const selectors: WeeksSelector = {
  name: 'weeks',
  getWeeks,
};

export default selectors;
