export enum DialogType {
  CHAT_MESSAGE_ACTIVATE_TELE_EXPERTISE = 'CHAT_MESSAGE_ACTIVATE_TELE_EXPERTISE',
  CHAT_MESSAGE_CANCEL_TELE_EXPERTISE = 'CHAT_MESSAGE_CANCEL_TELE_EXPERTISE',
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  DOCUMENT_SELECTION = 'DOCUMENT_SELECTION',
  SCHEDULE_WAITING_ROOM = 'SCHEDULE_WAITING_ROOM',
  TELEEXPERTISE_SUMMARY = 'TELEEXPERTISE_SUMMARY',
  TIMESLOT_HANDLING = 'TIMESLOT_HANDLING',
}

export const SET_DIALOG = 'SET_DIALOG';

const setDialogType = ({
  dialogType,
  dialogProps = {},
}: {
  dialogType: DialogType | null;
  dialogProps?: Object | null | undefined;
}) => ({
  type: SET_DIALOG,
  payload: { dialogType, dialogProps },
});

const dialogActions = {
  setDialogType,
};

export default dialogActions;
