import {
  assignItemsDictForPrimaryKey,
  assignItemsForSecondaryKey,
  getItemsFromKey,
  initialStateItemsDict,
  initIfEmptyForPrimaryKey,
  ItemsDictMapAction,
  ItemsDictMapReducer,
  itemsDictMapReducerGenerator,
  ItemsDictMapState,
  keysOptionsDefault,
} from '@docavenue/chat';
import { REVERT_SUCCESS } from '@docavenue/core';
import { TimeSlotGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

const name = 'timeSlots';
const {
  getPrimaryKey,
  getSecondaryKey,
  initialProperties,
} = keysOptionsDefault;

export const initialState = initialStateItemsDict;

const surchargeReducer = (
  initialReducer: ItemsDictMapReducer<TimeSlotGetDTO>,
) => {
  const extendReducer: ItemsDictMapReducer<TimeSlotGetDTO> = {};
  [REVERT_SUCCESS, `${REVERT_SUCCESS}/${name}`].forEach(actionType => {
    extendReducer[actionType] = (
      state: ItemsDictMapState<TimeSlotGetDTO>,
      action: ItemsDictMapAction<
        { items: TimeSlotGetDTO[] },
        { data: TimeSlotGetDTO }
      >,
    ) => {
      const { itemsDictMap } = state;
      const { query, payload } = action;
      const { startDateTime, endDateTime } = query?.data || {};
      const primaryKey = getPrimaryKey(query.data);
      const secondaryKey = getSecondaryKey(query.data);
      initIfEmptyForPrimaryKey<TimeSlotGetDTO>(
        itemsDictMap,
        initialProperties,
        primaryKey,
      );
      const { itemsDict } = itemsDictMap[primaryKey];
      const items = getItemsFromKey<TimeSlotGetDTO>(
        itemsDict,
        secondaryKey,
      ).filter(
        (item: any) =>
          new Date(item.endDateTime).getTime() <
            new Date(startDateTime).getTime() ||
          new Date(endDateTime).getTime() <
            new Date(item.startDateTime).getTime(),
      );
      assignItemsForSecondaryKey(
        itemsDict,
        [...items, ...(payload.items || [])],
        secondaryKey,
      );
      return {
        ...state,
        itemsDictMap: {
          ...assignItemsDictForPrimaryKey<TimeSlotGetDTO>(
            itemsDictMap,
            itemsDict,
            primaryKey,
          ),
        },
        isLoading: false,
        error: null,
      };
    };
  });
  return { ...initialReducer, ...extendReducer };
};

const timeSlots = itemsDictMapReducerGenerator<TimeSlotGetDTO>({
  name,
  keysOptions: keysOptionsDefault,
  surchargeReducer,
});

export default timeSlots;
