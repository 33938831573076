import { MedicalDocument } from './rootState';

export const SET_MEDICAL_CERTIFICATE = 'SET_MEDICAL_CERTIFICATE';
export const initialStateMedicalCertificate: MedicalDocument = {
  content: '',
  attachments: [],
};

function medicalCertificate(
  state = initialStateMedicalCertificate,
  action: {
    type: string;
    payload: MedicalDocument;
  },
) {
  const { type } = action;
  switch (type) {
    case SET_MEDICAL_CERTIFICATE: {
      return action.payload;
    }
    default:
      return state;
  }
}

export default medicalCertificate;
