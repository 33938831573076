import { actionsGeneratorV2 } from '@docavenue/core';

export const SET_OPEN = 'SET_OPEN';
export const SET_OPEN_COMPLEMENTARY = 'SET_OPEN_COMPLEMENTARY';
export const SET_OPEN_REPORT = 'SET_OPEN_REPORT';
export const SET_OPEN_MEDICAL_CERTIFICATE = 'SET_OPEN_MEDICAL_CERTIFICATE';

// TODO type prescriptions actions

const prescriptionCrud = actionsGeneratorV2({
  resource: 'prescription',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const setOpen = (isOpen: boolean) => ({
  resource: 'prescription',
  type: SET_OPEN,
  payload: isOpen,
});

const setOpenComplementary = (isOpen: boolean) => ({
  resource: 'prescription',
  type: SET_OPEN_COMPLEMENTARY,
  payload: isOpen,
});

const setOpenReport = (isOpen: boolean) => ({
  resource: 'prescription',
  type: SET_OPEN_REPORT,
  payload: isOpen,
});

const setOpenMedicalCertificate = (isOpen: boolean) => ({
  resource: 'prescription',
  type: SET_OPEN_MEDICAL_CERTIFICATE,
  payload: isOpen,
});

const prescriptionActions = {
  ...prescriptionCrud,
  setOpen,
  setOpenComplementary,
  setOpenReport,
  setOpenMedicalCertificate,
};

export default prescriptionActions;
