/* eslint-disable camelcase */
/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMap } from 'react-use';
import { NextRouter, useRouter } from 'next/router';
import find from 'lodash/find';
import omit from 'lodash/omit';

import { ParsedUrlQuery } from 'querystring';

import { serialActions } from '@docavenue/core';
import { Practitioner } from '@maiia/model/generated/model/api-pro/api-pro';

import { Dictionary } from '../../types/pro.types';
import { agendaSettingsActions, practitionersActions } from '../actions';
import {
  AGENDA,
  AGENDA_SETTINGS_VIEW_AGENDA,
  AGENDA_SETTINGS_VIEW_WEEK,
  DAY,
  matchAgendaSettingsViewToView,
  WEEK,
} from '../actions/calendar';
import {
  GAActionStatus,
  GACategories,
  GASideBarAgendaActions,
  GASideBarAgendaLabels,
} from '../constants/tracking';
import { formatAgendaKey, removeAgendaKey, unpack } from '../utils';
import {
  useAgendaSettingsDefaultWithChat,
  useCenterId,
  useIsPractitioner,
  usePractitionerId,
} from './selectors';
import { useAnalyticsTracker } from './utils';

export const useTimeSlotResourcesKeys = (): string[] => {
  type Router = NextRouter & {
    query: {
      centerId: string;
      practitionerId: string;
      timeSlotResources?: string;
    };
  };
  const {
    query: { timeSlotResources = '' },
  } = useRouter() as Router;
  const displayedTimeSlotsResources = useMemo(
    () => (timeSlotResources ? timeSlotResources.split(',') : []),
    [timeSlotResources],
  );
  return displayedTimeSlotsResources;
};

export const useAgendaKeys = () => {
  type Router = NextRouter & {
    query: {
      agendas?: string;
      centerId: string;
      practitionerId: string;
    };
  };
  const {
    query: { agendas },
  } = useRouter() as Router;
  const agendaKeys = useMemo(() => [...(agendas ? agendas.split(',') : [])], [
    agendas,
  ]);
  return agendaKeys;
};

export const useDisplayedAgendasKeys = () => {
  type Router = NextRouter & {
    query: {
      centerId: string;
      practitionerId: string;
    };
  };
  const {
    query: { centerId = '', practitionerId = '' },
  } = useRouter() as Router;
  const storedParctitionerId = usePractitionerId();
  const storedCenterId = useCenterId();
  const currentPractitionerId = practitionerId || storedParctitionerId;
  const currentCenterId = centerId || storedCenterId;
  const agendaKeys = useAgendaKeys();
  const timeSlotResourcesKeys = useTimeSlotResourcesKeys();
  const displayedAgendas = useMemo(() => {
    if (!currentPractitionerId || !currentCenterId) return [];
    return [
      ...(timeSlotResourcesKeys.length > 0
        ? []
        : [formatAgendaKey(currentPractitionerId, currentCenterId)]),
      ...agendaKeys,
    ];
  }, [
    agendaKeys,
    timeSlotResourcesKeys,
    currentPractitionerId,
    currentCenterId,
  ]);
  return displayedAgendas;
};

export const useCenterIdChatQuery = () => {
  const centerId = useCenterId()!;
  const isPractitioner = useIsPractitioner();
  const centerIds = useAgendaSettingsDefaultWithChat().map(
    agendaSetting => agendaSetting.centerId,
  );
  const centerIdQuery =
    !isPractitioner || centerIds.includes(centerId) ? centerId : centerIds[0];
  return centerIdQuery;
};

export const useIsPractitionerQuery = () => !!useRouter().query.practitionerId;

export const useCalendarView = (): 'day' | 'week' | 'agenda' => {
  type Router = NextRouter & {
    query: { view: string };
  };
  const {
    query: { view },
  } = useRouter() as Router;
  const agendaSetting = useSelector(state => state.agendaSettings.item);
  const timeSlotResourcesKeys = useTimeSlotResourcesKeys();
  if (view === DAY || view === WEEK || view === AGENDA) {
    return view;
  }
  const agendaSettingsView =
    agendaSetting?.agendaDisplaySettings?.agendaSettingsView ||
    AGENDA_SETTINGS_VIEW_WEEK;
  if (
    agendaSettingsView === AGENDA_SETTINGS_VIEW_AGENDA &&
    timeSlotResourcesKeys.length > 0
  ) {
    return WEEK;
  }
  return matchAgendaSettingsViewToView[agendaSettingsView];
};

export const createCheckedAgendaMap = (
  map: Record<string, boolean>,
  key: string,
  index: number,
) => (key ? { ...map, [key]: index === 0 } : map);

export const useOnChangePractitioner = (isWithMiniCalendar: boolean = true) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { pathname, query } = router;
  const currentPractitionerId = unpack(query.practitionerId);
  const currentCenterId = unpack(query.centerId);
  const isHome = pathname === '/';
  const timeSlotResourcesKeys = useTimeSlotResourcesKeys();
  const agendaSettings = useSelector(state => state.agendaSettings.items);
  const displayedAgendas = useDisplayedAgendasKeys();
  const [checkedMap, { setAll }] = useMap(
    displayedAgendas.reduce(createCheckedAgendaMap, {}),
  );

  useLayoutEffect(() => {
    // Ensure checkedMap (sidebar/agendas state) stay synchronized with displayedAgendas (url)
    if (Object.keys(checkedMap).length !== displayedAgendas.length) {
      setAll(displayedAgendas.reduce(createCheckedAgendaMap, {}));
    }
  }, [displayedAgendas]);

  const onChangePractitioner = (
    practitioner: Practitioner,
    newCenterId: string,
    extraQuery: Object = {},
    callback?: () => void,
  ) => {
    const agendaKey =
      currentPractitionerId &&
      currentCenterId &&
      formatAgendaKey(currentPractitionerId, currentCenterId);
    const agendaKeys = Object.keys(checkedMap).filter(key => key !== agendaKey);
    if (
      !timeSlotResourcesKeys.length &&
      practitioner.id === currentPractitionerId &&
      newCenterId === currentCenterId
    ) {
      callback?.();
      return;
    }
    const newAgendaKey = formatAgendaKey(practitioner.id, newCenterId);
    const newAgendaKeys = removeAgendaKey(agendaKeys, newAgendaKey);
    const keysToCheckedMap = [
      newAgendaKey,
      ...(agendaKeys.length > 0 && agendaKey ? [agendaKey] : []),
      ...newAgendaKeys,
    ];
    setAll(keysToCheckedMap.reduce(createCheckedAgendaMap, {}));
    const newQuery: ParsedUrlQuery = {
      ...omit(
        query,
        'keyAgendaAggregate',
        'substituteUserIds',
        'timeSlotResources',
      ),
      practitionerId: practitioner.id,
      centerId: newCenterId,
    };
    if (isWithMiniCalendar) {
      newQuery.agendas = [agendaKey, ...newAgendaKeys]
        .filter(Boolean)
        .join(',');
    }
    const agendaSettingsItem = find(agendaSettings, {
      centerId: newCenterId,
      practitionerId: practitioner.id,
    });
    dispatch(
      serialActions.serial([
        () => practitionersActions.setItem(practitioner),
        () => agendaSettingsActions.setItem(agendaSettingsItem || null),
        () => {
          setTimeout(() => {
            const route = {
              pathname:
                pathname === '/center-settings'
                  ? '/practitioner-settings'
                  : pathname,
              query: {
                ...omit(newQuery, agendaKeys.length ? '' : 'agendas'),
                ...extraQuery,
              },
            };
            // @ts-ignore
            router.push(route, route, { shallow: isHome }).then(() => {
              callback?.();
            });
          }, 125);
        },
      ]),
    );
  };
  useEffect(() => {
    // When displaying a timeslot resource calendar we need to clean checkedMap to avoid checked practitioners in the sidebar
    if (timeSlotResourcesKeys.length) {
      setAll({});
    }
  }, [setAll, timeSlotResourcesKeys.length]);
  return { onChangePractitioner, checkedMap, setAll };
};

export const useKeyAgendaAggregate = () => {
  const { keyAgendaAggregate } = useRouter().query;
  const r = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      keyAgendaAggregate
        ? Array.isArray(keyAgendaAggregate)
          ? [...keyAgendaAggregate]
          : keyAgendaAggregate.split(',')
        : [],
    [keyAgendaAggregate],
  );
  return r;
};

export const useKeyAgendaAggregateNavigation = () => {
  const router = useRouter();
  const storedCenterId = useCenterId();
  const storedPractitionerId = usePractitionerId();
  const storedAgendaKey =
    storedPractitionerId &&
    storedCenterId &&
    formatAgendaKey(storedPractitionerId, storedCenterId);
  const { query, pathname } = router;
  const currentPractitionerId = unpack(query.practitionerId);
  const currentCenterId = unpack(query.centerId);
  const keyAgendaAggregate = useKeyAgendaAggregate();
  const triggerGAEvent = useAnalyticsTracker(GACategories.SideBarAgenda);
  const navigateKeyAgendaAggregate = useCallback(
    (practitionerId: string, centerId: string) => {
      const agendaKey = formatAgendaKey(practitionerId, centerId);
      let newKeys: string[] = [];
      if (
        keyAgendaAggregate.length === 0 &&
        agendaKey !== storedAgendaKey &&
        practitionerId === currentPractitionerId &&
        storedAgendaKey
      ) {
        newKeys.push(storedAgendaKey);
      }
      if (
        practitionerId !== currentPractitionerId &&
        centerId === currentCenterId
      ) {
        newKeys = [agendaKey];
      } else if (
        practitionerId !== currentPractitionerId &&
        centerId !== currentCenterId &&
        currentCenterId
      ) {
        newKeys = [formatAgendaKey(practitionerId, currentCenterId), agendaKey];
      } else if (keyAgendaAggregate.includes(agendaKey)) {
        newKeys = [...keyAgendaAggregate.filter(key => key !== agendaKey)];
      } else if (storedAgendaKey === agendaKey) {
        newKeys = [storedAgendaKey, ...keyAgendaAggregate];
      } else {
        newKeys = [...newKeys, ...keyAgendaAggregate, agendaKey];
      }
      // TODO: remove multiple agenda
      const selectedCentersNumber = newKeys.length;
      if (selectedCentersNumber > 1) {
        const GALabel = Object.values(GASideBarAgendaLabels).find(
          label => label === selectedCentersNumber,
        ) as GASideBarAgendaLabels;
        triggerGAEvent(
          GASideBarAgendaActions.ClickMultipleCenterView,
          GAActionStatus.SUCCESS,
          GALabel,
        );
      }
      const newRoute = {
        pathname,
        query: {
          ...omit(query, 'agendas'),
          practitionerId,
          keyAgendaAggregate: newKeys.join(','),
        },
      };
      router.push(newRoute);
    },
    [
      currentCenterId,
      currentPractitionerId,
      keyAgendaAggregate,
      pathname,
      storedAgendaKey,
    ],
  );
  return navigateKeyAgendaAggregate;
};

export const useNextParameterFromUrl = () => {
  const {
    query: { next },
  } = useRouter();
  return next ? decodeURIComponent(next as string) : null;
};

export const useNextParameterAsObjectFromUrl = () => {
  const nextParameter = useNextParameterFromUrl();
  const reducer = (accumulator: Dictionary<string>, currentValue: string) => {
    const [key, value] = currentValue.split('=');
    return { ...accumulator, [key]: value };
  };
  return (
    (nextParameter || '')
      .split('?')[1]
      .split('&')
      .reduce(reducer, {}) || {}
  );
};

export const useIsAccountAlreadyUnlocked = () => {
  const {
    query: { account },
  } = useRouter();
  return account === 'unlocked';
};

export const useIsSessionExpired = (): boolean => {
  const {
    query: { session_expired = false },
  } = useRouter();
  return session_expired === 'true';
};
