import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const usePatientLimitationsStyles = makeStyles()((theme: any) => ({
  bannedPatient: {
    textDecoration: 'line-through',
  },
  incompatiblePatient: {
    color: theme.palette.warning.main,
  },
}));

export const useLoadAnimation = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;
    (function loaderScript() {
      const colors = [
        '#38B2AC',
        '#4FD1C5',
        '#81E6D9',
        '#4299E1',
        '#63B3ED',
        '#90CDF4',
        '#667EEA',
        '#7F9CF5',
        '#A3BFFA',
        '#9F7AEA',
        '#B794F4',
        '#D6BCFA',
        '#ED64A6',
        '#F687B3',
        '#FBB6CE',
        '#F56565',
        '#FC8181',
        '#FEB2B2',
        '#ED8936',
        '#F6AD55',
        '#FBD38D',
        '#ECC94B',
        '#F6E05E',
        '#FAF089',
        '#48BB78',
        '#68D391',
        '#9AE6B4',
        '#08A6A0',
        '#fb3b7c',
      ];

      const numBalls = 25;
      const balls: HTMLDivElement[] = [];
      const container = document.getElementById('loadercontainer');
      if (!container) return;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < numBalls; i++) {
        const ball = document.createElement('div');
        ball.classList.add('ball');
        ball.style.background =
          colors[Math.floor(Math.random() * colors.length)];
        ball.style.left = `${Math.floor(Math.random() * 100)}vw`;
        ball.style.top = `${Math.floor(Math.random() * 100)}vh`;
        ball.style.transform = `scale(${Math.random()})`;
        ball.style.width = `${Math.random()}em`;
        ball.style.height = ball.style.width;

        balls.push(ball);
        container.append(ball);
      }

      // Keyframes
      balls.forEach((el, i) => {
        const to = {
          x: Math.random() * (i % 2 === 0 ? -11 : 11),
          y: Math.random() * 12,
        };

        el.animate(
          [
            { transform: 'translate(0, 0)' },
            { transform: `translate(${to.x}rem, ${to.y}rem)` },
          ],
          {
            duration: (Math.random() + 1) * 2000,
            direction: 'alternate',
            fill: 'both',
            iterations: Infinity,
            easing: 'ease-in-out',
          },
        );
      });
    })();
  }, []);
};

export const useColorsCenters = () => {
  const theme = useTheme();
  const centersColors = [
    theme.palette.blue[300],
    theme.palette.main.main,
    theme.palette.purple[300],
    theme.palette.pink[300],
    theme.palette.orange[300],
    theme.palette.yellow[300],
  ];
  return centersColors;
};
