import { actionsGeneratorV2 } from '@docavenue/core';

import { PRO } from '../constants';

export type AuthData = {
  token?: string;
  tokenHash?: string;
  refresh?: string;
  uaaToken?: string;
  operatorId?: string;
  tlsFirstName?: string;
  tlsLastName?: string;
  securityCode?: string;
  type: typeof PRO;
  applicationType: string;
  maskedUsername?: string;
  oneTimePINCreationDate?: string;
};

const authenticationActions = actionsGeneratorV2({
  resource: 'authentication',
  chunkUrlResource: 'login',
  scope: 'auth',
});

const { create, getOne } = authenticationActions;

const createUAA = (params: any, options: any = {}) =>
  create(params, {
    chunkResource: 'uaa',
    ...options,
  });

const createMFA = (params: any, options: any = {}) =>
  create(params, {
    chunkUrlResource: 'mfa',
    ...options,
  });

const resendOneTimePIN = (mfaToken: string) =>
  create(
    {},
    {
      chunkUrlResource: `mfa/${mfaToken}/resend`,
      isListDst: false,
    },
  );

const getOneTimePINDetails = (mfaToken: string) =>
  getOne(mfaToken, {
    chunkUrlResource: 'mfa',
  });

const getSSOAuthentication = (token: string) =>
  getOne(token, {
    chunkUrlResource: 'sso/users',
  });

export default {
  ...authenticationActions,
  createUAA,
  createMFA,
  resendOneTimePIN,
  getOneTimePINDetails,
  getSSOAuthentication,
};
