import React, { useMemo } from 'react';
import { FieldProps } from 'formik';

import DebouncedTextField from './DebouncedTextField';
import TextFieldController from './TextFieldController';

type Props = FieldProps<string | number> &
  React.ComponentProps<typeof TextFieldController>;

const TextAreaController = (props: Props) => {
  const {
    rows = 7,
    multiline = true,
    maxRows,
    minRows,
    field,
    form,
    ...rest
  } = props;

  const rowsProperties = useMemo(() => {
    const result: { [key: string]: string | number } = {};

    if (typeof maxRows !== 'undefined') {
      result.maxRows = maxRows;
    }

    if (typeof minRows !== 'undefined') {
      result.minRows = minRows;
    }

    if (typeof maxRows === 'undefined' && typeof minRows === 'undefined') {
      result.rows = rows;
    }
    return result;
  }, [rows, maxRows, minRows]);

  return (
    <TextFieldController
      {...rowsProperties}
      multiline={multiline}
      InputComponent={DebouncedTextField as React.FunctionComponent<{}>}
      field={field}
      form={form}
      {...rest}
    />
  );
};

TextAreaController.displayName = 'TextAreaController';

export default TextAreaController;
