import React from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';

import { useDatacyName } from '@docavenue/chat';

import { useTranslation } from '../../../i18n';
import { notificationCentersActions } from '../../../src/actions';
import { NotificationsCenter } from '../../../types/pro.types';

const useStyles = makeStyles<{ lightText: boolean }>()(
  (theme: any, { lightText }) => ({
    notificationWrapperRoot: {
      // Common to NotificationFactu & NotificationAppointment
      display: 'grid',
      gridTemplateAreas: `'date info close'`,
      gridTemplateColumns: `${theme.spacing(8)} 270px ${theme.spacing(7)}`,
      padding: `${theme.spacing(2)} 0`,
      cursor: 'pointer',

      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },

      '& .notificationDate': {
        textTransform: 'capitalize',
      },

      '& .patientText': {
        color: theme.palette.grey['500'],
        ...theme.ellipsis,
        display: 'flex',
        alignItems: 'center',
        '& svg': {
          height: theme.spacing(3),
          width: theme.spacing(3),
        },
      },

      '& .internetIcon': {
        marginLeft: theme.spacing(1),
        '& div': {
          display: 'inline-block',
        },
      },

      '& .notificationTitle': {
        color: 'inherit',
        marginBottom: theme.spacing(1),
      },

      // Specific to notificationAppointmentView
      '&.notificationAppointmentViewRoot': {
        '& .updateArrowIcon': {
          color: 'inherit',
          height: 15,
        },
        '& .modificationDate': {
          display: 'flex',
          alignItems: 'center',
        },
      },

      // Specific to notificationFacturationView
      '&.notificationFacturationRoot': {
        '& .link': {
          ...theme.typography.link,
          fontSize: theme.size.font.sm,
        },
      },

      // Specific to notificationProductView
      '&.notificationProductRoot': {
        cursor: 'default',
        '& .notificationText': {
          color: 'inherit',
        },
        '& .link': {
          ...theme.typography.link,
          fontSize: theme.size.font.sm,
          color: theme.palette.common.white,
          textDecoration: 'underline',
          '&:hover': {
            cursor: 'pointer',
            color: theme.palette.grey[300],
          },
        },
      },
    },
    notificationInfo: {
      gridArea: 'info',
    },

    receiveDate: {
      gridArea: 'date',
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.size.font.xs,
      color: lightText ? theme.palette.common.white : theme.palette.grey[500],
      '& .badge': {
        backgroundColor: theme.palette.primary.main,
        width: 5,
        height: 5,
        borderRadius: '50%',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        '&.transparent': {
          backgroundColor: 'transparent',
        },
      },
    },
    closeButton: {
      gridArea: 'close',
      padding: theme.spacing(3),
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: lightText ? theme.palette.common.white : theme.palette.grey[500],
      '&:hover svg': {
        transition: 'all 0.2s ease',
        transform: 'scale(1.5)',
        transformOrigin: 'center',
      },
      '& svg': {
        maxHeight: 15,
        width: 15,
      },
    },
  }),
);
type NotificationWrapperProps = {
  notification: NotificationsCenter;
  onClick?: () => void;
  className?: string;
};

const getDateNotification = (date: Date, t: any) => {
  const momentDate = moment(date);
  const dateNow = moment();

  if (momentDate.isSame(dateNow, 'day')) {
    return momentDate.format('HH:mm');
  }
  const yesterday = dateNow.subtract(1, 'day');

  if (momentDate.isSame(yesterday, 'day')) {
    return t('notification_date_yesterday');
  }

  return momentDate.fromNow(true);
};

const NotificationWrapper: React.FunctionComponent<NotificationWrapperProps> = props => {
  const { notification, onClick, className, children } = props;
  const { classes, cx } = useStyles({
    lightText: notification.topic === 'PRODUCT',
  });
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const datacyName = useDatacyName('notification');

  const onCloseNotification = (idNotification: string) =>
    dispatch(notificationCentersActions.delete(idNotification));

  return (
    <div
      onClick={onClick}
      className={cx(classes.notificationWrapperRoot, className)}
      datacy={`${datacyName}-${notification.topic.toLowerCase()}_${notification.event.toLowerCase()}`}
    >
      {/* left */}
      <div className={classes.receiveDate}>
        <div className={cx('badge', notification.isRead && 'transparent')} />
        <span>{getDateNotification(notification.creationDate, t)}</span>
      </div>
      {/* center */}
      <div className={classes.notificationInfo}>{children}</div>
      {/* right */}

      <span
        className={classes.closeButton}
        onClick={e => {
          e.stopPropagation();
          onCloseNotification(notification.id);
        }}
        datacy={`${datacyName}-${notification.topic.toLowerCase()}_${notification.event.toLowerCase()}_close`}
      >
        <CloseIcon />
      </span>
    </div>
  );
};

export default NotificationWrapper;
