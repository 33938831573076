import { User } from '@maiia/model/generated/model/api-pro/api-pro';

/**
 * Discover Matomo tracking options
 * @https://developer.matomo.org/api-reference/tracking-javascript
 */
const setMatomoTrackingVariable = (
  key: string,
  value: string,
  index?: number,
) => {
  if (typeof window === 'undefined') {
    // eslint-disable-next-line no-console
    console.trace("Don't call setCustomMatomoTrackingVariable in server side");
    return;
  }
  (window as any)._paq = (window as any)._paq || [];
  const keyValue =
    typeof index === 'number' ? [key, index, value] : [key, value];

  (window as any)._paq.push(keyValue);
};

export const setMatomoTrackingUserId = (userId: string) => {
  setMatomoTrackingVariable('setUserId', userId);
};

const MATOMO_USER_ROLE_DIMENSION_INDEX = 1;
/**
 * Matomo custom dimensions are setted in matomo settings before being used
 * They are referred by an index (from 1 to 5)
 * For more information: @https://developer.matomo.org/guides/tracking-javascript-guide#custom-dimensions
 */
export const setMatomoTrackingUserRole = (
  role: NonNullable<User['role']['name']>,
) => {
  setMatomoTrackingVariable(
    'setCustomDimension',
    role,
    MATOMO_USER_ROLE_DIMENSION_INDEX,
  );
};
