/* eslint-disable import/no-cycle */
import React, {
  ReactChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { usePrevious, useSetState, useUpdateEffect } from 'react-use';
import camelCase from 'lodash/camelCase';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import { useCenterId, usePractitionerId } from '@/src/hooks/selectors';
import { useDeepEqualMemo } from '@/src/utils';
import { Dictionary } from '@/types/pro.types';

const PaginationContext = React.createContext<Dictionary<any>>({});

const defaultInitialState = { page: 0 };
const defaultDepsWithReset = {
  limit: 10,
  direction: 'desc',
};

type Props<D extends {}, R extends {}, E extends {}, I extends {}> = {
  children:
    | React.ReactNode
    | ReactChildren
    | ((value: Dictionary<any>) => React.ElementType);
  deps?: D;
  depsWithReset: R;
  extraState?: E;
  total?: number;
  initialState?: I;
  didStateChange?: (next: Dictionary<any>, prev?: Dictionary<any>) => boolean;
  onChange: (value: Dictionary<any>) => void;
};

export const usePaginationContext = () => {
  const context = useContext(PaginationContext);

  if (context === undefined) {
    throw new Error(
      'usePaginationContext must be used within a PaginationContextProvider',
    );
  }

  return context;
};

export const PaginationContextProvider = <
  D extends {} = {},
  R extends {} = {},
  E extends {} = {},
  I extends {} = {}
>(
  props: Props<D, R, E, I>,
) => {
  const {
    children,
    deps = {},
    depsWithReset = {},
    extraState = {},
    total,
    initialState = {},
    didStateChange = (prev, next) => !isEqual(prev, next),
    onChange,
  } = props;
  const centerId = useCenterId();
  const practitionerId = usePractitionerId();
  const globalDeps = {
    centerId,
    practitionerId,
  };
  const resetPage = omit(
    {
      ...defaultDepsWithReset,
      ...depsWithReset,
    },
    Object.keys(deps),
  );
  const initial = useMemo(
    () =>
      omit(
        {
          ...defaultInitialState,
          ...initialState,
        },
        Object.keys({ ...deps, ...resetPage }),
      ),
    [defaultInitialState, initialState, deps, resetPage],
  );
  const [paginationState, setPaginationState] = useSetState<Dictionary<any>>({
    ...initial,
    ...deps,
    ...resetPage,
    ...extraState,
    ...globalDeps,
  });
  const initialValues = useRef(paginationState);

  const getKeyState = (key: string) => paginationState[key];

  const setKeyState = (key: string) => (value: any) => {
    setPaginationState({ [key]: value });
  };

  const toStateArray = (obj: Dictionary<any>) =>
    Object.keys(obj).map(getKeyState);

  const setters = useMemo(
    () =>
      Object.keys(omit(paginationState, Object.keys(globalDeps))).reduce(
        (dict: Dictionary<any>, key: string) => ({
          ...dict,
          [camelCase(`set-${key}`)]: setKeyState(key),
        }),
        {},
      ),
    Object.keys(paginationState),
  );
  const value = useMemo(
    () => ({
      initialValues: initialValues.current,
      ...paginationState,
      ...globalDeps,
      ...setters,
      setState: setPaginationState,
      total,
    }),
    [
      initialValues,
      paginationState,
      setters,
      setPaginationState,
      total,
      practitionerId,
      centerId,
    ],
  );
  const previousState = usePrevious(paginationState);

  useEffect(() => {
    if (!previousState || didStateChange(paginationState, previousState)) {
      onChange(value);
    }
  }, useDeepEqualMemo([...toStateArray(initial), ...toStateArray(deps)]));

  useUpdateEffect(() => {
    setPaginationState(initial);
    if (!didStateChange(pick(paginationState, Object.keys(initial)), initial)) {
      onChange(value);
    }
  }, useDeepEqualMemo([...toStateArray(resetPage), practitionerId, centerId]));

  return (
    <PaginationContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </PaginationContext.Provider>
  );
};

export default PaginationContext;
