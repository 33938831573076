import {
  CLOSE_MINI_VIDEO_SESSION_ACTION,
  OPEN_MINI_VIDEO_SESSION_ACTION,
} from '../actions/miniVideoSession';

export type MiniVideoSessionState =
  | {
      open: true;
      videoSessionId: string;
    }
  | { open: false; videoSessionId: null };

type MiniVideoSessionActionType = {
  type: string;
  payload: string;
};

export const initialState: MiniVideoSessionState = {
  open: false,
  videoSessionId: null,
};

function miniVideoSession(
  state = initialState,
  action: MiniVideoSessionActionType,
) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_MINI_VIDEO_SESSION_ACTION:
      return { ...state, videoSessionId: payload, open: true };
    case CLOSE_MINI_VIDEO_SESSION_ACTION:
      return { ...state, videoSessionId: payload, open: false };
    default:
      return state;
  }
}

export default miniVideoSession;
