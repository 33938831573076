import React, {
  ComponentProps,
  ElementType,
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  RefAttributes,
} from 'react';
import get from 'lodash/get';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ gridObject }>()((_theme, { gridObject }) => ({
  root: {
    display: 'grid',
    ...gridObject,
  },
}));

type Props<T extends ElementType> = {
  className?: string;
  children: ReactNode[];
  component?: T; // TODO: abstract
  datacy?: string;
  gridkey?: string;
  id?: string;
} & ComponentProps<T>;

const GridWrapper = forwardRef(
  <T extends ElementType = 'div'>(props: Props<T>, ref: Ref<T>) => {
    const { component, className, children, ...rest } = props;
    const Component = component || 'div';

    const gridObject = children?.flat()?.reduce((obj: any, item) => {
      const gridkey = get(item, 'props.gridkey');
      if (gridkey) {
        // eslint-disable-next-line no-param-reassign
        obj[`& *[gridkey="${gridkey}"]`] = {
          'grid-area': gridkey,
        };
      }
      return obj;
    }, {});

    const { classes, cx } = useStyles({ gridObject });

    return (
      <Component className={cx(classes.root, className)} ref={ref} {...rest}>
        {children}
      </Component>
    );
  },
) as <T extends ElementType>(
  props: Props<T> & RefAttributes<T>,
) => ReactElement;

export default GridWrapper;
