/* eslint-disable import/no-cycle */
import {
  agendaSettingsDefaultSelectors,
  agendaSettingsSelectors,
  chatMessagesSelectors,
  chatRoomsSelectors,
  GenericSelector,
} from '@docavenue/chat';
import { selectorsGenerator } from '@docavenue/core';
import {
  CenterDTO,
  ProStatisticDTO,
  TreatmentGetDTO,
  User,
  WeekTemplateCycle,
} from '@maiia/model/generated/model/api-pro/api-pro';

import consultationReasons, {
  ConsultationReasonSelector,
} from './consultationReasonsSelectors';
import documents, { DocumentSelector } from './documentsSelectors';
import patients, { PatientSelector } from './patientsSelectors';
import practitioners, { PractitionerSelector } from './practitionersSelectors';
import statistics from './statisticsSelectors';
import substitutePractitioners from './substitutePractitionersSelectors';
import weeks, { WeeksSelector } from './weeksSelectors';
import weekTemplateCycles from './weekTemplateCyclesSelectors';

export type Selectors = {
  centersSelectors: GenericSelector<CenterDTO>;
  consultationReasonsSelectors: ConsultationReasonSelector;
  documentsSelectors: DocumentSelector;
  patientsSelectors: PatientSelector;
  practitionersSelectors: PractitionerSelector;
  treatmentsSelectors: GenericSelector<TreatmentGetDTO>;
  weeksSelectors: WeeksSelector;
  weekTemplateCyclesSelectors: GenericSelector<WeekTemplateCycle>;
  statisticsSelectors: GenericSelector<ProStatisticDTO>;
  substitutePractitionersSelectors: GenericSelector<User>;
};

const {
  centersSelectors,
  patientsSelectors,
  practitionersSelectors,
  consultationReasonsSelectors,
  documentsSelectors,
  weekTemplateCyclesSelectors,
  weeksSelectors,
  treatmentsSelectors,
  statisticsSelectors,
  substitutePractitionersSelectors,
}: Selectors = selectorsGenerator([
  patients,
  practitioners,
  { name: 'centers' },
  consultationReasons,
  documents,
  { name: 'treatments' },
  weeks,
  weekTemplateCycles,
  statistics,
  substitutePractitioners,
]);

// eslint-disable-next-line import/prefer-default-export
export {
  agendaSettingsDefaultSelectors,
  agendaSettingsSelectors,
  centersSelectors,
  chatMessagesSelectors,
  chatRoomsSelectors,
  consultationReasonsSelectors,
  documentsSelectors,
  patientsSelectors,
  practitionersSelectors,
  statisticsSelectors,
  substitutePractitionersSelectors,
  treatmentsSelectors,
  weeksSelectors,
  weekTemplateCyclesSelectors,
};
