import React, { ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DatacyContext, useDatacyName } from '@docavenue/chat';

import DialogAtoms from '../../atoms/Dialog';
import CardLayout from '../CardLayout';

import Paper, { WrappedPaperProps } from '@/components/atoms/Paper';

const useStyles = makeStyles<{ title: string }>()((_theme, { title }) => {
  return {
    root: {
      '&.visible .MuiPaper-root': {
        overflowY: 'visible',
      },
    },
    paper: {
      overflowY: 'auto',

      '& .MuiDialogContent-root': {
        overflowY: 'visible',
      },

      '@media only screen and (max-width: 600px)': {
        overflow: 'hidden',
        height: '100%',
        width: '100%',

        '& .MuiDialogContent-root > *': {
          padding: 0,
        },

        '& .MuiDialogContent-root:first-child': {
          paddingTop: 0,
        },
      },
      '& .MuiDialogContent-root:first-child': {
        paddingTop: title ? 20 : 0,
      },
    },
  };
});

export type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose?: (event: React.MouseEvent<Element, MouseEvent>) => void;
  overflowVisible?: boolean;
  title?: string | Function | React.ReactNode;
  titleActions?: ReactNode;
  buttons?: ReactNode;
  footer?: ReactNode;
  datacy?: string;
  severity?: 'info' | 'success' | 'warning' | 'alert';
  PaperProps?: WrappedPaperProps;
};

const Dialog = (props: Props) => {
  const {
    children,
    isOpen,
    onClose,
    title,
    titleActions,
    footer,
    buttons,
    datacy,
    severity,
    PaperProps,
    overflowVisible = false,
  } = props;
  const datacyName = useDatacyName(datacy);
  const { classes } = useStyles({
    ...(props as any),
  });
  return (
    <DatacyContext.Provider value={`${datacyName}_dialog`}>
      <DialogAtoms
        // @ts-ignore
        PaperComponent={Paper}
        PaperProps={PaperProps}
        onClose={onClose}
        open={isOpen}
        classes={classes}
        className={overflowVisible ? 'visible' : undefined}
      >
        <CardLayout
          onClose={onClose}
          title={title}
          titleActions={titleActions}
          footer={footer}
          buttons={buttons}
          severity={severity}
        >
          {children}
        </CardLayout>
      </DialogAtoms>
    </DatacyContext.Provider>
  );
};

export default Dialog;
