/* eslint-disable import/no-cycle */
import sortBy from 'lodash/sortBy';
import { createSelector } from 'reselect';

import { GenericSelector, InitialGenericSelector } from '@docavenue/chat';
import { generateInitialSelectors } from '@docavenue/core';
import { ConsultationReason } from '@maiia/model/generated/model/api-pro/api-pro';

import { RootState } from '../reducer/rootState';

const initialSelectors: InitialGenericSelector<ConsultationReason> = generateInitialSelectors(
  'consultationReasons',
);

export const sortConsultationReasonsByPosition = <T>(
  consultationReasons: T[],
): T[] => sortBy(consultationReasons, 'position');

export const getSortedConsultationReasonsByPosition = createSelector(
  state => state.consultationReasons.items,
  sortConsultationReasonsByPosition,
);

export const getSortedComplexFormConsultationReasons = createSelector(
  state => state.consultationReasonsComplexForm.items,
  sortConsultationReasonsByPosition,
);

// The list of the empty instructions.
export const getConsultationReasonsWithoutInstruction = createSelector(
  store => store.consultationReasons.items,
  consultationReasons => consultationReasons,
);

export type ConsultationReasonSelector = GenericSelector<ConsultationReason> & {
  getSortedConsultationReasonsByPosition: (
    state: RootState,
  ) => ConsultationReason[];
  getSortedComplexFormConsultationReasons: (
    state: RootState,
  ) => ConsultationReason[];
  getConsultationReasonsWithoutInstruction: (
    state: RootState,
  ) => ConsultationReason[];
};

const selectors: ConsultationReasonSelector = {
  name: 'consultationReasons',
  ...initialSelectors,
  getSortedConsultationReasonsByPosition,
  getSortedComplexFormConsultationReasons,
  getConsultationReasonsWithoutInstruction,
};

export default selectors;
