import { GenericSelector, InitialGenericSelector } from '@docavenue/chat';
import { generateInitialSelectors } from '@docavenue/core';
import { ProStatisticDTO } from '@maiia/model/generated/model/api-pro/api-pro';

const initialSelectors: InitialGenericSelector<ProStatisticDTO> = generateInitialSelectors(
  'statistics',
);

const selectors: GenericSelector<ProStatisticDTO> = {
  name: 'statistics',
  ...initialSelectors,
};

export default selectors;
