import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import Typography from '../../atoms/Typography';
import Dialog from '../Dialog';

const useStyles = makeStyles()((theme: any) => ({
  confirmAlert: {
    ...theme?.components?.rdvCard,
    '& > *': {
      padding: theme.spacing(2),
    },
  },
}));

const ConfirmAlert = forwardRef((_, ref) => {
  const [isOpen, setOpen] = useState(false);
  const handleRunRef = useRef<null | (() => void)>(null);
  const handleCancelRef = useRef<null | (() => void)>(null);
  const [resource, setResource] = useState({
    title: '',
    body: '',
    confirmText: null,
    additionalBodyMessage: null,
    warning: false,
    warningText: null,
    children: null,
  });
  const { t } = useTranslation();
  const { classes } = useStyles();
  useImperativeHandle(ref, () => ({
    runAfterConfirm(
      runCallback: () => void,
      newResource: any,
      cancelCallback: () => void,
    ) {
      handleRunRef.current = runCallback;
      handleCancelRef.current = cancelCallback;
      setOpen(true);
      setResource(newResource);
    },
  }));

  const resetCallback = () => {
    handleRunRef.current = null;
    handleCancelRef.current = null;
  };

  const handleCancel = () => {
    setOpen(false);
    if (handleCancelRef.current) handleCancelRef.current?.();
    resetCallback();
  };

  const handleRun = () => {
    setOpen(false);
    if (handleRunRef.current) handleRunRef.current?.();
    resetCallback();
  };

  return (
    <Dialog
      datacy="confirm_alert"
      title={resource.title}
      onClose={handleCancel}
      isOpen={isOpen}
      buttons={
        <>
          <Button
            key="cancel-button"
            datacy="cancel_btn"
            variant="text"
            color="secondary"
            onClick={handleCancel}
          >
            {t('common:cancel')}
          </Button>
          <Button
            key="confirm-button"
            datacy="confirm_btn"
            variant="contained"
            color="primary"
            onClick={handleRun}
          >
            {resource.confirmText ?? t('common:confirm')}
          </Button>
        </>
      }
    >
      <div className={classes.confirmAlert}>
        <Typography variant="body">{resource.body}</Typography>
        {resource.additionalBodyMessage && (
          <Typography variant="body">
            {resource.additionalBodyMessage}
          </Typography>
        )}
        {resource.warning && (
          <Typography variant="body" color="red.600">
            {resource.warningText
              ? resource.warningText
              : t('confirmalert_remove_message')}
          </Typography>
        )}
      </div>
    </Dialog>
  );
});

export default ConfirmAlert;
