import { ElementType } from 'react';
import { compose } from 'redux';

import { appWithTranslation } from '../i18n';
import { wrapper } from './store';

// todo remove from storybook (the storybook is no more in use)
const appComposeWithTranslation = () => (App: ElementType) =>
  compose(
    wrapper.withRedux,
    appWithTranslation,
    // @ts-ignore
  )(App);

export default appComposeWithTranslation;
