export const SET_OFFSET = 'SET_OFFSET';

const setOffset = ({ offset }: { offset: number }) => ({
  type: SET_OFFSET,
  payload: { offset },
});

const offsetActions = {
  setOffset,
};

export default offsetActions;
