import React from 'react';
import { makeStyles } from 'tss-react/mui';


import SvgIcon from '../atoms/SvgIcon';
import { IconProps } from './icons';

type Props = IconProps & {
  round?: boolean;
  className?: string;
};

const useStyles = makeStyles()((theme: any) => ({
  round: {
    backgroundColor: theme.palette.accent.main,
    borderRadius: '50%',
    padding: 3,
    color: theme.palette.common.white,
  },
}));

const FusionIcon = (props: Props) => {
  const { round, className } = props;
  const { classes, cx } = useStyles();
  const viewBox = round ? '-2 -1 26 26' : '0 -1 24 24';
  return (
    <SvgIcon
      viewBox={viewBox}
      className={cx(className && className, round && classes.round)}
    >
      <path
        d="M0 4.125C0 3.50156 0.501562 3 1.125 3H4.5H6.75937C7.57969 3 8.35312 3.38437 8.84531 4.03594L13.9453 10.725C14.0156 10.8188 14.1281 10.875 14.2453 10.875H20.1609L18.3328 9.04688C17.8922 8.60625 17.8922 7.89375 18.3328 7.45781C18.7734 7.02188 19.4859 7.01719 19.9219 7.45781L23.6719 11.2078C24.1125 11.6484 24.1125 12.3609 23.6719 12.7969L19.9219 16.5469C19.4813 16.9875 18.7688 16.9875 18.3328 16.5469C17.8969 16.1062 17.8922 15.3938 18.3328 14.9578L20.1609 13.1297H14.2406C14.1234 13.1297 14.0109 13.1859 13.9406 13.2797L8.84531 19.9641C8.34844 20.6156 7.575 21 6.75937 21H4.5H1.125C0.501562 21 0 20.4984 0 19.875C0 19.2516 0.501562 18.75 1.125 18.75H4.5H6.75937C6.87656 18.75 6.98906 18.6937 7.05938 18.6L12.0844 12L7.05469 5.4C6.98438 5.30625 6.87188 5.25 6.75469 5.25H4.5H1.125C0.501562 5.25 0 4.74844 0 4.125Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default FusionIcon;
