import { SET_OFFSET } from '../actions/offset';

export type Offset = {
  offset: number;
};

export const initialState: Offset = {
  offset: 0,
};

function offset(
  state = initialState,
  action: { type: string; payload: Offset },
) {
  switch (action.type) {
    case SET_OFFSET: {
      return { ...state, offset: action.payload.offset };
    }
    default:
      return state;
  }
}

export default offset;
