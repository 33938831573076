import React, { forwardRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  Avatar as MaterialAvatar,
  AvatarProps,
  Skeleton,
  SkeletonProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  hide: {
    display: 'none',
  },
}));

export type Props = {
  src?: string;
  srcFailing?: string;
  className?: string;
  noSkeleton?: boolean;
} & AvatarProps &
  SkeletonProps;

// TODO: on error set isLoaded to false
const Avatar = forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    const {
      src,
      srcFailing = '/static/images/default-doctor.png',
      className,
      noSkeleton = false,
      ...rest
    } = props;
    // if there is no src, it's probably an image
    const [isLoaded, setIsLoaded] = useState(!src || noSkeleton);
    const { classes, cx } = useStyles();
    const [isError, setIsError] = useState(false);
    useUpdateEffect(() => {
      setIsLoaded(false);
      setIsError(false);
    }, [src]);

    return (
      <>
        {!isLoaded && (
          <Skeleton variant="circular" className={className} {...rest} />
        )}
        <MaterialAvatar
          component="div"
          onLoad={() => {
            if (isLoaded) return;
            setIsError(false);
            setIsLoaded(true);
          }}
          onError={() => {
            if (isLoaded) return;
            setIsError(true);
            setIsLoaded(true);
          }}
          {...rest}
          src={isError ? srcFailing : src}
          ref={ref}
          className={cx(className, {
            [classes.hide]: !isLoaded,
          })}
        />
      </>
    );
  },
);

Avatar.displayName = 'Avatar';

export default Avatar;
