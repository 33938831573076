import { actionsGeneratorV2 } from '@docavenue/core';

const teleconsultationActions = actionsGeneratorV2({
  resource: 'teleconsultation',
  chunkUrlResource: 'tlc',
});

const { getOneWithCompositeKey } = teleconsultationActions;

const autosuggestion = (
  params: { practitionerId: string | null; centerId: string | null },
  options: Object = {},
) =>
  getOneWithCompositeKey(
    {},
    {
      chunkUrlResource: 'tlc',
      chunkResource: `autosuggestion/${params.centerId}/${params.practitionerId}`,
      ...(options || {}),
    },
  );

export default {
  ...teleconsultationActions,
  autosuggestion,
};
