import React from 'react';

import { TextField, TextFieldController } from '../../atoms/Fields';

type Props = React.ComponentProps<typeof TextFieldController>;

const InputComponent = (props: Props) => (
  <TextFieldController InputComponent={TextField} {...props} />
);

InputComponent.displayName = 'InputComponent';

export default InputComponent;
