import React from 'react';
import { makeStyles } from 'tss-react/mui';
import get from 'lodash/get';

import { useIsFreemium } from '@docavenue/chat';
import {
  Patient,
  ProAgendaSettingsDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';


import {
  getPractitionerDisplayName,
  getUserAvatarUrl,
} from '../../../src/utils';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import HeaderSearchItemView from '../../molecules/HeaderSearchItemView';
import { getPractitionerSpecialityAndCenterByAgendaSettings } from '../../utils/tools';
import LgcConnect from '../LgcConnect';
import HeaderPatientLabel from './HeaderPatientLabel';
import HeaderPatientSubLabel from './HeaderPatientSubLabel';

import { GALabels, GALabelsType } from '@/src/constants/tracking';

const FaFileIcon = () => (
  <FontAwesomeIcon name="file:regular" className="h-5 w-5" />
);

const FaPatient = () => (
  <FontAwesomeIcon name="user:regular" className="h-5 w-5" />
);

const FaTodayIcon = () => (
  <FontAwesomeIcon name="calendar-day:regular" className="h-5 w-5" />
);

const useStyles = makeStyles()((theme: any) => ({
  lgcItem: {
    fontSize: theme.size.font.xxs,
    fontWeight: 'bold',
  },
}));

type Props = {
  searchTerms: Array<string>;
  item: Patient | ProAgendaSettingsDTO;
  selected: boolean;
  searchTerm?: string;
  redirectToEditPatient: (patientId: string, gaLabel: GALabelsType) => void;
  redirectToAgenda: (
    centerId: string,
    practitionerId: string,
    gaLabel: GALabelsType,
  ) => void;
};

const HeaderSearchItem = (props: Props) => {
  const {
    item,
    selected,
    searchTerms,
    searchTerm,
    redirectToEditPatient,
    redirectToAgenda,
    ...rest
  } = props;
  const { classes } = useStyles();
  const isFreemium = useIsFreemium();

  switch (item.objectType) {
    case 'patient':
      return (
        <HeaderSearchItemView
          hideAvatar
          LeftIcon={FaPatient}
          primary={
            <HeaderPatientLabel
              searchTerms={searchTerms}
              patient={item as Patient}
            />
          }
          secondary={
            <HeaderPatientSubLabel
              searchTerms={searchTerms}
              mobilePhoneNumber={(item as Patient).mobilePhoneNumber}
              healthId={(item as Patient).healthId}
            />
          }
          selected={selected}
          {...rest}
          RightIcon={FaFileIcon}
          rightAction={
            isFreemium
              ? undefined
              : event => {
                  event.preventDefault();
                  event.stopPropagation();
                  redirectToEditPatient(
                    get(item, 'id'),
                    GALabels.GoPatientFile,
                  );
                }
          }
          rightTooltip="header_search_item_view__right_action_tooltip__patient"
          datacy={`patient_name_${(item as Patient).firstName?.toLowerCase()}_${(item as Patient).lastName?.toLowerCase()}`}
          LgcComponent={
            (item as Patient).syncData && (
              <LgcConnect
                patient={item as Patient}
                color="#fb3b7c"
                className={classes.lgcItem}
              />
            )
          }
        />
      );
    case 'agendaSettings': {
      const avatarUrl = getUserAvatarUrl(
        (item as ProAgendaSettingsDTO)?.practitioner,
        item as ProAgendaSettingsDTO,
      )!;
      return (
        <HeaderSearchItemView
          user={get(item, 'practitioner')}
          avatar={avatarUrl}
          primary={getPractitionerDisplayName(get(item, 'practitioner'))}
          secondary={getPractitionerSpecialityAndCenterByAgendaSettings(
            item as ProAgendaSettingsDTO,
          )}
          selected={selected}
          rightAction={event => {
            event.preventDefault();
            event.stopPropagation();
            redirectToAgenda(
              get(item, 'center.id'),
              get(item, 'practitioner.id'),
              GALabels.GoPractitionerAgenda,
            );
          }}
          RightIcon={FaTodayIcon}
          rightTooltip="header_search_item_view__right_action_tooltip__pract"
          {...rest}
        />
      );
    }
    case 'center':
      return (
        <HeaderSearchItemView
          avatar={get(item, 'officeInformation.mainPicture.s3Url')}
          primary={get(item, 'name')}
          selected={selected}
          rightAction={event => {
            event.preventDefault();
            event.stopPropagation();
            redirectToAgenda(
              get(item, 'id'),
              get(item, 'practitioners.0.id'),
              GALabels.GoCenterAgenda,
            );
          }}
          RightIcon={FaTodayIcon}
          rightTooltip="header_search_item_view__right_action_tooltip__center"
          {...rest}
        />
      );
    default:
      return null;
  }
};

export default HeaderSearchItem;
