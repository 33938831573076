const Tabs = Object.freeze({
  ACTIVITY: 'activity',
  AGENDA: 'agenda',
  INSTRUCTIONS: 'instructions',
  INSTRUCTIONS_BORNE: 'instructions-borne',
  MOTIFS: 'motifs',
  NOTIFICATIONS: 'notifications',
  PERMISSIONS: 'permissions',
  PROFILE: 'profile',
  RESOURCES: 'resources',
  SECRETARIAT_INSTRUCTIONS: 'secretariat-instructions',
  TIMELINE: 'timeline',
  DOCUMENTS: 'documents',
});

export default Tabs;
