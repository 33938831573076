import React from 'react';
import Favicon from 'react-favicon';
import { useSelector } from 'react-redux';
import { DefaultSeo } from 'next-seo';
import find from 'lodash/find';

import { useGet as useGetUnreadMessages } from '@maiia/model/generated/model/api-pro/hooks/proChatMessagesCountUnreadMessages';

import { useTranslation } from '../../../../../../i18n';

import { PENDING } from '@/src/constants';
import {
  isConnectOnboardingUser,
  isLGCOnboardingUser,
} from '@/src/hoc/securedPageFunctions';

// CONTAINER TO SHOW FAVICON IS HERE

const SeoContainer = () => {
  const { t } = useTranslation();

  const user = useSelector(state => state?.users.item);
  const isOnBoarding =
    isConnectOnboardingUser(user) || isLGCOnboardingUser(user);

  const { data: { count: unreadMessageCount = 0 } = {} } = useGetUnreadMessages(
    {},
    { enabled: !!user?.id && !isOnBoarding },
  );

  const hasTlcIncomingCall = useSelector(state =>
    find(state.videoSessions.items, { videoSessionStatus: PENDING }),
  );

  const title = unreadMessageCount
    ? t('common:title_new_message', {
        count: unreadMessageCount,
      })
    : t('seo-title_default');

  const urlFavicon =
    unreadMessageCount || hasTlcIncomingCall
      ? '/favicon-notifications.ico'
      : '/favicon.ico';

  return (
    <>
      <Favicon url={urlFavicon} />
      <DefaultSeo
        title={title}
        description={t('common:seo-description_default')}
      />
    </>
  );
};

export default SeoContainer;
