import { SET_OPEN_LOADER_MAIIA } from '../actions/loaderMaiia';

export type LoaderMaiiaState = {
  isOpen: boolean;
};

export const initialState: LoaderMaiiaState = {
  isOpen: false,
};

function loaderMaiia(
  state = initialState,
  action: { type: string; payload: boolean },
) {
  const { type, payload } = action;
  switch (type) {
    case SET_OPEN_LOADER_MAIIA: {
      return { ...state, isOpen: payload };
    }

    default:
      return state;
  }
}

export default loaderMaiia;
