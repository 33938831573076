export const OPEN_MINI_VIDEO_SESSION_ACTION = 'OPEN_MINI_VIDEO_SESSION';
export const CLOSE_MINI_VIDEO_SESSION_ACTION = 'CLOSE_MINI_VIDEO_SESSION';

export const openMiniVideoSession = (videoSessionId: string) => ({
  type: OPEN_MINI_VIDEO_SESSION_ACTION,
  payload: videoSessionId,
});

export const closeMiniVideoSession = () => ({
  type: OPEN_MINI_VIDEO_SESSION_ACTION,
  payload: null,
});
