import {
  crudInitialDefaultState as initialStateCrudState,
  GET_LIST_SUCCESS,
  reducersGenerator,
} from '@docavenue/core';

export const initialState = {
  ...initialStateCrudState,
  totalUnseen: 0,
  totalSeen: 0,
};

const name = 'documentsHistory';

const surchargeReducer = () => {
  const obj = {};

  [GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach((type: string) => {
    obj[type] = (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    });
  });

  return obj;
};

const documentsHistory = reducersGenerator({
  name,
  surchargeReducer,
});

export default documentsHistory;
