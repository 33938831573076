import { GenericSelector, InitialGenericSelector } from '@docavenue/chat';
import { generateInitialSelectors } from '@docavenue/core';
import { WeekTemplateCycle } from '@maiia/model/generated/model/api-pro/api-pro';

const initialSelectors: InitialGenericSelector<WeekTemplateCycle> = generateInitialSelectors(
  'weekTemplateCycles',
);

const selectors: GenericSelector<WeekTemplateCycle> = {
  name: 'weekTemplateCycles',
  ...initialSelectors,
};

export default selectors;
