import React from 'react';

type ConfirmAlert = {
  runAfterConfirm: (
    runCallback: () => void,
    resource?: any,
    cancelCallback?: () => void,
  ) => void;
};

const ConfirmAlertContext = React.createContext<React.RefObject<
  ConfirmAlert
> | null>(null);

export default ConfirmAlertContext;
