import { createContext } from 'react';

export type AgendaInterface = {
  centerId?: string;
  practitionerId: string;
  timeSlotResourceId?: string;
  indexAgenda: number;
};

const AgendaContext = createContext<AgendaInterface | null>(null);

export default AgendaContext;
