import { Patient } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_PATIENTS,
} from '../actions/mergePatients';

export type MergePatientsState = {
  items: Patient[];
  isMergeModalOpen: boolean;
};

export const initialState: MergePatientsState = {
  items: [],
  isMergeModalOpen: false,
};

const mergePatients = (
  state = initialState,
  action: { type: string; payload },
): MergePatientsState => {
  switch (action.type) {
    case SET_PATIENTS:
      return {
        ...state,
        items: action.payload.items,
      };
    case OPEN_MODAL:
      return {
        ...state,
        isMergeModalOpen: true,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        isMergeModalOpen: false,
      };

    default:
      return state;
  }
};

export default mergePatients;
