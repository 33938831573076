import getConfig from 'next/config';

import { get as _get } from '@docavenue/core';

const { publicRuntimeConfig = {} } = getConfig() || {};

export default {
  get: (key: string, defaultValue?: any) =>
    _get(publicRuntimeConfig, key, defaultValue),
};
