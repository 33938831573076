import React, { useMemo } from 'react';
import { Skeleton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

// eslint-disable-next-line unused-imports/no-unused-vars-ts
const useStyles = makeStyles<{ isOpen: true }>()((theme: any, _props) => ({
  skeleton: {
    margin: theme.spacing(3),

    '&.skel0': { width: '80%' },
    '&.skel1': { width: '70%' },
    '&.skel2': { width: '50%' },
    '&.skel3': { width: '35%' },
  },
}));

export const ListSkeletonComponent = () => {
  const { classes, cx } = useStyles({ isOpen: true });

  const renderItems = useMemo(() => {
    const result: any[] = [];
    for (let i = 0; i < 10; i++) {
      result.push(
        <Skeleton
          variant="rectangular"
          className={cx(
            classes.skeleton,
            `skel${Math.floor(Math.random() * 4)}`,
          )}
          key={`skeleton-loading-${i}`}
        />,
      );
    }

    return result;
  }, []);

  return <>{renderItems}</>;
};

export default ListSkeletonComponent;
