import React from 'react';
import { cn } from '@kiiwi/ui';

import SvgIcon from '../atoms/SvgIcon';
import { IconProps } from './icons';

type Props = {
  className?: string;
  colored?: boolean;
} & IconProps;

const BrandNameCegedim = ({ className, colored, ...rest }: Props) => {
  return (
    <SvgIcon
      viewBox="0 0 617 282"
      {...rest}
      className={cn('logo', colored ? 'colored' : 'fill-white', className)}
    >
      <g id="Calque_2" data-name="Calque 2">
        <g id="Logos">
          <g
            id="_1.1---Maiia-couleur-large"
            data-name="1.1---Maiia-couleur-large"
          >
            <g id="Logo-Maiia-couleur">
              <polygon
                id="Filet"
                className={cn(colored && 'fill-[#aec7d0]')}
                points="474.17 256.15 474.16 253.38 539.21 253.17 539.22 255.94 474.17 256.15"
              />
              <path
                id="santé"
                className={cn(colored && 'fill-[#08A6A0] opacity-[0.65]')}
                d="M419,232.73v7.41h7.91v5.27H419v13.3c0,3.39,1.46,4.72,4.1,4.93a9,9,0,0,0,4.78-.7l.28,5a16.45,16.45,0,0,1-5.79,1.15c-5.61.05-10.05-3.29-10.05-9.32V245.41h-4.39v-5.27h4.39V234Zm-64.34,7.2a10.76,10.76,0,0,1,8.27,3.6v-3.39h6.66v28.72h-6.66v-3.39a11.48,11.48,0,0,1-8.27,3.6c-7.49,0-14.91-5.46-14.91-14.59C339.76,245,347.18,239.93,354.67,239.93Zm-30.67,0c4.41,0,8.56,1.51,11.38,5.06l-4.1,3.13a8.84,8.84,0,0,0-6.68-3.18c-2.43,0-5.22,1-5.22,3s2.79,3.16,6.05,4.1c6.89,1.93,10.7,4,10.76,9.18s-5.77,7.77-12,7.88a15.81,15.81,0,0,1-12.61-6l4.23-2.92a11.08,11.08,0,0,0,8.46,4c2.48,0,5.27-1.07,5.27-3s-3.16-2.95-6.89-4.13c-5.14-1.64-10-4.4-9.89-9.13C312.9,242.82,318,239.93,324,239.93Zm122.63,0c9.21,0,15.92,6.86,14.88,16.88H438.67a8.31,8.31,0,0,0,8,6.31,8.64,8.64,0,0,0,7.44-4.36l5.87,3.05c-2.64,4.73-8.09,7.23-13.31,7.23-7.49,0-14.91-5.11-14.91-14.56S439.14,239.93,446.63,239.93Zm-53.28,0c5.82.39,9.5,5.16,9.5,12.08v16.85H396.2v-15.5c0-5.14-1.65-7.33-5.88-7.33a7.51,7.51,0,0,0-7.41,6.16v16.67h-6.66V240.14h6.66v4.33C384.5,241.65,388.81,239.67,393.35,239.93Zm-38.68,5.92a8.65,8.65,0,1,0,8.25,8.63A8.4,8.4,0,0,0,354.67,245.85Zm92,0a8.06,8.06,0,0,0-7.7,5.4h15.56C454,247.91,450.05,245.85,446.63,245.85Zm6.51-17,.56,1.07c.72,1.34.33,2-1,2.63l-9.09,4.16c-1.13.55-1.89.64-2.36-.16l-.37-.55c-.58-.95-.05-1.7,1-2.26l8.46-5.17C451.78,227.69,452.62,228,453.14,228.86Z"
              />
              <path
                id="cegedim"
                className={cn(colored && 'fill-[#08A6A0]')}
                d="M157.88,239.93a10.73,10.73,0,0,1,8.27,3.6v-3.39h6.66v27.8c0,8.95-7.41,14.06-14.9,14.06a14.81,14.81,0,0,1-13.06-7.22l5.88-3a8.19,8.19,0,0,0,15.42-3.79v-2.47a11.44,11.44,0,0,1-8.27,3.6c-7.49,0-14.9-5.46-14.9-14.59C143,245,150.39,239.93,157.88,239.93Zm83.35-11.19v40.12h-6.66v-3.39a11.48,11.48,0,0,1-8.27,3.6c-7.49,0-14.91-5.46-14.91-14.59,0-9.44,7.42-14.55,14.91-14.55a10.76,10.76,0,0,1,8.27,3.6V228.74ZM92.88,239.93a14.78,14.78,0,0,1,13.05,7.22l-5.87,3.06a8.23,8.23,0,0,0-7.18-4.36,8.64,8.64,0,0,0,0,17.27,8.23,8.23,0,0,0,7.18-4.36l5.87,3.05A14.77,14.77,0,0,1,92.88,269c-7.49,0-14.9-5.11-14.9-14.56S85.39,239.93,92.88,239.93Zm31.54,0c9.21,0,15.92,6.86,14.87,16.88H116.45a8.33,8.33,0,0,0,8,6.31,8.65,8.65,0,0,0,7.44-4.36l5.87,3.05c-2.64,4.73-8.09,7.23-13.31,7.23-7.5,0-14.91-5.11-14.91-14.56S116.92,239.93,124.42,239.93Zm68.39,0c9.21,0,15.92,6.86,14.88,16.88H184.85a8.31,8.31,0,0,0,8,6.31,8.65,8.65,0,0,0,7.44-4.36l5.87,3.05c-2.64,4.73-8.09,7.23-13.31,7.23-7.49,0-14.91-5.11-14.91-14.56S185.32,239.93,192.81,239.93Zm62.25.21v28.72h-6.65V240.14Zm41.66-.21c5.59.39,9.27,4.25,9.27,10.67v18.26h-6.66v-15.6c0-4.64-1.67-7-5.35-7s-6,2.46-6.52,5.93v16.64H280.8v-15.6c0-4.75-1.64-7-5.35-7s-5.9,2.46-6.53,5.93v16.64h-6.65V240.14h6.65v4.41c1.62-3,4.47-4.91,9.27-4.62,3.86.26,6.81,2.14,8.25,5.4C287.88,242.07,291.35,239.56,296.72,239.93Zm-138.84,5.92a8.65,8.65,0,1,0,8.25,8.63A8.4,8.4,0,0,0,157.88,245.85Zm68.42,0a8.65,8.65,0,1,0,8.25,8.63A8.4,8.4,0,0,0,226.3,245.85Zm-101.88,0a8.06,8.06,0,0,0-7.7,5.4h15.55C131.83,247.91,127.84,245.85,124.42,245.85Zm68.39,0a8.06,8.06,0,0,0-7.7,5.4h15.55C200.22,247.91,196.23,245.85,192.81,245.85Zm58.94-17.48a3.57,3.57,0,1,1-3.89,3.55A3.74,3.74,0,0,1,251.75,228.37Z"
              />
              <polygon
                id="Filet-2"
                data-name="Filet"
                className={cn(colored && 'fill-[#aec7d0]')}
                points="65.49 256.15 0 255.94 0.01 253.17 65.5 253.38 65.49 256.15"
              />
              <path
                id="Forme"
                className={cn(colored && 'fill-[#08A6A0] opacity-[0.65]')}
                d="M612.14,62.93A4.87,4.87,0,0,1,617,67.79v42a4.87,4.87,0,0,1-4.86,4.86h-42a4.86,4.86,0,0,1-4.86-4.86v-42a4.86,4.86,0,0,1,4.86-4.86ZM549.17,0A4.85,4.85,0,0,1,554,4.85v42a4.85,4.85,0,0,1-4.85,4.85h-42a4.86,4.86,0,0,1-4.86-4.85v-42A4.86,4.86,0,0,1,507.15,0Z"
              />
              <path
                id="Forme-2"
                data-name="Forme"
                className={cn(colored && 'fill-[#08A6A0]')}
                d="M570.13,0h42A4.85,4.85,0,0,1,617,4.86v42a4.85,4.85,0,0,1-4.86,4.85h-42a4.85,4.85,0,0,1-4.86-4.85v-42A4.85,4.85,0,0,1,570.13,0"
              />
              <path
                id="Maiia"
                className={cn(colored && 'fill-[#10748E]')}
                d="M244.24,78.57c15.49,0,31.19,7.24,39.86,20.63V79.34h21.42v120H284.1V179.64a49.12,49.12,0,0,1-39.86,20.52c-30.42,0-61.27-22.5-61.27-60.8C183,100.08,213.82,78.57,244.24,78.57Zm232.14,0c15.48,0,31.18,7.24,39.86,20.63V79.34h21.41v120H516.24V179.64a49.15,49.15,0,0,1-39.86,20.52c-30.42,0-61.28-22.5-61.28-60.8C415.1,100.08,446,78.57,476.38,78.57Zm-125.28.77v120H329.68v-120Zm47.65,0v120H377.34v-120ZM24,41l58.31,90.31h3L143.44,41h22.63V199.39H144.65V76.26h-.44l-55.12,83.3H78.43L23.2,76.26h-.33V199.39H1.35V41ZM244.24,97.77c-20.31,0-39.86,16.46-39.86,41.59S223.93,181,244.24,181,284,164.49,284,139.36,264.45,97.77,244.24,97.77Zm232.14,0c-20.32,0-39.87,16.46-39.87,41.59S456.06,181,476.38,181s39.75-16.46,39.75-41.59S496.58,97.77,476.38,97.77ZM340.44,32.48c7.14,0,13,5.49,13,12.07s-5.82,12.07-13,12.07-12.95-5.49-12.95-12.07S333.31,32.48,340.44,32.48Zm47.66,0c7.14,0,13,5.49,13,12.07s-5.82,12.07-13,12.07-13-5.49-13-12.07S381,32.48,388.1,32.48Z"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default BrandNameCegedim;
