import { useContext } from 'react';

import OpenTokNetworkTestingContext, {
  OpenTokNetworkTestingContextValue,
} from '../contexts/OpenTokNetworkTestingContext/OpenTokNetworkTestingContext';

const useOpenTokNetworkTestingContext = (): OpenTokNetworkTestingContextValue => {
  const context = useContext(OpenTokNetworkTestingContext);

  if (!context) {
    throw new Error(
      'useOpenTokNetworkTestingContext has to be used within <OpenTokNetworkTestingProvider>',
    );
  }

  return context;
};

export default useOpenTokNetworkTestingContext;
