import { actionsGeneratorV2 } from '@docavenue/core';

import { Appointment } from '../../types/pro.types';

const appointmentsConflictsActions = actionsGeneratorV2({
  resource: 'appointmentsConflicts',
  chunkUrlResource: 'appointments',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const {
  updateManyPost: _updateManyPost,
  deleteManyPost: _deleteManyPost,
} = appointmentsConflictsActions;

const updateManyPost = (items: Appointment[], options: Object = {}) =>
  _updateManyPost(items, {
    chunkUrlResource: 'update-appointments',
    ...options,
  });

const deleteManyPost = (appointmentIds: string[], options: Object = {}) =>
  _deleteManyPost(appointmentIds, {
    chunkUrlResource: 'delete-appointments',
    ...options,
  });

export default {
  ...appointmentsConflictsActions,
  updateManyPost,
  deleteManyPost,
};
