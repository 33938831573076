import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { createSelector } from 'reselect';

import { RootState } from '../../../src/reducer/rootState';
import ListNotificationView from './ListNotificationView';

const notificationCenterItemsSelector = (state: RootState) =>
  state.notificationCenters.items;

const sortedNotificationCenterItemsSelector = createSelector(
  notificationCenterItemsSelector,
  items => {
    const sortedItems = [...items];
    sortedItems.sort(
      (a, b) =>
        new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime(),
    );

    const filteredItems = sortedItems.filter(item => {
      if (item.topic === 'BILLING') {
        return moment(item.creationDate).isAfter(moment().subtract(3, 'day'));
      }
      return true;
    });

    return filteredItems;
  },
);

type Props = {
  closeModal: () => void;
};

const ListNotificationContainer: React.FunctionComponent<Props> = props => {
  const { closeModal } = props;
  const notifications = useSelector(sortedNotificationCenterItemsSelector);
  const unreadNotificationsCount = notifications.filter(n => !n.isRead).length;

  return (
    <ListNotificationView
      unreadNotificationsCount={unreadNotificationsCount}
      notifications={notifications}
      closeModal={closeModal}
    />
  );
};

export default ListNotificationContainer;
