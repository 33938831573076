import {
  SET_PRACTITIONER_VALUE,
  SET_TELE_STATUS,
} from '../actions/teleExpertiseFilters';
import { ALL } from '../constants';

export type TeleExpertiseStatus = 'ONGOING' | 'COMPLETED' | string;

export type TeleExpertiseFiltersState = {
  tleStatus: TeleExpertiseStatus[] | TeleExpertiseStatus;
  practitionerValue: string | null;
};

export const initialState: TeleExpertiseFiltersState = {
  tleStatus: ALL,
  practitionerValue: '',
};

function teleExpertiseFilters(
  state = initialState,
  action: { type: string; payload: string },
) {
  const { type, payload } = action;
  switch (type) {
    case SET_TELE_STATUS: {
      return { ...state, tleStatus: payload };
    }
    case SET_PRACTITIONER_VALUE: {
      return { ...state, practitionerValue: payload };
    }

    default:
      return state;
  }
}

export default teleExpertiseFilters;
