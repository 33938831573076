import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles<{ isIconHigtLigth: boolean | undefined }>()(
  (theme, { isIconHigtLigth }) => ({
    root: {
      display: 'grid',
      gridGap: theme.spacing(2),
      gridTemplateColumns: '24px 1fr',
    },
    icon: {
      color: isIconHigtLigth
        ? theme.palette.main.dark
        : theme.palette.text.body,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
    },
  }),
);

type Props = {
  Icon: any;
  children: any;
  className?: string;
  isIconHigtLigth?: boolean;
  gridkey?: string;
};

const FormFieldWithIcon = (props: Props) => {
  const { isIconHigtLigth, Icon, className, children, gridkey } = props;
  const { classes, cx } = useStyles({ isIconHigtLigth });

  return (
    <div gridkey={gridkey} className={cx(classes.root, className)}>
      <div className={classes.icon}>
        <Icon />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default FormFieldWithIcon;
