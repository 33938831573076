import { actionsGeneratorV2 } from '@docavenue/core';

export const SET_LOADING_GENERATION = 'SET_LOADING_GENERATION';
export const SET_LOADING_GENERATION_ERROR = 'SET_LOADING_GENERATION_ERROR';

const complexFormCrud = actionsGeneratorV2({
  resource: 'exportStatisticsDocument',
});

const setLoadingGeneration = () => ({
  resource: 'exportStatisticsDocument',
  type: SET_LOADING_GENERATION,
});
const setLoadingGenerationError = () => ({
  resource: 'exportStatisticsDocument',
  type: SET_LOADING_GENERATION_ERROR,
});

const exportStatisticsDocumentActions = {
  ...complexFormCrud,
  setLoadingGeneration,
  setLoadingGenerationError,
};

export default exportStatisticsDocumentActions;
