import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  maintenanceIllustrationRoot: {
    '& .st0': {
      fill: 'url(#SVGID_1_)',
    },
    '& .st1': { fill: 'url(#SVGID_2_)' },
    '& .st2': { fill: 'url(#SVGID_3_)' },
    '& .st3': { fill: 'url(#SVGID_4_)' },
    '& .st4': { fill: 'url(#SVGID_5_)' },
    '& .st5': { fill: '#DCEEEE' },
    '& .st6': { fill: 'url(#SVGID_6_)' },
    '& .st7': { fill: 'url(#SVGID_7_)' },
    '& .st8': {
      fill: 'url(#SVGID_8_)',
    },
    '& .st9': { fill: '#969596' },
    '& .st10': { fill: 'url(#SVGID_9_)' },
    '& .st11': { fill: 'url(#SVGID_10_)' },
    '& .st12': { fill: 'url(#SVGID_11_)' },
    '& .st13': { fill: 'url(#SVGID_12_)' },
    '& .st14': { fill: 'url(#SVGID_13_)' },
    '& .st15': { fill: 'url(#SVGID_14_)' },
    '& .st16': { fill: 'url(#SVGID_15_)' },
    '& .st17': { fill: 'url(#SVGID_16_)' },
    '& .st18': { fill: '#6D483D' },
    '& .st19': { fill: 'url(#SVGID_17_)' },
    '& .st20': { fill: 'url(#SVGID_18_)' },
    '& .st21': {
      fill: 'none',
      stroke: '#D0CFD0',
      strokeLinecap: 'round',
      strokeMiterlimit: 10,
    },
    '& .st22': { fill: '#F9CCBA' },
    '& .st23': { fill: 'url(#SVGID_19_)' },
    '& .st24': { fill: 'url(#SVGID_20_)' },
  },
});

type Props = {
  className?: string;
};

const MaintenanceIllustration = (props: Props) => {
  const { className } = props;
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.maintenanceIllustrationRoot, className)}>
      <svg
        className="animblob"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 288 288"
      >
        <path>
          <animate
            repeatCount="indefinite"
            attributeName="d"
            dur="60s"
            values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z; M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4 c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6 c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8 C48.9,198.6,57.8,191,51,171.3z; M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45 c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2 c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7 c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z	"
            filter="url(#inset-shadow)"
          />
        </path>
      </svg>
      <svg
        className="action"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 1451 1609.6"
        // style="enable-background:new 0 0 1451 1609.6;"
        xmlSpace="preserve"
      >
        <g id="computer">
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="1057.9008"
            y1="816.5051"
            x2="1057.9008"
            y2="927.9009"
          >
            <stop offset="0" style={{ stopColor: '#D4E8E7' }} />
            <stop offset="0.432" style={{ stopColor: '#CEDFE3' }} />
            <stop offset="1" style={{ stopColor: '#CAD9E0' }} />
          </linearGradient>
          <path
            className="st0"
            d="M985.7,816.5h147.5c0,0,6.1,72.5,10.3,82.1c3.4,7.8,16,14.5,22.4,18.9c6.1,4.2,5.4,10.3-6.4,10.3
s-192.3,0-203.6,0s-12.8-4.9-4.9-9.8c7.9-4.9,22.6-10.3,25.6-21.1S985.7,816.5,985.7,816.5z"
          />
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1="674.7974"
            y1="546.2897"
            x2="1441.0043"
            y2="546.2897"
          >
            <stop offset="0" style={{ stopColor: '#656564' }} />
            <stop offset="0.4551" style={{ stopColor: '#5C5B5B' }} />
            <stop offset="1" style={{ stopColor: '#575555' }} />
          </linearGradient>
          <path
            className="st1"
            d="M728.5,279.5c60.8-6.4,610.4-8.7,655.9,0c45.6,8.7,52.5,40.7,52.5,84.2c0,33.1,6.9,328.2,2.8,387.8
c-4.1,59.5-31.8,59.5-53.9,63.9c-22.1,4.4-620,4.4-661.5,0S676,779.2,676,751.6c0-27.6-2.8-360.2,0-403.8
C678.8,304.2,687.1,283.9,728.5,279.5z"
          />
          <linearGradient
            id="SVGID_3_"
            gradientUnits="userSpaceOnUse"
            x1="675.3867"
            y1="765.4516"
            x2="1440.4149"
            y2="765.4516"
          >
            <stop offset="0" style={{ stopColor: '#D4E8E7' }} />
            <stop offset="0.432" style={{ stopColor: '#CEDFE3' }} />
            <stop offset="1" style={{ stopColor: '#CAD9E0' }} />
          </linearGradient>
          <path
            className="st2"
            d="M675.4,710.8c0.1,21.9,0.2,37.1,0.2,42.2c0,27.6,6.9,59.5,48.3,63.9c41.4,4.4,639.4,4.4,661.5,0
c22.1-4.4,49.7-4.4,53.9-63.9c0.6-8.4,1-21.6,1.1-38C1182.9,734.7,905.2,728.7,675.4,710.8z"
          />
          <linearGradient
            id="SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1="691.0894"
            y1="499.7158"
            x2="1422.8883"
            y2="499.7158"
          >
            <stop offset="1.117318e-02" style={{ stopColor: '#69C1BD' }} />
            <stop offset="0.5536" style={{ stopColor: '#5CBAC3' }} />
            <stop offset="1" style={{ stopColor: '#4EB2C9' }} />
          </linearGradient>
          <path
            className="st3"
            d="M706.1,305.1c16.7-12.2,685.7-9.7,701-1.4c15.3,8.3,19.5,386.7,12.5,393.6c-7,7-703.1,8.9-723.3-5.6
C686.6,684.9,690.8,316.3,706.1,305.1z"
          />
          <g>
            <linearGradient
              id="SVGID_5_"
              gradientUnits="userSpaceOnUse"
              x1="754.8529"
              y1="972.5997"
              x2="1139.6366"
              y2="972.5997"
            >
              <stop offset="0" style={{ stopColor: '#D4E8E7' }} />
              <stop offset="0.432" style={{ stopColor: '#CEDFE3' }} />
              <stop offset="1" style={{ stopColor: '#CAD9E0' }} />
            </linearGradient>
            <path
              className="st4"
              d="M754.9,944.4c106.2,0,377.7,1.3,381.8,2.7c4,1.3,4,20.2,0,22.9c-4,2.7-32.3,30.9-39,30.9
    c-6.7,0-278.9-0.7-278.9-0.7L754.9,944.4z"
            />
            <path
              className="st5"
              d="M754.9,944.4c106.2,0,377.7,1.3,381.8,2.7c4,1.3-34.9,33.6-39,36.3c-4,2.7,2.7,17.5-4,17.5
    c-6.7,0-274.9-0.7-274.9-0.7L754.9,944.4z"
            />
          </g>
          <g>
            <linearGradient
              id="SVGID_6_"
              gradientUnits="userSpaceOnUse"
              x1="1165.3297"
              y1="974.72"
              x2="1269.0181"
              y2="974.72"
            >
              <stop offset="0" style={{ stopColor: '#D4E8E7' }} />
              <stop offset="0.432" style={{ stopColor: '#CEDFE3' }} />
              <stop offset="1" style={{ stopColor: '#CAD9E0' }} />
            </linearGradient>
            <path
              className="st6"
              d="M1224.7,950.4c32.3,0,44.4,8.1,44.4,13.4s-32.3,32.3-41.7,33.6s-53.8,2.7-60.5,0
    C1160.1,994.8,1174.9,950.4,1224.7,950.4z"
            />
            <path
              className="st5"
              d="M1224.7,950.4c32.3,0,44.4,8.1,44.4,13.4c0,2.7-17.5-13.4-35.5,0.2c-18.6,14.1-10.9,34.1-15.6,34.8
    c-9.4,1.3-44.4,1.3-51.1-1.3C1160.1,994.8,1174.9,950.4,1224.7,950.4z"
            />
          </g>
          <linearGradient
            id="SVGID_7_"
            gradientUnits="userSpaceOnUse"
            x1="692.0896"
            y1="507.6732"
            x2="1423.8882"
            y2="507.6732"
          >
            <stop offset="1.117318e-02" style={{ stopColor: '#E7E7E8' }} />
            <stop offset="7.410023e-02" style={{ stopColor: '#EAEAEB' }} />
            <stop offset="0.3191" style={{ stopColor: '#F1F0F1' }} />
            <stop offset="1" style={{ stopColor: '#F3F2F3' }} />
          </linearGradient>
          <path
            className="st7"
            d="M700.8,322.7c-10,87.7-11.8,353.3-3.5,359.2c20.2,14.5,716.3,12.5,723.3,5.6c5.8-5.8,3.9-268.7-5.8-361.6
C1246.5,332.5,850.6,330.9,700.8,322.7z"
          />
          <g id="cog">
            <g>
              <linearGradient
                id="SVGID_8_"
                gradientUnits="userSpaceOnUse"
                x1="1005.7482"
                y1="818.0056"
                x2="1291.3094"
                y2="818.0056"
                gradientTransform="matrix(0.8992 -0.4375 0.4375 0.8992 -342.1707 291.967)"
              >
                <stop offset="0" style={{ stopColor: '#EC808F' }} />
                <stop offset="0.297" style={{ stopColor: '#EA798A' }} />
                <stop offset="0.7426" style={{ stopColor: '#E4647C' }} />
                <stop offset="1" style={{ stopColor: '#DF5572' }} />
              </linearGradient>
              <path
                className="st8"
                d="M1171.5,492.6l-10.5,0c-2.6,0-6.8-3.2-8.5-7.7c-0.6-1.6-1.3-3.2-2-4.8c-2-4.5-1.3-9.7,0.5-11.5l7.4-7.4
        c9.2-9.3,9.2-24.3,0-33.5l-12.4-12.4c-4.5-4.5-10.4-6.9-16.8-6.9s-12.3,2.5-16.8,7l-7.4,7.4c-1.1,1.1-3.3,1.8-5.7,1.8
        c-2,0-4-0.4-5.8-1.2c-1.6-0.7-3.2-1.4-4.8-2c-4.6-1.8-7.8-5.9-7.8-8.5l0-10.5c0-13.1-10.7-23.7-23.7-23.7l-17.6,0
        c-13.1,0-23.7,10.7-23.7,23.7l0,10.5c0,2.6-3.2,6.8-7.7,8.5c-1.6,0.6-3.2,1.3-4.8,2c-1.8,0.8-3.8,1.2-5.8,1.2
        c-2.4,0-4.6-0.7-5.7-1.7l-7.4-7.4c-4.5-4.5-10.4-6.9-16.8-6.9c-6.3,0-12.3,2.5-16.8,7l-12.4,12.4c-9.2,9.2-9.2,24.3,0,33.5
        l7.4,7.4c1.9,1.8,2.5,7,0.6,11.5c-0.7,1.6-1.4,3.2-2,4.8c-1.8,4.6-5.9,7.8-8.5,7.8l-10.5,0c-13.1,0-23.7,10.7-23.7,23.7l0,17.6
        c0,13.1,10.7,23.7,23.7,23.7l10.5,0c2.6,0,6.8,3.2,8.5,7.7c0.6,1.6,1.3,3.2,2,4.8c2,4.5,1.3,9.7-0.5,11.5l-7.4,7.4
        c-9.2,9.2-9.2,24.3,0,33.5l12.4,12.4c4.5,4.5,10.4,6.9,16.8,6.9c6.3,0,12.3-2.5,16.8-7l7.4-7.4c1.1-1.1,3.2-1.8,5.7-1.8
        c2,0,4,0.4,5.8,1.2c1.6,0.7,3.2,1.4,4.8,2c4.6,1.8,7.8,5.9,7.8,8.5l0,10.5c0,13.1,10.7,23.7,23.7,23.7l17.6,0
        c13.1,0,23.7-10.7,23.7-23.7l0-10.5c0-2.6,3.2-6.8,7.7-8.5c1.6-0.6,3.2-1.3,4.8-2c1.8-0.8,3.8-1.2,5.8-1.2c2.5,0,4.6,0.7,5.7,1.7
        l7.4,7.4c4.5,4.5,10.5,6.9,16.8,6.9c0.7,0,1.4,0,2.1-0.1c8.7-0.8,16.3-8.4,20.4-12.6l0.5-0.5l6.2-6.2c9.2-9.3,9.2-24.3,0-33.5
        l-7.4-7.4c-1.9-1.8-2.5-7-0.6-11.5c0.7-1.6,1.4-3.2,2-4.8c1.8-4.6,5.9-7.8,8.5-7.8l10.5,0c13.1,0,23.7-10.7,23.7-23.7l0-17.6
        C1195.2,503.2,1184.6,492.6,1171.5,492.6z M1112.7,525.1c0,35.4-28.7,64.2-64.1,64.2c-35.4,0-64.2-28.7-64.2-64.1
        c0-35.4,28.7-64.2,64.1-64.2C1083.9,461,1112.7,489.8,1112.7,525.1z"
              />
            </g>
          </g>
        </g>
        <g id="standing_doctor">
          <ellipse
            transform="matrix(0.5804 -0.8143 0.8143 0.5804 -229.9306 890.8934)"
            className="st9"
            cx="749.6"
            cy="668.6"
            rx="31.3"
            ry="21.5"
          />

          <linearGradient
            id="SVGID_9_"
            gradientUnits="userSpaceOnUse"
            x1="2596.8958"
            y1="638.897"
            x2="2730.4883"
            y2="638.897"
            gradientTransform="matrix(-1 0 0 1 3370.0845 0)"
          >
            <stop offset="0" style={{ stopColor: '#E7B2A2' }} />
            <stop offset="0.2489" style={{ stopColor: '#F1C0AF' }} />
            <stop offset="0.5589" style={{ stopColor: '#F7C9B7' }} />
            <stop offset="1" style={{ stopColor: '#F9CCBA' }} />
          </linearGradient>
          <path
            className="st10"
            d="M647.6,599.2c34.3,4.3,77.6,5.5,87.7,9.1s35.5,15.5,37.7,20.8s-11.7,44.6-16.3,41.9s-1.4-26.2-3.7-30.6
c-2.3-4.3-15-8.9-21-6.9c-6,2-18.2,13.2-10.7,20.6c7.5,7.4,27.1,20.3,26,23.2c-1.1,2.9-24.8,1.4-39.3-5.3s-19.2-13.8-30-18.1
c-10.8-4.3-31.6-8.5-36.1-12C637.3,638.3,639.9,598.2,647.6,599.2z"
          />

          <linearGradient
            id="SVGID_10_"
            gradientUnits="userSpaceOnUse"
            x1="-120.0061"
            y1="923.1208"
            x2="381.6732"
            y2="1212.7655"
            gradientTransform="matrix(-1 0 0 1 391.3398 0)"
          >
            <stop offset="0" style={{ stopColor: '#656564' }} />
            <stop offset="0.4551" style={{ stopColor: '#5C5B5B' }} />
            <stop offset="1" style={{ stopColor: '#575555' }} />
          </linearGradient>
          <path
            className="st11"
            d="M384.5,703.4c-56.4,8.1-74.6,21.2-121.9,20.1s-118.9-24.2-131-20.1c-12.1,4-24.7,202-24.7,259.4
s13.1,195.4,14.1,238.8c1,43.3,4,201.5,6,213.6c2,12.1,32.2,15.1,50.4,16.1s42.3,0,50.4-5c8.1-5,19.1-150.1,20.1-191.4
c1-41.3,4-73.5,8.1-126.9S269.1,993,269.1,993s17.1,57.4,18.1,113.8c1,56.4,1,164.2,4,200.5s2,111.8,9.1,114.8
c32.5,13.9,84.6,8.1,101.8,3c7-2,14.1-145.1,17.1-304.2c3-159.2,3-231.7-2-281.1C412.2,790.5,384.5,703.4,384.5,703.4z"
          />

          <linearGradient
            id="SVGID_11_"
            gradientUnits="userSpaceOnUse"
            x1="2948.7383"
            y1="905.7254"
            x2="3255.3748"
            y2="905.7254"
            gradientTransform="matrix(-1 0 0 1 3370.0845 0)"
          >
            <stop offset="0" style={{ stopColor: '#656564' }} />
            <stop offset="0.4551" style={{ stopColor: '#5C5B5B' }} />
            <stop offset="1" style={{ stopColor: '#575555' }} />
          </linearGradient>
          <path
            className="st12"
            d="M114.7,891.3c0,15.3,1.7,86.1,2.6,120.8h188.1l7-47.2c0,0,3.5,24.1,6.6,47.2h99.3c1.9-34.6,3.5-106,2.9-121.6
c-0.8-20.6-8.7-91.2-8.7-91.2c-10.8,1.6-29.6,3.9-50.5,6.1c-1.9,0.2-3.8,0.4-5.7,0.6c-7.7,0.8-15.6,1.5-23.4,2.2
c-21.8,1.9-42.9,3.2-56.9,3c-0.5,0-1,0-1.4,0c-2.8-0.1-6.2-0.2-10-0.4c-7.2-0.3-16-0.8-25.8-1.4c-0.3,0-0.6,0-0.8-0.1
c-33.6-2.1-77.7-5.1-102-5.4h0c-10.7-0.2-17.6,0.2-18,1.4C116.3,810.5,114.7,870.7,114.7,891.3z"
          />

          <linearGradient
            id="SVGID_12_"
            gradientUnits="userSpaceOnUse"
            x1="2978.9949"
            y1="552.8066"
            x2="3241.0269"
            y2="552.8066"
            gradientTransform="matrix(-1 0 0 1 3370.0845 0)"
          >
            <stop offset="0" style={{ stopColor: '#96D0D0' }} />
            <stop offset="0.3318" style={{ stopColor: '#90CECD' }} />
            <stop offset="0.7608" style={{ stopColor: '#80C8C4' }} />
            <stop offset="1" style={{ stopColor: '#74C3BE' }} />
          </linearGradient>
          <path
            className="st13"
            d="M378.8,290.8c-21.4,9.5-105.5,21.8-150.7,5.2s-85.4,218.8-92.6,280.7c-6.2,54.1-7.6,224.5-5.8,224.9
c33.2,8.2,201,21.5,233.5,6.5s35.1-256.4,14.9-358.7C358,347.1,378.8,290.8,378.8,290.8z"
          />

          <linearGradient
            id="SVGID_13_"
            gradientUnits="userSpaceOnUse"
            x1="2700.1592"
            y1="647.8103"
            x2="3018.4509"
            y2="647.8103"
            gradientTransform="matrix(-1 0 0 1 3370.0845 0)"
          >
            <stop offset="1.117318e-02" style={{ stopColor: '#E7E6E7' }} />
            <stop offset="0.3671" style={{ stopColor: '#EDECED' }} />
            <stop offset="1" style={{ stopColor: '#F2F1F2' }} />
          </linearGradient>
          <path
            className="st14"
            d="M351.7,420C351.7,420,351.7,420,351.7,420c0,4.7,0.1,9.4,0.1,14.2v0c0.2,18.2,0.7,37.1,1.3,56.1c0,0,0,0,0,0
c0.2,7.8,0.5,15.7,0.8,23.5c0,0,0,0,0,0c3.6,94.8,10.2,187.5,9.7,208.1c-0.4,15.1-1.5,45.3-3,80.9c0,0.4,0,0.8-0.1,1.2
c-0.5,13.4-1.1,27.6-1.7,41.9c-0.3,7.6-0.6,15.3-0.9,22.9v0c-2.6,63.3-5.1,126.7-5.1,143.1H437c5.7-31.9,10.2-240.3,6.1-304.6
c-1.5-23.8,0-69.4,1.3-100.1c0.2-3.7,0.3-7.3,0.5-10.5c0.6-12.8,1.1-21.1,1.1-21.1s13.1,17.7,48.2,37.5c0,0,0,0,0,0
c6.7,3.7,14.1,7.6,22.4,11.4c32.1,14.7,76.9,28.9,138,36.6c6.7,0.8,10.4-3.8,12.3-11c0.8-3,1.3-6.4,1.7-10.1
c0.3-3.1,0.4-6.3,0.5-9.5c0.2-10.4-0.4-21.4-0.1-29.1c0.1-2.8,0.4-5.1,0.8-6.9c1.9-7.5-97.5-21.1-189.9-200.4
c-19.7-38.3-62.3-80.9-89.1-100.2c-3.4-2.4-6.5-4.5-9.3-6.1c-6-3.5-10.4-5.1-12.7-4.2c-0.4,0.2-0.8,0.4-1.2,0.6
c-2.8,1.8-5.1,5.9-7.1,11.8c-1.9,5.8-3.4,13.3-4.7,22.3v0C352.6,343.4,351.4,379.4,351.7,420z"
          />

          <linearGradient
            id="SVGID_14_"
            gradientUnits="userSpaceOnUse"
            x1="3139.0942"
            y1="781.1186"
            x2="3296.9058"
            y2="781.1186"
            gradientTransform="matrix(-1 0 0 1 3370.0845 0)"
          >
            <stop offset="1.117318e-02" style={{ stopColor: '#E7E6E7' }} />
            <stop offset="0.3671" style={{ stopColor: '#EDECED' }} />
            <stop offset="1" style={{ stopColor: '#F2F1F2' }} />
          </linearGradient>
          <path
            className="st15"
            d="M74.8,1016c-3.2-41.8-1.7-288.9,4-406.8v0c2-41.8,4.5-67.5,7.5-62.4c9.5,16.1,17.7,28.6,26.3,38.3
c13,14.7,26.9,23.3,48,29.6c30.2,8.9,62,127.6,60.6,184c0,1.2-0.1,2.5-0.1,3.8c0,0.2,0,0.5,0,0.7v0c0,0.6,0,1.1,0,1.7
c-0.6,61.9,10.9,182.5,9.7,211.1H74.8z"
          />
          <path
            className="st9"
            d="M743.9,666.1l5.2-8C712.2,634,634.3,619.8,511,614.8c-65-2.6-107.3-43.6-131.4-77.4
c-29.5-41.6-41.2-85.6-40.5-95.3c25.3-9.9,41.8-48.3,49.1-114.2c6.6-59.8,4.9-142.3-0.2-185.3l-9.5,1.1c5,42.5,6.7,124.1,0.2,183.1
c-6.8,61.7-22.6,99.7-44.4,106.9c-5.3,1.7-5,8.6-4.1,15.1c2.7,19.3,17.2,61.8,46.1,100.1c24.9,33,68.4,72.8,134.4,75.5
C632.2,629.3,708.5,643,743.9,666.1z"
          />
          <g id="face_1_">
            <linearGradient
              id="SVGID_15_"
              gradientUnits="userSpaceOnUse"
              x1="3075.5774"
              y1="212.9247"
              x2="3075.5774"
              y2="336.2738"
              gradientTransform="matrix(-1 0 0 1 3370.0845 0)"
            >
              <stop offset="0" style={{ stopColor: '#E7B2A2' }} />
              <stop offset="4.144597e-02" style={{ stopColor: '#EAB6A6' }} />
              <stop offset="0.2189" style={{ stopColor: '#F3C3B1' }} />
              <stop offset="0.4562" style={{ stopColor: '#F8CAB8' }} />
              <stop offset="1" style={{ stopColor: '#F9CCBA' }} />
            </linearGradient>
            <path
              className="st16"
              d="M334.1,212.9c0,13.2-2.1,31.1,1.6,46.2c3.4,13.8,41.3,29.5,41.3,29.5s-12,47.7-78.5,47.7
    c-24.8,0-88.4-47.8-86.3-49.9c2.1-2.1,41.1-18.3,40.8-42.3c-0.1-11.1,0.3-29.4,0.3-29.4s16.7,5.1,39.9,5.6
    C319.2,220.9,334.1,212.9,334.1,212.9z"
            />

            <linearGradient
              id="SVGID_16_"
              gradientUnits="userSpaceOnUse"
              x1="-280.2333"
              y1="202.9303"
              x2="-123.9336"
              y2="46.6306"
              gradientTransform="matrix(1 0 0 1 495.7912 0)"
            >
              <stop offset="0" style={{ stopColor: '#E7B2A2' }} />
              <stop offset="2.967358e-02" style={{ stopColor: '#E9B5A5' }} />
              <stop offset="0.2153" style={{ stopColor: '#F2C2B1' }} />
              <stop offset="0.4608" style={{ stopColor: '#F7C9B8' }} />
              <stop offset="1" style={{ stopColor: '#F9CBBA' }} />
            </linearGradient>
            <path
              className="st17"
              d="M379.1,53.9c5.4,20.1,6,28.7,3.1,49.2c-1.4,9.6,5.4,7,7.7,24c2.7,20.1-2.1,31.6-6.6,32.7
    c-5.1,1.3-4.9,29.8-6.9,34.8c-5.8,14.4-38.3,39.5-80.3,39.7c-67.5,0.4-74-25.4-78.1-38.3c-4.1-12.9-5.4-30.3-8.1-30.3
    s-28.7-1.2-30.1-27.1c-1.4-26,9.8-31.6,19.2-31.6c5.4,0,10.2-51.7,14.3-57.4s27.7-18.7,77.8-20.1S379.1,53.9,379.1,53.9z"
            />
            <path
              className="st18"
              d="M205.3,65.5c2.4-14.4,18.6-30.7,25.6-37.1c7-6.4,34.6-18,59.5-18.3c27.3-0.4,35.9-0.2,56.9,9
    c26.8,11.7,35.9,39.1,38.7,50.5c3.2,13-2.7,63-2.7,63s5.2-24.9-18.2-41.2c-7.5-5.2-51.1,16.6-68.3,19.4
    c-21.9,3.6-67.3,20.5-84.6,22.4C202.9,134.2,202.9,79.8,205.3,65.5z"
            />
          </g>
          <circle className="st9" cx="194.1" cy="143" r="10.9" />
          <g id="left_body_1_">
            <linearGradient
              id="SVGID_17_"
              gradientUnits="userSpaceOnUse"
              x1="17.2767"
              y1="774.5485"
              x2="72.2229"
              y2="869.7182"
            >
              <stop offset="0" style={{ stopColor: '#E7B2A2' }} />
              <stop offset="0.246" style={{ stopColor: '#F0BFAE' }} />
              <stop offset="0.5803" style={{ stopColor: '#F7C9B7' }} />
              <stop offset="1" style={{ stopColor: '#F9CCBA' }} />
            </linearGradient>
            <path
              className="st19"
              d="M25.1,770.1c-2.8,23.5-13.9,51.4-16.6,61.5s8.3,31.3,16.6,43.6s31.9,0,37.4,0s1.4-24.6,11.1-42.5
    s-8.3-55.9-9.7-64.8C62.5,758.9,25.1,770.1,25.1,770.1z"
            />
            <linearGradient
              id="SVGID_18_"
              gradientUnits="userSpaceOnUse"
              x1="24.201"
              y1="578.5568"
              x2="224.2953"
              y2="578.5568"
            >
              <stop offset="0" style={{ stopColor: '#F1F1F1' }} />
              <stop offset="0.6886" style={{ stopColor: '#EFEFEF' }} />
              <stop offset="0.9364" style={{ stopColor: '#E9E8E8' }} />
              <stop offset="1" style={{ stopColor: '#E6E5E5' }} />
            </linearGradient>
            <path
              className="st20"
              d="M25.6,779.8c-7.7-20.4,17.9-262.2,37.5-349.9c6-26.9,30.9-84.9,76.6-104.7c18.2-7.9,41.6-22.6,52.8-26.7
    c11.3-4.1,25.6-14.3,26.6-16.4s-12.3,58.3-10.2,120.8c2,62.4,13.3,463.7,15.4,467.8s-57.3,7.2-88,1S79.9,846.1,75.8,842
    s-7.2,11.3-3.4-49.4C73.3,778.9,25.6,779.8,25.6,779.8z"
            />
            <path
              className="st21"
              d="M91.3,729.2c2.2-1.1,16.7,10.3,40,18.4c24.9,8.7,50.8,9.7,51.9,10.8c1.1,1.1-2.2,23.8-4.3,27
    c-2.2,3.2-40-1.1-57.3-8.7c-20.4-8.9-35.7-17.3-36.8-20.6C83.8,753,89.4,730.2,91.3,729.2z"
            />
            <path
              className="st21"
              d="M65.6,789.6C65.6,789.6,65.6,789.6,65.6,789.6c-0.5,0-0.9-0.5-0.9-0.9C70.3,684.4,86.4,488.4,98,430.4
    c0.1-0.5,0.6-0.8,1.1-0.7c0.5,0.1,0.8,0.6,0.7,1.1c-11.6,57.9-27.7,253.8-33.3,358C66.5,789.3,66.1,789.6,65.6,789.6z"
            />
          </g>
          <path
            className="st9"
            d="M338,444.3c0.4,0,0.9,0,1.3,0l-0.4-9.5c-10,0.4-22.4-6.2-35.9-19.1c-24.5-23.5-51.9-67.4-73.2-117.5
c-22.1-51.8-30.6-118-30.6-154.8l-9.5,0c0,38.1,8.7,105.6,31.3,158.6c22.1,51.9,49.6,95.9,75.4,120.6
C311.5,436.9,325.5,444.3,338,444.3z"
          />
          <path
            className="st22"
            d="M363.3,1444.1c-1,19.2-4.9,23.3-4,36.7c1,13.4,0.7,38.3,0.7,38.3l-50.9-1.9c0,0,3.4-22,3.4-34.5
s-1.3-37.3-1.3-37.3L363.3,1444.1z"
          />
          <path
            className="st22"
            d="M201,1442.5c-1,13.4-1,21.1,0,38.3c1,17.2,2.9,38.3,2.9,38.3h-50.8c0,0,4.8-29.7,4.8-38.3s-1.9-38.3-1.9-38.3
H201z"
          />

          <linearGradient
            id="SVGID_19_"
            gradientUnits="userSpaceOnUse"
            x1="209.948"
            y1="1507.0776"
            x2="209.948"
            y2="1599.5598"
            gradientTransform="matrix(-1 0 0 1 391.3398 0)"
          >
            <stop offset="0" style={{ stopColor: '#F1F1F1' }} />
            <stop offset="0.6886" style={{ stopColor: '#EFEFEF' }} />
            <stop offset="0.9364" style={{ stopColor: '#E9E8E8' }} />
            <stop offset="1" style={{ stopColor: '#E6E5E5' }} />
          </linearGradient>
          <path
            className="st23"
            d="M203.8,1507.6c-1-1-12.4,0-22,1c-9.6,1-23.9-2.9-26.8-1c-2.9,1.9-5.7,17.2-7.7,24.9
c-1.9,7.7-11.5,20.1-11.5,30.6c0,23.9,15.3,36.4,43.1,36.4c28.7,0,46.7-9.6,47.9-25.9c1-13.4-3.8-19.2-9.6-29.7
C213.1,1536.5,207.7,1511.5,203.8,1507.6z"
          />

          <linearGradient
            id="SVGID_20_"
            gradientUnits="userSpaceOnUse"
            x1="-894.9754"
            y1="1254.2213"
            x2="-721.1357"
            y2="1254.2213"
            gradientTransform="matrix(-0.9596 -0.2815 -0.2815 0.9596 -38.9351 116.6424)"
          >
            <stop offset="2.793296e-03" style={{ stopColor: '#DCDCDC' }} />
            <stop offset="4.120475e-03" style={{ stopColor: '#DCDCDC' }} />
            <stop offset="3.896482e-02" style={{ stopColor: '#E4E4E4' }} />
            <stop offset="0.1145" style={{ stopColor: '#E6E6E6' }} />
            <stop offset="0.1512" style={{ stopColor: '#E7E8E8' }} />
            <stop offset="0.3743" style={{ stopColor: '#E8E9EA' }} />
          </linearGradient>
          <path
            className="st24"
            d="M360.7,1499.5c-6.4,8.4-14.7,11.2-28.8,9.4c-18-2.3-22.3-15.7-24-12.1c-3.3,7.4-13.9,56.8-10,65.5
c2.4,5.4,61.6,16.3,78.7,18.8c17.1,2.6,79.3,3.4,83.8,0.9c8-4.3,9.1-19.2-0.1-29.3c-2.5-2.7-18.1-6-59-26.8
C382.4,1516.3,360.7,1499.5,360.7,1499.5z"
          />
        </g>
      </svg>
    </div>
  );
};

export default MaintenanceIllustration;
