export enum ConfirmLogoutActions {
  OPEN_DIALOG = 'OPEN_DIALOG',
  CONFIRM_LOGOUT = 'CONFIRM_LOGOUT',
  CANCEL_LOGOUT = 'CANCEL_LOGOUT',
}

export enum ConfirmLogoutMode {
  ONGOING_TLC = 'ONGOING_TLC',
  WAITING_ROOM_EMPTY = 'WAITING_ROOM_EMPTY',
  WAITING_ROOM_NOT_EMPTY = 'WAITING_ROOM_NOT_EMPTY',
  DEFAULT = 'DEFAULT',
}

const openDialog = ({ mode }: { mode: ConfirmLogoutMode }) => ({
  type: ConfirmLogoutActions.OPEN_DIALOG,
  payload: { mode },
});

const confirmLogout = () => ({
  type: ConfirmLogoutActions.CONFIRM_LOGOUT,
});

const cancelLogout = () => ({
  type: ConfirmLogoutActions.CANCEL_LOGOUT,
});

const confirmLogoutActions = {
  openDialog,
  confirmLogout,
  cancelLogout,
};

export default confirmLogoutActions;
