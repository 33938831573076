import {
  crudInitialDefaultState as initialStateCrudState,
  reducersGenerator,
} from '@docavenue/core';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { CrudInitialDefaultState } from '@docavenue/core/src/types';

import {
  SET_OPEN,
  SET_OPEN_COMPLEMENTARY,
  SET_OPEN_MEDICAL_CERTIFICATE,
  SET_OPEN_REPORT,
} from '../actions/prescription';

export type Dosage = {
  codeUnitePrise: number;
  libUnitePrise: string;
  codeMoment: string;
  pendant1: number;
  libDuree1: string;
  renewable: number;
  comment: string;
  ns: boolean;
  ald: boolean;
};

export type ComplementaryPrescription = {
  prescription: string;
  ald: boolean;
  subCategory: string;
};

export type BcbPrescriptionProduct = {
  idProduit: string;
  libelleCourt: string;
};

export type Prescription = {
  id: string;
  bcbProduct: BcbPrescriptionProduct | null;
  dosage: Dosage | null;
  isEditMode: boolean;
  complementaryPrescription: ComplementaryPrescription | null;
};

export type PrescriptionState = {
  isOpen: boolean;
  isOpenComplementary: boolean;
  isOpenReport: boolean;
  isOpenMedicalCertificate: boolean;
} & CrudInitialDefaultState<Prescription>;

export const initialState: PrescriptionState = {
  ...(initialStateCrudState as CrudInitialDefaultState<Prescription>),
  isOpen: false,
  isOpenComplementary: false,
  isOpenReport: false,
  isOpenMedicalCertificate: false,
};

const surchargeReducer = () => {
  const obj: any = {};

  obj[SET_OPEN] = (state = initialState, action: { payload: boolean }) => {
    const { payload } = action;
    return { ...state, isOpen: payload, isOpenComplementary: false };
  };

  obj[SET_OPEN_COMPLEMENTARY] = (
    state = initialState,
    action: { payload: boolean },
  ) => {
    const { payload } = action;
    return { ...state, isOpenComplementary: payload, isOpen: false };
  };

  obj[SET_OPEN_REPORT] = (
    state: PrescriptionState = initialState,
    action: { payload: boolean },
  ) => {
    const { payload } = action;
    return { ...state, isOpenReport: payload, isOpen: false };
  };

  obj[SET_OPEN_MEDICAL_CERTIFICATE] = (
    state: PrescriptionState = initialState,
    action: { payload: boolean },
  ) => {
    const { payload } = action;
    return { ...state, isOpenMedicalCertificate: payload, isOpen: false };
  };

  return obj;
};

const prescription = reducersGenerator({
  name: 'prescription',
  surchargeReducer,
});

export default prescription;
