import {
  initialStateItemsDict,
  itemsDictMapReducerGenerator,
  keysOptionsDefault,
} from '@docavenue/chat';

import { Appointment } from '../../types/pro.types';

const name = 'appointmentsNotes';

export const initialState = initialStateItemsDict;

const appointmentsNotes = itemsDictMapReducerGenerator<Appointment>({
  name,
  keysOptions: keysOptionsDefault,
});

export default appointmentsNotes;
