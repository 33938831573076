import { CrudState } from '@docavenue/chat';
import { reducersGenerator } from '@docavenue/core';

import { NotificationsCenter } from '../../types/pro.types';
import { SET_ALL_NOTIFICATIONS_AS_READ } from '../actions/notificationCenters';

const surchargeReducer = () => {
  const obj: any = {};

  obj[SET_ALL_NOTIFICATIONS_AS_READ] = (
    state: CrudState<NotificationsCenter>,
  ) => {
    const items = state.items.map(notification => {
      if (!notification.isRead) {
        return {
          ...notification,
          isRead: true,
        };
      }
      return notification;
    });

    return {
      ...state,
      items,
    };
  };
  return obj;
};

const notificationCenters = reducersGenerator({
  name: 'notificationCenters',
  surchargeReducer,
});

export default notificationCenters;
