/**
 * Note:
 * Due to no-ssr, do not mix the following export with the one 
 *   in the OpenTokNetworkTestingProvider file as it contains only client side code
 * 
 */

import { createContext, } from 'react';
// eslint-disable-next-line prettier/prettier
import type { ErrorNames } from 'opentok-network-test-js';
import type { ConnectivityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testConnectivity';
import type {
  FailureCase,
  FailureType,
} from 'opentok-network-test-js/dist/NetworkTest/testConnectivity/errors/mapping';
import type { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import type { UpdateCallbackStats } from 'opentok-network-test-js/dist/NetworkTest/types/callbacks';

import type { MediaPermissionsErrorType } from '@/src/utils/mediaDevicesUtils';

/**
 * This is an UI oriented group of opentok's ErrorNames enums to better display error message
 * @see https://github.com/opentok/opentok-network-test-js?tab=readme-ov-file#errornames
 */
export enum NetworkTestingErrorNames {
  // The resolution is not supported
  UNSUPPORTED_RESOLUTION_ERROR = 'UNSUPPORTED_RESOLUTION_ERROR',
  // OpenTok has a list of supported browsers.
  // @see https://github.com/opentok/opentok-network-test-js?tab=readme-ov-file#supported-browsers
  UNSUPPORTED_BROWSER = 'UNSUPPORTED_BROWSER',
  // Anything related to media (camera/microphone) permissions
  MEDIA_PERMISSIONS = 'MEDIA_PERMISSIONS',
  // Anything related to media and different from media permissions. For instance there could be no media at all.
  MEDIA_REQUIRED = 'MEDIA_REQUIRED',
  // Anything related to network error when launching open tok
  OT_PUBLISHER_OR_SUBSCRIBER_NETWORK_ERROR = 'OT_PUBLISHER_OR_SUBSCRIBER_NETWORK_ERROR',
  // Issues while connecting to opentok servers
  OT_SERVER_ERROR = 'OT_SERVER_ERROR',
  // Unexpected error from code or abnormal behavior
  CODE_ERROR = 'CODE_ERROR',
  // Any error different from the ones above
  DEFAULT_ERROR = 'DEFAULT_ERROR',
}
  
type TestResultType = {
  connectivityTest: {
    loading: boolean;
    error: ConnectivityTestResults['failedTests'] | Error | null;
    data: true | null;
  };
  qualityTest: {
    loading: boolean;
    error: ConnectivityTestResults['failedTests'] | Error | null;
    data: {
      intermediate: UpdateCallbackStats | null;
      final: QualityTestResults | null;
    };
  };
}

export interface OpenTokNetworkTestingContextValue {
  /**
   * State containing loading, data and error values regarding both network and quality tests
   */
  networkTestResult: TestResultType;
  /**
   * Re-run the latest test. Useful when retrying failing test
   */
  retryLatestTest: () => void;
  /**
   * Loop through the given failedTests array to convert the first error to NetworkTestingErrorNames
   * @param failedTests Errors to lookup
   * @throws {Error}
   */
  getSimplifiedError: (
    failedTests: FailureCase[] | Error,
  ) => {
    /**
     * More UI error name
     */
    simplifiedName: NetworkTestingErrorNames;
    /**
     * The initial error name
     */
    name: ErrorNames | MediaPermissionsErrorType | string;
    /**
     * Error type classification from OpenTok 
     */
    type?: FailureType;
  };
  runNetworkTest: (params: {
    /**
     * Whether to perform network testing
     */
    network: boolean;
    /**
     * Whether to perform quality testing
     */
    quality: boolean;
    /**
     * Info needed to log pre-call tlc result in backend 
     */
    loggingInfo?: {
      origin: string;
      videoSessionId: string;
    };
    /**
     * Callback called on a successful test
     * @param dataTestResult partial TestResult object representing only the data part which is gather the truthful values as the test went well
     * @param duration the time taken to perform the test in seconds
     */
    onSuccessCallback?: (
      dataTestResult: {
        connectivityTest: true | null;
        qualityTest: {
          intermediate: UpdateCallbackStats | null;
          final: QualityTestResults | null;
        };
      }, 
      duration: number
    ) => void;
    /**
     * 
     * @param testResult full TestResult object
     * @param duration the time taken to perform the test in seconds
     */
    onFailureCallback?: (testResult: TestResultType, duration: number) => void;
  }) => void;
};
  
const OpenTokNetworkTestingContext = createContext<OpenTokNetworkTestingContextValue | null>(null);
OpenTokNetworkTestingContext.displayName = 'OpenTokNetworkTestingContext'
export default OpenTokNetworkTestingContext;
