import {
  ConfirmLogoutActions,
  ConfirmLogoutMode,
} from '../actions/confirmLogout';

export type ConfirmLogoutState = {
  isOpen: boolean;
  mode: ConfirmLogoutMode;
};

export const initialState = {
  isOpen: false,
  mode: ConfirmLogoutMode.DEFAULT,
};

function confirmLogout(
  state = initialState,
  action: { type: ConfirmLogoutActions; payload: { mode: ConfirmLogoutMode } },
) {
  switch (action.type) {
    case ConfirmLogoutActions.OPEN_DIALOG: {
      return { ...state, isOpen: true, mode: action.payload.mode };
    }
    case ConfirmLogoutActions.CONFIRM_LOGOUT:
    case ConfirmLogoutActions.CANCEL_LOGOUT: {
      return { ...state, isOpen: false };
    }

    default:
      return state;
  }
}

export default confirmLogout;
