import React from 'react';
import { makeStyles } from 'tss-react/mui';


import ListItem from '../../atoms/ListItem';
import Typography from '../../atoms/Typography';

/* eslint-disable-next-line no-unused-vars */
const useStyles = makeStyles()((theme: any) => ({
  buttonItem: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.size.spacing[1],
    backgroundColor: theme.palette.main.main,

    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.main.dark,
    },
  },
  text: {
    width: 'auto',
    position: 'relative',
    color: theme.palette.text.white,
    padding: 2,
    marginLeft: theme.spacing(2),
  },
  icon: {
    color: theme.palette.text.white,
  },
}));

type Props = {
  className?: string;
  classes?: {
    button: string;
    text: string;
  };
  icon?: React.ReactNode | null;
  text: string;
  datacy?: string;
  onClick?: (event: any) => void;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  button?: boolean;
};

const ButtonItem = (props: Props) => {
  const { icon, text, className, ...rest } = props;
  const { classes, cx } = useStyles();
  return (
    // TODO: use style BUTTON
    <ListItem
      className={cx(classes.buttonItem, className && className)}
      {...rest}
    >
      <span className={classes.icon}>{icon}</span>
      <Typography variant="body" component="span" className={classes.text}>
        {text}
      </Typography>
    </ListItem>
  );
};

ButtonItem.displayName = 'ButtonItem';

export default ButtonItem;
