/**
 * Those are a collection of utils related to the migration of the agenda (and more widely, the whole project)
 * to react-query, connected to WebScockets.
 */

/**
 * Enables react-query/websocket poc + agenda
 */
export function isPocReactQueryAgendaActivated() {
  return process.env.NEXT_PUBLIC_POC_REACT_QUERY_AGENDA_ACTIVATED === 'true';
}
