/* eslint-disable import/prefer-default-export */
import axios, { AxiosInstance } from 'axios';
import omitBy from 'lodash/omitBy';

import { transformerByTimezone } from '@docavenue/core';

import config from '../config';

let apiClientInstance: AxiosInstance | null = null;

/**
 * Returns the instance created in setupApiClient(store)
 * In the futur, when we don't need store anymore, this will be a real singleton
 */
export function getApiClient({
  apiName,
}: {
  apiName: 'api-pro' | 'api-patient' | 'api-pro-connect';
}) {
  if (process.env.NODE_ENV !== 'production' && apiName === 'api-patient') {
    console.warn(
      "You called getApiClient with 'api-patient' in pro-frontend, did you mean to do that?",
    );
  }
  if (!apiClientInstance) {
    throw new Error(
      'No api client initialized, make sure `setupApiClient` was called',
    );
  }
  return apiClientInstance;
}

type SetupApiClientType = {
  getToken: (url?: string) => string | undefined;
  getTimezone: (url?: string) => string | undefined;
};

export const setupApiClient = ({
  getToken,
  getTimezone,
}: SetupApiClientType) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'X-From': 'axios',
  };
  apiClientInstance = axios.create({
    baseURL: (process as any).browser
      ? config.get('API_ENDPOINT')
      : config.get('SERVER_API_ENDPOINT'),
    headers,
  });

  // retrieve token in store - for the moment, authentication / refresh token is not handled
  apiClientInstance.interceptors.request.use(req => {
    const token = getToken(req.url);
    if (!req.headers.Authorization && token) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  });

  apiClientInstance.interceptors.request.use(async req => {
    if (req.data && req.data instanceof FormData) {
      return req;
    }
    const timezone = getTimezone(req.url);
    if (timezone && req.params) {
      req.params = new URLSearchParams( // url encode query params
        (await transformerByTimezone()).out(
          omitBy(req.params, o => typeof o === 'undefined'), // avoid foo=undefined
          {
            centers: {
              item: {
                timeZone: timezone,
              },
            },
          },
        ),
      );
    }
    if (timezone && req.data) {
      req.data = (await transformerByTimezone()).out(req.data, {
        centers: {
          item: {
            timeZone: timezone,
          },
        },
      });
    }
    return req;
  });

  apiClientInstance.interceptors.response.use(async res => {
    if (res.data) {
      res.data = (await transformerByTimezone()).in(res.data, {
        centers: {
          item: {
            timeZone: getTimezone(),
          },
        },
      });
    }
    return res;
  });
};
