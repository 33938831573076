import React from 'react';

type Props = {
  userId: string;
};
const DynatraceDebugToken = ({ userId }: Props) => (
  <span
    className="debug_token"
    style={{
      display: 'none',
    }}
  >
    {userId}
  </span>
);

export default DynatraceDebugToken;
