import config from '../config';

const k8sEnv = config.get('ENVK8S')?.toLowerCase();
export const isProductionEnv = k8sEnv === 'production';
export const isIntegrationEnv = k8sEnv === 'integration';
export const isStagingEnv = k8sEnv === 'staging';
export const isDevEnv = process.env.NODE_ENV?.toLowerCase() === 'development';
export const isIntegrationTestEnv =
  typeof window !== 'undefined' && !!(window as any)?.Cypress;
export const isTestEnv = k8sEnv === 'test';
export const isDevTestEnv = isTestEnv || isDevEnv;
export const isF1Env = k8sEnv === 'f1';
export const isF2Env = k8sEnv === 'f2';
export const isF3Env = k8sEnv === 'f3';
export const isF4Env = k8sEnv === 'f4';
export const isF5Env = k8sEnv === 'f5';
export const isF6Env = k8sEnv === 'f6';
export const isF7Env = k8sEnv === 'f7';
export const isF8Env = k8sEnv === 'f8';
export const isF9Env = k8sEnv === 'f9';
export const isF10Env = k8sEnv === 'f10';
