import { TimeSlotGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { EventCalendar } from '../../types/pro.types';
import {
  SET_CALENDAR_EVENT,
  SET_CONFIG,
  SET_CURRENT_SLOT,
  SET_DUPLICATE_MODE,
  SET_TIMESLOT_STEP,
} from '../actions/calendar';

type Config = {
  step: number;
  timeslots: number;
};

export type CalendarState = {
  item?: EventCalendar;
  config: Config;
  currentSlot: TimeSlotGetDTO | null;
  configError?: string | null;
  isDuplicateMode: boolean;
  hoveredTimeSlot: TimeSlotGetDTO | null;
};

export const initialState: CalendarState = {
  item: undefined,
  currentSlot: null,
  config: {
    step: 10,
    timeslots: 6,
  },
  configError: null,
  hoveredTimeSlot: null,
  isDuplicateMode: false,
};

function calendar(
  state = initialState,
  action: {
    type: string;
    payload: {
      item?: EventCalendar;
      incr?: number;
      config?: Config;
      currentSlot?: TimeSlotGetDTO;
      isDuplicateMode?: boolean;
    };
  },
) {
  const { type } = action;
  switch (type) {
    case SET_CALENDAR_EVENT: {
      const { item } = action.payload;
      return {
        ...state,
        item,
      };
    }
    case SET_TIMESLOT_STEP: {
      const { incr } = action.payload;
      if (incr === undefined) return state;
      const hour = 60;
      const step = 5 * incr + state.config.step;
      if (step > 0 && step <= hour) {
        const timeslots = hour % step ? 1 : hour / step;
        const config = { ...state.config, timeslots, step };
        const newState = { ...state, config };
        return newState;
      }
      // do not rerender
      return state;
    }

    case SET_CONFIG: {
      const { config } = action.payload;
      return { ...state, config };
    }

    case SET_CURRENT_SLOT: {
      const { currentSlot } = action.payload;
      return { ...state, currentSlot };
    }

    case SET_DUPLICATE_MODE: {
      const { isDuplicateMode } = action.payload;
      return { ...state, isDuplicateMode };
    }

    default:
      return state;
  }
}

export default calendar;
