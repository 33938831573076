import React, { ReactNode, useEffect, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import cookies from 'next-cookies';
import { NextSeo } from 'next-seo';
import { useKeycloak } from '@react-keycloak/ssr';
import { KeycloakInstance } from 'keycloak-js';
import find from 'lodash/find';
import snakeCase from 'lodash/snakeCase';
import { Store } from 'redux';

import { stringify } from 'querystring';

import {
  agendaSettingsActions,
  centersActions,
  DatacyContext,
  setScreebIdentity,
  useIsFreemium,
  usersActions,
} from '@docavenue/chat';
import { asyncActions, serialActions, storeActions } from '@docavenue/core';

import ConsultationReasonsTimeslotRefContextProvider from '../components/contexts/ConsultationReasonsTimeslotRefContext';
import ExportStatisticsDocumentHandler from '../components/molecules/ExportStatisticsDocumentHandler';
import { useDisplayAutoOnboardingWelcomeDialog } from '../components/organisms/AutoOnboardingWelcomeDialog/AutoOnboardingWelcomeDialog';
import DocumentOverlay from '../components/organisms/DocumentOverlay';
import HeaderIsFreemium from '../components/organisms/Header/HeaderIsFreemium';
import MaintenancePage from '../components/organisms/MaintenancePage';
import MiniVideoSessionPendingDialog from '../components/organisms/MiniVideoSessionPendingDialog/MiniVideoSessionPendingDialog';
import DynatraceDebugToken from '../components/utils/components/Debug/DynatraceDebugToken';
import { SECRETARY, TELESECRETARY } from '../components/utils/constants';
import { getTlcCenterOrAmcAndHasCenterId } from '../components/utils/tools';
import {
  complexFormActions,
  offsetActions,
  patientsActions,
  practitionersActions,
  videoSessionsActions,
} from './actions';
import { isSubstituedListParams } from './actions/practitioners';
import config from './config';
import { PENDING, STARTED, WAITING } from './constants';
import Routes from './constants/routes';
import Tabs from './constants/tabs';
import securedPage from './hoc/securedPage';
import { useOpenComplexForm } from './hooks/actions';
import { useMaintenanceMaiia } from './hooks/maintenance';
import { useCenterId, useIsPractitioner, useUserId } from './hooks/selectors';
import Layout from './Layout';
import { RootState } from './reducer/rootState';
import { practitionersSelectors } from './selector';
import { getOffset } from './utils';
import {
  isAuthPath,
  isPathWithoutHeader,
  isPathWithSidebar,
  isValidRoute,
  requiresPractitionerId,
} from './utils/RoutesUtils';
import { isTabWithSidebar } from './utils/TabsUtils';

const ErrorComponent = dynamic(() =>
  import('../components/organisms/ErrorComponent'),
);
const Header = dynamic(() => import('../components/organisms/Header'));

const SideBar = dynamic(() => import('../components/organisms/SideBar'));
const SocketSuscribers = dynamic(() => import('./SocketSuscribers'));
const DialogMaster = dynamic(() =>
  import('../components/organisms/DialogMaster'),
);

const AutoOnboardingWelcomeDialog = dynamic(() =>
  import(
    '../components/organisms/AutoOnboardingWelcomeDialog/AutoOnboardingWelcomeDialog'
  ),
);

const ConfirmLogoutDialog = dynamic(() =>
  import('../components/organisms/ConfirmLogoutDialog'),
);

const ClearAccessEndDateDialog = dynamic(() =>
  import('../components/organisms/ClearAccessEndDateDialog'),
);

const NotificationsListener = dynamic(() =>
  import('../components/organisms/NotificationsListener/NotificationsListener'),
);
const PaymentErrorsDialog = dynamic(() =>
  import('../components/organisms/PaymentErrorsDialog'),
);

const VideoSessionPendingDialog = dynamic(() =>
  import('../components/organisms/VideoSessionPendingDialog'),
);
const TlcReminderDialog = dynamic(() =>
  import('../components/organisms/TlcReminderDialog'),
);
const TlcDeactivateDialog = dynamic(() =>
  import('../components/organisms/TlcDeactivateDialog'),
);
const TlcErrorsDialog = dynamic(() =>
  import('../components/organisms/TlcErrorsDialog'),
);
const WatchPingPatient = dynamic(() =>
  import('../components/organisms/WatchPingPatient'),
);
const SnackBuildContainer = dynamic(() =>
  import(
    '../components/metaOrganisms/snacks/Release/containers/SnackBuildContainer'
  ),
);
const FusionDialogContainer = dynamic(() =>
  import('../components/organisms/FusionDialog/FusionDialogContainer'),
);
const AutomaticTlcSuggestionDialog = dynamic(() =>
  import('../components/organisms/AutomaticTlcSuggestion'),
);
const PrivacyAndTermsUpdateModal = dynamic(() =>
  import('../components/organisms/PrivacyAndTermsUpdateModal'),
);
type Props = {
  pathname: string;
  tab: string;
  children: ReactNode;
  token: string;
  query: any;
};

const hideHeader = pathname =>
  isPathWithoutHeader(pathname) || !isValidRoute(pathname);

const LayoutWrapper = (props: Props) => {
  const { children, pathname = Routes.ROOT, tab, token, query } = props;
  const isPractitioner = useIsPractitioner();
  const userId = useUserId();
  const dispatch = useDispatch();
  const isFreemium = useIsFreemium();
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();

  const { appointmentId, patientPhoneNumber, apptPatientId, practitionerId } =
    query || {};
  const complexFormData = useSelector(state => state.complexForm.item);

  const isUp = useMaintenanceMaiia();
  const openComplexForm = useOpenComplexForm();
  const centerId = useCenterId();
  const {
    canDisplayDialog: canDisplayWelcomeDialog,
    handleCloseDialog: handleCloseWelcomeDialog,
  } = useDisplayAutoOnboardingWelcomeDialog();

  useEffect(() => {
    if (patientPhoneNumber && pathname !== Routes.CREATE_APPOINTMENT) {
      openComplexForm();
    }
  }, [patientPhoneNumber]);

  useEffect(() => {
    if (apptPatientId && practitionerId) {
      dispatch(
        serialActions.serial([
          () =>
            patientsActions.getOne(apptPatientId as string, {
              params: { centerId },
            }),

          () => pathname !== Routes.CREATE_APPOINTMENT && openComplexForm(),
        ]),
      );
    }
  }, [apptPatientId, practitionerId]);

  useEffect(() => {
    if (appointmentId && !complexFormData) {
      dispatch(
        serialActions.serial([
          () =>
            complexFormActions.getOne(appointmentId as string, {
              params: {
                aggregateWith:
                  'consultationReason,practitioner,patient,agendaSettings',
              },
            }),
          (state: RootState) =>
            patientsActions.setItem(state.complexForm.item?.patient || null),
          () => pathname !== Routes.CREATE_APPOINTMENT && openComplexForm(),
        ]),
      );
    }
  }, [appointmentId]);

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(30);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  useEffect(() => {
    const gtmId = config.get('GTM_ID');
    if (userId && gtmId) {
      ReactGA.gtag('config', gtmId, {
        user_id: userId,
      });
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      setScreebIdentity(dispatch, keycloak?.subject);
    }
  }, [userId, keycloak]);

  const sideBar = useMemo(
    () =>
      !isPathWithSidebar(pathname) && !isTabWithSidebar(tab) ? null : (
        <SideBar pathname={pathname} tab={tab} query={query} />
      ),
    [pathname, tab, isPractitioner, query],
  );

  if (!isUp)
    return (
      <>
        <SocketSuscribers token={token} />
        <MaintenancePage />
      </>
    );

  const page = snakeCase(pathname.slice(1)) || 'home';
  const variantSideBar = sideBar ? 'WithSideBar' : '';

  return (
    <>
      <NextSeo noindex nofollow />
      <DatacyContext.Provider value={`${page}_page`}>
        {pathname !== Routes.SIGNUP_LGC && <SocketSuscribers token={token} />}
        {userId && (
          <>
            <DynatraceDebugToken userId={userId} />
            <DialogMaster />
            {canDisplayWelcomeDialog && (
              <AutoOnboardingWelcomeDialog onClose={handleCloseWelcomeDialog} />
            )}
            <ConfirmLogoutDialog />
            <NotificationsListener />
            <DocumentOverlay />
            <VideoSessionPendingDialog />
            <TlcReminderDialog />
            <AutomaticTlcSuggestionDialog />
            <TlcErrorsDialog />
            <TlcDeactivateDialog />
            <PaymentErrorsDialog />
            <WatchPingPatient />
            <SnackBuildContainer />
            <ClearAccessEndDateDialog />
            <FusionDialogContainer />
            <MiniVideoSessionPendingDialog />
            <ErrorComponent />
            <ExportStatisticsDocumentHandler />
            <PrivacyAndTermsUpdateModal />
          </>
        )}

        {/* TODO: ADD WRAPPER FUSIONPATIENTDIALOG no props */}
        <ConsultationReasonsTimeslotRefContextProvider>
          <Layout
            pathname={pathname}
            variantSideBar={variantSideBar}
            hideHeader={hideHeader(pathname)}
            header={isFreemium ? <HeaderIsFreemium /> : <Header />}
            sideBar={sideBar}
            content={children}
          />
        </ConsultationReasonsTimeslotRefContextProvider>
      </DatacyContext.Provider>
    </>
  );
};

LayoutWrapper.getInitialProps = async ({
  ctx,
}: {
  ctx: {
    pathname: string;
    query: {
      practitionerId?: string;
      query?: string;
      centerId?: string;
      tab?: string;
      date?: string;
      oldCenterId?: string;
      rootCenterId?: string;
    };
    store: Store;
    req?: any;
    res?: any;
  };
}) => {
  const { pathname, query, store, req, res } = ctx;
  const { practitionerId, centerId, tab, rootCenterId } = query;
  const centerIdState = store.getState().centers.item?.id;
  const rootCenterIdState = store.getState().centers.rootCenterId;
  const practitionerIdState = practitionersSelectors.getItemId(
    store.getState(),
  );
  const updateLastVisitedCenter = async (state: RootState) => {
    const lastVisitedCenter = await asyncActions(
      store.dispatch,
      usersActions.updateOne(
        { id: state.users.item?.id },
        {
          chunkResource: `last-visited-center/${state.centers.item?.id}`,
        },
      ),
    );
    return lastVisitedCenter;
  };

  store.dispatch(
    // at the first error the flow is break (bad api return/timeout include)
    // TODO: WARNING: after 26/11/2019 move the logic in useEffect
    serialActions.serial(
      [
        (state: RootState) => {
          if (
            practitionerId &&
            centerId &&
            state.centers.item?.id === centerId &&
            practitionersSelectors.getItemId(state) === practitionerId
          ) {
            return serialActions.break();
          }
        },
        // // // GET USER
        (state: RootState) => {
          if (isAuthPath(pathname)) return serialActions.break();
          if (!state.users.item) return serialActions.break();
        },

        // GET DEFAULT CENTER OR CENTER BY QUERY PARAMS
        // maybe redirect if the center is forbiden
        (state: RootState) => {
          const centers =
            state.users.item?.userProInformation?.associatedCenters || [];
          const urlCenter = find(centers, { centerId });
          if (!urlCenter) {
            // check if lastVisitedCenter exists on user object
            const lastVisitedCenterId =
              state.users.item?.userProInformation?.lastVisitedCenterId;
            const lastVisitedCenter = find(centers, {
              centerId: lastVisitedCenterId,
            });

            const center =
              lastVisitedCenter ||
              find(centers, { isDefaultCenter: true }) ||
              centers[0] ||
              {};

            // @ts-ignore: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
            return centersActions.getOne(center.centerId, {
              params: { isWithExternalSyncCenters: true },
            });
          }
          if (urlCenter.centerId !== centerIdState) {
            // @ts-ignore: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
            return centersActions.getOne(urlCenter.centerId, {
              params: { isWithExternalSyncCenters: true },
            });
          }
        },

        (state: RootState) => {
          const offset = getOffset(state.centers.item);
          return offsetActions.setOffset({ offset });
        },

        // GET PRACTITIONERS LIST FOR THE SELECTED CENTER
        (state: RootState) => {
          if (
            (state.centers.item?.id !== centerIdState ||
              !practitionersSelectors.getItems(state).length ||
              rootCenterId !== rootCenterIdState) &&
            (state.centers.item?.id || rootCenterId)
          ) {
            const isSubstitute =
              state.users.item?.role?.name === 'SUBSTITUTE_PRACTITIONER';

            const practitionersParams = {
              centerId: rootCenterId || state.centers.item?.id,
              recursive: !!rootCenterId,
            };

            const substitutedListParams = {
              ...practitionersParams,
              userId: state.users.item?.id,
            };

            if (isSubstitute && isSubstituedListParams(substitutedListParams)) {
              return practitionersActions.getSubstitutedList(
                substitutedListParams,
              );
            }
            const payload = practitionersActions.getList(practitionersParams);
            return payload;
          }
        },
        // GET agendaSettings FOR SELECTED CENTER
        (state: RootState) => {
          const defaultPractitionerId =
            state?.users?.item?.userProInformation?.defaultPractitionerId;
          const currentPractitionerId = practitionerId ?? defaultPractitionerId;

          if (
            (centerId !== centerIdState ||
              !state.agendaSettings.items.length ||
              rootCenterId !== rootCenterIdState) &&
            (rootCenterId || state.centers.item?.id)
          ) {
            const practitionerIds = [
              currentPractitionerId,
              defaultPractitionerId,
            ];

            if (!practitionerIds.length) return null;

            const payload = {
              centerId:
                rootCenterId || (state.centers.item as { id: string })?.id,
              recursive: !!rootCenterId,
              practitionerIds: [currentPractitionerId, defaultPractitionerId],
            };

            return agendaSettingsActions.getList(payload);
          }
        },

        (state: RootState) => {
          const agendaSettingsState = state.agendaSettings.item;
          if (
            practitionerId &&
            centerId &&
            (agendaSettingsState?.centerId !== centerId ||
              agendaSettingsState?.practitionerId !== practitionerId)
          ) {
            const agendaSettings =
              find(state.agendaSettings.items, {
                practitionerId,
                centerId,
              }) || state.agendaSettings.items[0];

            if (agendaSettings) {
              return agendaSettingsActions.setItem(agendaSettings);
            }
          }
        },

        // return;
        // SET THE CURRENT PRACITIONER BASED ON QUERY PARAMS OR THE FIRST OF THE LIST, CAN BE NULL FOR SOME URL (ex: /settings)
        (state: RootState) => {
          if (!practitionerId && !requiresPractitionerId(pathname)) {
            return practitionersActions.setItem(null);
          }
          const isAgendaSettingsNotFound =
            state.agendaSettings.error &&
            state.agendaSettings.error.status === 404;

          const practitioners = practitionersSelectors.getItems(state);
          const practitioner = find(practitioners, { id: practitionerId });
          let selectedPractitioner =
            practitioner || state.agendaSettings.item?.practitioner || null;
          if (isAgendaSettingsNotFound) {
            selectedPractitioner =
              find(practitioners, {
                id:
                  state?.users?.item?.userProInformation?.defaultPractitionerId,
              }) || practitionersSelectors.getItems(state)?.[0];
          }
          return practitionersActions.setItem(selectedPractitioner); // could be replace by a getOne
        },

        (state: RootState) => {
          if (
            centerIdState &&
            (practitionersSelectors.getItemId(state) !== practitionerIdState ||
              state?.centers.item?.id !== centerIdState)
          ) {
            // KEEP resourceCenter if center dosent change

            const toKeepConditionallyMap = {
              appointmentsTlc: pathname === Routes.TRANSACTIONS,
              actionsHistory:
                pathname === Routes.ADMINISTRATION && tab === Tabs.ACTIVITY,
              documentsHistory:
                pathname === Routes.ADMINISTRATION && tab === 'documents',
              availabilities: pathname === Routes.CREATE_APPOINTMENT,
              complexForm: pathname === Routes.CREATE_APPOINTMENT,
              complexFormData: pathname === Routes.CREATE_APPOINTMENT,
              consultationReasons:
                pathname === Routes.SETTINGS &&
                (tab === Tabs.MOTIFS ||
                  tab === Tabs.INSTRUCTIONS ||
                  tab === 'instructions-borne'),
              consultationReasonsParent:
                pathname === Routes.SETTINGS && tab === Tabs.MOTIFS,
              notificationSettings:
                pathname === Routes.SETTINGS && tab === Tabs.NOTIFICATIONS,
              profiles:
                pathname === Routes.SETTINGS && (!tab || tab === Tabs.PROFILE),
              conventionedActs:
                pathname === Routes.SETTINGS && (!tab || tab === Tabs.PROFILE),
              appointmentsHistory: pathname === Routes.CREATE_APPOINTMENT,
              profilesParent:
                pathname === Routes.SETTINGS && (!tab || tab === Tabs.PROFILE),
              resourcesCenter:
                pathname === Routes.SETTINGS &&
                (tab === Tabs.RESOURCES || tab === Tabs.MOTIFS),
              resources:
                pathname === Routes.SETTINGS &&
                (tab === Tabs.RESOURCES || tab === Tabs.MOTIFS),
              patientInstructions:
                pathname === Routes.SETTINGS && tab === Tabs.INSTRUCTIONS,
              secretaryInstructions:
                pathname === Routes.SETTINGS &&
                tab === Tabs.SECRETARIAT_INSTRUCTIONS,
              secretaryInstructionCategories:
                pathname === Routes.SETTINGS &&
                tab === Tabs.SECRETARIAT_INSTRUCTIONS,
            };
            const toKeepConditionally = Object.keys(
              toKeepConditionallyMap,
            ).reduce(
              (list, slice) =>
                toKeepConditionallyMap[slice] ? [...list, slice] : list,
              [] as string[],
            );

            return storeActions.resetStore({
              toKeepStates: [
                'agendaSettings',
                'agendaSettingsDefault',
                'appointments',
                'appointmentsNotes',
                'authentication',
                'calendar',
                'centers',
                'centersChat',
                'chatMessages',
                'chatRooms',
                'chatUsersConnected',
                'chatUsersTyping',
                'documents',
                'expertises',
                'invitationSuggestions',
                'loading',
                'notificationSettingsTokens',
                'patients',
                'practitioners',
                'statistics',
                'timeSlots',
                'users',
                'videoSessions',
                'websocket',
                'weekTemplateCycles',
                'unreadMessages',
                'resourcesTimeSlot',
                'resourcesTimeSlotGroup',
                'searchInvitation',
                'chatInvitations',
                'rightsCenter',
                'userPractitioners',
                ...toKeepConditionally,
              ].filter(e => e),
            });
          }
        },

        // SAVE ROOT CENTER ID IN STATE TO AVOID UNNECESSARY DATA FETCHES
        () => centersActions.setRootCenterId(rootCenterId || null),

        // REDIRECT ON THE COUPLE PRACTITIONER CENTER ID (useful for navigater navigation by user)
        (state: RootState) => {
          const newCenterIdState = state.centers.item?.id || '';
          const isTLSOrSecretary = [TELESECRETARY, SECRETARY].includes(
            state.users.item?.role.name || '',
          );

          const isAgendaSettingsNotFound =
            state.agendaSettings.error &&
            state.agendaSettings.error.status === 404;

          let newPractitionerIdState =
            practitionersSelectors.getItemId(state) || '';

          if (!isAgendaSettingsNotFound) {
            newPractitionerIdState =
              state.agendaSettings.item?.practitionerId || '';
          }

          if (newPractitionerIdState === '') {
            newPractitionerIdState =
              state?.users?.item?.userProInformation?.defaultPractitionerId ||
              '';
          }

          if (!requiresPractitionerId(pathname) && !practitionerId) {
            newPractitionerIdState = '';
          }

          if (!newPractitionerIdState && requiresPractitionerId(pathname)) {
            newPractitionerIdState = state.practitioners.items[0]?.id || '';
          }

          if (
            newCenterIdState !== centerId ||
            newPractitionerIdState !== practitionerId
          ) {
            const { multiAgenda } = cookies(ctx);
            const newQuery =
              practitionerId === undefined &&
              centerId === undefined &&
              isTLSOrSecretary &&
              multiAgenda
                ? { ...query, ...JSON.parse(multiAgenda) }
                : {
                    ...query,
                    practitionerId: newPractitionerIdState,
                    centerId: newCenterIdState,
                  };
            if (req && res) {
              res.writeHead(302, {
                Location: `${pathname}?${stringify(newQuery)}`,
              });
              res.end();
            } else {
              Router.push({
                pathname,
                query: { ...Router.query, ...newQuery },
                // Spreading Router.query because ctx.query might be outdated and params could have been added, e.g. chatRoomId
              });
            }
            return serialActions.break();
          }
        },

        // // GET LIST OF VIDEOSSESIONS CALL ON EVERY URL CHANGEMENTS (query params include)
        (state: RootState) => {
          const defaultPractitionerId = find(
            state.agendaSettingsDefault.items,
            { centerId },
          )?.practitionerId;

          const agendaSettingsDefaultsWithTlc = state.agendaSettingsDefault.items.filter(
            agendaSettingsDefault => agendaSettingsDefault.isTeleconsultation,
          );

          const isTlcCenterOrAmcAndHasCenterId = getTlcCenterOrAmcAndHasCenterId(
            state,
            centerId,
          );

          if (
            agendaSettingsDefaultsWithTlc.length &&
            centerId &&
            defaultPractitionerId
          ) {
            return videoSessionsActions.getList({
              ...(isTlcCenterOrAmcAndHasCenterId && { centerId }),
              practitionerId: defaultPractitionerId,
              statuses: [PENDING, WAITING, STARTED].join(','),
              aggregateWith: 'patient,consultationReason,teleconsultationRelay',
            });
          }
        },
        // Save center as last visited center
        async (state: RootState) => {
          if (!req && state.centers.item?.id) {
            await updateLastVisitedCenter(state);
          }
        },
      ],
      false,
    ),
  );
  return { pathname, tab, query };
};

// LayoutWrapper.whyDidYouRender = true;

// export default LayoutWrapper;
export default securedPage(LayoutWrapper);
