import React, { forwardRef } from 'react';
import { MenuItemProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { DatacyContext, useDatacyName } from '@docavenue/chat';

import Highlight from '../Highlight/Highlight';
import MenuItem from '../MenuItem';

const useStyles = makeStyles<{
  disabled: boolean;
  selected: boolean;
  highlighted: boolean;
}>()((theme: any, { disabled, selected, highlighted }) => ({
  root: {
    ...theme.typography.listItemBody,
    paddingTop: theme.size.spacing[1],
    paddingBottom: theme.size.spacing[1],
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
    backgroundColor: theme.palette.background.defaultGrey,
    fontWeight: highlighted ? 'bold' : 'normal',
    // eslint-disable-next-line no-nested-ternary
    color: disabled
      ? theme.palette.grey[500]
      : selected
      ? theme.palette.accent.main
      : theme.palette.common.body,

    '&:hover': {
      fontWeight: disabled ? 'normal' : 'bold',
    },
    cursor: disabled ? 'not-allowed' : 'pointer',
  },
}));

type Props = {
  className?: string;
  highlighted?: boolean;
  datacy?: string;
  searchTerm?: string;
  // children: any;
  selected?: boolean;
  disabled?: boolean;
  button?: true;
} & MenuItemProps;

const HighlightableListItem = forwardRef(
  (props: Props, ref: React.Ref<HTMLLIElement>) => {
    const {
      className,
      highlighted,
      datacy = 'unknown-highlightable-list-item',
      searchTerm,
      children,
      selected = false,
      disabled = false,
      onClick = () => {},
      ...rest
    } = props;
    const datacyName = useDatacyName(datacy);
    const { classes, cx } = useStyles({
      highlighted: highlighted ?? false,
      selected,
      disabled,
    });
    const handleClick = (
      event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    ) => {
      if (!disabled) onClick(event);
    };
    return (
      <DatacyContext.Provider value={datacyName}>
        <MenuItem
          {...rest}
          onClick={handleClick}
          className={cx(classes.root, className)}
          ref={ref}
        >
          {typeof children === 'string' ? (
            <Highlight
              searchTerm={!selected ? searchTerm ?? '' : children}
              text={children}
            />
          ) : (
            children
          )}
        </MenuItem>
      </DatacyContext.Provider>
    );
  },
);

HighlightableListItem.displayName = 'HighlightableListItem';

export default HighlightableListItem;
