import React, { ElementType } from 'react';
import { FastField, Field, Form } from 'formik';

import { PublicInformation } from '@maiia/model/generated/model/api-pro/api-pro';

import { GridWrapper } from '../../utils/components';
import FormFieldWithIcon from './FormFieldWithIcon';

type FieldTypeCommons = {
  name: string;
  fieldProps?: Object;
  fieldRender?: any; // TODO: type with absctract
  extendField?: Object;
  Icon?: any;
  fast?: boolean;
  wrapperComponent?: ElementType<any>;
  wrapperProps?: Object;
};
interface FieldTypeWithComponent extends FieldTypeCommons {
  component: ElementType<any>;
  fields?: never;
}
interface FieldTypeWithNestedFields extends FieldTypeCommons {
  fields: FieldType[];
  component?: never | string;
}
export type FieldType = FieldTypeWithComponent | FieldTypeWithNestedFields;

export type RatingField = FieldType & {
  fieldProps?: {
    includeInRating: boolean;
    isFilled: ((publicInfo: PublicInformation | null) => boolean) | null;
    ratingLabel: string | null;
    [otherKeys: string]: unknown;
  };
};

export type GetFields = (params?: any) => RatingField[];

type Props = {
  id?: string;
  fields: FieldType[];
  className?: string;
  fieldRender?: (field: Object, index: number) => ElementType<any>;
  children?: React.ReactNode;
  gridkey?: string;
  datacy?: string;
};

type FieldComponentPocProps = {
  name: string;
  fast?: boolean;
  gridkey?: string;
};

export const FieldComponentPoc = ({
  children,
  fast,
  name,
  gridkey,
}: FieldComponentPocProps & { children: React.ReactElement }) => {
  const FieldComponent = fast ? FastField : Field;

  return (
    <FieldComponent key={name} name={name}>
      {(props: { field: Object; form: Object }) => (
        <>
          {React.cloneElement(children, {
            field: props.field,
            form: props.form,
            gridkey,
          })}
        </>
      )}
    </FieldComponent>
  );
};

export function withFieldComponentPoc<T extends any>(WrappedComponent: any) {
  return function CustomField(
    props: FieldComponentPocProps & Omit<T, 'field' | 'form' | 'meta'>,
  ) {
    const { fast, name, gridkey } = props;
    const FieldComponent = fast ? FastField : Field;
    return (
      <FieldComponent key={name} name={name}>
        {(fieldProps: { field: Object; form: Object }) => (
          <WrappedComponent
            {...props}
            field={fieldProps.field}
            form={fieldProps.form}
            gridkey={gridkey}
            datacy={name}
          />
        )}
      </FieldComponent>
    );
  };
}

export const fieldRenderDefault = (fieldParams: FieldType) => {
  const {
    name,
    component,
    Icon,
    fieldProps = {},
    extendField = {},
    fast = false,
    wrapperComponent,
    wrapperProps = {},
  } = fieldParams;

  const Component = component || (() => null);
  const gridkey = name;
  const FieldComponent = fast ? FastField : Field;
  const field = (
    <FieldComponent
      key={name}
      name={name}
      // useful for GridWrapper
      gridkey={gridkey}
    >
      {(props: { field: Object; form: Object; gridkey: string }) => {
        const newProps = {
          ...props,
          field: { ...props.field, ...extendField },
        };
        const jsx = (
          // repeat gridkey to pass in component
          <Component
            key={gridkey}
            // @ts-ignore
            gridkey={gridkey}
            datacy={name}
            {...newProps}
            {...fieldProps}
          />
        );
        if (wrapperComponent) {
          const Wrapper = wrapperComponent;
          return (
            <Wrapper {...wrapperProps} gridkey={`${gridkey}_wrapper`}>
              {jsx}
            </Wrapper>
          );
        }
        return jsx;
      }}
    </FieldComponent>
  );
  if (Icon) {
    return (
      <FormFieldWithIcon
        key={name}
        gridkey={gridkey}
        Icon={Icon}
        // isIconHigtLigth={!form.values[name] && fieldProps.required}
      >
        {field}
      </FormFieldWithIcon>
    );
  }
  return field;
};

const FieldsBuilder = (props: Props) => {
  const {
    fields,
    fieldRender = fieldRenderDefault,
    children,
    className,
    id,
    ...rest
  } = props;
  return (
    <GridWrapper
      id={id}
      component={Form}
      role="form"
      className={className}
      {...rest}
    >
      {fields.map((field, index) =>
        (field.fieldRender || fieldRender)(field, index),
      )}
      {children}
    </GridWrapper>
  );
};

export default FieldsBuilder;
