import { Rest } from '@docavenue/core';

import config from './config';

const rest = new Rest({
  api: (process as any).browser
    ? config.get('API_ENDPOINT')
    : config.get('SERVER_API_ENDPOINT'),
  auth: config.get('AUTH_ENDPOINT'),
  address: config.get('ADDRESS_ENDPOINT'),
  defaultApiScope: 'pro',
});

export default rest;
