export interface MaiiaShape {
  rounded: {
    sm: number;
    md: number;
    lg: number;
    full: number;
  };
}

// allow configuration using `createTheme`
export interface MaiiaShapeOptions extends Partial<MaiiaShape> {}

const shapeOverride: MaiiaShapeOptions = {
  rounded: {
    sm: 2,
    md: 4,
    lg: 8,
    full: 9999,
  },
};

export default shapeOverride;
