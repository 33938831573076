import { actionsGeneratorV2 } from '@docavenue/core';

const acknowledgeDocumentsActions = actionsGeneratorV2({
  resource: 'acknowledgeDocuments',
  chunkUrlResource: 'acknowledge-documents',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { create } = acknowledgeDocumentsActions;

const acknowledge = (
  params: { practitionerId: string; patientId: string },
  options: Object = {},
) =>
  create(params, {
    chunkId: params.patientId,
    chunkResource: params.practitionerId,
    ...options,
  });

const acknowledgeByDocumentId = (
  params: { documentId: string },
  options: Object = {},
) =>
  create(params, {
    chunkResource: params.documentId,
    ...options,
  });

export default {
  ...acknowledgeDocumentsActions,
  acknowledge,
  acknowledgeByDocumentId,
};
