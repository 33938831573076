import React, { useEffect, useRef, useState } from 'react';
import { TooltipProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';


import Tooltip from './Tooltip';

const useStyles = makeStyles()((theme: any) => ({
  ellipsis: {
    ...theme.ellipsis,
  },
}));

const EllipsisTooltip = ({
  children,
  className,
  ...tooltipProps
}: TooltipProps) => {
  const ellipsisRef = useRef<HTMLDivElement>(null);
  const [hasEllipsis, setHasEllipsis] = useState<boolean>(false);
  const { classes, cx } = useStyles();

  useEffect(() => {
    if (!ellipsisRef?.current) return;

    const { offsetWidth, scrollWidth } = ellipsisRef?.current;
    if (offsetWidth < scrollWidth) {
      setHasEllipsis(true);
    }
  }, [ellipsisRef?.current, tooltipProps.title]);

  const content = (
    <div className={cx(classes.ellipsis, className)} ref={ellipsisRef}>
      {children}
    </div>
  );

  return hasEllipsis ? <Tooltip {...tooltipProps}>{content}</Tooltip> : content;
};

export default EllipsisTooltip;
