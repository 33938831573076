import { ENQUEUE_SNACK, SnackNotification } from '../reducer/snacks';

export type SnackActions = {
  enqueueSnack: (
    params: SnackNotification,
  ) => {
    type: string;
    payload: SnackNotification;
  };
};

export const enqueueSnack = (params: SnackNotification) => {
  const {
    id,
    action,
    resource,
    message,
    variant = 'success',
    variables,
    duration,
    actionNode,
    onClick,
    onExited,
  } = params;
  return {
    type: ENQUEUE_SNACK,
    payload: {
      id,
      action,
      resource,
      message,
      variant,
      variables,
      duration,
      actionNode,
      onClick,
      onExited,
    },
  };
};

const snacksActions = {
  enqueueSnack,
};

export default snacksActions;
