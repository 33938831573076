import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';

import { serialActions } from '@docavenue/core';

import { useTranslation } from '../../../i18n';
import { useEditAppointmentInComplexFormAction } from '../../../src/hooks/actions';
import { AppointmentNotificationCenter } from '../../../types/pro.types';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Typography from '../../atoms/Typography';
import { PatientLabelField } from '../../molecules/PatientLabel';
import NotificationWrapper from './NotificationWrapper';

import {
  InternetPatientIcon,
  PatientName,
} from '@/components/molecules/PatientLabel/PatientLabel';
import {
  NOTIFICATION_CENTER_CANCELLED,
  NOTIFICATION_CENTER_MODIFIED,
} from '@/src/constants';
import { GAActions, GAActionStatus, GALabels } from '@/src/constants/tracking';

type NotificationAppointmentViewProps = {
  notification: AppointmentNotificationCenter;
  closeModal: () => void;
  triggerGAEvent: Function;
};

const getFormattedDate = (date: string) =>
  moment(date).format('dddd D MMMM [-] LT');

const useStyles = makeStyles<{ color: string }>()((theme, { color }) => ({
  notificationAppointmentView: {
    backgroundColor: theme.palette[color][100],
    color: theme.palette[color][500],
    transition: 'all 0.2s ease',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    '&:hover': {
      backgroundColor: theme.palette[color][150],
      borderTop: `1px solid ${theme.palette[color][300]}`,
      borderBottom: `1px solid ${theme.palette[color][300]}`,
    },
  },
}));

const NotificationAppointmentView: React.FunctionComponent<NotificationAppointmentViewProps> = props => {
  const { notification, closeModal, triggerGAEvent } = props;
  const { displayData: appointment } = notification;

  const { t } = useTranslation('common');
  const isCancelled =
    notification.event === NOTIFICATION_CENTER_CANCELLED ||
    (notification.event === NOTIFICATION_CENTER_MODIFIED &&
      appointment.appointmentStatus === NOTIFICATION_CENTER_CANCELLED);

  const isUpdated =
    notification.event === NOTIFICATION_CENTER_MODIFIED &&
    appointment.appointmentStatus !== NOTIFICATION_CENTER_CANCELLED;

  // eslint-disable-next-line no-nested-ternary
  const color = isCancelled ? 'red' : isUpdated ? 'orange' : 'green';
  const { classes, cx } = useStyles({ color });
  const dispatch = useDispatch();
  const editAppointmentInComplexFormAction = useEditAppointmentInComplexFormAction();

  function getNotificationTitleKey() {
    if (isCancelled) {
      return 'notification_appointment_canceltitle';
    }

    if (isUpdated) {
      return 'notification_appointment_updatetitle';
    }

    return 'notification_appointment_validatetitle';
  }

  const notificationTitle = `${t(getNotificationTitleKey())} @`;

  const onClick = () => {
    triggerGAEvent(
      GAActions.ClickNotification,
      GAActionStatus.SUCCESS,
      GALabels.AppointmentNotification,
    );
    closeModal();
    dispatch(
      serialActions.serial([
        () =>
          editAppointmentInComplexFormAction({
            ...notification.displayData,
          }),
      ]),
    );
  };

  return (
    <NotificationWrapper
      notification={notification}
      onClick={onClick}
      className={cx(
        classes.notificationAppointmentView,
        'notificationAppointmentViewRoot',
      )}
    >
      <Typography variant="subtitle" className="notificationTitle">
        {notificationTitle}
      </Typography>
      {isUpdated && appointment.previousStartDate ? (
        <>
          <Typography variant="bodySmall" className="notificationDate">
            {getFormattedDate(appointment.previousStartDate)}
          </Typography>
          <div className="modificationDate">
            <FontAwesomeIcon
              name="arrow-turn-down-right:regular"
              className="updateArrowIcon h-5 w-5"
            />
            <Typography variant="bodySmall">
              {getFormattedDate(appointment.startDate)}
            </Typography>
          </div>
        </>
      ) : (
        <Typography variant="bodySmall" className="notificationDate">
          {getFormattedDate(appointment.startDate)}
        </Typography>
      )}
      {appointment.patient && (
        <Typography variant="bodySmall" className="patientText">
          <FontAwesomeIcon name="user:solid" className="mr-1 h-5 w-5" />
          <PatientName
            field={PatientLabelField.FULLNAME}
            partialPatient={appointment.patient}
          />
          <InternetPatientIcon
            partialPatient={appointment.patient}
            wrapperComponent="span"
            wrapperProps={{
              className: 'ml-1 [&_div]:inline-block',
            }}
          />
        </Typography>
      )}
    </NotificationWrapper>
  );
};

export default NotificationAppointmentView;
