import { MedicalDocument } from './rootState';

export const SET_MEDICAL_REPORT = 'SET_MEDICAL_REPORT';
export const initialStateMedicalReport: MedicalDocument = {
  content: '',
  attachments: [],
};

function medicalReport(
  state = initialStateMedicalReport,
  action: {
    type: string;
    payload: MedicalDocument;
  },
) {
  const { type } = action;
  switch (type) {
    case SET_MEDICAL_REPORT: {
      return action.payload;
    }
    default:
      return state;
  }
}

export default medicalReport;
