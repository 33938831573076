import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';

import { useGetUnreadMessages } from '@docavenue/chat';

import { ROUTES_ALLOWED_TO_CHAT } from '../../../src/constants/routesLists';
import { useCenterIdChatQuery } from '../../../src/hooks/query';
import { useIsOfferChat, useIsSubstitute } from '../../../src/hooks/selectors';
import { practitionersSelectors } from '../../../src/selector';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import IconButton from '../../atoms/IconButton';
import ChatIconButton from '../../molecules/ChatIconButton';
import Menu from '../Menu';
import NeedContactAgenda from '../NeedContactAgenda';
import NeedContactTlc from '../WaitingRoomDialog/NeedContactTlc';

const IconsMenuFreemium = () => {
  const router = useRouter();
  const isSubstitute = useIsSubstitute();
  const practitioners = useSelector(practitionersSelectors.getItems);
  const isChat = useIsOfferChat();
  const centerIdChatQuery = useCenterIdChatQuery();
  const isChatOpen = router.pathname === '/chat';

  const refMenuContactAgenda = useRef<any>(null);
  const refMenuContactTlc = useRef<any>(null);
  useGetUnreadMessages(ROUTES_ALLOWED_TO_CHAT);

  const disableChat = !isChat || !!(isSubstitute && isEmpty(practitioners));

  const onCloseContactAgenda = () => {
    refMenuContactAgenda.current?.close();
  };
  const onCloseContactTlc = () => {
    refMenuContactTlc.current?.close();
  };

  return (
    <div className="bg-freemium-menu z-10 flex items-center gap-2 rounded-lg px-2">
      {!disableChat && (
        <ChatIconButton
          disabled={isChatOpen}
          onClick={() => {
            if (!isChatOpen) {
              router.push({
                pathname: '/chat',
                query: {
                  ...router.query,
                  centerId: centerIdChatQuery,
                },
              });
            }
          }}
          chatOpen={isChatOpen}
        />
      )}
      <Menu
        aria-label="Open"
        ref={refMenuContactAgenda}
        open={false}
        autoClose
        disablePortal={false}
        placement="bottom-start"
        trigger={
          <IconButton size="large">
            {' '}
            <FontAwesomeIcon
              name="calendar-day:regular"
              className="h-7 w-7"
            />{' '}
          </IconButton>
        }
      >
        <NeedContactAgenda close={onCloseContactAgenda} />
      </Menu>
      <Menu
        aria-label="Open"
        ref={refMenuContactTlc}
        open={false}
        autoClose
        disablePortal={false}
        placement="bottom-start"
        trigger={
          <IconButton size="large">
            {' '}
            <FontAwesomeIcon name="video:regular" className="h-7 w-7" />{' '}
          </IconButton>
        }
      >
        <NeedContactTlc close={onCloseContactTlc} />
      </Menu>
    </div>
  );
};

export default IconsMenuFreemium;
