export interface MaiiaPaletteColor {
  light: string;
  main: string;
  dark: string;
  contrastText?: string;
}

interface MaiiaPaletteAction {
  disabledBackground: string;
  disabled: string;
}

export interface MaiiaPalette {
  primary: MaiiaPaletteColor;
  primaryTonal: MaiiaPaletteColor;
  secondary: MaiiaPaletteColor;
  secondaryTonal: MaiiaPaletteColor;
  error: MaiiaPaletteColor;
  warning: MaiiaPaletteColor;
  info: MaiiaPaletteColor;
  infoTonal: MaiiaPaletteColor;
  success: MaiiaPaletteColor;
  action: MaiiaPaletteAction;
  text: MaiiaPaletteColor;
}

export interface MaiiaPaletteOptions extends Partial<MaiiaPalette> {}

const maiiaPalette: MaiiaPalette = {
  primary: {
    main: '#fb3b7c',
    light: 'hsl(350, 86%, 80%)',
    dark: '#D41153',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#08A6A0',
    light: '#08A6A0',
    dark: '#067F7A',
    contrastText: '#FFFFFF',
  },
  error: {
    light: '#FDE2E2',
    main: '#E51515',
    dark: '#D41153',
  },
  warning: {
    main: '#E07800',
    light: '#E07800',
    dark: '#B76200',
  },
  info: {
    main: '#50B3C8',
    light: '#009DC6',
    dark: '#459AAC',
  },
  success: {
    main: '#64C9A7',
    light: '#00AA3A',
    dark: '#00AA3A',
  },
  action: {
    disabled: 'rgba(0, 0, 0, 0.26)', // text color
    disabledBackground: 'rgba(0, 0, 0, 0.12)', // background color
  },
  text: {
    main: '#336071',
    light: '#B0B0B0',
    dark: '#4D4D4D',
    contrastText: '#FFFFFF',
  },
  primaryTonal: {
    main: '#FFD6E4',
    light: '#FFF5F8',
    dark: '#FEA9C7',
    contrastText: '#067F7A',
  },
  secondaryTonal: {
    main: '#D5F6F5',
    light: '#BEE9E8',
    dark: '#C0F1EF',
    contrastText: '#067F7A',
  },
  infoTonal: {
    main: '#EDF7FA',
    light: '#009DC6',
    dark: '#4EB3C9',
  },
  // source: https://www.figma.com/file/hV9gNh6DaJoqRsS4UWs1o9/Base-new-DS-%F0%9F%A5%9D?type=design&node-id=1267-131345&mode=design&t=sySIILghqXRQo7px-0
};

export default maiiaPalette;
