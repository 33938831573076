export const CHATUTILS_IS_CREATE_GROUP_DIALOG_OPEN =
  'CHATUTILS_IS_CREATE_GROUP_DIALOG_OPEN';
export const CHATUTILS_IS_ADD_MEMBER_DIALOG_OPEN =
  'CHATUTILS_IS_ADD_MEMBER_DIALOG_OPEN';
export const CHATUTILS_IS_HEADER_GROUP_SIDEBAR_OPEN =
  'CHATUTILS_IS_HEADER_GROUP_SIDEBAR_OPEN';
export const CHATUTILS_IS_PATIENT_DETAILS_DRAWER_OPEN =
  'CHATUTILS_IS_PATIENT_DETAILS_DRAWER_OPEN';
export const CHATUTILS_SEARCH_TERM = 'CHATUTILS_SEARCH_TERM';
export const CHATUTILS_SHARED_PATIENT_INFOS = 'CHATUTILS_SHARED_PATIENT_INFOS';

const setCreateGroupDialogOpen = (isCreateGroupDialogOpen: boolean) => ({
  type: CHATUTILS_IS_CREATE_GROUP_DIALOG_OPEN,
  payload: isCreateGroupDialogOpen,
});
const setAddMembersDialogOpen = (isAddMemberDialogOpen: boolean) => ({
  type: CHATUTILS_IS_ADD_MEMBER_DIALOG_OPEN,
  payload: isAddMemberDialogOpen,
});
const setHeaderGroupSidebarOpen = (isHeaderGroupSidebarOpen: boolean) => ({
  type: CHATUTILS_IS_HEADER_GROUP_SIDEBAR_OPEN,
  payload: isHeaderGroupSidebarOpen,
});

const setPatientDetailsDrawerOpen = (isPatientDetailsDrawerOpen: boolean) => ({
  type: CHATUTILS_IS_PATIENT_DETAILS_DRAWER_OPEN,
  payload: isPatientDetailsDrawerOpen,
});

const setChatSearchTerm = (searchTerm: string) => ({
  type: CHATUTILS_SEARCH_TERM,
  payload: searchTerm,
});
const setSharedPatientInfos = (
  sharedPatientInfos: { creationDate: string } | null,
) => ({
  type: CHATUTILS_SHARED_PATIENT_INFOS,
  payload: sharedPatientInfos,
});

const chatUtilsActions = {
  setCreateGroupDialogOpen,
  setAddMembersDialogOpen,
  setHeaderGroupSidebarOpen,
  setPatientDetailsDrawerOpen,
  setChatSearchTerm,
  setSharedPatientInfos,
};

export default chatUtilsActions;
