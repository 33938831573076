import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@kiiwi/ui';

import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

const TlcNetworkTestingLoading = () => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center">
      <FontAwesomeIcon
        name="spinner-third:duotone"
        className="mx-2 h-5 w-5 animate-spin"
      />
      <div>
        <Typography variant="body-1" className="font-semibold">
          {t('tlc_pre_call_loading__title')}
        </Typography>
        <Typography variant="body-1">
          {t('tlc_pre_call_loading__content')}
        </Typography>
      </div>
    </div>
  );
};

export default TlcNetworkTestingLoading;
