import {
  FontStyle,
  TypographyStyle,
  TypographyUtils,
  Variant,
} from '@mui/material/styles/createTypography';
import { CSSProperties } from '@mui/styles';

export interface MaiiaTypography
  extends Record<Variant, TypographyStyle>,
    FontStyle,
    TypographyUtils {
  maiiaButton: {
    fontFamily?: CSSProperties['fontFamily'];
    fontSize?: CSSProperties['fontSize'];
    fontWeight?: CSSProperties['fontWeight'];
  };
}

export interface MaiiaTypographyOptions extends Partial<MaiiaTypography> {}

const maiiaTypography: MaiiaTypographyOptions = {
  fontFamily: ['"Source Sans 3"'].join(','),
  maiiaButton: {
    fontFamily: '"Source Sans 3"',
    fontSize: '16px',
    fontWeight: 400,
  },
  body1: {
    fontSize: '16px',
  },
  body2: {
    fontSize: '14px',
  },
  caption: {
    fontSize: '16px',
  },
};

export default maiiaTypography;
