import { actionsGeneratorV2 } from '@docavenue/core';

const appointmentsTlcActions = actionsGeneratorV2({
  resource: 'appointmentsTlc',
  chunkUrlResource: 'appointments',
});

const { download: _download } = appointmentsTlcActions;

const download = (params: Object, options: Object = {}) =>
  _download(params, {
    chunkUrlResource: 'tlc-history',
    chunkResource: 'export',
    ...options,
  });

export default {
  ...appointmentsTlcActions,
  download,
};
