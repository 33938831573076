export const SET_TELE_STATUS = 'SET_TELE_STATUS';
export const SET_PRACTITIONER_VALUE = 'SET_PRACTITIONER_VALUE';

const setTeleStatus = (status: string) => ({
  type: SET_TELE_STATUS,
  payload: status,
});

const setTelePractitionerValue = (value: string | null) => ({
  type: SET_PRACTITIONER_VALUE,
  payload: value,
});

const teleExpertiseFiltersActions = {
  setTeleStatus,
  setTelePractitionerValue,
};

export default teleExpertiseFiltersActions;
