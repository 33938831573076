import { actionsGeneratorV2, REVERT } from '@docavenue/core';

const timeSlotsActions = actionsGeneratorV2({
  resource: 'timeSlots',
  chunkUrlResource: 'time-slots',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { create, getList: _getList } = timeSlotsActions;

const revert = (
  params: { practitionerId?: string | null; patientId?: string | null },
  options: Object = {},
) =>
  create(params, {
    chunkId: 'reverts',
    type: REVERT,
    ...options,
  });

const getTimeSlotResourceConflict = (params: {
  from: string;
  to: string;
  timeSlotId?: string;
  timeSlotResourceId: string;
}) =>
  _getList(params, {
    chunkUrlResource: 'time-slots/by-timeslot-resource',
  });

const ACTIVATE_LIGHT_TIMESLOT = false;
let getList = _getList;

if (ACTIVATE_LIGHT_TIMESLOT) {
  getList = (params: any, options: any = {}) =>
    _getList(params, {
      chunkUrlResource: 'light-time-slots',
      ...options,
    });
}

export default {
  ...timeSlotsActions,
  revert,
  getTimeSlotResourceConflict,
  getList,
};
