import { actionsGeneratorV2 } from '@docavenue/core';
import { TimeSlotResourceGroupDTO } from '@maiia/model/generated/model/api-pro/api-pro';

const resourcesTimeSlotGroupActions = actionsGeneratorV2({
  resource: 'resourcesTimeSlotGroup',
  chunkUrlResource: 'time-slot-resource-groups',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { updateManyPost } = resourcesTimeSlotGroupActions;

const updatePosition = (
  items: TimeSlotResourceGroupDTO[],
  options: Object = {},
) =>
  updateManyPost(items, {
    chunkUrlResource: 'update-time-slot-resource-groups',
    ...options,
  });

export default {
  ...resourcesTimeSlotGroupActions,
  updatePosition,
};
