import { createContext, useContext } from 'react';

import { GACategories, TrackUserEvent } from '@/src/constants/tracking';

export type AnalyticsContextType = {
  trackUserEvent?: TrackUserEvent;
  GACategory?: GACategories;
};
const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext) || {};

  if (context === undefined) {
    throw new Error(
      'useAnalyticsContext must be used within an AnalyticsContext.Provider',
    );
  }

  return context;
};

export default AnalyticsContext;
