import React from 'react';
import { InputProps as IProps } from '@mui/material';

import { TextFieldController } from '../../atoms/Fields';

type Props = {
  setOpen: Function;
  InputProps: Partial<IProps>;
};

const InputComponent = (props: Props) => {
  const { setOpen, InputProps, ...rest } = props;
  const { onFocus, onBlur, ...restInputProps } = InputProps;
  return (
    // @ts-ignore
    <TextFieldController
      {...rest}
      InputProps={{
        ...restInputProps,
        onFocus: (...args) => {
          setOpen(true);
          // eslint-disable-next-line no-unused-expressions
          onFocus && onFocus(...args);
        },
      }}
    />
  );
};

InputComponent.displayName = 'InputComponent';

export default InputComponent;
