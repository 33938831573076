import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';

import { ProductNotificationCenter } from '../../../types/pro.types';
import Typography from '../../atoms/Typography';
import NotificationWrapper from './NotificationWrapper';

import { GAActions, GAActionStatus, GALabels } from '@/src/constants/tracking';

const useStyles = makeStyles()((theme: any) => ({
  notificationProductRoot: {
    background: theme.palette.charte.gradient,
    color: theme.palette.common.white,
    filter: 'brightness(100%)',
    transition: 'all 0.2s ease',
    borderTop: '1px solid transparent',
    borderBottom: '1px solid transparent',
    '&:hover': {
      filter: 'brightness(105%)',
      borderTop: `1px solid ${theme.palette.main.dark}`,
      borderBottom: `1px solid ${theme.palette.main.dark}`,
    },
  },
}));

type NotificationProductViewProps = {
  notification: ProductNotificationCenter;
  closeModal: () => void;
  triggerGAEvent: Function;
};

const NotificationProductView = (props: NotificationProductViewProps) => {
  const { notification, closeModal, triggerGAEvent } = props;
  const { title, description, url } = notification.displayData;
  const { classes, cx } = useStyles();
  const { t } = useTranslation('common');
  const handleChangeView = () => {
    triggerGAEvent(
      GAActions.ClickNotification,
      GAActionStatus.SUCCESS,
      GALabels.NewsNotification,
    );
    closeModal();
    if (url) window.open(url, '_blank');
  };

  return (
    <NotificationWrapper
      notification={notification}
      className={cx(
        'notificationProductRoot',
        classes.notificationProductRoot,
      )}
    >
      <Typography variant="subtitle" className="notificationTitle">
        {title}
      </Typography>

      <Typography
        variant="bodySmall"
        className="notificationText"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
      {url && (
        <Typography onClick={handleChangeView} className="link">
          {t('notification_product_more_info')}
        </Typography>
      )}
    </NotificationWrapper>
  );
};

export default NotificationProductView;
