import { createContext } from 'react';

interface ActionCallbacksInterface {
  close: () => void;
}

const ActionCallbacksContext = createContext<ActionCallbacksInterface | null>(
  null,
);

export default ActionCallbacksContext;
