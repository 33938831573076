/* eslint-disable react/jsx-key */
import React from 'react';

import { Trans, useTranslation } from '../../../i18n';
import { useSendMailContact } from '../../../src/hooks/actions';
import MarketingMessage from '../../molecules/MarketingMessage';

type Props = {
  close: () => void;
};

const NeedContactTlc = (props: Props) => {
  const { close } = props;
  const { t } = useTranslation();

  const { sendMail, error, message } = useSendMailContact({
    objectMail: 'header_need_contact_tlc_mail_object',
    messageMail: 'header_need_contact_tlc_mail_message',
    contactReason: 'UPSELL_TLC',
  });

  return (
    <MarketingMessage
      errorMessage={error}
      message={message || null}
      onClick={sendMail}
      mainIllu="/static/images/illu-market-tlc.svg"
      catchTitle={t('WaitingRoomDialog_NeedContactTlc_catchtitle')}
      catchPhrases={[
        <Trans
          i18nKey="common:WaitingRoomDialog_NeedContactTlc_catchphrase1"
          components={[<strong key="strong" />]}
        />,
        <Trans
          i18nKey="common:WaitingRoomDialog_NeedContactTlc_catchphrase2"
          components={[<strong key="strong" />]}
        />,
        <Trans
          i18nKey="common:WaitingRoomDialog_NeedContactTlc_catchphrase3"
          components={[<strong key="strong" />]}
        />,
      ]}
      close={close}
    />
  );
};

export default NeedContactTlc;
