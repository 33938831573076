import React, { forwardRef, memo } from 'react';
import isEqual from 'lodash/isEqual';
import { makeStyles } from 'tss-react/mui';

import { useDatacyName } from '@docavenue/chat';

import { useTranslation } from '../../../i18n';
import ListItem from '../../atoms/ListItem';
import MaterialList from '../../atoms/MaterialList';

const useStyles = makeStyles<{ isOpen: boolean }>()((theme, { isOpen }) => ({
  ListComponent: {
    width: '100%',
    '--itemSize': '26px',
    maxHeight: 'calc(8 * var(--itemSize))',
    overflowY: 'auto',
    zIndex: theme.zIndex.listComponent,
    position: 'absolute',
    padding: 0,
    backgroundColor: theme.palette.background.defaultGrey,
    borderWidth: 0,
    boxShadow: isOpen ? theme.shadows[7] : 'none',
    marginTop: theme.spacing(1),
  },
}));

type Props = {
  isLoading?: boolean;
  isOpen: boolean;
  children?: any;
  className?: string;
  closeMenu?: () => void;
};

const ListComponent = forwardRef(
  (props: Props, ref: React.Ref<HTMLUListElement>) => {
    const { t } = useTranslation();
    const {
      isLoading = false,
      isOpen = false,
      children,
      closeMenu,
      ...rest
    } = props;
    const nextChildren =
      !children || children.length === 0 ? [] : [...children];
    if (!isLoading && isOpen && nextChildren.length === 0)
      nextChildren.push(<ListItem disabled>{t('search_empty')}</ListItem>);
    const { classes } = useStyles({ isOpen });
    const datacy = useDatacyName('list');
    return (
      <MaterialList
        datacy={datacy}
        ref={ref}
        className={classes.ListComponent}
        {...rest}
      >
        {nextChildren}
      </MaterialList>
    );
  },
);

export default memo(ListComponent, (p, n) => isEqual(p, n));
