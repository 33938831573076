import remove from 'lodash/remove';

import { reducersGenerator, UPDATE_SUCCESS } from '@docavenue/core';
import { Patient } from '@maiia/model/generated/model/api-pro/api-pro';

const removeItems = (
  items: Array<Patient & { action?: { payload: { id: string } } }>,
  id: string | number,
  predicate: (
    elem: Patient & { action?: { payload: { id: string } } },
  ) => boolean = e => e.id === id,
) => {
  if (!id) return items;
  remove(items, predicate);
  return items;
};

const surchargeReducer = () => {
  const obj: any = {};

  obj[UPDATE_SUCCESS] = (
    state: {
      item: (Patient & { error?: any }) | null;
      items: (Patient & { error?: any })[];
      failedActions: Object[];
    },
    action: { payload: Patient },
  ) => {
    const { id, mergedPatientSourceId } = action.payload;
    let item = state.item ? { ...state.item } : null;
    const items = [...state.items];
    const index = items.findIndex(e => e.id === id);
    if (index !== -1) {
      items[index] = { ...action.payload, error: null };
    }
    const failedActions = removeItems(
      // @ts-ignore
      [...state.failedActions],
      id,
      e => e.action?.payload.id === id,
    );

    if (item && item.id === id) {
      item = { ...action.payload, error: null };
    }

    return {
      ...state,
      item,
      items: removeItems(items, mergedPatientSourceId || ''),
      failedActions,
      isLoading: false,
      error: null,
    };
  };

  return obj;
};

const patients = reducersGenerator({
  name: 'patients',
  surchargeReducer,
});

export default patients;
