import React from 'react';
import { usePrevious } from 'react-use';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  },
  20% {
    transform: scale(1.4);
  },
  50% {
    transform: scale(0.9);
  },
  80% {
    transform: scale(1.2);
  },
  100% {
    transform: scale(1);
  },
`;

const sonarAnimation = keyframes`
  0% {
    transform: scale(.9);
    opacity: 1;
  },
  100% {
    transform: scale(2);
    opacity: 0;
  },
`;

const useStyles = makeStyles()((theme: any) => ({
  badge: {
    position: 'relative',
    borderRadius: '20px',
    height: 20,
    width: 20,
    border: '2px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: theme.size.font.sm,
    '&.more': {
      width: 'auto',
      height: theme.spacing(5),
      padding: theme.spacing(1),
    },
    '& *': {
      position: 'relative',
      top: 0.5,
      fontWeight: 600,
    },
    '&.badge-appearing': {
      animation: `${pulseAnimation} 0.7s ease-in`,
      '&::after': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        border: '2px solid rgba(255,0,0,.5)',
        borderRadius: '50%',
        height: '100%',
        width: '100%',
        animation: `${sonarAnimation} 1.5s 1`,
        opacity: 1,
      },
    },
  },
}));

type Props = {
  count?: number | null;
};

const ChatBadge = (props: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const { count, className } = props;
  const { classes, cx } = useStyles();
  const previousCount = usePrevious(count);

  if (!count) return null;

  return (
    <div
      className={cx(
        className,
        'badge',
        classes.badge,
        previousCount === 0 && count === 1 && 'badge-appearing',
        count > 9 && 'more',
      )}
    >
      <span className="count">{count > 9 ? '9+' : count}</span>
    </div>
  );
};

export default React.memo(ChatBadge);
