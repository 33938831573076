import { actionsGeneratorV2 } from '@docavenue/core';

export const SET_OPEN = 'SET_OPEN';
export const CLEAR_EXPIRED = 'CLEAR_EXPIRED';
export const SET_SHARED_DOCUMENTS = 'SET_SHARED_DOCUMENTS';
export const CLEAR_SHARED_DOCUMENTS = 'CLEAR_SHARED_DOCUMENTS';
export const UPDATE_PATIENT_PINGS = 'UPDATE_PATIENT_PINGS';

export type SubscribersPing = {
  emitter: 'web' | 'mobile';
  // The subscriberId may be
  //  - a patientId in case of TLC in general
  //  - a practitionerId in case of VideoProToPro meeting
  subscriberId: string;
  videoSessionId: string;
  dateIsoString: string;
};

const videoSessionsCrud = actionsGeneratorV2({
  resource: 'videoSessions',
  chunkUrlResource: 'video-sessions',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

export const setOpenWaitingRoom = (isOpenWaitingRoom: boolean) => ({
  resource: 'videoSessions',
  type: SET_OPEN,
  payload: isOpenWaitingRoom,
});

const setSharedDocuments = (doc: Document[]) => {
  return {
    resource: 'videoSessions',
    type: SET_SHARED_DOCUMENTS,
    payload: doc,
  };
};

export const clearExpiredSession = () => ({
  resource: 'videoSessions',
  type: CLEAR_EXPIRED,
});

export const clearSharedDocuments = () => ({
  resource: 'videoSessions',
  type: CLEAR_SHARED_DOCUMENTS,
});

export const updateSubscribersPing = (payload: {
  videoSessionId: string;
  data: SubscribersPing | null;
}) => ({
  resource: 'videoSessions',
  type: UPDATE_PATIENT_PINGS,
  payload,
});

const videoSessionsActions = {
  ...videoSessionsCrud,
  setOpenWaitingRoom,
  clearExpiredSession,
  setSharedDocuments,
  clearSharedDocuments,
  updateSubscribersPing,
};

export default videoSessionsActions;
