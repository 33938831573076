import React, { Fragment } from 'react';
import Link, { LinkProps } from 'next/link';
import { cn, Typography, TypographyProps } from '@kiiwi/ui';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { useDatacyName } from '@docavenue/chat';
import {
  Patient,
  PatientGetDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { useTranslation } from '../../../i18n';
import { useConvertDateToAge } from '../../../src/hooks/dateFormat';
import { useIsTelesecretary } from '../../../src/hooks/selectors';
import {
  isKnownByPractitioner,
  isMaiiaPatient,
  maskSecurityNumber,
} from '../../../src/utils';
import FontAwesomeIcon from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Tooltip from '../../atoms/Tooltip';
import LgcConnect from '../../organisms/LgcConnect';
import ClickToCall from '../ClickToCall/ClickToCall';

import { LgcConnectProps } from '@/components/organisms/LgcConnect/LgcConnect';
import { SYNCHRO } from '@/src/constants';

export enum PatientLabelField {
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
  BIRTHNAME = 'BIRTHNAME',
  MERGE_ICON = 'MERGE_ICON',
  MERGE_ICON_ROUND = 'MERGE_ICON_ROUND',
  AGE = 'AGE',
  NAME = 'NAME',
  FULLNAME = 'FULLNAME',
  BIRTHDATE = 'BIRTHDATE',
  SECURITY_NUMBER = 'SECURITY_NUMBER',
  MOBILE_PHONE_NUMBER = 'MOBILE_PHONE_NUMBER',
  EMAIL = 'EMAIL',
  INTERNET_ICON = 'INTERNET_ICON',
  LGC_ICON = 'LGC_ICON',
  SYNC = 'SYNC',
  HEART_ICON = 'HEART_ICON',
  LOCK_ICON = 'LOCK_ICON',
  STAR_ICON = 'STAR_ICON',
  FULLNAME_AGE = 'FULLNAME_AGE',
  FULLNAME_BIRTHDATE = 'FULLNAME_BIRTHDATE',
}

const gridkeyMap: Record<PatientLabelField, string> = {
  [PatientLabelField.FIRSTNAME]: 'firstname',
  [PatientLabelField.LASTNAME]: 'lastname',
  [PatientLabelField.BIRTHNAME]: 'birthname',
  [PatientLabelField.MERGE_ICON]: 'merge-icon',
  [PatientLabelField.MERGE_ICON_ROUND]: 'merge-icon-round',
  [PatientLabelField.AGE]: 'age',
  [PatientLabelField.NAME]: 'name',
  [PatientLabelField.FULLNAME]: 'name',
  [PatientLabelField.BIRTHDATE]: 'birthdate',
  [PatientLabelField.SECURITY_NUMBER]: 'health-id',
  [PatientLabelField.MOBILE_PHONE_NUMBER]: 'mobile',
  [PatientLabelField.EMAIL]: 'email',
  [PatientLabelField.SYNC]: 'sync',
  [PatientLabelField.INTERNET_ICON]: 'internet-icon',
  [PatientLabelField.LGC_ICON]: 'lgc-icon',
  [PatientLabelField.HEART_ICON]: 'heart-icon',
  [PatientLabelField.LOCK_ICON]: 'lock-icon',
  [PatientLabelField.STAR_ICON]: 'star-icon',
  [PatientLabelField.FULLNAME_AGE]: 'fullname-age',
  [PatientLabelField.FULLNAME_BIRTHDATE]: 'fullname-birthdate',
  // STATISTICS_BADGE: 'statistics-badge',
};

export type PatientComplexLabelField = {
  name?: PatientLabelField;
  component?: React.ElementType | null;
  props?: any;
};

const getClassNames = (isBanned?: boolean, fieldName?: PatientLabelField) => {
  const isLineThrough =
    isBanned &&
    fieldName &&
    [
      PatientLabelField.FULLNAME,
      PatientLabelField.FULLNAME_AGE,
      PatientLabelField.FULLNAME_BIRTHDATE,
      PatientLabelField.NAME,
    ].includes(fieldName);

  return clsx(
    `patient-label${fieldName ? `-${gridkeyMap[fieldName]}` : ''}-component`,
    isBanned && 'banned',
    isBanned && 'text-alert-light-error',
    isLineThrough && 'line-through',
  );
};

type BasePatientLabelProps = {
  separator?: string;
  hideGridKey?: boolean;
  wrapperComponent?: React.ElementType;
  wrapperProps?: React.HTMLAttributes<
    BasePatientLabelProps['wrapperComponent']
  >;
  contentWrapper?: React.ElementType;
  contentWrapperProps?: React.HTMLAttributes<
    BasePatientLabelProps['contentWrapper']
  >;
};

type BasePatientLabelIconProps = BasePatientLabelProps & {
  className?: string;
};

type BaseTextPatientLabelProps<T extends object> = BasePatientLabelProps &
  Partial<TypographyProps> & {
    partialPatient: T;
    variant?: TypographyProps['variant'];
    className?: TypographyProps['className'];
  };

const BasePatientLabel = ({
  hideGridKey = true,
  wrapperComponent: WrapperComponent = 'div',
  wrapperProps,
  separator,
  children,
  isBanned,
  field,
}: BasePatientLabelProps & {
  children: React.ReactNode;
  isBanned?: boolean;
  field: PatientLabelField;
}) => {
  const datacyName = useDatacyName();
  const { className: wrapperClassName, ...restWrapperProps } =
    wrapperProps || {};
  const gridkey = (!hideGridKey && gridkeyMap[field]) || undefined;
  const defaultClassName = getClassNames(isBanned, field);
  return (
    <>
      <WrapperComponent
        gridkey={gridkey}
        className={cn(wrapperClassName, defaultClassName)}
        datacy={`${datacyName}-${field}`}
        {...restWrapperProps}
      >
        {children}
      </WrapperComponent>
      {separator}
    </>
  );
};

type PatientNameProps<T extends object> = BaseTextPatientLabelProps<T> & {
  displayFullName?: boolean;
  field:
    | PatientLabelField.FIRSTNAME
    | PatientLabelField.LASTNAME
    | PatientLabelField.BIRTHNAME
    | PatientLabelField.NAME
    | PatientLabelField.FULLNAME
    | PatientLabelField.FULLNAME_AGE
    | PatientLabelField.FULLNAME_BIRTHDATE;
  linkTo?: LinkProps['href'];
};

export const PatientName = <
  T extends Pick<
    Partial<Patient>,
    | 'firstName'
    | 'lastName'
    | 'birthName'
    | 'isBanned'
    | 'birthDate'
    | 'moonBirthDate'
  >
>({
  partialPatient: {
    firstName,
    lastName,
    birthName,
    isBanned,
    birthDate,
    moonBirthDate,
  },
  variant = 'body-1',
  className,
  linkTo,
  field,
  hideGridKey,
  separator,
  wrapperComponent,
  wrapperProps,
  ...restProps
}: PatientNameProps<T>) => {
  const datacyName = useDatacyName();
  const gridkey = (!hideGridKey && gridkeyMap[field]) || undefined;
  const defaultClassName = getClassNames(isBanned, field);
  const patientAge = useConvertDateToAge(birthDate);

  const patientBirthDate = birthDate
    ? dayjs(birthDate).format('DD/MM/YYYY')
    : moonBirthDate;
  const getLabel = () => {
    if (field === PatientLabelField.FIRSTNAME) return firstName;
    if (field === PatientLabelField.LASTNAME) return lastName;
    if (field === PatientLabelField.BIRTHNAME) return birthName;
    if (field === PatientLabelField.NAME) return `${firstName} ${lastName}`;
    if (field === PatientLabelField.FULLNAME_AGE)
      return `${firstName} ${lastName} ${
        birthName || patientAge
          ? `(${[birthName, patientAge].filter(Boolean).join(' ')})`
          : ''
      }`;
    if (field === PatientLabelField.FULLNAME_BIRTHDATE)
      return `${firstName} ${lastName} ${
        birthName || patientBirthDate
          ? `(${[birthName, patientBirthDate].filter(Boolean).join(' ')})`
          : ''
      }`;

    return `${firstName} ${lastName}${birthName ? ` (${birthName})` : ''}`;
  };
  const label = getLabel();

  const datacy =
    field === PatientLabelField.FULLNAME
      ? `${datacyName}-patient_name_${firstName?.toLowerCase()}_${lastName?.toLowerCase()}`
      : undefined;

  if (!label) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={field}
    >
      {linkTo ? (
        <Link datacy={datacy} href={linkTo} gridkey={gridkey}>
          <a>
            <Typography
              className={cn('text-action-active', defaultClassName, className)}
              variant={variant}
              {...restProps}
            >
              {label}
            </Typography>
          </a>
        </Link>
      ) : (
        <Typography
          className={cn(defaultClassName, className)}
          variant={variant}
          gridkey={gridkey}
          datacy={datacy}
          {...restProps}
        >
          {label}
        </Typography>
      )}
    </BasePatientLabel>
  );
};

/**
 * This component handles display of fields(
 *  PatientLabelField.FIRSTNAME, PatientLabelField.LASTNAME
 *  PatientLabelField.BIRTHNAME, PatientLabelField.FULLNAME
 *  PatientLabelField.FULLNAME_AGE, PatientLabelField.FULLNAME_BIRTHDATE)
 *  at once
 */
export const PatientNames = <
  T extends Pick<Patient, 'firstName' | 'lastName' | 'birthName' | 'isBanned'>
>({
  partialPatient,
  fieldsProps,
}: Pick<BaseTextPatientLabelProps<T>, 'partialPatient'> & {
  fieldsProps?: Array<Omit<PatientNameProps<T>, 'partialPatient'>>;
}) => {
  return fieldsProps?.map((fieldProps, index) => (
    <PatientName
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      partialPatient={partialPatient}
      {...fieldProps}
    />
  ));
};

export const PatientEmail = <
  T extends Pick<Partial<PatientGetDTO>, 'email' | 'isBanned'>
>({
  partialPatient: { email, isBanned },
  variant = 'body-1',
  className,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  wrapperComponent,
  wrapperProps,
  hideGridKey,
  separator,
  ...restProps
}: BaseTextPatientLabelProps<T>) => {
  const content = email;
  if (!content) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.EMAIL}
    >
      <Typography className={cn(className)} variant={variant} {...restProps}>
        <ContentWrapper {...contentWrapperProps}>{content}</ContentWrapper>
      </Typography>
    </BasePatientLabel>
  );
};

export const PatientBirthDate = <
  T extends Pick<
    Partial<PatientGetDTO>,
    'birthDate' | 'moonBirthDate' | 'isBanned'
  >
>({
  partialPatient: { birthDate, moonBirthDate, isBanned },
  variant = 'body-1',
  className,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  wrapperComponent,
  wrapperProps,
  hideGridKey,
  separator,
  ...restProps
}: BaseTextPatientLabelProps<T>) => {
  const content = birthDate
    ? dayjs(birthDate).format('DD/MM/YYYY')
    : moonBirthDate;
  if (!content) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.BIRTHDATE}
    >
      <Typography className={cn(className)} variant={variant} {...restProps}>
        <ContentWrapper {...contentWrapperProps}>{content}</ContentWrapper>
      </Typography>
    </BasePatientLabel>
  );
};

export const PatientAge = <
  T extends {
    birthDate?: Patient['birthDate'];
    isBanned?: Patient['isBanned'];
  }
>({
  partialPatient: { birthDate, isBanned },
  variant = 'body-1',
  className,
  isCalendarAppointmentInfo,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  wrapperComponent,
  wrapperProps,
  hideGridKey,
  separator,
  ...restProps
}: BaseTextPatientLabelProps<T> & {
  isCalendarAppointmentInfo?: boolean;
}) => {
  const patientAge = useConvertDateToAge(birthDate);

  if (!patientAge) return null;
  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.AGE}
    >
      <Typography className={cn(className)} variant={variant} {...restProps}>
        <ContentWrapper>
          {isCalendarAppointmentInfo && patientAge ? (
            <>&nbsp;({patientAge})</>
          ) : (
            patientAge
          )}
        </ContentWrapper>
      </Typography>
    </BasePatientLabel>
  );
};

export const PatientMobileNumber = <
  T extends Pick<Partial<PatientGetDTO>, 'mobilePhoneNumber' | 'isBanned'>
>({
  partialPatient: { mobilePhoneNumber, isBanned },
  variant = 'body-1',
  className,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  wrapperComponent,
  wrapperProps,
  hideGridKey,
  separator,
  ...restProps
}: BaseTextPatientLabelProps<T>) => {
  const isTls = useIsTelesecretary();
  const content = mobilePhoneNumber ?? '';

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.MOBILE_PHONE_NUMBER}
    >
      {isTls && <ClickToCall phoneNumber={content} isMobile />}
      <Typography className={cn(className)} variant={variant} {...restProps}>
        <ContentWrapper {...contentWrapperProps}>{content}</ContentWrapper>
      </Typography>
    </BasePatientLabel>
  );
};

export const PatientSecurityNumber = <
  T extends Pick<Partial<PatientGetDTO>, 'healthId' | 'isBanned'>
>({
  partialPatient: { healthId, isBanned },
  variant = 'body-1',
  className,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  wrapperComponent,
  wrapperProps,
  hideGridKey,
  separator,
  ...restProps
}: BaseTextPatientLabelProps<T>) => {
  const content = maskSecurityNumber(healthId || '');
  if (!content) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.SECURITY_NUMBER}
    >
      <Typography className={cn(className)} variant={variant} {...restProps}>
        <ContentWrapper {...contentWrapperProps}>{content}</ContentWrapper>
      </Typography>
    </BasePatientLabel>
  );
};

export const PatientLgcConnect = ({
  patient,
  hideGridKey,
}: LgcConnectProps & {
  hideGridKey?: boolean;
}) => {
  const gridkey =
    (!hideGridKey && gridkeyMap[PatientLabelField.SYNC]) || undefined;
  const defaultClassName = getClassNames(
    patient.isBanned,
    PatientLabelField.BIRTHDATE,
  );
  return (
    <LgcConnect
      gridkey={gridkey}
      patient={patient}
      className={cn('w-100 h-100 text-xs font-bold', defaultClassName)}
    />
  );
};

// #region Icons

export const KnownPatientIcon = <
  T extends Pick<PatientGetDTO, 'origin' | 'knownPractitioners' | 'isBanned'>
>({
  partialPatient: { origin, knownPractitioners, isBanned },
  practitionerId,
  contentWrapper: ContentWrapper = 'div',
  contentWrapperProps,
  wrapperComponent,
  wrapperProps,
  hideGridKey,
  separator,
  className,
}: BasePatientLabelIconProps & {
  partialPatient: T;
  practitionerId: string;
}) => {
  const { t } = useTranslation();

  if (!isKnownByPractitioner({ origin, knownPractitioners }, practitionerId))
    return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.STAR_ICON}
    >
      <Tooltip title={t('known_patient_indicator__tooltip')}>
        <ContentWrapper {...contentWrapperProps}>
          <FontAwesomeIcon
            className={cn('h-5 w-5', className)}
            name="star:regular"
          />
        </ContentWrapper>
      </Tooltip>
    </BasePatientLabel>
  );
};

export const BannedPatientIcon = ({
  isBanned,
  wrapperComponent,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  wrapperProps,
  hideGridKey,
  separator,
  className,
}: BasePatientLabelIconProps & {
  isBanned: boolean;
}) => {
  const { t } = useTranslation();

  if (!isBanned) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.LOCK_ICON}
    >
      <Tooltip title={t('banned_patient_indicator__tooltip')}>
        <ContentWrapper {...contentWrapperProps}>
          <FontAwesomeIcon
            className={cn('h-5 w-5', className)}
            name="lock-keyhole:regular"
          />
        </ContentWrapper>
      </Tooltip>
    </BasePatientLabel>
  );
};

export const InternetPatientIcon = <
  T extends Pick<PatientGetDTO, 'profileId' | 'isBanned'>
>({
  partialPatient,
  hideGridKey,
  wrapperComponent,
  wrapperProps,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  separator,
  className,
}: BasePatientLabelIconProps & {
  partialPatient: T;
}) => {
  const { t } = useTranslation();

  if (!isMaiiaPatient(partialPatient)) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={!!partialPatient.isBanned}
      field={PatientLabelField.INTERNET_ICON}
    >
      <Tooltip title={t('internet_patient_indicator__tooltip')}>
        <ContentWrapper {...contentWrapperProps}>
          <FontAwesomeIcon
            className={cn('h-5 w-5', className)}
            name="at:regular"
          />
        </ContentWrapper>
      </Tooltip>
    </BasePatientLabel>
  );
};

export const MergePatientIcon = ({
  isBanned,
  mergePatientCandidateIds,
  hideGridKey,
  wrapperComponent,
  wrapperProps,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  separator,
  className,
}: BasePatientLabelIconProps & {
  mergePatientCandidateIds?: string[];
  isBanned?: boolean;
}) => {
  const { t } = useTranslation();

  if (!mergePatientCandidateIds?.length) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={isBanned}
      field={PatientLabelField.MERGE_ICON}
    >
      <Tooltip title={t('merge_indicator__tooltip')}>
        <ContentWrapper {...contentWrapperProps}>
          <FontAwesomeIcon
            className={cn('h-5 w-5', className)}
            name="merge:regular"
          />
        </ContentWrapper>
      </Tooltip>
    </BasePatientLabel>
  );
};

export const LgcIcon = ({
  patient,
  hideGridKey,
  wrapperComponent,
  wrapperProps,
  contentWrapper: ContentWrapper = Fragment,
  contentWrapperProps,
  separator,
  className,
}: BasePatientLabelIconProps & {
  patient?: PatientGetDTO;
}) => {
  const { t } = useTranslation();

  if (patient?.origin !== SYNCHRO) return null;

  return (
    <BasePatientLabel
      wrapperComponent={wrapperComponent}
      wrapperProps={wrapperProps}
      hideGridKey={hideGridKey}
      separator={separator}
      isBanned={!!patient?.isBanned}
      field={PatientLabelField.MERGE_ICON}
    >
      <Tooltip title={t('medical_folder_ipp')}>
        <ContentWrapper {...contentWrapperProps}>
          <FontAwesomeIcon
            className={cn('h-5 w-5', className)}
            name="file:regular"
          />
        </ContentWrapper>
      </Tooltip>
    </BasePatientLabel>
  );
};
