import React from 'react';

import SvgIcon from '../atoms/SvgIcon';
import { IconProps } from './icons';

type Props = IconProps;

const BulletCheck = (props: Props) => (
  <SvgIcon viewBox="0 0 18 17" {...props}>
    <path
      d="M17.7435 8.31325C17.7435 10.5181 16.8088 12.6326 15.145 14.1916C13.4813 15.7506 11.2247 16.6265 8.87176 16.6265C6.51882 16.6265 4.26226 15.7506 2.59848 14.1916C0.934701 12.6326 0 10.5181 0 8.31325C0 6.10844 0.934701 3.99393 2.59848 2.4349C4.26226 0.875858 6.51882 0 8.87176 0C11.2247 0 13.4813 0.875858 15.145 2.4349C16.8088 3.99393 17.7435 6.10844 17.7435 8.31325V8.31325ZM13.3409 5.16461C13.2617 5.09064 13.1674 5.0324 13.0636 4.99337C12.9598 4.95434 12.8487 4.93533 12.7369 4.93746C12.6251 4.9396 12.5149 4.96284 12.4129 5.00579C12.3109 5.04875 12.2192 5.11054 12.1432 5.18747L8.29177 9.78574L5.97069 7.60974C5.81303 7.47208 5.60449 7.39713 5.38901 7.40069C5.17354 7.40425 4.96795 7.48605 4.81557 7.62884C4.66318 7.77164 4.57589 7.96428 4.57209 8.16619C4.56829 8.3681 4.64827 8.56351 4.79519 8.71125L7.72952 11.4619C7.80857 11.5358 7.9027 11.5941 8.0063 11.6332C8.1099 11.6723 8.22085 11.6915 8.33252 11.6895C8.44419 11.6876 8.5543 11.6646 8.65628 11.6219C8.75826 11.5792 8.85003 11.5177 8.9261 11.4411L13.3531 6.25572C13.504 6.10868 13.5874 5.91205 13.5853 5.70804C13.5833 5.50404 13.4959 5.30893 13.342 5.16461H13.3409Z"
      fill="#64C9C3"
    />
  </SvgIcon>
);

export default BulletCheck;
