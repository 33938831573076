import React, { forwardRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import HighlightableListItem from '../../atoms/HighlightableListItem';

// eslint-disable-next-line unused-imports/no-unused-vars-ts
const useStyles = makeStyles<ItemComponentProps>()((theme: any, _props) => ({
  itemComponentRoot: {
    fontSize: theme.size.font.sm,
  },
}));

export type ItemComponentProps = {
  highlighted?: boolean;
  datacy?: string;
  searchTerm?: string;
  children: any;
} & React.ComponentProps<typeof HighlightableListItem>;

const ItemComponent = forwardRef((props: ItemComponentProps, ref: any) => {
  const { classes, cx } = useStyles({
    ...props,
  });
  return (
    <HighlightableListItem
      className={cx(classes.itemComponentRoot, 'itemComponentRoot')}
      {...props}
      ref={ref}
    />
  );
});

ItemComponent.displayName = 'ItemComponent';

export default ItemComponent;
