/* eslint-disable import/prefer-default-export */
import Router from 'next/router';

import Routes from './constants/routes';
import { isNextRouteSafe, isSynchronizationNeeded } from './utils';

type LoginProps = { next: any; center: any; simplyVersion?: string | string[] };

export const login = ({ next, center, simplyVersion }: LoginProps) => {
  if (!process.env.STORYBOOK) {
    if (typeof window !== 'undefined') {
      let nextRoute: string = next;
      const appointmentIdInRoute = new RegExp('appointmentId').test(next);
      // const apptPatientIdInRoute = new RegExp('apptPatientId').test(next);
      if (appointmentIdInRoute) {
        nextRoute = `/create-appointment${next.substr(1)}`;
      }
      if (isSynchronizationNeeded(center, simplyVersion)) {
        if (simplyVersion === '4.1.0') {
          nextRoute = `${Routes.SIGNUP_LGC_SYNC}?version=${simplyVersion}`;
        } else {
          nextRoute = `${Routes.SIGNUP_LGC_SYNC}`;
        }
      }
      Router.push(isNextRouteSafe(nextRoute) ? nextRoute : '/');
    }
  }
};
