import { actionsGeneratorV2 } from '@docavenue/core';

export const SET_ALL_NOTIFICATIONS_AS_READ = 'SET_ALL_NOTIFICATIONS_AS_READ';

// type ActionNotification = Actions<NotificationsCenter> & {
//   acknowledge: () => DataAction<NotificationsCenter>;
//   product: (payload: ProductNews, options?: Object) => DataAction<ProductNews>;
//   setAllNotificationsAsRead: () => any;
// };

const notificationCentersActions = actionsGeneratorV2({
  resource: 'notificationCenters',
  chunkUrlResource: 'notification-centers',
});

const { create } = notificationCentersActions;

const setAllNotificationsAsRead = () => ({
  type: SET_ALL_NOTIFICATIONS_AS_READ,
  resource: 'notificationCenters',
});

const acknowledge = () =>
  create({} as any, {
    chunkResource: 'acknowledge',
  });

const productNotificationCentersActions = actionsGeneratorV2({
  resource: 'notificationCenters',
  chunkUrlResource: 'notification-centers',
  chunkResource: 'product',
});

const product = (payload, options = {}) =>
  productNotificationCentersActions.create(payload, {
    ...productNotificationCentersActions.common,
    ...options,
  });

export default {
  ...notificationCentersActions,
  setAllNotificationsAsRead,
  acknowledge,
  product,
};
