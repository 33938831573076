/* eslint-disable react/jsx-no-literals */
import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Box, SxProps } from '@mui/material';

import { useTranslation } from '../../../i18n';
import ActionButton from './ActionButton';

const styles: { [key: string]: SxProps } = {
  container: {
    px: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    gap: '28px',
  },
  iconButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
  },
};

interface MiniVideoSessionPendingDialogActionsProps {
  onReject: () => void;
  onWait: () => void;
  onStart: () => void;
}

const buttons = ['reject', 'wait', 'start'] as const;

const MiniVideoSessionPendingDialogActions = ({
  onReject,
  onWait,
  onStart,
}: MiniVideoSessionPendingDialogActionsProps) => {
  const [isHover, setIsHover] = useState<typeof buttons[number]>('start');
  const { t } = useTranslation();
  return (
    <Box sx={styles.container}>
      <ActionButton
        onEnter={() => setIsHover('reject')}
        onLeave={() => setIsHover('start')}
        onClick={onReject}
        isHover={isHover === 'reject'}
        label={t('common:miniVideoSessionPendingDialog_reject')}
        color="error.main"
        icon={
          <Box sx={styles.iconButtonWrapper}>
            <CloseIcon />
          </Box>
        }
      />
      <ActionButton
        onEnter={() => setIsHover('wait')}
        onLeave={() => setIsHover('start')}
        onClick={onWait}
        isHover={isHover === 'wait'}
        label={t('common:miniVideoSessionPendingDialog_wait')}
        color="secondary.main"
        icon={
          <Box sx={styles.iconButtonWrapper}>
            <HourglassEmptyIcon />
          </Box>
        }
      />
      <ActionButton
        label={t('common:miniVideoSessionPendingDialog_accept')}
        onEnter={() => setIsHover('start')}
        onLeave={() => setIsHover('start')}
        onClick={onStart}
        isHover={isHover === 'start'}
        color="primary.main"
        icon={
          <>
            <VideocamIcon />
            <Box
              sx={{
                height: 8,
                width: 8,
                backgroundColor: '#78D9B8',
                borderRadius: '16px',
              }}
            />
          </>
        }
      />
    </Box>
  );
};

export default MiniVideoSessionPendingDialogActions;
