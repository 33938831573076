import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import Routes from '../../../src/constants/routes';
import { ChatPractitionerInvitationNotificationCenter } from '../../../types/pro.types';
import PractitionerAvatar from '../../atoms/PractitionerAvatar';
import Typography from '../../atoms/Typography';
import NotificationWrapper from './NotificationWrapper';

import { ACCEPTED, CREATED } from '@/src/constants';
import { GAActions, GAActionStatus, GALabels } from '@/src/constants/tracking';

const useStyles = makeStyles()((theme: any) => ({
  notificationInvitationRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),

    '& .link': {
      ...theme.typography.blueLink,
      textDecoration: 'underline',
      fontSize: theme.size.font.sm,
    },
  },
}));

type NotificationPractitionerInvitationViewProps = {
  notification: ChatPractitionerInvitationNotificationCenter;
  closeModal: () => void;
  triggerGAEvent: Function;
};

const NotificationPractitionerInvitationView = (
  props: NotificationPractitionerInvitationViewProps,
) => {
  const { notification, closeModal, triggerGAEvent } = props;
  const { classes } = useStyles();
  const { t } = useTranslation('common');
  const router = useRouter();
  const { query } = router;

  const { displayData, event: status } = notification || {};
  const { cardHcdDTO } = displayData || {};
  const { professional = {}, publicInformation = {} } = cardHcdDTO || {};
  const { speciality: { isParamedical = true } = {} } = professional || {};

  const s3Id = publicInformation.mainPicture?.s3Id;
  const avatarUrl = s3Id && `/avatars/${s3Id}`;
  const civility = isParamedical ? '' : 'Dr. ';
  const name = `${civility}${professional.firstName} ${professional.lastName}`;
  const isValidStatus = [ACCEPTED, CREATED].includes(status);
  const description = useMemo(() => {
    if (status === CREATED)
      return t('common:notification_practitioner_invitation_sent', { name });
    if (status === ACCEPTED)
      return t('common:notification_practitioner_invitation_accepted', {
        name,
      });
  }, [status, name]);

  const handleChangeView = useCallback(() => {
    triggerGAEvent(
      GAActions.ClickNotification,
      GAActionStatus.SUCCESS,
      GALabels.NewsNotification,
    );
    router.push({
      pathname: Routes.CHAT_CREATE_INVITATIONS,
      query,
    });
    closeModal();
  }, []);

  if (!isValidStatus) return null;
  return (
    <NotificationWrapper notification={notification}>
      <div className={classes.notificationInvitationRoot}>
        <PractitionerAvatar src={avatarUrl} user={professional} />
        <div className="text">
          <Typography>{description}</Typography>
          <Typography onClick={handleChangeView} className="link">
            {t('common:notification_network_redirect')}
          </Typography>
        </div>
      </div>
    </NotificationWrapper>
  );
};

export default NotificationPractitionerInvitationView;
