import React, { forwardRef } from 'react';
import { Badge as MaterialBadge, BadgeProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Props = {
  color?: string;
  variant?: string;
} & BadgeProps;

const useStyles = makeStyles<{ color?: string }>({})((_theme, { color }) => {
  return {
    badge: {
      backgroundColor: color || 'initial',
    },
  };
});

const Badge = forwardRef(
  (props: Props, ref: React.Ref<HTMLDivElement>): JSX.Element => {
    const { color, variant, ...rest } = props;
    const { classes } = useStyles({ color });

    return (
      <MaterialBadge
        classes={{ badge: classes.badge }}
        variant={variant}
        {...rest}
        ref={ref}
      />
    );
  },
);

Badge.displayName = 'Badge';

export default Badge;
