import { DialogType, SET_DIALOG } from '../actions/dialog';

export type DialogState = {
  dialogType: DialogType | null;
  dialogProps: Object;
};

export const initialState: DialogState = {
  dialogType: null,
  dialogProps: {},
};

function dialog(
  state = initialState,
  action: {
    type: string;
    payload: { dialogType: DialogType; dialogProps: Object };
  },
) {
  const { type } = action;
  switch (type) {
    case SET_DIALOG: {
      const { dialogType, dialogProps } = action.payload;
      return { ...state, dialogType, dialogProps };
    }

    default:
      return state;
  }
}

export default dialog;
