import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';

import { useTranslation } from '../../../i18n';
import {
  exportStatisticsDocumentActions,
  snacksActions,
} from '../../../src/actions';
import { getDocumentUrl } from '../../../src/utils';
import SnackbarEvents from '../SnackbarEvents';

const ExportStatisticsDocumentHandler = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [loaderIsOpen, setLoaderIsOpen] = useState(false);
  const exportIsInProgress = useSelector(
    state => state.exportStatisticsDocument.isLoadingGeneration,
  );
  const exportedDocument = useSelector(
    state => state.exportStatisticsDocument.item,
  );
  useEffect(() => {
    if (exportIsInProgress) {
      setLoaderIsOpen(true);
    }
  }, [exportIsInProgress]);
  useEffect(() => {
    if (!exportIsInProgress && exportedDocument) {
      dispatch(
        snacksActions.enqueueSnack({
          message: t('exportstatisticsdocumenthandler_success_message'),
          variant: 'success',
          duration: null,
          actionNode: (
            <Link href={getDocumentUrl(exportedDocument)} target="_blank">
              {t('download')}
            </Link>
          ),
          onExited: () =>
            dispatch(exportStatisticsDocumentActions.setItem(null)),
        }),
      );
    }
  }, [exportIsInProgress]);
  if (exportIsInProgress && loaderIsOpen) {
    return (
      <SnackbarEvents
        variant="warning"
        isLoading
        text={t('exportstatisticsdocumenthandler_loading_message')}
        onClose={() => setLoaderIsOpen(false)}
      />
    );
  }
  return null;
};

export default ExportStatisticsDocumentHandler;
